import { useListboxContext } from '@reach/listbox';
import React, { useEffect } from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';

const SelectKeyboardScrollWrapper = ({ children }) => {
	const { isExpanded, selectedOptionRef, highlightedOptionRef } =
		useListboxContext();

	useEffect(() => {
		if (selectedOptionRef.current && isExpanded) {
			selectedOptionRef.current.scrollIntoView({ block: 'nearest' });
		}
	}, [isExpanded, selectedOptionRef]);

	useEffect(() => {
		const ensureHighlightedInView = () => {
			if (highlightedOptionRef.current) {
				highlightedOptionRef.current.scrollIntoView({ block: 'nearest' });
			}
		};

		window.addEventListener('keydown', ensureHighlightedInView);
		return () => {
			window.removeEventListener('keydown', ensureHighlightedInView);
		};
	}, [highlightedOptionRef]);

	return <>{children}</>;
};

SelectKeyboardScrollWrapper.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default SelectKeyboardScrollWrapper;

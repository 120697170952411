import styled from 'styled-components';

export const AlertWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	position: absolute;
	top: ${({ fixed }) => (fixed ? '25vh' : '4.5rem')};
	left: 0;
	z-index: 900 !important;
`;

export const AlertContent = styled.div`
	margin: 0 auto;
`;

import styled, { css } from 'styled-components';

// Import styles
import { flex, focusBasic } from 'components/styles';

export const Wrapper = styled.div`
	border-width: 1px;
	border-style: solid;
	border-color: ${({ theme, isError }) =>
		isError ? theme.red : theme.grayLight};

	border-radius: 0.6rem;
	overflow: hidden;
	${flex({ ai: 'flex-start' })}

	${({ theme, isFocused }) =>
		isFocused &&
		css`
			border-color: ${theme.primaryBlueLight};
			${focusBasic};
		`}

	&:hover {
		border-color: ${({ theme }) => theme.primaryBlueLight};
	}
`;

import React from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import icons
import { ReactComponent as InstitutionIcon } from 'assets/icons/ic_institution.svg';

// Import components
import { Button } from 'components/elements';

// Import paths
import { INSTITUTIONS_PATHS } from 'components/routes/paths';

const { INSTITUTIONS_LIST } = INSTITUTIONS_PATHS;

const Institution = () => {
	const { t } = useTranslation();

	const title = t('common_institution_title');

	return (
		<Tooltip placement="bottom" title={title}>
			<Button
				modifiers="navIcon"
				aria-label={title}
				as={Link}
				to={INSTITUTIONS_LIST}
			>
				<InstitutionIcon aria-hidden="true" title={title} />
			</Button>
		</Tooltip>
	);
};

export default Institution;

import styled, { css } from 'styled-components';
import { default as SlickSlider } from 'react-slick';

// Import styles
import { transitionMain, flex, focusBasic } from 'components/styles';

const borderAccent = css`
	border: 1px solid ${({ theme }) => theme.primaryBlueLight};
`;

export const PassSliderWrapper = styled.div`
	width: 100%;
	height: auto;
	--tileWidth: ${({ isMobile }) => (isMobile ? 28 : 38.7)}rem;
`;

export const PassSlider = styled(SlickSlider)`
	--items: ${({ toShow }) => toShow};
	.slick-slider {
		height: auto;
	}
	.slick-slide {
		width: var(--tileWidth) !important;
		margin: 0 1rem;
	}
	.slick-list {
		width: calc((var(--items) * var(--tileWidth)) + calc(var(--items) * 2rem));
	}
`;

export const PassesGrid = styled.ul`
	display: grid;
	width: 100%;
	grid-auto-flow: column;
	gap: 2rem;
	--tileWidth: ${({ isMobile }) => (isMobile ? 28 : 38.7)}rem;
`;

export const PassTileBody = styled.li`
	list-style: none;
	width: var(--tileWidth);
	padding: 4rem 1rem;
	${flex({ fd: 'column' })}
	background-color: ${({ theme }) => theme.darkBrown};
	border: 1px solid ${({ theme }) => theme.whiteTransparent015};
	border-radius: 0.4rem;
	color: ${({ theme }) => theme.white};

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.35;
			cursor: default;
			:focus-within {
				${borderAccent}
			}
		`}

	&:hover,
	&:focus,
	&:focus-within {
		${({ disabled }) =>
			!disabled &&
			css`
				cursor: pointer;
				${borderAccent}
			`}
	}

	h1 {
		color: ${({ theme }) => theme.white};
		font-size: ${({ theme }) => theme.fontSize.set('xs', 0.2)}rem;
		font-weight: ${({ theme }) => theme.font.medium};
		margin-bottom: 0.6rem;
	}
	h2 {
		color: ${({ theme }) => theme.secondaryGray};
		font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
		font-weight: ${({ theme }) => theme.font.normal};
		margin-bottom: 1.2rem;
	}
	${transitionMain}
`;

export const PassContent = styled.div`
	margin-bottom: 2rem;
	p {
		color: ${({ theme }) => theme.white};
		font-size: ${({ theme }) => theme.fontSize.set('md', 0.2)}rem;
		font-weight: ${({ theme }) => theme.font.medium};
	}
	.description {
		color: ${({ theme }) => theme.secondaryGray};
		font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
	}
`;

export const OldPrice = styled.del`
	margin: 0 0.5rem;
	color: ${({ theme }) => theme.yellow};
	font-size: ${({ theme }) => theme.fontSize.set('md', 0.2)}rem;
	font-weight: ${({ theme }) => theme.font.medium};
`;

export const RegularPrice = styled.p`
	margin: 0 0.5rem;
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) => theme.fontSize.set('md', 0.2)}rem;
	font-weight: ${({ theme }) => theme.font.medium};
`;

export const SkipButton = styled.div`
	width: ${({ isMobile }) => (isMobile ? 28 : 38.7)}rem;
	margin: 0 auto;
	margin-top: 3.4rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ArrowController = styled.button`
	position: absolute;
	z-index: 10;
	height: 100%;
	width: max-content;
	display: flex !important;
	justify-content: center;
	align-items: center;

	&:hover,
	&:focus {
		:not(.slick-disabled) {
			cursor: pointer;
			svg {
				path {
					fill: ${({ theme }) => theme.primaryBlueLight};
				}
			}
		}
	}

	&:focus-visible {
		${focusBasic}
	}

	&.slick-disabled {
		opacity: 0.25;
		cursor: default;
	}

	&::before {
		display: none !important;
	}

	${({ isPrevArrow }) =>
		isPrevArrow
			? css`
					left: -3rem;
			  `
			: css`
					right: -3rem;
			  `};
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

// Import components
import { AriaBaseModal, AriaModalContent } from 'components/elements';
import { CommonButton } from 'components/views/vod/components/buttons';
import PassTile from './PassTile';
import PassCarousel from './PassCarousel';
import { SkipButton, Content } from './styles';

// Import context
import usePassesContext from 'components/context/passes/usePassesContext';

const RecommendedPassModal = ({ isOpened, toggle, redirect }) => {
	const { t } = useTranslation();
	const { recommendedPasses, hasRecommendedPasses } = usePassesContext();
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

	const titleId = 'pass_purchase_modal_title';

	if (!isOpened || !hasRecommendedPasses) return null;
	return (
		<AriaBaseModal
			titleId={titleId}
			close={toggle}
			style={{ padding: '6.8rem 11.2rem', width: 'auto', marginTop: '10vh' }}
			centerModalContent={false}
		>
			<AriaModalContent
				title={t('pass_purchase_modal_title')}
				titleId={titleId}
			>
				<Content>
					<PassCarousel>
						{recommendedPasses?.map((product) => (
							<PassTile key={product?.uuid} {...product} />
						))}
					</PassCarousel>
					<SkipButton isMobile={isMobile}>
						<CommonButton as={Link} to={redirect} isPrime>
							{t('common_skip_label')}
						</CommonButton>
					</SkipButton>
				</Content>
			</AriaModalContent>
		</AriaBaseModal>
	);
};

RecommendedPassModal.propTypes = {
	redirect: PropTypes.object,
	isOpened: PropTypes.bool,
	toggle: PropTypes.func
};

export default RecommendedPassModal;

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import getSlug from 'speakingurl';

// Import components
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import helpers
import { getSectionPath } from 'helpers';
import { getActiveIndexes } from './helpers';
import { SLIDER_TYPES } from 'helpers/variables';

const { PRODUCTS } = APP_CORE_PATHS;
const { EPISODES } = SLIDER_TYPES;

const useSliderSection = ({
	id,
	name,
	isDesktop,
	type,
	slidesCount,
	slidesToShow
}) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const params = useParams();

	const pageName = params.name || PRODUCTS;
	const menuId = params.menuId || 1;
	const sectionName = name ? getSlug(name) : undefined;

	const sliderContentUrl = getSectionPath({
		menuId,
		pageName,
		sectionId: id,
		sectionName,
		institutionUuid: params.institutionUuid
	});

	const showAllButtonModifiers = isDesktop ? 'secondary' : 'mobile';

	const showHeader = !!name && type !== EPISODES;

	const activeIndexes = getActiveIndexes({
		currentSlide,
		slidesToShow,
		slidesCount
	});

	return {
		sliderContentUrl,
		showAllButtonModifiers,
		showHeader,
		activeIndexes,
		setCurrentSlide
	};
};

export default useSliderSection;

import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Import helpers
import { isNumber } from 'helpers/prices';
import { fetchSectionContent } from './helpers';
import { filterProductsWithVideoBanner } from 'helpers';

// Import api
import { QUERY_SECTION_CONTENT } from 'helpers/api';

// Import variables
import { PAGES_LABELS, SECTIONS_TYPES } from 'helpers/variables';

const { INSTITUTION, MAIN_WEB } = PAGES_LABELS;
const { VIDEO_BANNER } = SECTIONS_TYPES;

const useSectionsTemplate = ({ sections, label }) => {
	const { institutionUuid: products_group_uuid } = useParams();

	const queryKey = `${QUERY_SECTION_CONTENT}_${label}_${products_group_uuid}`;

	const queryFn = ({ pageParam: index }) =>
		fetchSectionContent({ sections, index, products_group_uuid });

	const getPreviousPageParam = () => false;

	const getNextPageParam = (prev) => {
		const prevIndex = prev?.index;

		if (isNumber(prevIndex)) {
			const nextIndex = prevIndex + 1;
			const isNextSection = nextIndex < sections.length;
			return isNextSection ? nextIndex : undefined;
		}

		return undefined;
	};

	const checkPageContentExists = (section) => {
		const isVideoBanner = section?.type === VIDEO_BANNER;

		const isContentExists = isVideoBanner
			? filterProductsWithVideoBanner(section?.content)?.length
			: section?.content?.length;

		return isContentExists;
	};

	const select = (data) => ({
		...data,
		pages: data?.pages.filter(checkPageContentExists)
	});

	const { data, fetchNextPage, hasNextPage, isLoading, remove } =
		useInfiniteQuery({
			queryKey,
			queryFn,
			getPreviousPageParam,
			getNextPageParam,
			select
		});

	useEffect(() => {
		return () => remove();
		//eslint-disable-next-line
	}, []);

	const sectionsData = data?.pages ?? [];

	const isContentFetched = sectionsData.length === sections.length;

	const hasContent = sectionsData.some((section) => section?.content?.length);

	const isInstitutionPage = label === INSTITUTION;
	const isHomePage = label === MAIN_WEB;

	const showContentMessage =
		isContentFetched && !hasContent && !isInstitutionPage && !isHomePage;

	return {
		fetchNextPage,
		hasNextPage,
		sectionsData,
		isLoading,
		isContentFetched,
		hasContent,
		showContentMessage
	};
};

export default useSectionsTemplate;

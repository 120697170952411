import React from 'react';
import { string, object, shape } from 'prop-types';

// Import styled components
import { StyledLink, ParagraphStyled, ProductType } from './styles';

// Import custom hook
import { useSearchItem } from './useSearchItem';

const SearchItem = ({ product }) => {
	const {
		productTypeAlias,
		isContentBlocked,
		placeholderRatingText,
		title,
		linkParams
	} = useSearchItem({ product });

	return (
		<StyledLink to={linkParams}>
			{isContentBlocked ? (
				<ParagraphStyled>{placeholderRatingText}</ParagraphStyled>
			) : (
				title && <ParagraphStyled>{title}</ParagraphStyled>
			)}
			<ProductType>{productTypeAlias}</ProductType>
		</StyledLink>
	);
};

SearchItem.propTypes = {
	product: shape({
		uuid: string.isRequired,
		images: object,
		screenshots: object,
		title: string.isRequired,
		type: string.isRequired
	}).isRequired
};

export default SearchItem;

import React, { useRef, useEffect } from 'react';
import { string } from 'prop-types';
import { Pannellum } from 'pannellum-react';

// Import helpers
import { graphics360Settings } from 'components/elements/organisms/product_gallery/helpers';

// Import styles
import { ImageContainer360 } from './styles';

const Graphics360 = ({ url }) => {
	const viewerRef = useRef();
	const contentRef = useRef();

	useEffect(() => {
		contentRef.current = viewerRef.current.panorama;
		return () => {
			contentRef.current.destroy();
		};
	}, []);

	return (
		<ImageContainer360>
			<Pannellum
				ref={viewerRef}
				image={url}
				preview={url} // Should be a resized, smaller image
				{...graphics360Settings}
			/>
		</ImageContainer360>
	);
};

Graphics360.propTypes = {
	url: string
};

export default React.memo(Graphics360);

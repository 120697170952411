import { useSelector, useDispatch } from 'react-redux';
import { signOut } from 'store/actions';

export const useAuthenticationButton = () => {
	const { isLogged } = useSelector(({ auth }) => auth);
	const dispatch = useDispatch();

	const handleSignOut = () => signOut()(dispatch);

	return { isLogged, signOut: handleSignOut };
};

import React from 'react';
import PropTypes from 'prop-types';

// Import component
import { ListBoxSelect, ListBoxOption } from 'components/elements';

// Import logic
import useGenresSelect from './useGenresSelect';

// Import helpers
import { getSelectedElementId } from 'helpers';

const GenresSelect = ({ selectValue }) => {
	const { selectChange, genresList } = useGenresSelect();

	const listId = 'genres_select';

	return (
		<ListBoxSelect
			currentValue={selectValue}
			listId={listId}
			changeHandler={selectChange}
			title="common_genres_title"
		>
			{({ handleMouseOptionSelect, handleKeyOptionEvents }) => (
				<>
					{genresList.map(({ id, name }) => {
						const isSelected = name === selectValue;
						const selectedId = getSelectedElementId({ isSelected, listId });

						return (
							<ListBoxOption
								key={id}
								id={selectedId}
								role="option"
								aria-selected={isSelected}
								data-value={name}
								tabIndex={0}
								onMouseUp={handleMouseOptionSelect}
								onKeyDown={handleKeyOptionEvents}
							>
								{name}
							</ListBoxOption>
						);
					})}
				</>
			)}
		</ListBoxSelect>
	);
};

GenresSelect.propTypes = {
	selectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default GenresSelect;

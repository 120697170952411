import styled, { css } from 'styled-components';

// Import styles
import { flex, transitionMain } from 'components/styles';

// Import utilties
import { customMinResponsive, size } from 'components/styles';

export const Wrapper = styled.div`
	${flex({ jc: 'space-between' })};
	background: ${({ theme }) => theme.body.header.bgOnMobile};
	min-height: 8rem;
	padding: 2.3rem 1.8rem;
	width: 100%;
	flex-wrap: ${({ theme: { isWCAG } }) => (isWCAG ? 'wrap' : 'nowrap')};

	${customMinResponsive(
		size.medium,
		css`
			background: ${({ theme: { body } }) => body.header.bgOnDesktop};
			align-items: start;
			padding: 1rem 4rem;
			min-height: ${({ theme: { isWCAG } }) => (isWCAG ? '10rem' : '8rem')};
		`
	)}

	${transitionMain}
`;

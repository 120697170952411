// Import variables
import { LOCAL_STORAGE_DISABILITY_SETTINGS } from 'helpers/variables';

export const saveLocalStorageData = ({ key, value }) => {
	const disabilityStorageSettings = JSON.parse(
		localStorage.getItem(LOCAL_STORAGE_DISABILITY_SETTINGS)
	);

	const disabilitySettings = {
		...disabilityStorageSettings,
		[key]: value
	};

	localStorage.setItem(
		LOCAL_STORAGE_DISABILITY_SETTINGS,
		JSON.stringify(disabilitySettings)
	);
};

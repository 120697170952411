import getSlug from 'speakingurl';

// Import routing paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { PAGE, SECTION, CATEGORIES, HOME_PAGE } = APP_CORE_PATHS;

const MODEL_TYPES = {
	PAGE: 'page',
	SECTION: 'section',
	GENRE: 'genre'
};

export const getLinkDetails = ({
	modelId,
	modelType,
	name,
	basePath = '',
	genresList = [],
	id
}) => {
	const isInternalLink = !!modelId && !!modelType;
	const title = getSlug(name);
	let path = '';

	switch (modelType) {
		case MODEL_TYPES.PAGE:
			path = `/${PAGE}/${id}/${title}`;
			break;
		case MODEL_TYPES.SECTION:
			path = `${basePath}/${SECTION}/${modelId}/${title}`;
			break;
		case MODEL_TYPES.GENRE:
			const corePath = `${basePath}/${CATEGORIES}`;
			const genres = genresList.find(({ id }) => id === +modelId);
			const slug = genres?.slug;
			const category = slug ? `?gatunek=${getSlug(slug)}` : '';
			path = `${corePath}${category}`;
			break;
		default:
			path = HOME_PAGE;
			break;
	}

	return { isInternalLink, path };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { getYear } from 'date-fns';
import VisuallyHidden from '@reach/visually-hidden';

// Import components
import { ProperName } from 'components/elements';

// Import styles
import { Container, StyledLink } from './styles';

// Import variables
import { EXTERNAL_LINKS } from 'helpers/variables';
import { LANGUAGES_TYPES } from 'helpers/variables';

const { EN } = LANGUAGES_TYPES;

const Copyrights = () => {
	const { t } = useTranslation();
	const currentYear = getYear(new Date());

	return (
		<Container>
			<p>
				{currentYear} {t('common_footer_copyrights')}
			</p>
			<p>
				{t('common_footer_system_origin')}{' '}
				<VisuallyHidden>
					<ProperName
						text={t('common_footer_system_origin_blueonline')}
						lang={EN}
					/>
				</VisuallyHidden>
				<StyledLink
					href={EXTERNAL_LINKS.BLUEONLINE}
					target="_blank"
					rel="noopener noreferrer"
					role="link"
				>
					<ProperName
						text={t('common_footer_system_origin_blueonline')}
						lang={EN}
					/>
					<VisuallyHidden>{t('aria_external_link_information')}</VisuallyHidden>
				</StyledLink>
			</p>
		</Container>
	);
};

export default Copyrights;

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FocusOn } from 'react-focus-on';

// Import components
import NavList from './NavList';
import NavSearch from './nav_search/NavSearch';
import NavTemplate from './nav_template';

// Import styles
import { NavStyled } from './styles';

// Import variables
import { SECTIONS_ID } from 'helpers/variables';

// Import utilities
import { useMenuToggle } from 'components/layout/nav/hooks';

const { NAVIGATION } = SECTIONS_ID;

const Nav = () => {
	const { t } = useTranslation();
	const { isOpen, toggle } = useMenuToggle();
	const searchRef = useRef(null);

	const focusDeactivation = () =>
		window.setTimeout(() => searchRef.current && searchRef.current.focus(), 0);

	return (
		<FocusOn
			enabled={isOpen}
			scrollLock={false}
			onDeactivation={focusDeactivation}
			onClickOutside={toggle}
			noIsolation
		>
			<NavTemplate>
				<NavStyled
					aria-label={t('aria_header_nav_label_visible')}
					id={NAVIGATION}
					tabIndex="-1"
				>
					{isOpen ? (
						<NavSearch toggle={toggle} isOpen={isOpen} />
					) : (
						<NavList searchRef={searchRef} toggle={toggle} />
					)}
				</NavStyled>
			</NavTemplate>
		</FocusOn>
	);
};

export default Nav;

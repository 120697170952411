import {
	ListboxButton,
	ListboxPopover,
	ListboxList,
	ListboxGroupLabel,
	ListboxGroup,
	ListboxInput,
	ListboxOption
} from '@reach/listbox';
import styled, { css } from 'styled-components';

import {
	customMinResponsive,
	size,
	customResponsive,
	focusBasic
} from 'components/styles';

const labelTransformBaseStyle = css`
	transform: scale(0.8);
	top: ${({ theme }) => (theme.isWCAG ? '-20px' : '-8px')};
	color: ${({ theme }) => theme.white};
	padding: 0 0.8rem;
`;

export const SelectLabel = styled.label`
	position: absolute;
	pointer-events: none;
	top: 15px;
	transform: translate(0, -50%) scale(1);
	transform: scale(1);
	transform-origin: top left;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	color: ${({ theme }) => theme.authentication.input.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	line-height: 1;
	left: 2rem;
	z-index: 1;
	&.filled {
		${labelTransformBaseStyle}
	}

	background-color: ${({ theme, backgroundColor }) =>
		backgroundColor
			? theme[backgroundColor]
			: theme.authentication.labelBgOnDesktop};

	${customMinResponsive(
		size.medium,
		css`
			background-color: ${({ theme, backgroundColor }) =>
				backgroundColor
					? theme[backgroundColor]
					: theme.authentication.labelBgOnDesktop};
		`
	)}
`;
export const StyledListboxButton = styled(ListboxButton)`
	border-radius: 4px;
	width: 100%;
	display: flex;
	height: auto;
	color: ${({ theme }) => theme.authentication.input.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	padding: 1.1rem 2rem;
	justify-content: space-between;

	${({ theme }) =>
		theme.isWCAG &&
		css`
			min-height: 8rem;
			height: fit-content !important;
		`}

	&:focus-visible {
		${focusBasic}
	}

	&.is-expanded,
	&:focus,
	&:hover {
		border-color: ${({ theme }) => theme.authentication.input.focusBorderColor};
	}

	span[data-reach-listbox-arrow=''] {
		font-size: 1rem;
	}

	${({ withLabel }) =>
		withLabel &&
		css`
			padding: 1.4rem 2rem;
			justify-content: flex-end;

			&.has-value {
				padding: 1.1rem 2rem;
				justify-content: space-between;
			}

			&.errors {
				border: 1px solid ${({ theme }) => theme.red};
			}
		`}
`;

export const StyledListboxList = styled(ListboxList)`
	margin: 0 !important;
	padding: 0 !important;
`;

export const SelectWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	&:focus-within label {
		${labelTransformBaseStyle}
	}
	input:-webkit-autofill + label {
		${labelTransformBaseStyle}
	}
`;

export const StyledListboxGroupLabel = styled(ListboxGroupLabel)`
	color: ${({ theme }) => theme.white};
	padding: 1.2rem 2.6rem;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
`;

export const StyledListboxGroup = styled(ListboxGroup)`
	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.gray};
	}
`;

export const StyledListboxInput = styled(ListboxInput)`
	width: 100%;
	position: relative;

	${customResponsive(
		size.medium,
		css`
			padding: 0 1.8rem;
		`
	)}

	${({ mediumWidth }) =>
		mediumWidth &&
		customMinResponsive(
			size.medium,
			css`
				width: ${({ theme }) => (theme.isWCAG ? '70%' : mediumWidth)};
			`
		)}
`;

export const StyledListboxPopover = styled(ListboxPopover)`
	z-index: 99999;
	background-color: ${({ theme }) => theme.blackBg};
	max-height: 250px;
	overflow-y: auto;
	border: 1px solid ${({ theme }) => theme.gray};
	border-radius: 4px;
	left: 0;
	right: 0;

	${({ withRWDMargin }) =>
		withRWDMargin &&
		customResponsive(
			size.medium,
			css`
				margin: 0 1.8rem;
			`
		)}

	&:focus-within {
		box-shadow: none;
		outline: none;
	}
	${({ fullWidth }) =>
		fullWidth &&
		css`
			width: 100%;
		`}
`;

export const StyledListboxOption = styled(ListboxOption)`
	white-space: normal;
	color: ${({ theme }) => theme.graySemiLight};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	padding: 1.2rem 2.6rem;
	cursor: pointer;
	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.gray};
	}
	&[data-reach-listbox-option=''][data-current-nav=''] {
		background: transparent;
		color: ${({ theme }) => theme.primaryBlueLight};
		border: 1px solid ${({ theme }) => theme.primaryBlueLight};
		outline: 0.2rem dashed transparent;
		outline-offset: -0.3rem;
	}
	&:focus-within {
		color: ${({ theme }) => theme.primaryBlueLight};
	}
	&:focus {
		outline: none;
	}
`;

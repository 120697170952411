// Import helpers
import { getArrayRange } from 'helpers';

export const checkIsActive = ({ activeIndexes, index }) =>
	activeIndexes.some((activeIndex) => activeIndex === index);

export const getActiveIndexes = ({
	currentSlide,
	slidesToShow,
	slidesCount
}) => {
	const isLastSlide = currentSlide + slidesToShow > slidesCount;
	if (isLastSlide) {
		return getArrayRange({
			start: slidesCount - slidesToShow,
			stop: slidesCount - 1
		});
	} else {
		return getArrayRange({
			start: currentSlide,
			stop: currentSlide + slidesToShow - 1
		});
	}
};

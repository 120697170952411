import { bool, string } from 'prop-types';

// Import helpres
import { PLAYER_SELECTORS } from 'helpers/variables';

// Import utilities
import { useAuth } from 'components/utilities';

// Import components
import { useFloatWidget } from 'components/elements';
import useLivePlayer from './useLivePlayer';
import useVodPlayer from './useVodPlayer';

const playerVodType = PLAYER_SELECTORS.VOD;

const useFloatPlayer = ({ playerType, ...rest }) => {
	const { isAuth } = useAuth();
	const floatMode = useFloatWidget();

	const isVodPlayer = playerType === playerVodType;
	const hookMode = isVodPlayer ? useVodPlayer : useLivePlayer;

	const playerModeProps = hookMode({ ...rest, playerType });

	return { isVodPlayer, floatMode, playerModeProps, isAuth };
};

useFloatPlayer.defaultProps = {
	isCatchup: false
};

useFloatPlayer.propTypes = {
	isCatchup: bool,
	playerType: string
};

export default useFloatPlayer;

import React from 'react';
import { string, number, func, shape, instanceOf, oneOfType } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { VisuallyHidden } from '@reach/visually-hidden';

// Import styled components
import {
	Wrapper,
	StyledLink,
	ProductTitle,
	TrashIconStyled,
	ButtonStyled
} from '../../common_styles.js';
import { StyledProductType, Time } from './styles';

// Import components
import ConfirmationDeletingModal from 'components/layout/nav/nav_toolbar/confirmation_deleting';
import { ProperContent } from 'components/elements';

// Import logic
import useReminder from './useReminder';

const Reminder = ({
	toggle,
	reminder,
	setDeletedReminderTitle,
	headingRef
}) => {
	const { t } = useTranslation();
	const {
		deleteReminder,
		programStartTime,
		link,
		productAlias,
		isModalVisible,
		toggleModalVisibility
	} = useReminder(reminder);
	const { title } = reminder;

	const handleReminderDelete = () => {
		setDeletedReminderTitle(title);
		deleteReminder();
		toggleModalVisibility();
		headingRef.current.focus();
	};

	return (
		<>
			{isModalVisible && (
				<ConfirmationDeletingModal
					title={t('common_reminders_deleting_confirmation_modal_title')}
					deleteFn={handleReminderDelete}
					toggle={toggleModalVisibility}
				/>
			)}

			<Wrapper>
				<StyledLink onClick={toggle} to={link}>
					{title && <ProductTitle>{title}</ProductTitle>}
					<StyledProductType>
						<ProperContent name={productAlias} />{' '}
						<Time>{programStartTime}</Time>
					</StyledProductType>
				</StyledLink>
				<ButtonStyled onClick={toggleModalVisibility}>
					<TrashIconStyled aria-hidden />
					<VisuallyHidden>{t('common_product_remove')}:</VisuallyHidden>
					<VisuallyHidden>{title}</VisuallyHidden>
				</ButtonStyled>
			</Wrapper>
		</>
	);
};

Reminder.propTypes = {
	toggle: func.isRequired,
	reminder: shape({
		title: string.isRequired,
		id: number.isRequired,
		program_start: instanceOf(Date).isRequired,
		product_uuid: string.isRequired,
		type: string.isRequired
	}),
	setDeletedReminderTitle: func.isRequired,
	headingRef: oneOfType([func, shape({ current: instanceOf(Element) })])
};

export default Reminder;

import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateWatchingInProgress,
	updateVideoSession,
	setPlayerReady
} from 'store/actions';
import throttle from 'lodash/throttle';
import * as workerTimers from 'worker-timers';

// import shaka-player
import shaka from 'shaka-player/dist/shaka-player.ui.js';

// Import helpers
import {
	INITIAL_STATE_PLAYER_VOD,
	TIME_THROTTLE,
	calculateVideoIntervalTime,
	getUserConfigVolume,
	setUserConfigVolume
} from '../helpers';
import {
	playerDeleteSyncRequest,
	SHAKA_PLAYER_ERRORS,
	AUTO_QUALITY_ITEM
} from 'helpers/player';
import { checkProductSubtype } from 'helpers';
import { getImage } from 'components/views/vod/helpers/getImage';

// styles and helpers
import {
	VideoContainer,
	ShakaVideo,
	fetchPlaylistData,
	removeShakaPlayerListeners,
	playerInitialization,
	setSupportError,
	setTrackingEvent,
	sendPlayerEvents,
	sendPreviousSessionTracking
} from '../shakaPlayer';

// Variables
import {
	TRACKING_EVENTS,
	PRODUCT_IMAGES_TYPES,
	TRACKING_INTERVAL_TIME
} from 'helpers/variables';

// Create context
export const ShakaPlayerContext = createContext();

const { SHAKA_PLAYER_ERROR_MESSAGE } = SHAKA_PLAYER_ERRORS;
const { COVER } = PRODUCT_IMAGES_TYPES;

class ShakaPlayerProvider extends React.Component {
	_isMounted = false;
	isPlayerReady = false;
	intervalUpdateWatchingProgress = null;
	intervalVideoSession = null;
	trackingInterval = null;
	streamStartDate = null;

	constructor() {
		super();
		this.state = INITIAL_STATE_PLAYER_VOD;
		this.timeThrottle = throttle(
			(time) => this.handleSetCurrentTime(time),
			TIME_THROTTLE
		);

		//Creating reference to store video component and video container
		this.videoComponentRef = React.createRef();
		this.videoContainerRef = React.createRef();

		// Shaka media
		this.video = null;
		this.videoContainer = null;
		this.player = null;
		this.castProxy = null;
		this.coverImage = '';

		this.playerTarget = null;
		this.videoTarget = null;
	}

	async componentDidMount() {
		const {
			playlist,
			isError,
			videoSessionId,
			isSiedebarVisible,
			configuration: { movieEndMargin },
			images
		} = this.props;

		this._isMounted = true;
		this.setState({ isSiedebarVisible, movieEndMargin });

		this.coverImage = getImage({ images, type: COVER });

		// send previous session tracking data
		sendPreviousSessionTracking();

		// Delete video sesion
		window.addEventListener('beforeunload', this.beforeunload, false);

		const playlistData = await fetchPlaylistData(playlist);

		if (videoSessionId && this._isMounted && !this.isPlayerReady && !isError) {
			// Install built-in polyfills to patch browser incompatibilities.
			shaka.polyfill.installAll();

			//Getting reference to video and video container on DOM
			this.video = this.videoComponentRef.current;
			this.videoContainer = this.videoContainerRef.current;

			if (shaka.Player.isBrowserSupported()) {
				playerInitialization.call(this, [shaka, playlistData]);
			} else {
				setSupportError.call(this);
			}
		} else if (isError && videoSessionId) {
			// If there is an error and video session is not equal to null
			// Turn on buffering
			this._isMounted && this.setState({ buffering: false });
		} else if (isError && !videoSessionId) {
			// If there is an error and video session is equal to null
			// Stop player
			this.playerInstanceDestroy();
			// Turn on buffering,Set player ready
			this._isMounted && this.setState({ buffering: false, isReady: false });
		}
	}

	componentWillUnmount() {
		// Update watching progress time
		this.handleFetchWatchingProgress();

		this._isMounted = false;

		// Clear intervals
		this.clearPlayerIntervals();

		const { isError, videoSessionId, type, deleteVideoSession } = this.props;
		// Check if player is not undefined / null, if player container exists, if is not any API errors

		if (videoSessionId) {
			// Dispatch an deleteVideoSession action in player folder
			deleteVideoSession({ type });
		}

		if (!isError && this.playerTarget && this.isPlayerReady) {
			// send tracking events
			setTrackingEvent.call(this, TRACKING_EVENTS.CLOSED);
			sendPlayerEvents.call(this);
			// remove event listeners
			removeShakaPlayerListeners.call(this);
			// Destroy the player
			this.playerInstanceDestroy();
			this.isPlayerReady = false;
		}
	}

	handleFetchWatchingProgress = (currentProgress = null) => {
		const { isPreview } = this.props;
		const { isReady } = this.state;
		const isAvaialbe =
			this._isMounted && isReady && this.videoTarget?.currentTime;

		if (isAvaialbe && !isPreview) {
			const currentTime = currentProgress || this.videoTarget.currentTime;
			const time = Math.floor(currentTime);
			this.props.updateWatchingInProgress({
				productUuid: this.props.productID,
				time
			});
		}
	};

	beforeunload = () => {
		const { videoSessionId } = this.props;
		// Destroy the player
		this.playerInstanceDestroy();
		// delete session
		playerDeleteSyncRequest(videoSessionId);
	};

	setFullScreen = () => {
		if (!document.fullscreenElement) {
			this.videoContainer.requestFullscreen();
			this.setState({ isFullScreen: true });
		} else {
			document.exitFullscreen();
			this.setState({ isFullScreen: false });
		}
	};

	play = () => {
		this.videoTarget.play();
		this.setState({ isPaused: false });
	};

	pause = () => {
		this.videoTarget.pause();
		this.setState({ isPaused: true });
	};

	updateTime = (time) => {
		this.videoTarget.currentTime = time;
		setTrackingEvent.call(this, TRACKING_EVENTS.SEEKING);
	};

	updateSeekTime = (next = true) => {
		const { seekSec } = this.state;
		const { currentTime, duration } = this.videoTarget;

		let time = next
			? parseInt(currentTime + seekSec, 10)
			: parseInt(currentTime - seekSec, 10);

		if (time > duration) {
			time = parseInt(duration, 10);
		} else if (time < 0) {
			time = 0;
		}

		this.updateTime(time);
		return time;
	};

	setLanguage = (lang) => {
		const { profiles, selectedProfileBandwidth } = this.state;
		this.setState({ selectedLanguage: lang });
		this.playerTarget.selectAudioLanguage(lang);

		// set quality profile after audio language change
		const videoProfile = profiles[lang].find(
			({ bandwidth }) => bandwidth === selectedProfileBandwidth
		);

		if (videoProfile) {
			const { id, bandwidth } = videoProfile;
			this.setProfile({ id, bandwidth, lang });
		}
	};

	// Set subtitle
	setSubtitle = (selectedSubtitle) => {
		if (selectedSubtitle === 'disabled') {
			this.playerTarget.setTextTrackVisibility(false);
		} else {
			this.playerTarget.setTextTrackVisibility(true);
			this.playerTarget.selectTextLanguage(selectedSubtitle);
		}

		this.setState({ selectedSubtitle });
	};

	// Set profile
	setProfile = ({ id, bandwidth, lang }) => {
		const { profiles, selectedLanguage } = this.state;
		const clearBuffer = true;
		let language = selectedLanguage;

		// after change language 'selectedLanguage' has an old value
		// if 'lang' param exist set its value to language variable
		if (lang) {
			language = lang;
		}

		if (id !== 'auto') {
			this.playerTarget.configure({ abr: { enabled: false } });
			const variant = profiles[language].find((variant) => variant.id === id);
			this.playerTarget.selectVariantTrack(variant, clearBuffer);
		} else {
			this.playerTarget.configure({ abr: { enabled: true } });
		}

		setTrackingEvent.call(this, TRACKING_EVENTS.TRACK_CHANGED);

		this.setState({
			selectedProfile: id,
			selectedProfileBandwidth: bandwidth
		});
	};

	// Mute or unmute player
	mute = () => {
		const { isMuted, volume } = this.state;
		const isVideoMuted = parseInt(volume) ? !isMuted : true;
		const options = { volume, isMuted: isVideoMuted };
		this.setState(options);
		setUserConfigVolume(options);
	};

	// Set current volume
	setVolume = (volume) => {
		this.videoTarget.volume = volume / 100;
		const isMuted = parseInt(volume) === 0;
		const options = { volume, isMuted };
		this.setState(options);
		setUserConfigVolume(options);
	};

	onReady = () => {
		const { type, setPlayerReady } = this.props;

		// Set player ready
		this.isPlayerReady = true;
		this.setState({ isReady: true });
		// Dispatch an setPlayerReady action in player folder
		setPlayerReady({ type });

		this.setPlayerIntervals();

		// Set player volume
		const { volume } = getUserConfigVolume();
		this.setVolume(volume);
	};

	setPlayerIntervals = () => {
		const { videoSession } = this.props.configuration;
		const { REACT_APP_WATCHING_IN_PROGRESS } = process.env;

		// Set watching in progress interval
		const watchingIntervalTime = parseInt(REACT_APP_WATCHING_IN_PROGRESS);
		this.intervalUpdateWatchingProgress = workerTimers.setInterval(
			this.handleFetchWatchingProgress,
			watchingIntervalTime
		);
		// Set video session interval
		const videoSessionInvervalTime = calculateVideoIntervalTime(videoSession);
		this.intervalVideoSession = workerTimers.setInterval(
			this.handleUpdateVideoSession,
			videoSessionInvervalTime
		);

		this.trackingInterval = workerTimers.setInterval(
			() => sendPlayerEvents.call(this),
			TRACKING_INTERVAL_TIME
		);
	};

	clearPlayerIntervals = () => {
		[
			this.intervalVideoSession,
			this.intervalUpdateWatchingProgress,
			this.trackingInterval
		].forEach((id) => id && workerTimers.clearInterval(id));
	};

	handleUpdateVideoSession = () => {
		const { videoSessionId, type, productID, updateVideoSession } = this.props;

		updateVideoSession({ videoSessionId, productID, type }, () => {
			// When is an api error
			// Destroy the player
			this.playerInstanceDestroy();
			// Turn on buffering
			this.setState({ buffering: false, isReady: false });
			// Clear intervals
			this.clearPlayerIntervals();
		});
	};

	playerInstanceDestroy = () => {
		this.player && this.player.destroy();
		this.castProxy && this.castProxy.destroy(true);
	};

	onPlay = () => {
		this.setState({ isPaused: false });
		setTrackingEvent.call(this, TRACKING_EVENTS.PLAYING);
	};

	onPause = () => {
		this.setState({ isPaused: true });
		setTrackingEvent.call(this, TRACKING_EVENTS.PAUSED);
	};

	onBufferStateChange = () => {
		const buffering = this.playerTarget.isBuffering();
		this.setState({ buffering });
		buffering && setTrackingEvent.call(this, TRACKING_EVENTS.BUFFERING);
	};

	onEnded = () => {
		this.setState({ isEnded: true, isPaused: true });
		setTrackingEvent.call(this, TRACKING_EVENTS.COMPLETE);
	};

	onAutoQualityChange = () =>
		setTrackingEvent.call(this, TRACKING_EVENTS.TRACK_CHANGED);

	retryPlaying = () => {
		this.player.retryStreaming();
	};

	onErrorEvent = (event) => {
		// Extract the shaka.util.Error object from the event.
		this.onError(event.detail);
	};

	onError = (error) => {
		const isOnline = navigator.onLine;

		if (!isOnline) return;

		if (this && this.props) {
			const { setPlayerPlaybackError, type, isPreview, productID } = this.props;

			setTrackingEvent.call(this, TRACKING_EVENTS.ERROR);

			// Catch only known errors which should be handled in UI
			if (error && error.code && isOnline) {
				this.setState({ isReady: false, buffering: false });
				// Destroy the player
				this.playerInstanceDestroy();
				// Set error message
				error.message = SHAKA_PLAYER_ERROR_MESSAGE;
				// Dispatch an setPlayerPlaybackError action in player folder
				const uuid = isPreview ? null : productID;
				setPlayerPlaybackError(error, type, uuid);
				// Clear intervals
				this.clearPlayerIntervals();
			}
		}
	};

	onFullScreenChange = () => {
		const isFullScreen = !!document.fullscreenElement;
		this.setState({ isFullScreen });
	};

	getDuration = () => {
		const { isReady, duration } = this.state;

		const isAvailable = this._isMounted && isReady && this.video && duration;
		return isAvailable ? this.video.duration : null;
	};

	handleSetCurrentTime = (time) => {
		const { isReady } = this.state;
		const isAvaialbe = this._isMounted && this.video && isReady;
		// Get duration
		if (isAvaialbe) {
			const duration = this.getDuration();
			if (duration && this.state.duration !== duration) {
				this.setState({ duration });
			}

			// Set end margin action
			const { endMarginAction, movieEndMargin } = this.state;
			const endMargin = duration - movieEndMargin;

			if (duration && time > endMargin && !endMarginAction) {
				this.setState({ endMarginAction: true });
			} else if (duration && time < endMargin && endMarginAction) {
				this.setState({ endMarginAction: false });
			}
		}
	};

	currentTimeProgress = (callback) =>
		this.video.addEventListener('timeupdate', () =>
			callback(this.video.currentTime)
		);

	restart = () => {
		this.video.currentTime = 0;
		this.setState({ isEnded: false });
	};

	onTimeUpdate = () => this.timeThrottle(this.video.currentTime);

	setEndMargin = (value = true) => {
		this.setState({ endMarginAction: value, endMarginActionCancel: value });
	};

	cast = () => {
		const { isCasting } = this.state;
		isCasting ? this.castProxy.suggestDisconnect() : this.castProxy.cast();
	};

	onCastStatusChanged = ({ target }) => {
		const receiverName = target.receiverName();
		const isCasting = target.isCasting();
		const isCastAvailable = target.canCast();
		this.setProfile(AUTO_QUALITY_ITEM);
		this.setState({ receiverName, isCasting, isCastAvailable });
	};

	render() {
		const {
			closePreview,
			isPreview,
			isSafari,
			isFocus,
			children,
			productID,
			title,
			configuration: { videoId },
			floatMode,
			isIphone,
			productType,
			autoplay
		} = this.props;

		const { isReady, isMuted, movieSubtitles } = this.state;

		const { isLiveEvent } = checkProductSubtype(productType);

		return (
			<ShakaPlayerContext.Provider
				value={{
					...this.state,
					isPreview,
					isSafari,
					closePreview,
					setVolume: this.setVolume,
					setSubtitle: this.setSubtitle,
					setLanguage: this.setLanguage,
					setProfile: this.setProfile,
					mute: this.mute,
					updateTime: this.updateTime,
					updateSeekTime: this.updateSeekTime,
					setFullScreen: this.setFullScreen,
					play: this.play,
					pause: this.pause,
					restart: this.restart,
					currentTimeProgress: this.currentTimeProgress,
					setEndMargin: this.setEndMargin,
					cast: this.cast,
					productID,
					videoId,
					video: this.video,
					floatMode,
					isLiveEvent,
					castProxy: this.castProxy,
					videoTarget: this.videoTarget
				}}
			>
				<VideoContainer
					className="video-container"
					ref={this.videoContainerRef}
					isReady={isReady}
					isFocus={isFocus}
					isIphone={isIphone}
				>
					<ShakaVideo
						className="shaka-video"
						id="shaka-video"
						ref={this.videoComponentRef}
						muted={isMuted}
						alt={title}
						autoPlay={autoplay}
						poster={this.coverImage}
						crossOrigin="true"
					>
						{isSafari &&
							movieSubtitles.map(({ id, language, url }) => (
								<track
									key={id}
									label="Subtitles"
									kind="subtitles"
									srcLang={language}
									src={url}
									default=""
								/>
							))}
					</ShakaVideo>
					{children}
				</VideoContainer>
			</ShakaPlayerContext.Provider>
		);
	}
}

ShakaPlayerProvider.defaultProps = {
	isSiedebarVisible: false,
	isSafari: false,
	floatMode: { isPlayerOpen: false, isFloatMode: false, isPageLoaded: false },
	autoplay: true,
	isIphone: false
};

ShakaPlayerProvider.propTypes = {
	isSiedebarVisible: PropTypes.bool,
	playlist: PropTypes.string.isRequired,
	isError: PropTypes.bool.isRequired,
	videoSessionId: PropTypes.string,
	deleteVideoSession: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	productID: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	configuration: PropTypes.object.isRequired,
	closePreview: PropTypes.func.isRequired,
	isPreview: PropTypes.bool.isRequired,
	isSafari: PropTypes.bool.isRequired,
	isFocus: PropTypes.bool.isRequired,
	autoplay: PropTypes.bool.isRequired,
	updateWatchingInProgress: PropTypes.func.isRequired,
	setPlayerReady: PropTypes.func.isRequired,
	updateVideoSession: PropTypes.func.isRequired,
	setPlayerPlaybackError: PropTypes.func.isRequired,
	continueWatchingTime: PropTypes.number,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	floatMode: PropTypes.object,
	isIphone: PropTypes.bool.isRequired,
	productType: PropTypes.string.isRequired,
	images: PropTypes.object
};

const mapStateToProps = (state, { type }) => {
	const { continue_watching } = state;

	const {
		playlist,
		configuration,
		error: { isError },
		videoSessionId,
		details: { data }
	} = state[type];

	const continueWatchingTime = continue_watching?.continueProgress?.time;

	return {
		playlist,
		productID: data?.uuid,
		title: data?.metadata?.title,
		isError,
		configuration,
		videoSessionId,
		continueWatchingTime,
		productType: data?.type,
		images: data?.images
	};
};

export default connect(mapStateToProps, {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateWatchingInProgress,
	updateVideoSession,
	setPlayerReady
})(ShakaPlayerProvider);

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { REGISTER, LOGIN, PASSWORD_RESET, EMAIL_CONFIRMATION } = APP_CORE_PATHS;

export const LOGIN_ROUTES = [
	`/${REGISTER}`,
	`/${LOGIN}`,
	`/${PASSWORD_RESET}`,
	`/${EMAIL_CONFIRMATION}`
];

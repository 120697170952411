import _ from 'lodash';
import i18n from 'i18next';

// Import variables
import { PRODUCT_IMAGES_TYPES, EXTERNAL_LINKS } from 'helpers/variables';

// Import assets
import ckfLogo from 'assets/icons/logo_ckf.svg';

// API-URL
const { REACT_APP_API_URL } = process.env;

const { COVER, LOGO } = PRODUCT_IMAGES_TYPES;
const { CKF_WEBSITE } = EXTERNAL_LINKS;

const DEFAULT_INSTITUTION = {
	logoUrl: ckfLogo,
	website: CKF_WEBSITE,
	name: i18n.t('common_full_app_name')
};

export const getInstitutionAssets = ({ assets, uuid = '' }) => {
	const coverAsset = assets?.find(({ collection }) => collection === COVER);
	const logoAsset = assets?.find(({ collection }) => collection === LOGO);

	const coverUrl = coverAsset?.url || '';
	const logoUrl = logoAsset?.url || `${REACT_APP_API_URL}/assets/${uuid}/logo`;

	return { coverUrl, logoUrl };
};

export const getInstitutionData = (institution) => {
	if (institution?.uuid) {
		const { uuid, url: website, name, assets } = institution;
		const { logoUrl } = getInstitutionAssets({ assets, uuid });
		return { logoUrl, website, name };
	}

	return DEFAULT_INSTITUTION;
};

export const sortInstitutions = (list) => {
	if (list?.length) {
		const mainInstitution = list.find(({ is_main }) => !!is_main);

		const filteredInstitutions = list.filter(
			({ uuid }) => uuid !== mainInstitution?.uuid
		);

		const sortedInstitutions = _.sortBy(filteredInstitutions, 'name');

		const institutions = mainInstitution
			? [mainInstitution, ...sortedInstitutions]
			: sortedInstitutions;

		return institutions;
	}

	return [];
};

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import Gallery from './components/Gallery';
import { Heading } from 'components/elements';

// Import styles
import { GalleriesGrid } from './styles';

// Import logic
import { useProductGalleries } from './hooks';

const ProductGalleries = () => {
	const { t } = useTranslation();
	const { galleries, showGalleries, isArticle } = useProductGalleries();

	if (!showGalleries) return null;
	return (
		<GalleriesGrid isArticle={isArticle}>
			<Heading.Second modifiers="hidden">
				{t('vod_movieDetails_gallery_heading')}
			</Heading.Second>
			{galleries?.map(({ id, assets, metadata, type }) => (
				<Gallery
					key={id}
					assets={assets}
					metadata={metadata}
					type={type}
					id={id}
				/>
			))}
		</GalleriesGrid>
	);
};

export default ProductGalleries;

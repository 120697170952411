import React from 'react';
import { string } from 'prop-types';

// Import components
import { PlayerElements } from 'components/elements';

// Import logic
import useEpisodeController from './useEpisodeController';

// Import styles
import { StyledButton } from './styles';

const EpisodeController = ({ title, type }) => {
	const { isPrevious, episodeUuid, playEpisode } = useEpisodeController(type);

	return (
		<StyledButton
			disabled={!episodeUuid}
			onClick={playEpisode}
			modifiers={['icon', 'playerIconHover', 'playerEpisode']}
			title={title}
			aria-label={title}
		>
			{isPrevious ? (
				<PlayerElements.IconPrevious />
			) : (
				<PlayerElements.IconNext />
			)}
		</StyledButton>
	);
};

EpisodeController.defaultProps = {
	title: '',
	type: ''
};

EpisodeController.propTypes = {
	title: string.isRequired,
	type: string.isRequired
};

export default EpisodeController;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Import components
import { PassContent, PassTileBody } from './styles';
import { CommonButton } from 'components/views/vod/components/buttons';
import PassPrice from './PassPrice';

// Import helpers
import usePassTile from './hooks/usePassTile';

const PassTile = (props) => {
	const {
		title,
		subtitle,
		value,
		description,
		to,
		buttonText,
		isPassAvailable,
		usage_limit,
		handleButtonClick,
		disabled
	} = usePassTile(props);

	return (
		<PassTileBody disabled={disabled}>
			<h1>{title}</h1>
			<h2>{subtitle}</h2>
			<PassContent>
				{isPassAvailable ? (
					<p>{value}</p>
				) : (
					<PassPrice product={props} usage_limit={usage_limit || 1} /> // Prevent usage_limit=0
				)}
				<p className="description">{description}</p>
			</PassContent>
			<CommonButton
				as={Link}
				to={to}
				isPrime={isPassAvailable}
				onClick={handleButtonClick}
				disabled={disabled}
				tabIndex={disabled ? '-1' : '1'}
			>
				{buttonText}
			</CommonButton>
		</PassTileBody>
	);
};

PassTile.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	usage_limit: PropTypes.number,
	current_limit: PropTypes.number,
	prices: PropTypes.object,
	prices_by_payment_method: PropTypes.object,
	isPassAvailable: PropTypes.bool,
	context: PropTypes.object
};

export default PassTile;

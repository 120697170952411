import React from 'react';
import {
	number,
	func,
	bool,
	string,
	object,
	oneOfType,
	arrayOf,
	node,
	array,
	shape
} from 'prop-types';

// Import components
import { Spacer, AvailabilityInfoHidden } from 'components/elements';
import SlideImage from './SlideImage';

// Import styles
import {
	StyledButton,
	Title,
	Description,
	IconTickCircle
} from 'components/elements/molecules/slider/SliderItem/styles';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';
import { getTileMetadata, getRenderAsLink } from 'helpers';

// Import utilities
import {
	useProductAvailability,
	useProductTranslations
} from 'components/utilities';

// Import variables
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';

const { COVER } = PRODUCT_IMAGES_TYPES;

const BasicSlide = ({
	slideWidth,
	slideHeight,
	spaceBetweenSlides,
	onItemClick,
	renderSlideAsLink,
	index,
	product,
	description,
	linkParams,
	isActive
}) => {
	const { uuid, available_in, subtype, images, context } = product;
	const image = getImage({ images, type: COVER });

	const { title } = useProductTranslations(product);
	const metadata = getTileMetadata(context);

	const renderAs = getRenderAsLink(renderSlideAsLink);
	const handleItemClick = () => onItemClick(index);
	const isAvailable = useProductAvailability(uuid, available_in, subtype);

	return (
		<StyledButton
			as={renderAs}
			to={linkParams}
			onClick={handleItemClick}
			marginright={spaceBetweenSlides}
			width={slideWidth}
			height={slideHeight}
			tabIndex={isActive ? null : '-1'}
		>
			{isAvailable && <IconTickCircle aria-hidden role="img" />}
			<SlideImage slideHeight={slideHeight} src={image} />
			{title && (
				<Title className="slide__title">
					<Spacer elements={[metadata, title]} />
					<AvailabilityInfoHidden product={product} />
				</Title>
			)}
			{description && (
				<Description className="slide__description">
					<Spacer elements={description} />
				</Description>
			)}
		</StyledButton>
	);
};

BasicSlide.defaultProps = {
	onItemClick: () => {},
	linkParams: '/',
	renderSlideAsLink: false
};

BasicSlide.propTypes = {
	slideWidth: number.isRequired,
	slideHeight: number.isRequired,
	spaceBetweenSlides: number.isRequired,
	onItemClick: func.isRequired,
	renderSlideAsLink: bool,
	index: number.isRequired,
	linkParams: object.isRequired,
	description: oneOfType([arrayOf(node), node, string]),
	slideType: string,
	product: shape({
		uuid: string.isRequired,
		available_in: array,
		subtype: string.isRequired,
		images: object,
		title: string.isRequired
	}),
	isActive: bool
};

export default BasicSlide;

import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { focusBasic } from 'components/styles';

const INPUT_MODIFIERS = {
	voucher: ({ theme }) => css`
		height: 4.4rem;
		width: 20rem;
		color: ${theme.grayLight};
		font-size: ${theme.fontSize.set('xs', -0.2)}rem;
		padding: 1rem 2rem;
		border: none;
		border-radius: 0;
	`
};
const baseInputStyles = css`
	width: 100%;
	height: 4rem;
	padding: 10px 12px;
	background-color: transparent;
	border: 1px solid ${({ theme }) => theme.grayLight};
	border-radius: 4px;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	line-height: 1.28;
	color: ${({ theme }) => theme.grayLight};

	&:focus-visible {
		border-color: ${({ theme }) => theme.primaryBlueLight};
		${focusBasic}
	}

	::-webkit-input-placeholder {
		color: ${({ theme }) => theme.grayLight};
	}

	&.errors {
		border: 1px solid ${({ theme }) => theme.red};
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.authentication.input.color};
		transition: background-color 5000s ease-in-out 0s;
	}

	${applyStyleModifiers(INPUT_MODIFIERS)};
`;

const Input = styled.input`
	${baseInputStyles}
`;

const Textarea = styled.textarea`
	${baseInputStyles}
`;

const Select = styled.select`
	${baseInputStyles}
`;

export { Input, Textarea, Select, baseInputStyles };

import React from 'react';
import { number, string } from 'prop-types';
import { useSelector } from 'react-redux';

// Import styles
import {
	Figure,
	Image
} from 'components/elements/molecules/slider/SliderItem/styles';

// Import utilities
import { ImageOnLoadRPC } from 'components/utilities';

const SlideImage = ({ slideHeight, src, alt }) => {
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);
	return (
		<Figure slideHeight={slideHeight} aria-hidden>
			<ImageOnLoadRPC>
				{({ setError }) => (
					<Image
						isContrast={isContrast}
						onError={setError}
						src={src}
						isImageUrl={src}
						alt={alt || ''}
					/>
				)}
			</ImageOnLoadRPC>
		</Figure>
	);
};

SlideImage.defaultProps = {
	alt: ''
};

SlideImage.propTypes = {
	slideHeight: number.isRequired,
	src: string.isRequired,
	alt: string
};

export default SlideImage;

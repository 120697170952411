import styled, { css } from 'styled-components';

// Import utilities
import { flex } from 'components/styles';

//Import styles
import { customMinResponsive, size } from 'components/styles';

export const AuthenticationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: ${({ theme: { body } }) => body.bg.primary};
	${customMinResponsive(
		size.medium,
		css`
			flex-direction: row;
			background-color: ${({ theme: { body } }) => body.bg.secondary};
		`
	)}
`;

export const LogoContainer = styled.div`
	display: none;
	${customMinResponsive(
		size.medium,
		css`
			flex: 1 1 50%;
			max-width: ${({ theme }) => (theme.isWCAG ? 30 : 50)}%;
			${flex()};
			background: ${({ background }) => `url(${background}) top center`};
			background-size: cover;
			background-repeat: no-repeat;
			padding: 0 3rem;
			margin-bottom: 0;
		`
	)}
`;

export const StyledLogo = styled.img`
	filter: ${({ isContrast }) => (isContrast ? 'grayscale(1)' : 'grayscale(0)')};

	${({ theme }) =>
		theme.isWCAG &&
		css`
			max-width: 100%;
		`}
`;

export const AuthenticationContainer = styled.div`
	width: 100%;
	${customMinResponsive(
		size.medium,
		css`
			flex: 1 1 50%;
			max-width: ${({ theme }) => (theme.isWCAG ? 70 : 50)}%;
			${flex()}
		`
	)}
`;

export const AuthenticationBox = styled.div`
	width: 100%;
	margin: 0 auto;
`;

import { parseFontSize } from 'helpers';

const FONT_SIZE_KEYS = { xs: 'xs', s: 's', md: 'md', lg: 'lg', xl: 'xl' };

const { xs, s, md, lg, xl } = FONT_SIZE_KEYS;

const FONT_SIZE_TYPES = { [xs]: 1.6, [s]: 2, [md]: 2.4, [lg]: 3.6, [xl]: 4.8 };

const setSize =
	(size) =>
	(type = xs, num = 0) => {
		if (!FONT_SIZE_KEYS[type]) {
			throw new Error('Invalid font size type in setSize theme function');
		}

		const numSize = FONT_SIZE_TYPES[type] + num;
		const fontSize = numSize * size;
		const parsedFontSize = parseFontSize(fontSize);
		return Number(parsedFontSize.toFixed(1));
	};

export const setFontSize = (size = 1) => ({
	fontSize: {
		set: setSize(size),
		// Below are the default font size properties
		// In some cases in the app there are custom font sizes mixed with defaults
		xs: parseFontSize(FONT_SIZE_TYPES[xs] * size),
		s: parseFontSize(FONT_SIZE_TYPES[s] * size),
		md: parseFontSize(FONT_SIZE_TYPES[md] * size),
		lg: parseFontSize(FONT_SIZE_TYPES[lg] * size),
		xl: parseFontSize(FONT_SIZE_TYPES[xl] * size),

		product: {
			media: {
				title: parseFontSize(2.6 * size),
				description: parseFontSize(1.4 * size),
				productType: parseFontSize(0.8 * size),
				availabilityDate: parseFontSize(1 * size)
			}
		},
		wcagNav: {
			text: parseFontSize(1.3 * size),
			media: { text: parseFontSize(1.2 * size) }
		},
		nav: {
			link: parseFontSize(1.8 * size),
			logOutBtn: parseFontSize(1.4 * size)
		},
		footer: {
			media: {
				link: parseFontSize(1.4 * size),
				text: parseFontSize(1.2 * size)
			}
		},
		productDetails: {
			media: { infoItemText: parseFontSize(1.2 * size) },
			link: parseFontSize(1.8 * size),
			sectionDescription: { text: parseFontSize(1.4 * size) },
			article: {
				author: parseFontSize(1.8 * size),
				media: { author: parseFontSize(1.2 * size) }
			},
			statute: {
				text: parseFontSize(1.6 * size),
				link: parseFontSize(1.6 * size)
			}
		},
		user: {
			logOutBtn: parseFontSize(1.9 * size),
			nav: { link: parseFontSize(1.8 * size) },
			media: { heading: parseFontSize(2.2 * size) },
			heading: parseFontSize(2.4 * size),
			general: {
				base: {
					media: { label: parseFontSize(1.4 * size) },
					label: parseFontSize(1.8 * size)
				}
			},
			table: {
				header: parseFontSize(1.4 * size),
				cell: parseFontSize(1.4 * size)
			},
			agreements: {
				description: parseFontSize(1.8 * size),
				obligatoryPrefix: parseFontSize(1.4 * size)
			}
		}
	}
});

import React from 'react';
import {
	arrayOf,
	array,
	number,
	oneOfType,
	node,
	object,
	string,
	func,
	bool
} from 'prop-types';

// Import styles
import { SliderWrapper, StyledSlickSlider } from './styles';

// Import components
import SliderArrow from './SliderArrow/SliderArrow';

// Import helpers
import { defaultSliderSettings } from './helpers';
import { SLIDER_ARROW_TYPES } from 'helpers/variables';

// Import hooks
import useSliderAccessibility from './useSliderAccessibility';

const { PREV, NEXT } = SLIDER_ARROW_TYPES;

const Slider = ({
	children,
	slidesToShow,
	settings = defaultSliderSettings,
	slideHeight,
	name,
	setCurrentSlide,
	activeIndexes,
	slidesCount,
	renderSlideAsLink
}) => {
	const {
		sliderRef,
		prevArrowRef,
		nextArrowRef,
		handleReInit,
		handleAfterChange
	} = useSliderAccessibility({
		setCurrentSlide,
		activeIndexes,
		slidesCount,
		slidesToShow,
		renderSlideAsLink
	});

	return (
		<SliderWrapper>
			<StyledSlickSlider
				ref={sliderRef}
				nextArrow={
					<SliderArrow
						type={NEXT}
						slideHeight={slideHeight}
						name={name}
						isSliderSection
						ref={nextArrowRef}
					/>
				}
				prevArrow={
					<SliderArrow
						type={PREV}
						slideHeight={slideHeight}
						name={name}
						isSliderSection
						ref={prevArrowRef}
					/>
				}
				slidesToShow={slidesToShow}
				slidesToScroll={parseInt(slidesToShow, 10)}
				afterChange={handleAfterChange}
				onReInit={handleReInit}
				{...settings}
			>
				{children}
			</StyledSlickSlider>
		</SliderWrapper>
	);
};

Slider.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	slidesToShow: number,
	settings: object,
	slideHeight: number.isRequired,
	name: string,
	setCurrentSlide: func,
	activeIndexes: array,
	slidesCount: number,
	renderSlideAsLink: bool
};

export default Slider;

import { useSelector } from 'react-redux';

// Import helpers
import { checkProductSubtype } from 'helpers';
import { getGalleriesTranslations } from '../helpers';

const useProductGalleries = () => {
	const { asset_groups, isError, isLoading } = useSelector(
		({ asset_groups }) => asset_groups
	);
	const { subtype } = useSelector(({ movieDetails }) => movieDetails.data);
	const { isArticle } = checkProductSubtype(subtype);

	const galleries = getGalleriesTranslations(asset_groups);

	const showGalleries = asset_groups.length && !isError && !isLoading;

	return {
		galleries,
		isArticle,
		showGalleries
	};
};

export default useProductGalleries;

import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { customMinResponsive, size } from 'components/styles';

// Heading First
const ELLIPSIS_MODIFIERS = {
	info: ({ theme }) => css`
		color: ${theme.graySemiLight};
		min-width: 25rem;
	`
};

const Ellipsis = styled.p`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('xs', 0.2)}rem;
		`
	)}

	${({ theme }) =>
		theme.isWCAG &&
		css`
			word-break: break-word;
			white-space: normal;
			hyphens: auto;
		`}

	${applyStyleModifiers(ELLIPSIS_MODIFIERS)};
`;

const Type = styled.span`
	display: inline-block;
	margin-bottom: 0.6rem;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme, fontWeight = 'normal' }) => theme.font[fontWeight]};
	color: ${({ theme }) => theme.graySemiLight};
`;

export { Ellipsis, Type };

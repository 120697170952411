import styled, { css } from 'styled-components';

// Import styles
import { flex, focusBasic, customMinResponsive, size } from 'components/styles';

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	border-radius: 0.5rem;
	cursor: ${({ cursor }) => cursor};
	filter: ${({ theme: { isContrast } }) =>
		isContrast ? 'grayscale(1)' : 'grayscale(0)'};
`;

export const VideoWrapper = styled.div`
	position: relative;
	z-index: 10;
	display: flex;
	flex-direction: column;
	height: 100%;
	div[tabIndex='0']:focus-within div {
		outline: 0.3rem solid ${({ theme }) => theme.primaryBlueLight};
		outline-offset: -0.3rem;
		-moz-outline-radius: 0.5rem;
	}
`;

export const VideoContainer = styled.div`
	width: 100%;
	flex-grow: 1;
	overflow: hidden;
	${flex()}
`;

export const Video = styled.video`
	border-radius: 0.5rem;
	cursor: ${({ cursor }) => cursor};
	filter: ${({ isContrast }) => (isContrast ? 'grayscale(1)' : 'grayscale(0)')};
	height: 100%;
`;

export const Controls = styled.nav`
	position: relative;
	${flex()}
	bottom: ${({ open }) => (open ? '21rem' : 0)};
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: ${({ theme }) => theme.primaryBgBlack};
	padding: 0.75rem 0.5rem;
	z-index: 22;

	${customMinResponsive(
		size.xs,
		css`
			padding: 1.5rem ${({ showFullscreen }) => (showFullscreen ? 18 : 12)}%;
		`
	)}
`;

export const ProgressButtonsWrapper = styled.div`
	${flex()}
	column-gap: 1rem;

	${customMinResponsive(
		size.xs,
		css`
			column-gap: 2rem;
		`
	)}
`;

export const IconButton = styled.button`
	border: none;
	background: transparent;
	width: 3rem;
	height: 3rem;
	align-self: center;
	outline: none;
	transform: scale(0.8);

	svg {
		path,
		g {
			width: 100%;
			height: 100%;
			fill: ${({ theme }) => theme.white};
		}
	}

	&:hover,
	&:focus {
		cursor: pointer;
		svg {
			path,
			g {
				fill: ${({ theme }) => theme.primaryBlueLight};
			}
		}
	}

	&:focus-visible {
		${focusBasic}
	}

	${customMinResponsive(
		size.xs,
		css`
			transform: scale(1);
		`
	)}
`;

export const TimeSeeker = styled.input`
	cursor: pointer;
	outline: none;
	width: 100%;
	margin: 0 1rem;
	max-width: 90%;
	border-radius: 1.5rem;
	accent-color: ${({ theme }) => theme.primaryBlueLight};

	${({ isSafari }) =>
		isSafari &&
		css`
			&::-webkit-slider-runnable-track {
				border-radius: 1.5rem;
				box-shadow: inset 0 0 1rem ${({ theme }) => theme.contrastGray};
			}
		`}

	&:focus {
		::-webkit-slider-thumb {
			outline: 0.2rem solid ${({ theme }) => theme.white};
			border-radius: 50%;
		}
	}

	&:focus-visible {
		${focusBasic}
	}

	${customMinResponsive(
		size.xs,
		css`
			margin: 0 2rem;
			max-width: 75%;
		`
	)}
`;

export const ImageContainer360 = styled.div`
	${flex()}
	width: 100%;
	height: 100%;
	div[tabIndex='0']:focus-within div {
		outline: 0.3rem solid ${({ theme }) => theme.primaryBlueLight};
		outline-offset: -0.3rem;
		-moz-outline-radius: 0.5rem;
	}
	filter: ${({ theme: { isContrast } }) =>
		isContrast ? 'grayscale(1)' : 'grayscale(0)'};
`;

export const VideoContainer360 = styled.video`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	border-radius: 0.5rem;
	position: relative;
`;

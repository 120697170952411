import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Button, PlayerElements } from 'components/elements';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import helpers
import { SEEK_TYPES } from './helpers';

const { FORWARD } = SEEK_TYPES;

const { IconSeekNext, IconSeekPrev } = PlayerElements;

const SeekController = ({ title, type }) => {
	const { updateSeekTime } = usePlayerVodContext();

	const isForward = type === FORWARD;

	const handleUpdateSeekTime = () => updateSeekTime(isForward);

	return (
		<Button
			modifiers={['icon', 'playerIconHover', 'seek']}
			onClick={handleUpdateSeekTime}
			title={title}
			aria-label={title}
		>
			{isForward ? <IconSeekNext aria-hidden /> : <IconSeekPrev aria-hidden />}
		</Button>
	);
};

SeekController.propTypes = {
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired
};

export default SeekController;

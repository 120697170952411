import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

export const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 1.2rem;
	padding: 0 2rem;
	left: 50%;
	transform: translateX(-50%);
	bottom: 4%;
	z-index: 11;

	> a {
		margin-right: auto;
	}

	${customMinResponsive(
		size.medium,
		css`
			padding: 0 4rem;
			transform: translateX(0);
			bottom: ${({ theme }) => (theme.isWCAG ? 5 : 15)}%;
			left: auto;

			> a {
				width: 20rem;
			}
		`
	)}

	${customMinResponsive(
		size.large,
		css`
			bottom: ${({ theme }) => (theme.isWCAG ? 5 : 25)}%;
		`
	)}

	${customMinResponsive(
		1600,
		css`
			bottom: ${({ theme }) => (theme.isWCAG ? 5 : 35)}%;
		`
	)}
`;

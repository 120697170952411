import * as types from 'store/actions/types';
import services from 'services/services';
import i18next from 'i18next';
import _ from 'lodash';
import { openNotification } from 'components/utilities/notification/notification';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';
import { getProductsRegardingPackets } from './helpers';

// ******************** FETCH USER PRODUCTS ********************
export const fetchUserProducts = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_USER_PRODUCTS_LOADING,
			payload: true
		});

		const {
			data: { data }
		} = await services.get('subscriber/products');
		const productsFromPackets = await getProductsRegardingPackets(data);
		const products = _.uniqBy([...data, ...productsFromPackets], 'uuid');

		dispatch({
			type: types.FETCH_USER_PRODUCTS_SUCCESS,
			payload: products
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_USER_PRODUCTS_ERROR,
			payload: true
		});

		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: i18next.t('common_products_error_title'),
				description: i18next.t('common_products_error_description')
			});
	}
};

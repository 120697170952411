import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { PlayerSettings } from 'components/elements';
import QualityItem from './QualityItem';
import { QualityName } from 'components/elements/organisms/player_vod/PlayerWrapper/controllers/styles';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import helpers
import { AUTO_QUALITY_ITEM } from 'helpers/player';

const Quality = () => {
	const { t } = useTranslation();

	const { profiles, selectedLanguage, isSafari, selectedProfile } =
		usePlayerVodContext();

	const hasVideoProfile = profiles && selectedLanguage;

	if (isSafari || !hasVideoProfile) return null;

	const qualityArray = [AUTO_QUALITY_ITEM, ...profiles[selectedLanguage]];

	const { name, height } = qualityArray.find(
		({ id }) => id === selectedProfile
	);

	const buttonText = name ?? `${height}p`;

	return (
		<PlayerSettings.Menu
			buttonContent={<QualityName aria-hidden>{buttonText}</QualityName>}
			title={t('player_settings_profiles')}
			type="quality"
			ariaText={t('aria_player_settings_quality_button')}
		>
			{qualityArray.map(({ id, height, bandwidth, name }) => (
				<QualityItem
					key={id}
					id={id}
					height={height}
					bandwidth={bandwidth}
					name={name}
				/>
			))}
		</PlayerSettings.Menu>
	);
};

export default Quality;

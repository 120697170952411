import styled, { css } from 'styled-components';

// Import styles
import { size, flex, customMinResponsive } from 'components/styles';

export const Container = styled.div`
	width: 100%;
	position: absolute;
	bottom: 0;
	z-index: 999;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	visibility: ${({ isFocus }) => (isFocus ? 'visible' : 'hidden')};
	transition: opacity 0.3s ease-in-out;
	row-gap: 1rem;
	padding: 0 1rem 1rem 1rem;
	${flex({ fd: 'column' })}

	${customMinResponsive(
		size.medium,
		css`
			padding-left: ${({ isFloatMode }) => (isFloatMode ? 2 : 4.4)}rem;
			padding-right: ${({ isFloatMode }) => (isFloatMode ? 2 : 4.4)}rem;
		`
	)}

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			button {
				svg {
					height: 2.6rem;
					width: 2.6rem;
				}
			}
		`}
`;

export const ContainerRow = styled.div`
	width: 100%;
	${({ justify = 'center' }) => flex({ jc: justify })}
`;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Import utilities
import { useEventListener, useToggle } from 'components/utilities';

// Import variables
import { EVENTS_NAMES, KEY_CODES, SECTIONS_ID } from 'helpers/variables';

const { SEARCH_INPUT, SEARCH } = SECTIONS_ID;
const { KEYDOWN } = EVENTS_NAMES;
const { ESCAPE } = KEY_CODES;

const useMenuToggle = () => {
	const { pathname, hash } = useLocation();
	const [isOpen, toggle] = useToggle();

	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

	const handleCloseMobileMenu = ({ keyCode }) =>
		keyCode === ESCAPE && isMobile && isOpen && toggle();

	useEventListener(KEYDOWN, handleCloseMobileMenu, document);

	useEffect(() => {
		const currentSectionId = hash.replace('#', '');
		const isSearchSection = currentSectionId === SEARCH;

		let timeoutId = null;

		if (isOpen) {
			toggle();
		} else if (isMobile && isSearchSection && !isOpen) {
			toggle();

			timeoutId = setTimeout(() => {
				const searchElement = document.getElementById(SEARCH_INPUT);
				searchElement.focus();
			}, 0);
		}

		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line
	}, [pathname, hash]);

	return {
		isOpen,
		toggle
	};
};

export default useMenuToggle;

export const APP_CORE_PATHS = {
	HOME_PAGE: '/strona-glowna',
	PAGE: 'strona',
	HOME: 'strona-glowna',
	WATCH: '/strona/ogladaj',
	RECORDINGS: 'nagrania',
	PACKETS: 'pakiety',
	PAYMENTS: 'historia-platnosci',
	FAVOURITES: 'ulubione',
	REGULATION: 'dokument/regulamin',
	CHANNELS: 'kanaly',
	PROGRAM_DETAILS: 'szczegoly-programu',
	SEARCH: 'szukaj',
	SECTION: 'sekcja',
	REGISTER: 'rejestracja',
	ADDITIONAL_INFO: 'dodatkowe-informacje',
	LOGIN: 'logowanie',
	TRANSACTION: 'transakcja',
	USER: 'uzytkownik',
	PASSWORD_RESET: 'reset-hasla',
	EMAIL_CONFIRMATION: 'potwierdzenie-email/:hash',
	DOCUMENT: 'dokument/:alias',
	CONTACT: 'kontakt',
	CATEGORIES: 'kategorie',
	PAYMENT: 'platnosc',
	PRODUCT: 'produkt',
	PRODUCTS: 'produkty',
	INSTITUTIONS: 'instytucja',
	COOKIES: 'cookies'
};

import React from 'react';
import {
	oneOfType,
	arrayOf,
	node,
	func,
	string,
	number,
	object,
	bool
} from 'prop-types';

// Import components
import { ButtonLoader } from 'components/elements';

// Import styles
import { StyledButton } from './styles';

// Import variables
import { ARIA_LABEL } from 'helpers/variables';

const CommonButton = ({
	children,
	isDisabled,
	ariaLabel,
	isPrime,
	isLoading,
	...restProps
}) => {
	let options = {};
	if (ariaLabel) {
		options[ARIA_LABEL] = ariaLabel;
	}

	return (
		<StyledButton
			disabled={isDisabled}
			prime={isPrime ? 1 : 0}
			{...options}
			{...restProps}
		>
			{children}
			{isLoading && <ButtonLoader loaderColor="primaryBlueLight" />}
		</StyledButton>
	);
};

CommonButton.defaultProps = {
	onClick: () => {},
	ariaLabel: '',
	width: 0,
	as: 'button',
	isDisabled: false,
	isPrime: false,
	isLoading: false
};

CommonButton.propTypes = {
	children: oneOfType([arrayOf(node), node]),
	onClick: func.isRequired,
	ariaLabel: string.isRequired,
	width: number,
	isDisabled: bool,
	to: oneOfType([string, object]),
	isPrime: bool,
	isLoading: bool
};

export default CommonButton;

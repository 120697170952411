import styled from 'styled-components';

import { ListboxOption } from '@reach/listbox';

export const StyledListboxOption = styled(ListboxOption)`
	white-space: normal;
	color: ${({ theme }) => theme.graySemiLight};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	padding: 1.2rem 2.6rem;
	text-align: left;
	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.gray};
	}
	&[data-reach-listbox-option=''][data-current-nav=''] {
		background: transparent;
		color: ${({ theme }) => theme.primaryBlueLight};
	}
	&:focus-within {
		color: ${({ theme }) => theme.primaryBlueLight};
	}
	&:focus {
		outline: none;
	}
`;

import styled, { css } from 'styled-components';
import { MenuButton } from '@reach/menu-button';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import {
	flex,
	transitionMain,
	customMinResponsive,
	size,
	customResponsive,
	focusBasic
} from 'components/styles';

// Import icons
import { ReactComponent as VodHeartIcon } from 'assets/icons/vod_heart.svg';
import { ReactComponent as VodHeartFullIcon } from 'assets/icons/vod_heart_full.svg';
import { ReactComponent as IconReminderSVG } from 'assets/icons/ic_reminder.svg';
import { ReactComponent as IconPlay } from 'assets/icons/vod_play.svg';
import { ReactComponent as IconInfoSVG } from 'assets/icons/ic_info.svg';
import { ReactComponent as MoreEpisodesSVG } from 'assets/icons/more_episodes.svg';

const BUTTON_MODIFIERS = {
	userTable: () => css`
		padding: 0.8rem 2rem;
		height: unset;
		margin: 0;

		${customMinResponsive(
			size.small,
			css`
				padding: 0.8rem 2rem;
			`
		)}
	`,
	article: ({ theme }) =>
		css`
			background-color: ${theme.white};
			border-color: ${theme.white};
		`
};

const iconsCommonStyles = css`
	margin-right: 8px;
	height: 100%;
`;

export const buttonsCommonStyles = css`
	font-size: ${({ theme }) => theme.fontSize.xs}rem;
	color: ${({ theme }) => theme.black};
	background-color: ${({ theme }) => theme.product.buttons.secondary};
	height: 4rem;
	border: 2px solid ${({ theme }) => theme.product.buttons.secondary};
	border-radius: 6px;
	cursor: pointer;
	font-weight: ${({ theme }) => theme.font.medium};
	margin-bottom: 1.6rem;
	padding: 0 0.5rem;
	position: relative;
	${flex()}
	${transitionMain}

	${({ prime }) =>
		prime === 1 &&
		css`
			background-color: ${({ theme }) => theme.product.buttons.primary};
			border-color: ${({ theme }) => theme.product.buttons.primary};
		`}

	&:disabled {
		cursor: not-allowed;
		color: ${({ theme }) => theme.black};
	}

	&:hover {
		outline: none;
		border-color: ${({ theme }) => theme.primaryBlueLight};
		box-shadow: 0 0 4px ${({ theme }) => theme.primaryBlueLight};
		background-color: ${({ theme }) => theme.primaryBlueLight};
	}

	&:focus-visible {
		outline: none;
		border-color: ${({ theme }) => theme.primaryBlueLight};
		box-shadow: 0 0 4px ${({ theme }) => theme.primaryBlueLight};
		background-color: ${({ theme }) => theme.primaryBlueLight};
		${focusBasic}
	}

	${customResponsive(
		size.small,
		css`
			width: 100%;
		`
	)}

	${customMinResponsive(
		size.small,
		css`
			padding: 0 2.8rem;
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('s', -0.1)}rem;
		`
	)}
`;

export const VerticalBar = styled.span`
	margin: 0 0.7rem;
`;

export const StyledButton = styled.button`
	${buttonsCommonStyles}
	${applyStyleModifiers(BUTTON_MODIFIERS)}
`;

export const StyledMenuButton = styled(MenuButton)`
	${buttonsCommonStyles}
`;

export const IconFavorite = styled(VodHeartIcon)`
	${iconsCommonStyles}
`;

export const IconFavoriteFull = styled(VodHeartFullIcon)`
	${iconsCommonStyles}
`;

export const IconReminder = styled(IconReminderSVG)`
	${iconsCommonStyles}
`;

export const PlayIcon = styled(IconPlay)`
	${iconsCommonStyles}
`;

export const InfoIcon = styled(IconInfoSVG)`
	margin-right: 0.8rem;
	height: 100%;
`;

export const MoreEpisodesIcon = styled(MoreEpisodesSVG)`
	${iconsCommonStyles};
`;

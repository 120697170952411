import styled, { css } from 'styled-components';

// Import styles
import {
	flex,
	transitionMain,
	customMinResponsive,
	size,
	focusBasic
} from 'components/styles';

export const Button = styled.button`
	background: transparent;
	outline: none;
	border: none;
	cursor: pointer;
	height: 2.5rem;
	${flex()}
	${transitionMain}
	&:hover, &:focus-visible {
		color: ${({ theme }) => theme.body.header.iconActive};

		svg {
			path {
				${transitionMain}
				fill: ${({ theme }) => theme.body.header.iconActive};
			}
			circle {
				${transitionMain}
				stroke: ${({ theme }) => theme.body.header.iconActive};
			}
		}
	}
	&:focus-visible {
		${focusBasic}
	}

	${({ marginRight }) =>
		marginRight &&
		css`
			margin-right: 1.85rem;
			${customMinResponsive(
				size.medium,
				css`
					margin-right: 2rem;
				`
			)};
		`}
`;

import styled from 'styled-components';

// Import styles
import { Label } from '../styles';
import { focusBasic } from 'components/styles';

// Import components
import { Textarea } from 'components/elements';

export const TextareaFieldStyled = styled.div`
	margin-bottom: 1.9rem;
	position: relative;
`;

export const TextareaStyled = styled(Textarea)`
	border: 1px solid ${({ theme }) => theme.authentication.input.borderColor};
	color: ${({ theme }) => theme.authentication.input.color};
	height: auto;

	&:focus,
	&:hover {
		border-color: ${({ theme }) => theme.authentication.input.focusBorderColor};
	}

	&:focus {
		${focusBasic}
	}

	&.errors {
		border: 1px solid ${({ theme }) => theme.red};
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.authentication.input.color};
		transition: background-color 5000s ease-in-out 0s;
	}
	&:focus {
		border-color: ${({ theme }) => theme.authentication.input.focusBorderColor};
	}
`;

export const LabelStyled = styled(Label)`
	top: 2rem;
`;

import * as types from 'store/actions/types';
import services from 'services/services';
import i18next from 'i18next';
import { openNotification } from 'components/utilities/notification/notification';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';

// Import api
import { GET_WALLETS_TRANSACTIONS_API } from 'helpers/api';

// ******************** FETCH WALLET TRANSACTIONS ********************
export const fetchWalletTransactions = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_WALLET_TRANSACTIONS_LOADING,
			payload: true
		});

		const url = `${GET_WALLETS_TRANSACTIONS_API}?columns[5][search][value]=increase&order[0][column]=8&order[0][dir]=desc`;

		const { data } = await services.get(url);

		dispatch({
			type: types.FETCH_WALLET_TRANSACTIONS_SUCCESS,
			payload: data.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_WALLET_TRANSACTIONS_ERROR,
			payload: true
		});

		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: i18next.t('common_products_error_title'),
				description: i18next.t('common_products_error_description')
			});
	}
};

import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

// Import styles
import { fadeIn, linearGradient } from 'components/styles';

// Import styles
import { scroll } from 'components/styles';

const Item = styled.div`
	width: 98%;
	background: ${({ theme, isSliderItem }) =>
		isSliderItem ? 'transparent' : theme.primary};
	color: ${({ theme }) => theme.white};
	margin: 0;
	margin-right: ${({ isSliderItem }) => (isSliderItem ? 0 : 1)}rem;
	margin-bottom: ${({ isSliderItem }) => (isSliderItem ? 0 : 1)}rem;
	height: ${({ height = 400 }) => height}px;
	display: inline-block;
	position: relative;
	opacity: 0;
	animation: ${fadeIn(0.6)} 0.5s ease-in-out forwards;
`;

const Link = styled(RouterLink)`
	&:link,
	&:visited {
		color: ${({ theme }) => theme.white};
	}
`;

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: ${({ isSliderItem }) => (isSliderItem ? 1 : 0)}rem;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;
const Img = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
`;

const Mask = styled.div`
	position: absolute;
	top: 0;
	left: ${({ isSliderItem }) => (isSliderItem ? 1 : 0)}rem;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to bottom,
		transparent,
		transparent,
		rgba(0, 0, 0, 0.9)
	);
	transition: all 0.3s ease-in-out;

	${({ live }) =>
		live &&
		css`
			cursor: pointer;
		`}

	&:hover {
		background: rgba(0, 0, 0, 0.5);

		& > div {
			height: 100%;
		}
	}
`;

const Content = styled.div`
	position: absolute;
	left: ${({ isSliderItem }) => (isSliderItem ? 1 : 0)}rem;
	bottom: 0;
	width: ${({ isSliderItem }) => (isSliderItem ? 90 : 100)}%;
	height: 8.2rem;
	overflow: hidden;
	padding: 2rem;
	background: transparent;
	display: flex;
	flex-direction: column;
	transition: height 0.3s ease-in-out;

	${({ live }) =>
		live &&
		css`
			height: 9.5rem;
		`}
`;

const Info = styled.div`
	padding-bottom: 2rem;
`;

const Ellipsis = styled.p`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.medium};
`;

const DescriptionBox = styled.div`
	flex: 1;
	overflow: hidden;
	overflow-y: overlay;
	${scroll({ thumb: 'transparent', track: 'transparent', scrollbarWidth: '0' })}
`;

const Description = styled.p`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
	font-weight: ${({ theme }) => theme.font.medium};
`;

const ProgressBar = styled.div`
	display: flex;
	background-color: gray;
	height: 0.2rem;
	width: 100%;
	margin: 1rem 0;
`;
const CurrentProgress = styled.div`
	flex-basis: 50%;
	height: 100%;
	${linearGradient({ deg: 'to left' })}
`;

export {
	Item,
	Link,
	Mask,
	Content,
	Info,
	Ellipsis,
	Description,
	DescriptionBox,
	Wrapper,
	Img,
	ProgressBar,
	CurrentProgress
};

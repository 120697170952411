import React from 'react';
import { string, oneOfType, arrayOf, node } from 'prop-types';
import { Menu } from '@reach/menu-button';

// Import styles
import { Wrapper } from './styles';

// Import components
import PlayerSettingsContent from './PlayerSettingsContent';

const PlayerSettings = ({ title, children, buttonContent, type, ariaText }) => (
	<Wrapper>
		<Menu>
			{({ isExpanded }) => (
				<PlayerSettingsContent
					title={title}
					buttonContent={buttonContent}
					type={type}
					ariaText={ariaText}
					isExpanded={isExpanded}
				>
					{children}
				</PlayerSettingsContent>
			)}
		</Menu>
	</Wrapper>
);

PlayerSettings.defaultProps = {
	title: '',
	ariaText: ''
};

PlayerSettings.propTypes = {
	title: string.isRequired,
	children: oneOfType([arrayOf(node), node]).isRequired,
	buttonContent: node.isRequired,
	ariaText: string.isRequired,
	type: string.isRequired
};

export default PlayerSettings;

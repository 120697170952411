import React from 'react';
import { func, oneOfType, shape, instanceOf } from 'prop-types';

// Import components
import NavLink from './NavLink';
import NavToolbar from './nav_toolbar/NavToolbar';

// Import styles
import { NavListStyled, NavLinkStyled } from './styles';

// Import logic
import { useNavLinks } from './useNavLinks';

const NavList = ({ toggle, searchRef }) => {
	const {
		navRef,
		activeSubmenuId,
		isMoveSubnav,
		isUserDropdownOpen,
		menuLinks,
		setActiveSubmenuId,
		setIsUserDropdownOpen
	} = useNavLinks();

	return (
		<NavListStyled dropdown role="list" ref={navRef}>
			{menuLinks?.length
				? menuLinks.map(({ submenu, model_id, model_type, link, name, id }) => (
						<NavLinkStyled key={id} isMoveSubnav={isMoveSubnav}>
							<NavLink
								submenu={submenu}
								setActiveSubmenuId={setActiveSubmenuId}
								activeSubmenuId={activeSubmenuId}
								modelId={model_id}
								modelType={model_type}
								link={link}
								name={name}
								id={id}
							/>
						</NavLinkStyled>
				  ))
				: null}
			<NavToolbar
				toggle={toggle}
				searchRef={searchRef}
				setActiveSubmenuId={setActiveSubmenuId}
				isUserDropdownOpen={isUserDropdownOpen}
				setIsUserDropdownOpen={setIsUserDropdownOpen}
			/>
		</NavListStyled>
	);
};

NavList.propTypes = {
	toggle: func.isRequired,
	searchRef: oneOfType([func, shape({ current: instanceOf(Element) })])
};

export default NavList;

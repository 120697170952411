import React from 'react';
import { useTranslation } from 'react-i18next';
import { shape, number, string } from 'prop-types';

// Import components
import { ProductPrice } from 'components/elements';

// Import styles
import { OptionWrapper } from './styles';

// Import context
import { useProductPaymentContext } from 'components/context/payment';

// Import helpers
import { getPaymentOptionLimitInfo } from './helpers';

const OptionContent = ({
	option: {
		price,
		period,
		period_unit,
		views_limit,
		payment_method: paymentMethod
	}
}) => {
	const { t } = useTranslation();

	const {
		productDetails: { till }
	} = useProductPaymentContext();

	const limitInfo = getPaymentOptionLimitInfo({
		period,
		period_unit,
		views_limit,
		till
	});

	return (
		<OptionWrapper>
			{t(`payment_method_${paymentMethod}`)}
			<ProductPrice regular={price} isSpanTag />
			{limitInfo}
		</OptionWrapper>
	);
};

OptionContent.propTypes = {
	option: shape({
		price: number.isRequired,
		period: number,
		period_unit: string,
		views_limit: number,
		payment_method: string.isRequired
	}).isRequired
};

export default OptionContent;

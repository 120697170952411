import styled, { css } from 'styled-components';

// Import styles
import { itemFocus, flex } from 'components/styles';

export const Root = styled.div`
	width: 100%;
	position: relative;
	${flex()}

	${({ isVisible }) =>
		isVisible &&
		css`
			display: none;
		`}

	&:hover {
		.preview {
			display: block;
		}
	}
`;

export const StrapsContainer = styled.div`
	height: 20px;
	width: 100%;
	cursor: pointer;
	${flex({ jc: 'start' })}

	div[role='slider'] {
		width: 100%;
		height: 100%;
		border-radius: 5px;
		${flex()}

		&:focus-visible {
			${itemFocus()}
		}
	}
`;

export const Progress = styled.progress`
	height: 5px;
	width: 100%;
	cursor: pointer;
	accent-color: ${({ theme }) => theme.primaryBlueLight};
	border: none;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.3);
	-webkit-appearance: none;

	&::-webkit-progress-bar {
		background-color: rgba(255, 255, 255, 0.3);
		border-radius: 5px;
	}

	&::-webkit-progress-value {
		background-color: ${({ theme }) => theme.primaryBlueLight};
		border-radius: 5px;
	}

	&::-moz-progress-bar {
		background-color: ${({ theme }) => theme.primaryBlueLight};
		border-radius: 5px;
	}
`;

export const Point = styled.div.attrs(({ translateX, leftPercent }) => ({
	style: {
		transform: `translate(${translateX}px)`,
		left: `${leftPercent}%`
	}
}))`
	position: absolute;
	left: 0;
	width: 1px;
	height: 20px;
	${flex()}
`;

export const DotContainer = styled.div`
	position: relative;
	${flex()}
`;

export const Dot = styled.div`
	height: 15px;
	width: 15px;
	border-radius: 100px;
	border: 1px solid ${({ theme }) => theme.primaryBlueLight};
	cursor: pointer;
	background: ${({ theme }) => theme.primaryBlueLight};
	${flex()}
`;

export const PreviewWrapper = styled.div`
	position: absolute;
	bottom: ${({ isCatchup }) => (isCatchup ? 4 : 2)}rem;
	left: ${({ cursorPos }) => `calc(${cursorPos * 100}% - 3.2rem)`};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	padding: ${({ isSmall }) => (isSmall ? 0.3 : 0.5)}rem;
	font-weight: 300;
	background-color: ${({ theme }) => theme.black};
	border-radius: 0.5rem;
	text-align: center;
	display: none;

	&::before {
		content: '';
		position: absolute;
		display: inline-block;
		width: 1rem;
		height: 1rem;
		background-color: ${({ theme }) => theme.black};
		bottom: 0;
		left: 50%;
		transform: rotate(-45deg) translateX(-50%);
		z-index: -1;
	}
`;

export const SeekTime = styled.p`
	margin-top: ${({ isSmall }) => (isSmall ? 0 : 0.5)}rem;
	color: ${({ theme }) => theme.white};
`;

export const PreviewImage = styled.div`
	height: 9rem;
	width: 16rem;
	background-image: ${({ imgUrl }) => `url(${imgUrl})`};
	background-position: center ${({ currentFrame }) => currentFrame * -9}rem;
	background-size: cover;
	background-color: ${({ theme }) => theme.black};
	cursor: pointer;
`;

import React from 'react';
import { func, string } from 'prop-types';

// Import components
import { AriaBaseModal, AriaModalContent } from 'components/elements';

const VideoDescriptionModal = ({ title, toggle, description }) => {
	const titleId = `gallery_description_modal`;

	return (
		<AriaBaseModal titleId={titleId} close={toggle} style={{ width: '90vw' }}>
			<AriaModalContent
				title={title}
				titleId={titleId}
				description={description}
			/>
		</AriaBaseModal>
	);
};

VideoDescriptionModal.propTypes = {
	title: string.isRequired,
	toggle: func.isRequired,
	description: string.isRequired
};

export default VideoDescriptionModal;

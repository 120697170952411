import React from 'react';
import { bool } from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

// Import styles
import { ButtonStyled } from './styles';

// Import components
import { InputField, ButtonLoader } from 'components/elements';

const Fields = ({ isSubmitting }) => {
	const { t } = useTranslation();
	const newsletterButtonText = t('common_footer_newsletter_subscribe_btn');

	return (
		<>
			<Field disabled={isSubmitting} name="email_news_letter">
				{(props) => (
					<InputField
						{...props}
						styles={{ label: { background: 'gray' } }}
						placeholder={t('common_footer_newsletter_input_title')}
						required
						type="email"
						autoComplete="email"
						isErrorBackgroundLight
						aria-invalid={null}
					/>
				)}
			</Field>

			<ButtonStyled type="submit" disabled={isSubmitting} modifiers="default">
				{isSubmitting ? <ButtonLoader /> : newsletterButtonText}
			</ButtonStyled>
		</>
	);
};

Fields.propTypes = {
	isSubmitting: bool.isRequired
};

export default Fields;

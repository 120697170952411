import { useRef, useEffect } from 'react';

// Import utilities
import { useCarousel } from 'components/utilities';

const useSliderBanners = ({ content, sliderSettings, slideTransitionTime }) => {
	const sliderRef = useRef(null);
	const {
		currentIndex,
		prevArrowRef,
		nextArrowRef,
		changeCurrentIndex,
		handleArrowAriaUpdate
	} = useCarousel({ content, slideTransitionTime });
	const hasArrows = sliderSettings.arrows && content?.length > 1;

	useEffect(() => {
		if (sliderRef.current) {
			sliderRef.current.slickGoTo(currentIndex);
		}
		// eslint-disable-next-line
	}, [currentIndex]);

	return {
		sliderRef,
		currentIndex,
		hasArrows,
		prevArrowRef,
		nextArrowRef,
		changeCurrentIndex,
		handleArrowAriaUpdate
	};
};

export default useSliderBanners;

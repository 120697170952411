import * as types from 'store/actions/types';
import services from 'services/services';

// ******************** FETCH USER'S PAYMENTS ********************
export const fetchUserPayments = () => async (dispatch) => {
	try {
		dispatch({
			type: types.USER_PAYMENTS_LOADING,
			payload: true
		});

		// Get user's payments
		const url =
			'/subscriber/payments?order[0][column]=9&order[0][dir]=desc&columns[10][search][value]=succeed';
		const { data } = await services.get(url);

		// Dispatch an action
		dispatch({
			type: types.USER_PAYMENTS_SUCCESS,
			payload: data.data
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.USER_PAYMENTS_ERROR,
			payload: error.response.data
		});
	}
};

import styled, { css } from 'styled-components';

// Import styles
import {
	flex,
	fadeInUp,
	customMinResponsive,
	size,
	focusBasic
} from 'components/styles';

// Import icons
import { ReactComponent as IconCloseSVG } from 'assets/icons/ic_close_thin.svg';

// Import components
import { Button } from 'components/elements';

export const Box = styled.div`
	position: relative;
	background-color: ${({ theme }) => theme.blackBg};
	border: 1px solid rgba(237, 236, 237, 0.15);
	border-radius: 3px;
	padding: 5rem 1.7rem;
	width: 100%;
	min-height: 20rem;
	width: 100%;
	animation: ${fadeInUp} 0.5s ease-in-out forwards;

	${customMinResponsive(
		size.medium,
		css`
			width: ${({ theme }) => (theme.isWCAG ? '100vw' : '57.7vw')};
			padding: 5rem 8.2rem 6rem 8.2rem;
		`
	)}
`;

export const Bottom = styled.div`
	margin-top: 4rem;
	${({ flexDirection = 'column' }) => flex({ fd: flexDirection })}

	${customMinResponsive(
		size.medium,
		css`
			${({ flexDirection = 'row' }) => flex({ fd: flexDirection })}
		`
	)}

	& > button {
		width: 100%;
		padding: 0;

		${customMinResponsive(
			size.medium,
			css`
				margin: 0;

				&:not(:first-of-type) {
					margin-left: 1.6rem;
				}
			`
		)}
	}
`;

export const ButtonClose = styled(Button)`
	position: absolute;
	top: 1.2rem;
	right: 0;
	border: none;

	${customMinResponsive(
		size.medium,
		css`
			right: 1.4rem;
		`
	)}

	&:hover,
	&:focus-visible {
		cursor: pointer;

		svg > g > g {
			fill: ${({ theme }) => theme.primaryBlueLight};
		}
	}

	&:focus-visible {
		${focusBasic}
	}
`;

export const IconClose = styled(IconCloseSVG)`
	position: relative;
	height: 1.8rem;
`;

const textBaseStyles = (theme) => css`
	color: ${theme.vod.font.white};
	font-size: ${({ theme }) => theme.fontSize.set('md', 0.1)}rem !important;
	white-space: initial;
	margin-bottom: 2rem;
`;

export const Text = styled.p`
	${({ theme }) => css`
		${textBaseStyles(theme)}
		font-weight: ${theme.font.light};
	`}
`;

export const Heading = styled.h1`
	${({ theme }) => css`
		${textBaseStyles(theme)}
		font-weight: ${theme.font.normal};
		outline: 0;
	`}
`;

import i18n from 'i18next';

// Import variables
import { PRODUCTS_TYPE_NAMES } from './variables';
import { capitalizeWords } from 'helpers/typography';

export const PAGE_META_TAGS = {
	COMMON: {
		title: `meta_page_common_title`,
		description: `meta_page_common_description`,
		keywords: `meta_page_common_keywords`
	},
	LOGIN: {
		title: 'meta_page_login_title',
		description: 'meta_page_login_description'
	},
	REGISTER: {
		title: 'meta_page_register_title',
		description: 'meta_page_register_description'
	},
	INSTITUTION: {
		title: 'meta_page_institutions_title',
		description: 'meta_page_institutions_description',
		keywords: 'meta_page_institutions_keywords'
	},
	CONTACT: {
		title: 'meta_page_contact_title',
		description: 'meta_page_contact_description',
		keywords: 'meta_page_common_keywords'
	},
	ABOUTE_US: {
		title: 'meta_page_about_title',
		description: 'meta_page_about_description',
		keywords: 'meta_page_common_keywords'
	},
	ACCESSIBILITY_STATEMENT: {
		title: 'meta_page_accessibility_statement_title'
	},
	USER: {
		GENERAL: { title: 'meta_page_user_profile_general_title' },
		PARENTAL_CONTROL: { title: 'meta_page_user_profile_parental_title' },
		PRODUCTS: { title: 'meta_page_user_profile_active_purschase_title' },
		WALLET: { title: 'meta_page_user_profile_wallet_title' },
		VOUCHERS: { title: 'meta_page_user_profile_vouchers_title' },
		PURCHASE_HISTORY: {
			title: 'meta_page_user_profile_history_purschase_title'
		},
		SUBSCRIPTIONS: {
			title: 'meta_page_user_profile_subscriptions_title'
		},
		FAVOURITES: { title: 'meta_page_user_profile_favourites_title' },
		REMINDERS: { title: 'meta_page_user_profile_reminders_title' },
		TERMS_CONDITIONS: {
			title: 'meta_page_user_profile_terms_conditions_title'
		},
		PRIVACY_POLICY: { title: 'meta_page_user_profile_privacy_policy_title' },
		COOKIES_POLICY: { title: 'meta_page_user_profile_cookies_policy_title' }
	},
	DOCUMENTS: {
		TERMS_CONDITIONS: { title: 'meta_page_documents_terms_conditions_title' },
		PRIVACY_POLICY: { title: 'meta_page_documents_privacy_policy_title' },
		COOKIES_POLICY: { title: 'meta_page_documents_cookies_policy_title' }
	},
	PACKETS: {
		title: 'meta_page_packets_title'
	},
	PAYMENT: {
		title: 'meta_page_payment_title'
	},
	SEARCH: {
		title: 'meta_page_search_title'
	}
};

export const META_TAGS_COMMON_NAME = 'common';

/**
 * Description
 * @param {string} element
 * @returns {string}
 */
const convertToStringItem = (element) => `, ${element}`;
/**
 * Description
 * @param {array} data
 * @returns {string}
 */
const generateKeywordList = (data) => {
	const list = data.join(', ');
	return convertToStringItem(list);
};

/**
 * Description
 * @param {array} genres
 * @returns {string}
 */
const getGenreKeywords = (genres) => {
	const genreList = genres.map((genre) => genre.name);
	let genresTextList = '';

	if (genreList.length > 1) {
		genresTextList = genreList.join(', ');
	} else {
		genresTextList = genres[0]?.name;
	}

	return convertToStringItem(genresTextList);
};

/**
 * Description
 * @param {string} title
 * @returns {string}
 */
const getTitleKeywords = (title) =>
	capitalizeWords(title).split(/\s/).join(', ');

/**
 * Description
 * @param {array} {genres
 * @param {object} metadata
 * @param {string} provider}
 * @returns {string}
 */
const getKeywords = ({ genres, metadata, provider }) => {
	const { actors, authors, category, title } = metadata;
	const titleKeywords = getTitleKeywords(title);
	let keywords = `${titleKeywords}`;

	if (genres) {
		keywords += getGenreKeywords(genres);
	}
	if (provider) {
		keywords += convertToStringItem(provider);
	}

	if (category) {
		keywords += convertToStringItem(category);
	}

	if (authors?.length) {
		keywords += generateKeywordList(authors);
	}

	if (actors?.length) {
		keywords += generateKeywordList(actors);
	}
	return keywords;
};

/**
 * Description
 * @param {object} product
 * @returns {object}
 */
export const getMovieProductMetaTags = (product) => {
	if (!product) return null;

	const { metadata, type, images } = product;
	let productTitle = i18n.t('meta_page_common_title');
	let productDescription = i18n.t('meta_page_common_description');
	let productKeywords = i18n.t('meta_page_common_keywords');

	const isPacket = PRODUCTS_TYPE_NAMES.PACKET === type;

	if (isPacket) {
		const { title, description } = product;
		productDescription = description;
		productTitle = `${productTitle} - ${capitalizeWords(title)}`;
		productKeywords = title?.replace(/\s/, ', ');
	} else {
		const { summary_short: summaryShort, title } = metadata;
		productDescription = summaryShort;
		productTitle = `${productTitle} - ${capitalizeWords(title)}`;
		productKeywords = getKeywords(product);
	}

	const productImage = images?.cover?.[0]?.url;

	return {
		description: productDescription,
		title: productTitle,
		keywords: productKeywords,
		image: productImage
	};
};

export const getInstitutionMetaTags = (institution) => {
	if (!institution) return null;

	const defaultTitle = i18n.t('meta_page_common_title');

	const { name } = institution;

	const institutionTitle = `${defaultTitle} - ${capitalizeWords(name)}`;

	return {
		title: institutionTitle
	};
};

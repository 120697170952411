import { useTranslation } from 'react-i18next';

// Import helpers
import {
	checkProductSubtype,
	urlTranslationTypes,
	checkIsWithinTimePeriod
} from 'helpers';

// Import paths
import { VOD_PATHS } from 'components/routes/paths';

// Import utilities
import { useProductLimits } from 'components/utilities';

// Import helpers
import { PRODUCT_LIMIT_TYPES, getRenderAsLink } from 'helpers';
import { generatePath } from 'react-router-dom';

const { VIEWS } = PRODUCT_LIMIT_TYPES;
const { series, movie } = urlTranslationTypes;

const usePlayButton = ({ uuid, product }) => {
	const { t } = useTranslation();

	const {
		is_available: isAvailable,
		since,
		is_audio: isAudio,
		till,
		uuid: productUuid,
		subtype
	} = product;

	const { isLimitExceeded, limitMessage, limitCounter } = useProductLimits({
		product,
		limitType: VIEWS
	});

	const { isSeries, isEpisode } = checkProductSubtype(subtype);

	const type = isSeries || isEpisode ? series : movie;

	const linkRedirect = generatePath(VOD_PATHS.PLAYER, {
		type,
		id: uuid || productUuid
	});

	const translationKey = isAudio ? 'vod_buttons_listen' : 'vod_buttons_play';

	const buttonText = isLimitExceeded
		? limitMessage
		: `${t(translationKey)}${limitCounter}`;

	const isWithinTimePeriod = checkIsWithinTimePeriod({ since, till });

	const isAvailableToPlay =
		!isLimitExceeded && (isWithinTimePeriod || isAvailable);

	const opacity = isAvailableToPlay ? 1 : 0.8;

	const renderAs = getRenderAsLink(isAvailableToPlay);

	const showPlayIcon = isAvailableToPlay && !isLimitExceeded;

	return {
		linkRedirect,
		buttonText,
		isAvailableToPlay,
		opacity,
		renderAs,
		showPlayIcon
	};
};

export default usePlayButton;

import { ListboxButton } from '@reach/listbox';
import styled, { css } from 'styled-components';

import { customMinResponsive, size, focusBasic } from 'components/styles';

export const StyledListboxButton = styled(ListboxButton)`
	border: none;
	width: 100%;
	display: flex;
	justify-content: space-between;
	color: ${({ theme }) => theme.authentication.input.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.bold};
	padding: 0 0 0.5rem 0;
	border-bottom: 1px solid ${({ theme }) => theme.white};
	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
		`
	)};

	span[data-reach-listbox-arrow=''] {
		display: ${({ isEditing }) => (isEditing ? 'block' : 'none')} !important;
		font-size: 1rem !important;
	}

	&:focus {
		outline: none;
	}

	&:focus-visible {
		${focusBasic}
	}

	&.is-expanded,
	&:focus {
		border-color: ${({ theme }) => theme.authentication.input.focusBorderColor};
	}

	&.is-disabled {
		border: none;
		opacity: 1;
		justify-content: flex-end;

		${customMinResponsive(
			size.medium,
			css`
				justify-content: flex-start;
			`
		)};
	}
`;

export const SelectWrapper = styled.div`
	width: 100%;
	position: relative;
`;

import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { NavLink as RouterNavLink, Link as RouterLink } from 'react-router-dom';

// Import utilties
import { customMinResponsive, focusBasic, size } from 'components/styles';

const LIST_MODIFIERS = {
	searchPage: () => css`
		padding-top: 0.8rem;
	`
};

const ITEM_MODIFIERS = {
	searchPage: () => css`
		padding: 0 1.6rem;
	`
};

const NAV_LINK_MODIFIERS = {
	searchPage: ({ theme }) => css`
		&:link,
		&:visited {
			padding: 0.9rem;
			font-size: ${theme.fontSize.xs}rem;

			&::after {
				height: 2px;
			}

			&:hover {
				color: ${theme.white};
			}
		}
	`
};
const LINK_MODIFIERS = {
	linkAsButton: ({ theme, padding, width }) => css`
		&:link,
		&:visited {
			display: inline-block;
			width: ${width ? width : 'auto'};
			font-size: ${theme.fontSize.xs}rem;
			font-weight: ${theme.font.normal};
			line-height: 4.1rem;
			text-align: center;
			border-radius: 0.5rem;
			padding: ${padding ? `0 ${padding}rem` : '0 2.4rem'};
			background-color: ${theme.globalButtons.secondary.bgColor};
			border: 2px solid ${theme.globalButtons.secondary.bgColor};
			color: ${theme.globalButtons.secondary.color};
			${customMinResponsive(
				size.medium,
				css`
					font-size: ${theme.fontSize.set('xs', 0.3)}rem;
				`
			)}
		}

		&:hover {
			border-color: ${theme.primaryBlueLight};
			box-shadow: 0 0 4px ${theme.primaryBlueLight};
		}
		&:focus-visible {
			border-color: ${theme.primaryBlueLight};
			color: ${theme.black};
			box-shadow: 0 0 4px ${theme.primaryBlueLight};
			background-color: ${theme.primaryBlueLight};
		}
	`
};

const linkStyle = css`
	text-transform: capitalize;
	&:link,
	&:visited {
		position: relative;
		display: flex;
		align-items: center;
		padding: 0 2.4rem;
		color: ${({ theme: { body } }) => body.nav.color};
		font-size: ${({ theme }) => theme.fontSize.nav.link}rem;
		transition: all 0.3s ease-in-out;
	}

	&.active-nav {
		&:link,
		&:visited {
			color: ${({ theme: { body } }) => body.nav.menuActiveText};
			&::after {
				transform: scaleX(1);
			}
		}
	}

	&:hover,
	&:focus-visible {
		color: ${({ theme }) => theme.primaryBlueLight};

		&.active-nav {
			color: ${({ theme }) => theme.primaryBlueLight};
		}
	}

	&:focus-visible {
		${focusBasic}
	}
`;

const List = styled.ul`
	list-style: none;
	display: flex;

	${applyStyleModifiers(LIST_MODIFIERS)};
`;

const Item = styled.li`
	display: flex;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);
		height: 2.7rem;
		width: 1px;
		background-color: white;
	}
	${applyStyleModifiers(ITEM_MODIFIERS)};
`;

const NavLink = styled(RouterNavLink)`
	${linkStyle}

	${({ inverse }) =>
		inverse &&
		css`
			&.active-nav {
				&:link,
				&:visited {
					color: ${({ theme }) => theme.white};
				}
			}
		`}

	${applyStyleModifiers(NAV_LINK_MODIFIERS)};
`;

const AnchorLink = styled.a`
	${linkStyle}
	${applyStyleModifiers(NAV_LINK_MODIFIERS)};
`;

const Link = styled(RouterLink)`
	${linkStyle}
	${applyStyleModifiers(LINK_MODIFIERS)};
`;

export { List, Item, NavLink, Link, AnchorLink };

import i18n from 'i18next';

// Import helpers
import { getPeriodWithUnit, getFormatedDate } from 'helpers';

// Import variables
import { DATE_FORMAT } from 'helpers/variables';

const { DAY_MONTH_YEAR_FORMAT } = DATE_FORMAT;

export const getPaymentOptionLimitInfo = ({
	period,
	period_unit,
	views_limit,
	till
}) => {
	const periodWithUnit = getPeriodWithUnit({ period, period_unit });

	const tillDate = getFormatedDate(till, DAY_MONTH_YEAR_FORMAT);

	const accessPeriod = period
		? periodWithUnit
		: i18n.t('payment_limits_to', { tillDate });

	const limitInfo = views_limit
		? i18n.t('payment_limits_limit', { views_limit, accessPeriod })
		: accessPeriod;

	return `- ${limitInfo}`;
};

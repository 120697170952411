import * as types from 'store/actions/types';
import produce from 'immer';

// Import helpers
import { AVAILABLE_FONT_SIZE } from 'helpers/wcag';

// Import variables
import {
	LOCAL_STORAGE_DISABILITY_SETTINGS,
	DISABILITY_ANIMATION_KEY,
	DISABILITY_CONTRAST_KEY,
	DISABILITY_FONT_SIZE_KEY
} from 'helpers/variables';

const { DEFAULT } = AVAILABLE_FONT_SIZE;

const localSettings =
	JSON.parse(localStorage.getItem(LOCAL_STORAGE_DISABILITY_SETTINGS)) || null;

const INITIAL_STATE = {
	[DISABILITY_ANIMATION_KEY]: localSettings?.[DISABILITY_ANIMATION_KEY] ?? true,
	[DISABILITY_CONTRAST_KEY]: localSettings?.[DISABILITY_CONTRAST_KEY] ?? false,
	[DISABILITY_FONT_SIZE_KEY]:
		localSettings?.[DISABILITY_FONT_SIZE_KEY] ?? DEFAULT
};

const DEFAULT_STATE = {
	[DISABILITY_ANIMATION_KEY]: true,
	[DISABILITY_CONTRAST_KEY]: false,
	[DISABILITY_FONT_SIZE_KEY]: DEFAULT
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.RESET_TO_DEFAULT_SETTINGS:
				return { ...DEFAULT_STATE };

			case types.TOGGLE_APP_CONTRAST:
				draft.isContrast = !state.isContrast;
				return;

			case types.TOGGLE_ANIMATIONS_AVAILABILITY:
				draft.areAnimationsAvailable = !state.areAnimationsAvailable;
				return;

			case types.SET_FONT_SIZE:
				draft.fontSize = action.payload;
				return;

			default:
				return state;
		}
	});

import { useDispatch } from 'react-redux';

//Import actions
import { setFloatWidgetInitPageMode } from 'store/actions';

const useCloseFloatMode = () => {
	const dispatch = useDispatch();

	const handleCloseFloatMode = () => setFloatWidgetInitPageMode()(dispatch);

	return { closeFloatMode: handleCloseFloatMode };
};

export default useCloseFloatMode;

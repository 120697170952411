// Import variables
import { TRANSACTION_TYPES } from 'helpers/prices';

// Import helpers
import { INITIAL_PROMOTION } from 'helpers';

export const INITIAL_STATE = {
	selectedPriceId: '',
	paymentMethods: [],
	productName: '',
	isPaymentValid: false,
	transactionType: '',
	userPromotions: [],
	promotion: INITIAL_PROMOTION,
	need_invoice: false,
	isInvoiceValid: false
};

export const getTransactionType = ({ payment_method, period }) => {
	const { RENT, PURCHASE, SUBSCRIPTION } = TRANSACTION_TYPES;

	if (payment_method.includes(SUBSCRIPTION)) {
		return SUBSCRIPTION;
	} else if (!period) {
		return PURCHASE;
	}

	return RENT;
};

export const PAYMENT_METHOD = {
	id: null,
	payment_method: '',
	period: 0,
	period_unit: 'hour',
	price: 0,
	promotions: {},
	since_first_access: false,
	views_limit: null
};

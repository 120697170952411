// Import redux
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// Import redux thunk
import reduxThunk from 'redux-thunk';
// Import reducers
import reducers from './reducers';

// Import variables
import { LOCALSTORAGE_USER } from 'helpers/variables';

// Import helpers
import { checkFunctionalCookiesAccepted } from 'helpers/cookies';

// Get user data from local storage
const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER)) || null;

// Initial state
const INITIAL_STATE = {
	auth: {
		authenticate: user,
		error: false,
		deviceExists: user ? user.deviceExists : false,
		isDeviceLimited: user ? user.isDeviceLimited : false,
		isAuthenticated: user ? user.isAuthenticated : false,
		isLogged: user ? user.isLogged : false,
		accepetedAgreements: user ? user.accepetedAgreements : false,
		isRegisterCompleted: user ? !user.need_complete_registration : false,
		functionalAgreementsPermission: checkFunctionalCookiesAccepted(),
		isFirebaseAuth: false,
		userDetails: {
			firstname: '',
			lastname: '',
			email: '',
			signInMethod: ''
		},
		firebaseRegisterError: {
			message: '',
			name: '',
			isError: false,
			errorCode: null,
			status: 200
		},
		authError: {
			message: '',
			name: '',
			isError: false,
			errorCode: null,
			status: 200
		},
		packets: {
			data: [],
			isLoaded: false,
			isError: false
		},
		passes: {
			data: [],
			isLoaded: false,
			isError: false
		},
		products: [],
		devices: {
			limitMessage: user ? user.limitMessage : '',
			data: [],
			isLoaded: false,
			errorDevice: false
		},
		signOutError: false,
		signOutLoading: false,
		isLoaded: false,
		removeAccount: {
			loading: false,
			error: false,
			success: false
		},
		allPackets: []
	}
};

// Create middleware with redux thunk
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);

let store;

if (process.env.NODE_ENV === 'production') {
	// Create store
	store = createStoreWithMiddleware(reducers, INITIAL_STATE);
} else {
	// Create store
	store = createStoreWithMiddleware(
		reducers,
		INITIAL_STATE,
		composeWithDevTools()
	);
}

export default store;

import React from 'react';
import Slider from 'react-slick';
import { array, number, object, string } from 'prop-types';
import { useSelector } from 'react-redux';

// Import components
import Banner from './Banner';
import SliderDot from './SliderDot';
import SliderArrow from '../slider/SliderArrow/SliderArrow';

// Import styles
import { Wrapper, SliderDots } from './styles';

// Import helpers
import { baseSettings } from './helpers';
import { SLIDER_ARROW_TYPES, SECTIONS_TYPES } from 'helpers/variables';

// Import logic
import { useSliderBanners } from './hooks';

const { PREV, NEXT } = SLIDER_ARROW_TYPES;
const { BANNER } = SECTIONS_TYPES;

const SliderBanners = ({
	content,
	type,
	desktopRatio,
	mobileRatio,
	settings,
	name
}) => {
	const sliderSettings = { ...baseSettings, ...settings };
	const {
		sliderRef,
		currentIndex,
		hasArrows,
		prevArrowRef,
		nextArrowRef,
		changeCurrentIndex,
		handleArrowAriaUpdate
	} = useSliderBanners({
		content,
		sliderSettings,
		slideTransitionTime: sliderSettings.speed
	});

	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	const customPaging = (index) => (
		<SliderDot
			content={content}
			index={index}
			changeSlide={changeCurrentIndex}
		/>
	);

	const appendDots = (dots) => <SliderDots role="list">{dots}</SliderDots>;
	const isLowBanner = type === BANNER;

	const handleReInit = () =>
		handleArrowAriaUpdate({
			prev: prevArrowRef.current,
			next: nextArrowRef.current
		});

	return (
		<Wrapper
			desktopRatio={desktopRatio}
			mobileRatio={mobileRatio}
			isLowBanner={isLowBanner}
			isContrast={isContrast}
		>
			<Slider
				{...sliderSettings}
				prevArrow={
					<SliderArrow
						type={PREV}
						name={name}
						compact
						changeCurrentIndex={changeCurrentIndex}
						ref={prevArrowRef}
					/>
				}
				nextArrow={
					<SliderArrow
						type={NEXT}
						name={name}
						compact
						changeCurrentIndex={changeCurrentIndex}
						ref={nextArrowRef}
					/>
				}
				customPaging={customPaging}
				appendDots={appendDots}
				ref={sliderRef}
				onReInit={handleReInit}
			>
				{content.map((banner, index) => (
					<Banner
						{...banner}
						key={banner.uuid}
						isCurrent={currentIndex === index}
						type={type}
						hasArrows={hasArrows}
						isLowBanner={isLowBanner}
					/>
				))}
			</Slider>
		</Wrapper>
	);
};

SliderBanners.defaultProps = {
	settings: {}
};

SliderBanners.propTypes = {
	content: array.isRequired,
	type: string.isRequired,
	desktopRatio: number,
	mobileRatio: number,
	settings: object,
	name: string.isRequired
};

export default SliderBanners;

import { useState } from 'react';

// Hook implementation of observer pattern
function useObserver() {
	const [state, setState] = useState(null);
	const [observers, setObservers] = useState([]);

	const attatch = (newObserver) => setObservers([...observers, newObserver]);

	const detach = (toRemove) =>
		setObservers(observers.filter((observer) => observer !== toRemove));

	const notify = (newState) => {
		setState(newState);
		observers.forEach((observer) => observer(newState));
	};

	return {
		state,
		observers,
		attatch,
		detach,
		notify
	};
}

export default useObserver;

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { PlayerElements, Button } from 'components/elements';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const { IconExitFullScreen, IconFullScreen } = PlayerElements;

const FullScreenButton = () => {
	const { t } = useTranslation();

	const { setFullScreen, isFullScreen } = usePlayerVodContext();

	const buttonTitle = isFullScreen
		? t('common_player_full_screen_exit_title')
		: t('common_player_full_screen_title');

	return (
		<Button
			onClick={setFullScreen}
			modifiers={['icon', 'playerIconHover', 'fullscreen']}
			title={buttonTitle}
			aria-label={buttonTitle}
		>
			{isFullScreen ? (
				<IconExitFullScreen aria-hidden />
			) : (
				<IconFullScreen aria-hidden />
			)}
		</Button>
	);
};

export default FullScreenButton;

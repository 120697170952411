import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

// Import components
import { Heading } from 'components/elements';

// Import styled components
import { Nav, Item, StyledLink } from './styles';

// import helpers
import { FOOTER_NAV_LINKS } from './helpers';

const FooterNav = () => {
	const { t } = useTranslation();

	const renderFooterNavLinks = FOOTER_NAV_LINKS.map(({ path, text }) => (
		<Item key={text}>
			<StyledLink to={path}>{ReactHtmlParser(t(text))}</StyledLink>
		</Item>
	));

	return (
		<>
			<Heading.Second modifiers="hidden">
				{t('common_footer_navigation_heading')}
			</Heading.Second>
			<nav>
				<Nav role="list">{renderFooterNavLinks}</Nav>
			</nav>
		</>
	);
};

export default FooterNav;

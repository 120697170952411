import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Import styles
import { customResponsive } from 'components/styles';

const SectionNavWrapper = ({
	children,
	isInverse = false,
	columns = '1fr auto',
	epg = false,
	boxShadow = false
}) => (
	<Wrapper isInverse={isInverse} boxShadow={boxShadow} epg={epg}>
		<Content epg={epg} columns={columns}>
			{children}
		</Content>
	</Wrapper>
);

const Wrapper = styled.div`
	border-bottom: 1px solid
		${({ isInverse, theme }) => (isInverse ? theme.grayBorder : theme.line)};

	background-color: ${({ isInverse, theme }) =>
		isInverse ? theme.black : 'transparent'};

	${({ boxShadow }) =>
		boxShadow &&
		css`
			box-shadow: 0px 6px 7px 0px #cccccc2e;
		`};
`;

const Content = styled.div`
	padding: 0rem 2rem;
	display: grid;
	grid-template-columns: ${({ columns }) => columns};

	${customResponsive(
		1200,
		css`
			padding-bottom: 0.6rem;
		`
	)}
`;

SectionNavWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	epg: PropTypes.bool,
	isInverse: PropTypes.bool,
	boxShadow: PropTypes.bool
};

export default SectionNavWrapper;

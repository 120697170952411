import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

export const Root = styled.div`
	position: relative;
	background-color: ${({ theme }) => theme.body.bg.primary};
	padding-top: 6.2rem
	padding-left: 2rem;

	${customMinResponsive(
		size.medium,
		css`
			padding-top: 16.3rem;
			padding-left: 4rem;
		`
	)}
`;

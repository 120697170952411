import styled from 'styled-components';

export const Wrapper = styled.ul`
	display: flex;
	margin-bottom: 2rem;
	list-style: none;

	svg {
		margin-right: 2rem;
		pointer-events: visible;
	}
`;

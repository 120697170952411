import React from 'react';
import { oneOfType, string, array, bool, number } from 'prop-types';

// Import variables
import { PRODUCTS_WITH_GENRES } from 'store/actions/vod/moves_list_actions/movie_list_actions_helper';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import {
	FilterSelect,
	GenresSelect,
	ProductTypeSelect,
	InstitutionSelect
} from './components';
import { Heading } from 'components/elements';

// Import styles
import { StyledFieldsetContainer } from './styles';

const ProductListFilters = ({
	genre,
	genresList,
	sortingName,
	type,
	isSearch = false
}) => {
	const { t } = useTranslation();

	const genreName =
		!genre || genre === 'all'
			? t('vod_section_details_all_movies_and_series')
			: genresList.find(({ id }) => id === genre).name;

	const sortName = t(sortingName);

	const showGenresSelect = PRODUCTS_WITH_GENRES.includes(type);

	return (
		<>
			<Heading.Second modifiers="hidden">
				{t('common_filters_heading')}
			</Heading.Second>
			<StyledFieldsetContainer>
				<legend className="visually-hidden">
					{t('common_filters_legend')}
				</legend>
				<InstitutionSelect />
				<FilterSelect selectValue={sortName} isSearch={isSearch} />
				<ProductTypeSelect selectValue={type} />
				{showGenresSelect && <GenresSelect selectValue={genreName} />}
			</StyledFieldsetContainer>
		</>
	);
};

ProductListFilters.propTypes = {
	genre: oneOfType([string, number]),
	genresList: array,
	sortingName: string,
	type: string,
	isSearch: bool
};

export default ProductListFilters;

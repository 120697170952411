export const SEEK_TYPES = {
	FORWARD: 'forward',
	BACKWARD: 'backward'
};

export const SEEK_CONTROLLERS = [
	{
		type: SEEK_TYPES.BACKWARD,
		title: 'common_player_seek_prev_title'
	},
	{
		type: SEEK_TYPES.FORWARD,
		title: 'common_player_seek_next_title'
	}
];

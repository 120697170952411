import React from 'react';
import { func, bool } from 'prop-types';
import { Dropdown, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

// Import components
import { AuthModal } from 'components/elements';

// Import icons
import { ReactComponent as ProfileIcon } from 'assets/icons/ic_nav_profile.svg';

// Import styles
import {
	StyledMenu,
	LogoutItem,
	ButtonLogout,
	StyledMenuButton,
	ItemStyled,
	MenuDivider
} from './styles';
import { Nav } from 'components/elements/molecules/dropdown/styles';

// Import utilities
import { useNavDropdown } from './useNavDropdown';

// Import variables
import { USER_MENU_NAV_LINKS } from 'helpers/user_menu_nav';

const NavDropdown = ({
	setActiveSubmenuId,
	isUserDropdownOpen,
	setIsUserDropdownOpen
}) => {
	const { t } = useTranslation();
	const {
		isAuth,
		menuContainerRef,
		menuButtonRef,
		isTooltipVisible,
		toggleTooltip,
		handleSignOut,
		renderPopupContainer,
		handleDropdownVisibleChange,
		handleDropdownItemClick
	} = useNavDropdown({
		isUserDropdownOpen,
		setIsUserDropdownOpen,
		setActiveSubmenuId
	});

	const menu = (
		<div ref={menuContainerRef}>
			<StyledMenu>
				{USER_MENU_NAV_LINKS.map(({ path, translation }) => (
					<ItemStyled key={path}>
						<Nav
							to={path}
							activeClassName="active-drop-nav"
							onClick={handleDropdownItemClick}
						>
							{ReactHtmlParser(t(`user_profile_${translation}`))}
						</Nav>
					</ItemStyled>
				))}
				<MenuDivider aria-hidden />
				<LogoutItem>
					<ButtonLogout onClick={handleSignOut}>
						{t('common_static_btn_sign_out')}
					</ButtonLogout>
				</LogoutItem>
			</StyledMenu>
		</div>
	);

	const renderMenuButton = () => {
		const userProfileTitle = t('common_user_profile_title');

		return (
			<Tooltip
				placement="bottom"
				title={userProfileTitle}
				visible={isTooltipVisible}
				onVisibleChange={toggleTooltip}
				trigger={['hover', 'focus']}
			>
				<StyledMenuButton
					modifiers={['navIcon']}
					aria-label={userProfileTitle}
					onClick={toggleTooltip}
					aria-haspopup
					aria-expanded={isUserDropdownOpen}
					ref={menuButtonRef}
				>
					<ProfileIcon aria-hidden />
				</StyledMenuButton>
			</Tooltip>
		);
	};

	return isAuth ? (
		<Dropdown
			overlay={menu}
			trigger={['click']}
			getPopupContainer={(triggerNode) =>
				renderPopupContainer(triggerNode.parentNode)
			}
			onVisibleChange={handleDropdownVisibleChange}
			visible={isUserDropdownOpen}
		>
			{renderMenuButton()}
		</Dropdown>
	) : (
		<AuthModal>{renderMenuButton}</AuthModal>
	);
};

NavDropdown.propTypes = {
	setActiveSubmenuId: func.isRequired,
	isUserDropdownOpen: bool.isRequired,
	setIsUserDropdownOpen: func.isRequired
};

export default NavDropdown;

import React from 'react';

// Import styled components
import { StyledMenu } from './styles';

// Import icons
import { ReactComponent as Arrow } from 'assets/icons/icon_mobile_menu_arrow_down.svg';

// Import components
import UserMenu from '../UserMenu';
import NavMenu from '../NavMenu';
import AuthenticationButton from '../AuthenticationButton';

// Import utilities
import { useAuth } from 'components/utilities';

const MobileMenu = () => {
	const { isAuth } = useAuth();

	return (
		<StyledMenu
			mode="inline"
			expandIcon={<Arrow role="img" aria-hidden="true" />}
		>
			<NavMenu />
			{isAuth && <UserMenu />}
			<AuthenticationButton />
		</StyledMenu>
	);
};

export default MobileMenu;

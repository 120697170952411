import React from 'react';
import { string, number, array, oneOfType, bool, shape } from 'prop-types';

// Import components
import {
	SliderSection,
	SliderBanners,
	VideoBanners
} from 'components/elements';
import Carousel from 'components/views/tv/home/carousel/Carousel';

// Import helpers
import { SECTIONS_TYPES } from 'helpers/variables';
import {
	CONTAINER_SCREEN_RATIO_DESKTOP,
	CONTAINER_SCREEN_RATIO_MOBILE
} from 'components/elements/molecules/slider_banners/helpers';

const { CAROUSEL, BANNER, HIGH_BANNER, VIDEO_BANNER } = SECTIONS_TYPES;

const Section = React.memo(
	({ section }) => {
		const { id, type, name, content } = section;

		switch (type) {
			case CAROUSEL:
				return (
					<Carousel content={content}>
						{({ changeCurrentIndex }) => (
							<SliderSection
								modifiers="carousel"
								content={content}
								type={type}
								id={id}
								name={name}
								renderSlideAsLink={false}
								onItemClick={changeCurrentIndex}
							/>
						)}
					</Carousel>
				);

			case HIGH_BANNER:
				return (
					<SliderBanners
						type={type}
						content={content}
						desktopRatio={CONTAINER_SCREEN_RATIO_DESKTOP[HIGH_BANNER]}
						mobileRatio={CONTAINER_SCREEN_RATIO_MOBILE[HIGH_BANNER]}
						name={name}
					/>
				);

			case BANNER:
				return (
					<SliderBanners
						type={type}
						content={content}
						desktopRatio={CONTAINER_SCREEN_RATIO_DESKTOP[BANNER]}
						mobileRatio={CONTAINER_SCREEN_RATIO_MOBILE[BANNER]}
						settings={{ draggable: false }}
						name={name}
					/>
				);

			case VIDEO_BANNER:
				return <VideoBanners name={name} type={type} content={content} />;

			default:
				return (
					<SliderSection
						modifiers="sectionsList"
						content={content}
						type={type}
						id={id}
						name={name}
					/>
				);
		}
	},
	(prev, next) => prev.index === next.index
);

Section.propTypes = {
	isInstitution: bool.isRequired,
	index: number.isRequired,
	section: shape({
		id: oneOfType([number, string]).isRequired,
		type: string.isRequired,
		name: string.isRequired,
		content: array.isRequired
	}).isRequired
};

export default Section;

import React from 'react';
import {
	object,
	oneOfType,
	node,
	arrayOf,
	bool,
	string,
	func
} from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import helpers
import { DISPLAY_FLEX_STYLES } from './helpers';

// Import styles
import { Box, ButtonClose, IconClose, Bottom, Text, Heading } from './styles';

// Import components
import { AriaModal } from 'components/elements';

const AriaBaseModal = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const {
		children,
		close,
		titleText,
		titleId,
		style,
		centerModalContent,
		isCloseButton,
		closeButtonAriaLabel
	} = props;

	const underlayStyle = centerModalContent ? DISPLAY_FLEX_STYLES : {};

	return (
		<AriaModal
			underlayStyle={underlayStyle}
			titleText={titleText}
			titleId={titleId}
			onExit={close}
		>
			<Box ref={ref} style={style} tabIndex="-1">
				{isCloseButton && (
					<ButtonClose
						onClick={close}
						aria-label={
							closeButtonAriaLabel || t('common_modal_close_button_title')
						}
					>
						<IconClose aria-hidden role="img" />
					</ButtonClose>
				)}

				{children}
			</Box>
		</AriaModal>
	);
});

AriaBaseModal.defaultProps = {
	style: {},
	centerModalContent: true,
	isCloseButton: true
};

AriaBaseModal.propTypes = {
	children: oneOfType([node, arrayOf(node)]),
	style: object,
	close: func,
	titleText: string,
	titleId: string,
	centerModalContent: bool,
	isCloseButton: bool,
	closeButtonAriaLabel: string
};

AriaBaseModal.Bottom = Bottom;
AriaBaseModal.Heading = Heading;
AriaBaseModal.Text = Text;

export default AriaBaseModal;

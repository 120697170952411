import styled from 'styled-components';

// Import styles
import { flex, focusBasic } from 'components/styles';

// Import components
import { Button } from 'components/elements';

export const Wrapper = styled.div`
	${flex({ jc: 'flex-start' })};
	height: 7.5rem;
	padding: 0 ${({ isLogged }) => (isLogged ? '3rem' : '2.5rem')};
	background: ${({ theme }) => theme.primaryBgBlack};
	border-bottom: 1px solid ${({ theme }) => theme.line};
`;

export const LogInButton = styled(Button)`
	text-align: center;
	font-size: ${({ theme }) => theme.fontSize.xs}rem;
	line-height: 1.9rem;
	font-weight: ${({ theme }) => theme.font.normal};
	width: 100%;
	border-radius: 0.6rem;
	padding: 1.1rem 0;

	&:focus,
	&:focus-visible {
		${focusBasic}
	}
`;

export const LogOutButton = styled.button`
	font-size: ${({ theme }) => theme.fontSize.xs}rem;
	line-height: 1.9rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.white};
	outline: none;
	border: none;
	background: transparent;

	&:focus,
	&:focus-visible {
		${focusBasic}
	}
`;

import { generatePath } from 'react-router-dom';
import services from 'services/services';

// Import api
import { GET_SECTION_CONTENT_API } from 'helpers/api';

export const fetchSectionContent = async ({
	sections = [],
	index = 0,
	products_group_uuid
}) => {
	const section = sections[index];

	if (section) {
		const { id, main_limit } = section;

		const url = generatePath(GET_SECTION_CONTENT_API, { id, main_limit });

		const response = await services.get(url, {
			params: { products_group_uuid }
		});

		const content = response?.data?.data || [];

		return { ...section, content, index };
	}

	return { content: [], index: 0 };
};

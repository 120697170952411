import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

// Import variables
import { CSS_TRANSITION_TIME, FOCUS_SCROLL_OFFSET } from 'helpers/variables';

export const useFocusScroll = () => {
	const { isLoaded: isMenuLoaded } = useSelector(({ menu }) => menu);
	const { fontSize } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);
	const headerRef = useRef(null);
	const stylesRef = useRef(null);

	const handleFocusScroll = () => {
		const height = headerRef.current.offsetHeight;
		const { sheet } = stylesRef.current;

		while (sheet.cssRules.length) {
			sheet.deleteRule(0);
		}

		sheet.insertRule(
			`html { scroll-padding-top:${height + FOCUS_SCROLL_OFFSET}px }`
		);
	};

	useEffect(() => {
		if (isMenuLoaded) {
			const header = document.querySelector('header');
			const style = document.head.appendChild(document.createElement('style'));
			headerRef.current = header;
			stylesRef.current = style;

			handleFocusScroll();
		}
		// eslint-disable-next-line
	}, [isMenuLoaded]);

	useEffect(() => {
		let timeoutId;
		if (headerRef?.current) {
			timeoutId = setTimeout(handleFocusScroll, CSS_TRANSITION_TIME + 100);
		}

		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line
	}, [fontSize]);
};

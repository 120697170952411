import Store from 'store/store';
import {
	signOut,
	setPortalAvailability,
	setInternalServerError
} from 'store/actions';

// import helpers
import { checkAppType, getLanguage } from 'helpers';

// Import variables
import { SYSTEM_TYPES } from 'helpers/variables';

const checkServerStatus = (error) => {
	const { url } = error.response.config;

	if (!url.includes('recordings')) {
		Store.dispatch(setInternalServerError(true));
	}
};

export const checkErrorStatus = (error) => {
	const status = error?.response?.status;

	if (status === 401) {
		const authenticate = false;
		// Dispatch an signOut action in auth folder
		Store.dispatch(signOut(authenticate));

		return Promise.reject(error);
	} else if (status === 502) {
		Store.dispatch(setPortalAvailability());
	} else if (status === 500) {
		checkServerStatus(error);
		return Promise.reject(error);
	} else {
		return Promise.reject(error);
	}
};

export const checkUrlPlatform = (url) => {
	const platform = `platform=BROWSER`;
	const regex = /\w*platform=BROWSER\w*/i.test(url);
	const systemName = getUrlSystemName();

	let requestUrl = url;

	if (!regex) {
		const type = !/\?/i.test(url) ? '?' : '&';
		requestUrl = `${url}${type}${platform}`;
	}

	return `${requestUrl}&system=${systemName}`;
};

export const getRating = () => {
	const { auth } = Store.getState();
	return auth &&
		auth.authenticate &&
		(auth.authenticate.rating || auth.authenticate.rating === 0)
		? `&rating=${auth.authenticate.rating}`
		: '';
};

export const createAxiosInterceptors = (axios) => {
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			return checkErrorStatus(error);
		}
	);
	return axios;
};

export const getUrlSystemName = () => {
	const { OTT, TVONLINE } = SYSTEM_TYPES;
	const { isTvSmart } = checkAppType();
	const system = isTvSmart ? OTT : TVONLINE;

	return system;
};

// convert errors from request response validators
export const convertErrors = (response) =>
	Object.keys(response).reduce((prev, next, index) => {
		prev[next] = Object.values(response)[index][0];
		return prev;
	}, {});

// set custom text to errors
export const addCustomTranslationToErrors = (errors, translationText) => {
	Object.keys(errors).forEach((key) => (errors[key] = translationText));
	return errors;
};

export const prepareFetchApiUrl = (url) => {
	const baseUrl = checkUrlPlatform(url);
	const rating = getRating();
	const lang = getLanguage();

	return `${baseUrl}${rating}&language=${lang}`;
};

import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import heleprs
import { callAllFns } from 'helpers';

// Import utilities
import { useToggle } from 'components/utilities';

// Import components
import { AriaBaseModal, AriaModalContent } from 'components/elements';
import { AuthButton } from '../AuthButton';
import useAuthModal from './useAuthModal';

const AuthModal = ({ children }) => {
	const { t } = useTranslation();
	const { isAuth, redirect, auth, open } = useAuthModal();
	const [isOpened, toggle] = useToggle();

	const title = t('common_auth_modal_login_required');
	const titleId = 'auth_modal_login_required_title';
	const description = t('common_auth_modal_login_description_required');

	return (
		<>
			{React.cloneElement(children, {
				onClick: callAllFns(auth(toggle), open(children?.props?.onClick)),
				isAuth
			})}

			{isOpened && (
				<AriaBaseModal
					titleId={titleId}
					close={toggle}
					style={{ width: '47vw', minWidth: '28rem' }}
				>
					<AriaModalContent
						title={title}
						titleId={titleId}
						description={description}
					>
						<AuthButton onClick={redirect(toggle)} />
					</AriaModalContent>
				</AriaBaseModal>
			)}
		</>
	);
};

AuthModal.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired
};

export default AuthModal;

import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

// Import styled components
import { StyledSubMenu, StyledMenuItem, StyledLink } from '../common_styles';

// Import variables
import { USER_MENU_NAV_LINKS } from 'helpers/user_menu_nav';

// Import helpers
import { useMobileMenu } from '../MobileMenu/useMobileMenu';

const USER_MOBILE_MENU_UNIQUE_KEY = 'user_mobile_menu';

const UserMenu = ({ ...props }) => {
	const { t } = useTranslation();
	const { handleKeyEvent } = useMobileMenu();

	return (
		<StyledSubMenu
			key={USER_MOBILE_MENU_UNIQUE_KEY}
			title={t('user_profile_heading')}
			{...props}
			eventKey={`item_${USER_MOBILE_MENU_UNIQUE_KEY}`}
			subMenuKey={`${USER_MOBILE_MENU_UNIQUE_KEY}-menu-`}
			tabIndex="0"
			onKeyPress={handleKeyEvent}
			forceSubMenuRender
		>
			{USER_MENU_NAV_LINKS.map(({ path, translation }) => (
				<StyledMenuItem key={`${translation}-mobile`}>
					<StyledLink to={path}>
						{ReactHtmlParser(t(`user_profile_${translation}`))}
					</StyledLink>
				</StyledMenuItem>
			))}
		</StyledSubMenu>
	);
};

export default UserMenu;

import React from 'react';
import { string } from 'prop-types';

// Import components
import { Row, Loader, Static } from 'components/elements';
import StaticContent from './StaticContent';
import useStaticPage from './useStaticPage';

const StaticPage = ({ alias }) => {
	const { isUserProfile, showLoader } = useStaticPage(alias);

	return (
		<Static.Container isUserProfile={isUserProfile}>
			{showLoader && <Loader zIndex={900} position="absolute" />}

			{isUserProfile ? (
				<Static.Wrapper>
					<StaticContent isUserProfile />
				</Static.Wrapper>
			) : (
				<Row>
					<StaticContent />
				</Row>
			)}
		</Static.Container>
	);
};

StaticPage.defaultProps = { alias: '' };
StaticPage.propTypes = { alias: string };

export default StaticPage;

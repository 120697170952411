import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import placeholder
import placeholder from 'assets/images/placeholder.png';

// Import styles
import { customMinResponsive, size, itemFocus, flex } from 'components/styles';

const IMAGE_RATIO = 236 / 419;

export const TileContentWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const Figure = styled.figure`
	width: 100%;
	height: 100%;
	margin: 0;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 0.4rem;
	background: no-repeat url(${placeholder}) center/cover;

	${customMinResponsive(
		size.medium,
		css`
			margin-bottom: 0.4rem;
		`
	)}
`;

export const Image = styled.img`
	height: 100%;
	width: ${({ isImageUrl }) => (isImageUrl ? '100%' : 'auto')};
	object-fit: cover;
	object-position: center;
	filter: ${({ isContrast }) => (isContrast ? 'grayscale(1)' : 'grayscale(0)')};
`;

export const Figcaption = styled.figcaption`
	display: none;
`;

export const TileTitle = styled.h3`
	font-size: ${({ theme }) => theme.fontSize.s}rem;
	line-height: 2.5rem;
	color: ${({ theme }) => theme.white};
	margin-top: ${({ theme }) => (theme.isWCAG ? 4 : 0.5)}rem !important;
	margin-bottom: 0 !important;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('xs', 0.2)}rem;
			line-height: 2.6rem;
			margin-bottom: 0.6rem !important;
		`
	)}
`;

export const TileDescription = styled.div`
	${flex({ jc: 'flex-start' })}
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
	line-height: 1.8rem;
	text-transform: uppercase;
	color: ${({ theme }) => theme.grayLight};
	${({ theme }) => theme.isWCAG && 'margin-top: 2em'};

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
			font-weight: ${({ theme }) => theme.font.medium};
		`
	)}
`;

export const StyledLink = styled(Link)`
	position: relative;
	display: block;
	width: 100%;
	padding-top: calc(${IMAGE_RATIO} * 100%); //to keep image ratio in css grid
	margin-bottom: 2rem;

	${customMinResponsive(
		size.medium,
		css`
			margin-bottom: 0;
		`
	)}

	&:focus,
	&:hover {
		outline: none;

		figure {
			${itemFocus()}
		}
	}

	&:focus-visible {
		figure {
			border-style: dashed;
		}
	}
`;

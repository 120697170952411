import React from 'react';
import { bool, number } from 'prop-types';

// Import styles
import { Gradient } from './styles';

const BannerGradient = ({ zIndex, isContrast, hasContent, isLowBanner }) => {
	if (isContrast) return null;
	return (
		<Gradient
			zIndex={zIndex}
			hasBottomGradient={!isLowBanner}
			hasRadialGradient={hasContent}
		/>
	);
};

BannerGradient.defaultProps = {
	zIndex: 1,
	isContrast: false,
	hasContent: false,
	isLowBanner: false
};

BannerGradient.propTypes = {
	zIndex: number,
	isContrast: bool,
	hasContent: bool,
	isLowBanner: bool
};

export default BannerGradient;

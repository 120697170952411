import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';

// Import api
import { GET_WALLETS_CURRENT_API, QUERY_WALLETS_CURRENT } from 'helpers/api';

const useAccountBalanceQuery = ({
	onSuccess = () => {},
	onError = () => {},
	enabled = true
}) => {
	const queryFn = () => services.get(GET_WALLETS_CURRENT_API);

	const { data, status, isLoading, isFetching, isError, remove } = useQuery({
		queryKey: QUERY_WALLETS_CURRENT,
		queryFn,
		onSuccess,
		onError,
		enabled
	});

	useEffect(() => {
		return () => remove();
		//eslint-disable-next-line
	}, []);

	const accountBalance = data?.data || 0;

	return {
		accountBalance,
		accountBalanceStatus: status,
		isAccountBalanceLoading: isLoading || isFetching,
		isAccountBalanceError: isError
	};
};

export default useAccountBalanceQuery;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Import styles
import { flex } from 'components/styles';

// Import components
import { Heading, NavCategoryList, NavDropdown } from 'components/elements';
import { VodSectionButton } from 'components/views/vod/components/buttons/VodSectionButton';

const SectionHeader = ({
	path,
	showLinks = true,
	showlinkToAll = true,
	navLinks,
	title,
	linkToAll,
	sectionId = ''
}) => (
	<SectionHeaderStyled>
		<Wrapper>
			<Box>
				<HeadingFirstStyled showlinkToAll={showlinkToAll}>
					{title}
				</HeadingFirstStyled>
				{showLinks && <NavCategoryList path={path} links={navLinks} />}
				{showLinks && <NavDropdown path={path} links={navLinks} />}
			</Box>
			{showlinkToAll && (
				<VodSectionButton
					sectionId={sectionId}
					sectionTitle={title}
					redirectPath={linkToAll}
					addBottomMargin
				/>
			)}
		</Wrapper>
	</SectionHeaderStyled>
);

const SectionHeaderStyled = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.line};
	margin-bottom: 1.8rem;
`;

const Wrapper = styled.div`
	${flex()}
	justify-content: space-between;
	align-items: flex-end;
	margin-right: 4.5rem;
`;

const Box = styled.div`
	padding: 0 1.5rem;
	${flex()}
	justify-content: initial;
`;

const HeadingFirstStyled = styled(Heading.First)`
	font-size: ${({ theme }) => theme.fontSize.md}rem;
	margin: 0 3rem 1.5rem 0;
`;

SectionHeader.propTypes = {
	title: PropTypes.string.isRequired,
	links: PropTypes.array,
	linkToAll: PropTypes.string,
	showLinks: PropTypes.bool,
	showlinkToAll: PropTypes.bool,
	path: PropTypes.string,
	sectionId: PropTypes.string,
	navLinks: PropTypes.array
};

export default SectionHeader;

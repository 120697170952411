import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { PlayerSettings } from 'components/elements';
import SubtitlesItem from './SubtitlesItem';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import helpers
import { DISABLED_SUBTITLES_ITEM } from 'helpers/player';

// Import icons
import { ReactComponent as IconSubtitles } from 'assets/icons/ic_subtitles.svg';

const Subtitles = () => {
	const { t } = useTranslation();

	const { subtitles } = usePlayerVodContext();

	if (!subtitles.length) return null;

	const subtitlesArray = [DISABLED_SUBTITLES_ITEM, ...subtitles];

	return (
		<PlayerSettings.Menu
			buttonContent={<IconSubtitles />}
			title={t('player_settings_subtitles')}
			type="subtitles"
			ariaText={t('aria_player_settings_subtitles')}
		>
			{subtitlesArray.map(({ id, language, name }) => (
				<SubtitlesItem key={id} language={language} name={name} />
			))}
		</PlayerSettings.Menu>
	);
};

export default Subtitles;

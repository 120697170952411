import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import VisuallyHidden from '@reach/visually-hidden';

// Import utilities
import { DrawerRPC, ToggleRPC } from 'components/utilities';

// Import icons
import { ReactComponent as FavoriteIcon } from 'assets/icons/ic_nav_favorite.svg';

// Import components
import { AuthModal, Loader } from 'components/elements';
import Favourite from './Favourite';

// Import styled components
import { StyledButton } from './styles';

const Favourites = () => {
	const { t } = useTranslation();
	const navButtonRef = useRef(null);
	const headingRef = useRef(null);
	const [deletedProductTitle, setDeletedProductTitle] = useState('');

	const navItemTitle = t('common_favourites_title');

	const { data: favourites, isLoaded } = useSelector(
		({ favourites }) => favourites
	);

	const renderContent = (toggle) =>
		favourites.map((favourite) => (
			<Favourite
				toggle={toggle}
				key={favourite.uuid}
				favourite={favourite}
				setDeletedFavouriteTitle={setDeletedProductTitle}
				headingRef={headingRef}
			/>
		));

	useEffect(() => {
		setDeletedProductTitle('');
	}, [favourites]);

	const isAnnouncementAvailable = deletedProductTitle && isLoaded;

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					<AuthModal>
						<Tooltip placement="bottom" onClick={toggle} title={navItemTitle}>
							<StyledButton
								modifiers={['navIcon']}
								aria-label={navItemTitle}
								ref={navButtonRef}
							>
								<FavoriteIcon aria-hidden="true" title={navItemTitle} />
							</StyledButton>
						</Tooltip>
					</AuthModal>

					<DrawerRPC
						isOpen={on}
						onClose={toggle}
						title={navItemTitle}
						returnFocusRef={navButtonRef}
						headingRef={headingRef}
					>
						{isLoaded ? (
							<>
								{/* eslint-disable-next-line  */}
								<ul role="list">{renderContent(toggle)}</ul>
							</>
						) : (
							<Loader
								zIndex={900}
								position="relative"
								background={false}
								width={8}
								height={8}
								top="2rem"
							/>
						)}
						{isAnnouncementAvailable && (
							<VisuallyHidden role="alert">
								{t('common_header_nav_favourites_product_delete', {
									deletedProductTitle
								})}
							</VisuallyHidden>
						)}
					</DrawerRPC>
				</>
			)}
		</ToggleRPC>
	);
};

export default Favourites;

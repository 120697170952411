import React, { createContext, useState, useRef, useEffect } from 'react';
import {
	oneOfType,
	arrayOf,
	node,
	array,
	object,
	string,
	number
} from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

// Import helpers
import { getUserAgentInfo, checkProductSubtype } from 'helpers';
import { getThumbnailAssets, getAssetInfo } from '../helpers';
import { ASSET_GROUP_TYPES } from 'helpers/variables';

// Import utilities
import { useToggle } from 'components/utilities';
import { useObserver } from '../hooks';

const { VIDEO_2D, VIDEO_360 } = ASSET_GROUP_TYPES;

export const GalleryContext = createContext(null);

const GalleryProvider = ({ assets, metadata, type, galleryId, children }) => {
	const galleryRef = useRef();
	const timeoutIdRef = useRef(null);

	const [galleryIndex, setGalleryIndex] = useState(0);
	const [isDescriptionModalVisible, toggleDescriptionModalVisibility] =
		useToggle();
	const [showFullscreen, toggle] = useToggle();
	const [isNavOpen, toggleNav] = useToggle();

	const handleFullScreenToggle = () => {
		if (!isDescriptionModalVisible && showFullscreen) {
			timeoutIdRef.current = setTimeout(() => toggle(), 300);
			return;
		}

		toggle();
	};

	const observer = useObserver();

	const { isMobile } = getUserAgentInfo();
	const isCompact = useMediaQuery({ query: '(max-width: 900px)' });
	const isMobileView = isMobile || isCompact;

	const { subtype } = useSelector(({ movieDetails }) => movieDetails.data);
	const { isArticle } = checkProductSubtype(subtype);

	const is360Gallery = type.includes('360');
	const isVideo = type === VIDEO_2D || type === VIDEO_360;

	const showGallery = Boolean(assets?.length);
	const numberOfAssets = assets?.length;

	const thumbnailAssets = getThumbnailAssets({ assets });

	const getAssetMetadata = (assetMetadata) =>
		getAssetInfo({ galleryMetadata: metadata, metadata: assetMetadata });

	const getAssetFooter = (assetMetadata) => ({
		author: assetMetadata?.author || metadata?.author,
		source: assetMetadata?.source || metadata?.source,
		description: assetMetadata?.title
	});

	const videoDescription = isVideo
		? assets[galleryIndex]?.metadata?.description
		: null;

	useEffect(() => {
		return () => {
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
				timeoutIdRef.current = null;
			}
		};
	}, []);

	return (
		<GalleryContext.Provider
			value={{
				galleryIndex: galleryIndex ?? 0,
				galleryRef,
				isNavOpen,
				toggleNav,
				galleryId,
				showFullscreen,
				toggle: handleFullScreenToggle,
				assets,
				metadata,
				setGalleryIndex,
				showGallery,
				type,
				isArticle,
				subtype,
				getAssetMetadata,
				numberOfAssets,
				isMobileView,
				is360Gallery,
				observer,
				thumbnailAssets,
				getAssetFooter,
				isVideo,
				isDescriptionModalVisible,
				toggleDescriptionModalVisibility,
				videoDescription
			}}
		>
			{children}
		</GalleryContext.Provider>
	);
};

GalleryProvider.propTypes = {
	galleryId: number,
	assets: array.isRequired,
	metadata: object,
	type: string.isRequired,
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default GalleryProvider;

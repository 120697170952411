import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import helpers
import { PRODUCTS_TYPES } from 'helpers/variables';
import {
	checkIsBeforeAvailability,
	getTileMetadata,
	getFormatedDate,
	createLinkParams
} from 'helpers';

// Import variables
import { DATE_FORMAT } from 'helpers/variables';

const { DATE_WITH_TIME } = DATE_FORMAT;
const { REACT_APP_API_URL } = process.env;
const { EPISODE } = PRODUCTS_TYPES;

const useEpisodeItem = ({ product }) => {
	const params = useParams();
	const season = useSelector(({ tvShowSeason }) => tvShowSeason.data);

	const { title, uuid, is_available: isAvailable, since, number } = product;

	const episodeCover = `${REACT_APP_API_URL}/assets/${uuid}/cover`;

	const showAvailabilityDate =
		!isAvailable && checkIsBeforeAvailability({ since });

	const availablilityDate = getFormatedDate(since, DATE_WITH_TIME);

	const linkParams = createLinkParams({
		id: uuid,
		type: EPISODE,
		title,
		params
	});

	const metadata = getTileMetadata({
		seasonNumber: season?.number,
		episodeNumber: number
	});

	return {
		episodeCover,
		linkParams,
		title,
		metadata,
		isAvailable,
		availablilityDate,
		showAvailabilityDate
	};
};

export default useEpisodeItem;

import i18next from 'i18next';
import services from 'services/services';
import * as types from 'store/actions/types';
import { openNotification } from 'components/utilities/notification/notification';

// Import helpers
import { checkErrorStatus } from '../../helpers/error_status';
import { getCodeErrorMessage } from 'helpers/error_code_api';
import { getProductRequestOptions } from 'helpers';

/**
 * @param {string} id
 * @param {string} type
 * @param {Function} dispatch
 * @return {Promise<void>}
 */
export async function fetchMovieDetails(id, type, dispatch, preview) {
	try {
		dispatch({ type: types.MOVIE_DETAILS_LOADING });

		const { path, options } = getProductRequestOptions({ id, type, preview });
		const { data } = await services.get(path, options);

		dispatch({
			type: types.MOVIE_DETAILS_SUCCESS,
			payload: data
		});
	} catch (error) {
		if (error.response) {
			const { errorCode, message } = error.response.data;

			const errorMessage = getCodeErrorMessage(errorCode) || message;
			error.response.data.message = errorMessage;

			if (errorMessage) {
				dispatch({
					type: types.MOVIE_DETAILS_ERROR,
					payload: error.response.data
				});
			}
		} else {
			dispatch({
				type: types.MOVIE_DETAILS_ERROR
			});
			checkErrorStatus(error) &&
				openNotification({
					type: 'error',
					title: i18next.t('vod_movieDetails_error_title'),
					description: i18next.t('vod_movieDetails_error_description')
				});
		}
	}
}
export async function fetchMovieEpisodesPrevNext(id, dispatch) {
	try {
		const url = `/products/episode/${id}/prevNext`;

		const { data } = await services.get(url);

		dispatch({
			type: types.MOVIE_EPISODES_PREV_NEXT_SUCCESS,
			payload: data
		});
	} catch (error) {
		if (error.response) {
			const { errorCode, message } = error.response.data;

			const errorMessage = getCodeErrorMessage(errorCode) || message;

			if (errorMessage) {
				dispatch({
					type: types.MOVIE_DETAILS_ERROR,
					errorMessage
				});
			}
		} else {
			dispatch({
				type: types.MOVIE_DETAILS_ERROR
			});
			checkErrorStatus(error) &&
				openNotification({
					type: 'error',
					title: i18next.t('vod_movieDetails_error_title'),
					description: i18next.t('vod_movieDetails_error_description')
				});
		}
	}
}

export const clearMovieDetails = () => (dispatch) => {
	dispatch({ type: types.MOVIE_DETAILS_CLEAR });
};

export async function fetchNextEpisodeToWatch(seriesUuid, dispatch) {
	try {
		const url = `/subscriber/bookmarks/watching/getNextToWatch/${seriesUuid}`;

		const { data } = await services.get(url);

		dispatch({
			type: types.NEXT_EPISODE_TO_WATCH_SUCCESS,
			payload: data
		});

		return data;
	} catch {
		dispatch({
			type: types.NEXT_EPISODE_TO_WATCH_ERROR
		});
	}
}

export const setSelectedPriceLimit = (id) => (dispatch) => {
	dispatch({
		type: types.SET_SELECTED_PRICE_LIMIT,
		payload: id
	});
};

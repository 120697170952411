import styled from 'styled-components';

// Import components
import { Loader } from 'components/elements';

export const Empty = styled.p`
	color: ${({ theme: { allMovies } }) => allMovies.breadcrumbs.color};
	font-size: ${({ theme }) => theme.fontSize.set('lg', -0.1)}rem;
	text-align: center;
	margin-top: 5rem;
`;

export const InfiniteLoader = styled(Loader)`
	margin-top: 8rem;
	min-height: 10rem;
`;

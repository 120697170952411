import React from 'react';
import { string } from 'prop-types';

// Import components
import { PlayerSettings } from 'components/elements';

// Import logic
import useSubtitlesItem from './useSubtitlesItem';

const SubtitlesItem = (props) => {
	const { isSelected, ariaText, optionText, setSubtitle } =
		useSubtitlesItem(props);

	return (
		<PlayerSettings.Option
			onSelect={setSubtitle}
			isSelected={isSelected}
			ariaText={ariaText}
		>
			{optionText}
		</PlayerSettings.Option>
	);
};

SubtitlesItem.propTypes = {
	language: string.isRequired,
	name: string
};

export default SubtitlesItem;

import React from 'react';
import { arrayOf, oneOfType, func, node } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import heleprs
import { callAllFns } from 'helpers';

// Import components
import { AriaBaseModal, AriaModalContent, Loader } from 'components/elements';
import EmailConfirmation from './EmailConfirmation';

// Import components
import { CommonButton } from 'components/views/vod/components/buttons';

// Import logic
import usePaymentProtect from './usePaymentProtect';

const PaymentProtect = ({ children, onClick }) => {
	const { t } = useTranslation();

	const isLoading = children?.props?.isLoading;
	const redirectUrl = children?.props?.to;
	const onChildrenClick = children?.props?.onClick;

	const {
		isOpened,
		modalToggle,
		title,
		description,
		isGeolockError,
		isEmailConfirmed,
		isUserDetailsLoading,
		protect
	} = usePaymentProtect({ redirectUrl });

	const titleId = 'payment_protect_title';

	const renderContent = () => {
		if (isGeolockError) {
			return (
				<CommonButton onClick={modalToggle}>
					{t('common_modal_close_button_title')}
				</CommonButton>
			);
		}

		if (!isEmailConfirmed) {
			return <EmailConfirmation />;
		}

		return null;
	};

	return (
		<>
			{React.cloneElement(children, {
				onClick: callAllFns(onClick, protect(onChildrenClick)),
				isLoading: isLoading || isUserDetailsLoading
			})}

			{isUserDetailsLoading && (
				<Loader position="absolute" background={false} />
			)}

			{isOpened && (
				<AriaBaseModal
					titleId={titleId}
					close={modalToggle}
					style={{ width: '47vw', minWidth: '28rem' }}
				>
					<AriaModalContent
						title={t(title)}
						titleId={titleId}
						description={t(description)}
						childrenDirection="column"
					>
						{renderContent()}
					</AriaModalContent>
				</AriaBaseModal>
			)}
		</>
	);
};

PaymentProtect.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	onClick: func
};

export default PaymentProtect;

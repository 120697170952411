import React from 'react';
import { string, func, shape, oneOfType, number } from 'prop-types';

// Import components
import { Wrapper } from 'components/elements/molecules/form/field/styles/index';
import { ListBoxOption, ListBoxSelect } from 'components/elements';

// Import styles
import { SelectWrapper } from './styles';

// Import helpers
import { getSelectedElementId } from 'helpers';
import { SELECT_AGE_OPTIONS } from './helpers';

const ParentalControlSelect = ({
	field: { name, value },
	form: { setFieldValue },
	placeholder
}) => {
	const listId = 'parental_control_select';

	const selectButtonContent = SELECT_AGE_OPTIONS.find(
		(option) => option.value === parseInt(value)
	)?.name;

	const handleChangeValue = (selectedValue) =>
		setFieldValue(name, selectedValue);

	return (
		<Wrapper>
			<SelectWrapper>
				<ListBoxSelect
					isFormField
					listId={listId}
					title={placeholder}
					currentValue={value}
					selectButtonContent={selectButtonContent}
					changeHandler={handleChangeValue}
				>
					{({ handleMouseOptionSelect, handleKeyOptionEvents }) => (
						<>
							{SELECT_AGE_OPTIONS.map((option) => {
								const isSelected = option.value === parseInt(value);
								const selectedId = getSelectedElementId({
									isSelected,
									listId
								});

								return (
									<ListBoxOption
										key={option.value}
										id={selectedId}
										role="option"
										aria-selected={isSelected}
										data-value={option.value}
										tabIndex={0}
										onMouseUp={handleMouseOptionSelect}
										onKeyDown={handleKeyOptionEvents}
									>
										{option.name}
									</ListBoxOption>
								);
							})}
						</>
					)}
				</ListBoxSelect>
			</SelectWrapper>
		</Wrapper>
	);
};

ParentalControlSelect.propTypes = {
	field: shape({
		name: string.isRequired,
		value: oneOfType([string, number]).isRequired
	}),
	form: shape({
		setFieldTouched: func.isRequired
	}),
	placeholder: string.isRequired
};

export default ParentalControlSelect;

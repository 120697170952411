// Import helpers
import { getSearchParametr } from 'helpers';
import {
	VOD_PARAMS,
	QUERY_ORDER,
	QUERY_ORDER_DIR,
	QUERY_TYPES,
	PRODUCTS_TYPES
} from 'helpers/variables';

export const getSearchParams = (searchLocation) => {
	const genre = getSearchParametr(searchLocation, VOD_PARAMS.genre);
	const type = getSearchParametr(searchLocation, VOD_PARAMS.type);
	const order = getSearchParametr(searchLocation, VOD_PARAMS.order);
	const orderDir = getSearchParametr(searchLocation, VOD_PARAMS.orderDir);

	return {
		paramsUrl: {
			genre: genre ? `gatunek=${genre}` : '',
			type: type ? `&typ=${type}` : '',
			order: order ? `&kolejnosc=${order}` : '',
			orderDir: orderDir ? `&sortowanie=${orderDir}` : ''
		},
		params: { genre, type, order, orderDir }
	};
};

export const getSortName = (order, orderDir, sortingName) =>
	order && orderDir
		? `vod_section_details_filters_${QUERY_ORDER[order]}_${QUERY_ORDER_DIR[orderDir]}`
		: sortingName;

export const getDetailsResources = ({ locationSearch, sortingName }) => {
	const { params } = getSearchParams(locationSearch);

	const genre = params.genre || '';
	const order = params.order || '';
	const orderDir = params.orderDir || '';
	const type = params.type || QUERY_TYPES[PRODUCTS_TYPES.ALL];

	// Get sort name value
	const sortName = getSortName(order, orderDir, sortingName);

	return {
		genre,
		order: QUERY_ORDER[order],
		orderDir: QUERY_ORDER_DIR[orderDir],
		type: QUERY_TYPES[type],
		sortName
	};
};

export const getGenreIdFromUrl = ({ locationSearch, genresList }) => {
	const genreParam = getSearchParametr(locationSearch, VOD_PARAMS.genre);

	if (genresList.length > 0 && genreParam) {
		const item = genresList.find(({ slug }) => slug === genreParam);
		const genreId = item && item.id ? item.id : 'all';
		return genreId;
	} else {
		return 'all';
	}
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import styles
import { Wrapper, Name } from './styles';

// Import components
import { PlayerElements } from 'components/elements';

const { IconCastConnected } = PlayerElements;

const CastReceiverName = () => {
	const { t } = useTranslation();
	const { receiverName, isCasting } = usePlayerVodContext();

	return (
		<Transition
			in={isCasting}
			timeout={{ enter: 0, exit: 500 }}
			mountOnEnter
			unmountOnExit
		>
			{(state) => (
				<Wrapper className={state} aria-hidden tabIndex="-1">
					<IconCastConnected modifiers="receiver" />
					<Name>
						{t('player_cast_receiver_info')} {receiverName}
					</Name>
				</Wrapper>
			)}
		</Transition>
	);
};

export default CastReceiverName;

import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

// Import styles
import { scroll, focusBasic } from 'components/styles';

export const List = styled.ul`
	margin-top: 0.5rem;
	background-color: ${({ theme, isContrast }) =>
		isContrast ? theme.darkBrown : theme.primaryBgBlack};
	padding: 0;
	border-radius: 4px;
	list-style: none;
	min-width: 23rem;
	width: 100%;
	max-height: 75vh !important;
	overflow-y: scroll;
	${({ isContrast }) =>
		scroll({
			track: 'transparent',
			thumb: isContrast ? 'primaryBgBlack' : 'darkBrown',
			scrollbarWidth: 6
		})}

	${({ isSubmenu }) =>
		isSubmenu &&
		css`
			padding: 1.6rem 0;
			gap: 1.6rem;
		`}
`;

export const Item = styled.li`
	padding: 0 2.6rem;
	list-style: none;
`;

const baseLink = css`
	font-size: ${({ theme }) => theme.fontSize.nav.link}rem;
	line-height: 3.4rem;
	text-align: left;
	text-transform: capitalize;
	color: ${({ theme }) => theme.dropdown.submenu.textColor};

	&:focus-visible {
		${focusBasic}
		outline-offset: -0.3rem;
	}

	&[data-selected] {
		color: ${({ theme }) => theme.dropdown.submenu.textColor};
	}
`;

export const StyledNavLink = styled(NavLink)`
	${baseLink}
`;

export const AnchorLink = styled.a`
	${baseLink}
`;

export const SubNavigationList = styled.ul`
	display: ${({ isOpened }) => (isOpened ? 'flex' : 'none')};
	flex-direction: column;
	list-style: none;
	position: absolute;
	min-width: 23rem;
	max-height: 75vh !important;
	margin-top: 3rem;
	padding: 1.6rem 0;
	background-color: ${({ theme, isContrast }) =>
		isContrast ? theme.darkBrown : theme.primaryBgBlack};
	border-radius: 0.4rem;
	border: ${({ theme, isContrast }) =>
		isContrast ? theme.darkBrown : theme.primaryBgBlack};
	border-style: solid;
	border-width: 0.5rem 0;
	overflow-y: scroll;
	z-index: 10;

	${({ isContrast }) =>
		scroll({
			track: 'transparent',
			thumb: isContrast ? 'primaryBgBlack' : 'darkBrown',
			scrollbarWidth: 6
		})}
`;

export const StyledListItem = styled.li`
	a {
		&:hover,
		&:focus {
			color: ${({ theme }) => theme.dropdown.submenu.textColorOnHover};
		}
	}
`;

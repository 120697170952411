import { useEffect } from 'react';
import { bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { playerOnCancel, clearMovieDetails } from 'store/actions';

// Import utilities
import { usePip } from 'components/utilities';

// Import helpres
import { PLAYER_SELECTORS } from 'helpers/variables';
import { fetchMovieResources } from './helpers';

const playerSelector = PLAYER_SELECTORS.VOD;

export function useLogic({ isArticle, autoplay = true }) {
	const { isReady, isLoaded } = useSelector((state) => state[playerSelector]);

	const {
		error,
		errorMessage,
		loading: isDetailsLoaded
	} = useSelector((state) => state.movieDetails);
	const dispatch = useDispatch();
	const { type, id } = useParams();

	// Set use pip
	const { exitPip } = usePip();

	useEffect(() => {
		fetchMovieResources({
			id,
			isArticle,
			type
		})(dispatch);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		return () => {
			// Clear player
			playerOnCancel(playerSelector)(dispatch);
			// Clear movie details
			clearMovieDetails()(dispatch);
			// Exit pip
			exitPip();
		};
		// eslint-disable-next-line
	}, []);

	return {
		isReady,
		isLoaded,
		isDetailsLoaded,
		error,
		errorMessage,
		type: playerSelector,
		autoplay
	};
}

useLogic.propTypes = { isArticle: bool, autoplay: bool };

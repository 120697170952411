import React from 'react';
import { string, func, bool, number } from 'prop-types';

// Import icons
import { ReactComponent as CloseIcon } from 'assets/icons/ic_close_thin.svg';

// Import styles
import {
	Wrapper,
	SearchIconStyled,
	FormStyled,
	InputStyled,
	ButtonStyled
} from './styles';

import { useTranslation } from 'react-i18next';
import { HiddenLabel } from 'components/elements/atoms/HiddenLabel';

const SearchBar = ({
	searchQuery,
	setSearchQuery,
	clearSearchQuery,
	placeholder,
	showClearButton,
	ariaLabel,
	label,
	searchIconWidth,
	searchIconHeight
}) => {
	// Prevent default form actions
	const handleOnSubmit = (event) => event.preventDefault();
	const { t } = useTranslation();

	return (
		<Wrapper className="search_bar">
			<SearchIconStyled
				width={searchIconWidth}
				height={searchIconHeight}
				role="img"
				aria-hidden="true"
			/>
			<FormStyled onSubmit={handleOnSubmit} showClearButton={showClearButton}>
				<HiddenLabel htmlFor="search_query">{label}</HiddenLabel>
				<InputStyled
					name="search_query"
					type="text"
					id="search_query"
					placeholder={placeholder}
					onChange={setSearchQuery}
					value={searchQuery}
					aria-label={ariaLabel}
				/>
			</FormStyled>
			{showClearButton && (
				<ButtonStyled
					isVisible={searchQuery}
					title={t('common_search_clear_button_title')}
					onClick={clearSearchQuery}
				>
					<CloseIcon />
				</ButtonStyled>
			)}
		</Wrapper>
	);
};

SearchBar.defaultProps = {
	searchQuery: '',
	setSearchQuery: () => {},
	clearSearchQuery: () => {},
	placeholder: '',
	showClearButton: true,
	ariaLabel: '',
	label: '',
	searchIconWidth: 2.4,
	searchIconHeight: 2.4
};

SearchBar.propTypes = {
	searchQuery: string.isRequired,
	setSearchQuery: func.isRequired,
	clearSearchQuery: func.isRequired,
	placeholder: string,
	showClearButton: bool,
	ariaLabel: string.isRequired,
	label: string.isRequired,
	searchIconWidth: number,
	searchIconHeight: number
};

export default SearchBar;

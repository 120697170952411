import React from 'react';
import { useTranslation } from 'react-i18next';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import styles
import { InputSlider, Track, Range, Handle } from './styles';

const Slider = () => {
	const { t } = useTranslation();
	const { volume, setVolume } = usePlayerVodContext();

	const getAriaLabel = () => t('aria_player_settings_volume_slider');
	const getAriaValueText = (value) => `${value}%`;

	return (
		<InputSlider
			value={volume}
			min={0}
			max={100}
			step={10}
			orientation="vertical"
			onChange={setVolume}
			getAriaLabel={getAriaLabel}
			getAriaValueText={getAriaValueText}
			title={t('aria_player_settings_volume_slider')}
		>
			<Track>
				<Range />
				<Handle />
			</Track>
		</InputSlider>
	);
};

export default Slider;

import i18next from 'i18next';

// NO CONTENT MESSAGES

const DEFAULT_NO_CONTENT_MESSAGE = {
	title: i18next.t('common_no_content_message_header'),
	description: i18next.t('common_no_content_message_description')
};

const SEARCH_NO_CONTENT_MESSAGE = {
	title: i18next.t('common_no_search_results'),
	description: ''
};

export const getNoContentMessage = (isSearch) =>
	isSearch ? SEARCH_NO_CONTENT_MESSAGE : DEFAULT_NO_CONTENT_MESSAGE;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import PreviewPopover from './PreviewPopover';
import ProgressBar from './ProgressBar';

// Import styles
import { Root, Point, DotContainer, Dot, StrapsContainer } from './styles';

// Import logic
import { useSlider } from './useSlider';

const ShakaSlider = ({ isVisible }) => {
	const { t } = useTranslation();

	const {
		mouseDown,
		click,
		containerRef,
		pointRef,
		translateX,
		shownPercent,
		cursorPosition,
		onContainerMouseMove,
		currentTime
	} = useSlider();

	return (
		<Root
			ref={containerRef}
			onMouseMove={onContainerMouseMove}
			isVisible={isVisible}
			aria-label={t('aria_player_progress_bar')}
		>
			<PreviewPopover cursorPosition={cursorPosition} />
			<StrapsContainer onClick={click}>
				<ProgressBar currentTime={currentTime} />
			</StrapsContainer>
			<Point ref={pointRef} translateX={translateX} leftPercent={shownPercent}>
				<DotContainer>
					<Dot onMouseDown={mouseDown} />
				</DotContainer>
			</Point>
		</Root>
	);
};

ShakaSlider.propTypes = {
	isVisible: PropTypes.bool
};

export default ShakaSlider;

import styled, { css } from 'styled-components';

// Import styles utilities
import { focusBasic, customMinResponsive, size } from 'components/styles';

export const StyledButton = styled.button`
	border: none;
	background: transparent;
	margin-left: 1rem;
	padding: 0.2rem;
	transform: scale(0.8);

	&:hover,
	&:focus,
	&:focus-visible {
		cursor: pointer;
		outline: none;

		svg {
			path:not(:last-child),
			rect {
				stroke: ${({ theme }) => theme.primaryBlueLight};
			}

			path:last-child {
				fill: ${({ theme }) => theme.primaryBlueLight};
			}
		}
	}

	&:focus-visible {
		${focusBasic}
	}

	${customMinResponsive(
		size.xs,
		css`
			margin-left: 2.6rem;
			transform: scale(1);
		`
	)}
`;

import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';

// Import api
import { GET_LAST_GATEWAY_API, QUERY_PAYMENT_LAST_GATEWAY } from 'helpers/api';

const useLastGatewayQuery = ({
	onSuccess = () => {},
	onError = () => {},
	enabled = true
} = {}) => {
	const queryFn = () => services.get(GET_LAST_GATEWAY_API);

	const { data, status, isLoading, isFetching, isError, remove } = useQuery({
		queryKey: QUERY_PAYMENT_LAST_GATEWAY,
		queryFn,
		onSuccess,
		onError,
		enabled
	});

	useEffect(() => {
		return () => remove();
		//eslint-disable-next-line
	}, []);

	const lastGateway = data?.data;

	return {
		lastGateway,
		lastGatewayStatus: status,
		isLastGatewayLoading: isLoading || isFetching,
		isLastGatewayError: isError
	};
};

export default useLastGatewayQuery;

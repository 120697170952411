import { useEffect, useRef, useReducer } from 'react';

// IMPORT TYPES, INITIALSTATE, REDUCER
import { TYPES, initialState, sliderReducer } from './helpers';

// Import utilities
import { useEventListener } from 'components/utilities';

// Import helpers
import { EVENTS_NAMES } from 'helpers/variables';

// Import utilities
import { useCurrentTime } from 'components/utilities';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const { MOUSEMOVE, MOUSEUP, RESIZE } = EVENTS_NAMES;

const percent = (fraction, totality) => (100 * fraction) / totality;

export function useSlider() {
	const [state, dispatch] = useReducer(sliderReducer, initialState);
	const prev = useRef({});
	const containerRef = useRef(null);
	const pointRef = useRef(null);

	const { duration, updateTime } = usePlayerVodContext();

	// this function starts dragging action
	const mouseDown = (event) => {
		const { offsetLeft } = pointRef.current;
		const { offsetWidth } = containerRef.current;

		prev.current = {
			clientX: event.clientX,
			maxInLeft: offsetLeft,
			maxInRight: offsetWidth - offsetLeft
		};

		dispatch({ type: TYPES.SET_DRAGGABLE, payload: true });
	};

	const mouseMove = (event) => {
		const { draggable } = state;

		if (draggable) {
			const { clientX: prevClientX, maxInLeft, maxInRight } = prev.current;

			const difference = event.clientX - prevClientX;
			let translateX;

			if (difference < 0 && Math.abs(difference) > maxInLeft) {
				translateX = -maxInLeft;
			} else if (difference > maxInRight) {
				translateX = maxInRight;
			} else {
				translateX = difference;
			}

			// set translateX
			dispatch({ type: TYPES.SET_TRANSLATE_X, payload: translateX });
		}
	};

	const mouseUp = (event) => {
		const { draggable, contWidth } = state;
		if (draggable) {
			const { clientX: prevClientX, maxInLeft, maxInRight } = prev.current;

			const difference = event.clientX - prevClientX;

			const newLeftPix = maxInLeft + difference;
			let newLeftPercent = 0;

			if (difference < 0 && Math.abs(difference) > maxInLeft) {
				newLeftPercent = 0;
			} else if (difference > maxInRight) {
				newLeftPercent = 100;
			} else {
				newLeftPercent = percent(newLeftPix, contWidth);
			}

			updateTime((duration * newLeftPercent) / 100);
			// set translateX
			dispatch({ type: TYPES.SET_TRANSLATE_X, payload: 0 });
			// set draggable
			dispatch({ type: TYPES.SET_DRAGGABLE, payload: false });
			// set shown percent
			dispatch({ type: TYPES.SET_SHOWN_PERCENT, payload: newLeftPercent });
		}
	};

	const click = (event) => {
		const { left } = event.target.getBoundingClientRect();
		const { contWidth } = state;
		const newLeftPix = event.clientX - left;
		const newLeftPercent = percent(newLeftPix, contWidth);

		updateTime((duration * newLeftPercent) / 100);
		// set shown percent
		dispatch({ type: TYPES.SET_SHOWN_PERCENT, payload: newLeftPercent });
	};

	const updateContainerData = () => {
		if (containerRef.current) {
			// set left percent
			const { offsetWidth } = containerRef.current;
			dispatch({ type: TYPES.SET_CONT_WIDTH, payload: offsetWidth });
		}
	};

	const onContainerMouseMove = ({ clientX }) => {
		const container = containerRef.current;
		const { left } = container.getBoundingClientRect();
		const cursorPosition = (clientX - left) / container.offsetWidth;
		// set cursor position
		dispatch({ type: TYPES.SET_CURSOR_POSITION, payload: cursorPosition });
	};

	useEffect(() => {
		updateContainerData();
	}, [containerRef]);

	const { currentTime } = useCurrentTime((currentTime) => {
		const newLeftPercent = percent(currentTime, duration);
		// set shown percent
		dispatch({ type: TYPES.SET_SHOWN_PERCENT, payload: newLeftPercent });
	});

	// events
	useEventListener(MOUSEMOVE, mouseMove, window);
	useEventListener(MOUSEUP, mouseUp, window);
	useEventListener(RESIZE, updateContainerData, window);

	return {
		mouseDown,
		click,
		containerRef,
		pointRef,
		onContainerMouseMove,
		currentTime,
		...state
	};
}

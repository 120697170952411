import services from 'services/services';

// Import api
import { GET_DOCUMENT_BY_ALIAS_API } from 'helpers/api';

// Import helpers
import { replaceStringChar } from 'helpers';

export const fetchDocumentByAlias = (type) => {
	const url = replaceStringChar(GET_DOCUMENT_BY_ALIAS_API, { ':type': type });
	return services.get(url);
};

import styled, { css } from 'styled-components';
// Import styles
import { customMinResponsive, size, flex } from 'components/styles';

// Import components
import { Input } from 'components/elements';

export const CodeFieldStyled = styled.div`
	${flex({ ai: 'flex-start', jc: 'space-between' })}
	flex-direction: column;
	width: ${({ theme }) => (theme.isWCAG ? 30 : 15)}rem;
	margin-bottom: 1.5rem;

	${customMinResponsive(
		size.medium,
		css`
			width: ${({ theme: { isWCAG } }) => (isWCAG ? '100%' : '10.9rem')};
			flex-direction: row;
			margin: ${({ theme: { isWCAG } }) => (isWCAG ? '4rem 0' : '0 1rem 0 0')};
		`
	)}
`;

export const StyledInput = styled(Input)`
	text-align: center;
	color: ${({ theme, error }) =>
		error ? theme.red : theme.user.tableItemName};
	font-size: ${({ theme }) => theme.fontSize.set('md', 0.4)}rem;
	${({ theme }) => theme.isWCAG && 'height: fit-content'};
`;

import styled, { css } from 'styled-components';
import { Select } from 'antd';

// Import styles
import {
	customMinResponsive,
	size,
	customResponsive,
	flex
} from 'components/styles';

const { Option } = Select;

const StyledSelect = styled(Select)`
	width: 100%;

	${customResponsive(
		size.medium,
		css`
			padding: 0 1.8rem;
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			width: 30rem;
		`
	)}
`;

const OptionWrapper = styled.span`
	${flex({ jc: 'flex-start' })}
`;

export { StyledSelect, Option, OptionWrapper };

import styled, { keyframes } from 'styled-components';

const load = keyframes`
    to{transform: rotate(1turn)}
`;

export const SpinnerContent = styled.div`
	width: ${({ size }) => size};
	padding: ${({ thickness }) => thickness};
	background: ${({ color }) => color};

	aspect-ratio: 1;
	border-radius: 50%;
	-webkit-mask: ${({ theme }) => theme.payment.verification.spinner.gradient};
	mask: ${({ theme }) => theme.payment.verification.spinner.gradient};
	-webkit-mask-composite: source-out;
	mask-composite: subtract;
	box-sizing: border-box;
	animation: ${load} 2s linear infinite;
`;

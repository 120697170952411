import React from 'react';
import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import { Progress } from '../styles';

// Import logic
import useProgressBar from './useProgressBar';

const ProgressBar = React.memo(
	({ currentTime }) => {
		const { t } = useTranslation();

		const { valueText, value, duration, keyDown } = useProgressBar(currentTime);

		return (
			<div
				tabIndex={0}
				role="slider"
				onKeyDown={keyDown}
				aria-valuemin={0}
				aria-valuenow={value}
				aria-valuemax={duration}
				aria-label={t('aria_player_progress_bar')}
				aria-valuetext={valueText}
			>
				<Progress value={value} max={duration} />
			</div>
		);
	},
	(prev, next) => prev.currentTime === next.currentTime
);

ProgressBar.propTypes = {
	currentTime: number.isRequired
};

export default ProgressBar;

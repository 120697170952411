import styled from 'styled-components';

// Import styles
import { flex } from 'components/styles';

export const ControllersWrapper = styled.div`
	position: relative;
	column-gap: 2rem;
	${flex()}
`;

export const QualityName = styled.span`
	border: 2px solid ${({ theme }) => theme.white};
	border-radius: 3px;
	width: ${({ theme }) => (theme.isWCAG ? 10 : 5.1)}rem;
	height: ${({ theme }) => (theme.isWCAG ? 'fit-content' : '2.5rem')};
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.3)}rem;
	${flex()};
`;

export const Timer = styled.span`
	font-size: ${({ theme }) => theme.fontSize.xs}rem;
	color: ${({ theme }) => theme.white};
`;

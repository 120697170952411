import styled, { css } from 'styled-components';
import { MenuButton, MenuItems } from '@reach/menu-button';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { flex, transitionMain } from 'components/styles';

// Import components
import { Heading } from 'components/elements';

const BUTTON_MODIFIERS = {
	subtitles: ({ theme }) => css`
		&:hover,
		&:focus {
			svg {
				path {
					stroke: ${theme.primaryBlueLight};
				}
			}
		}
	`,
	languages: ({ theme }) => css`
		&:hover,
		&:focus {
			svg {
				path {
					fill: ${theme.primaryBlueLight};
				}
			}
		}
	`,
	quality: ({ theme }) => css`
		&:hover,
		&:focus {
			& > span {
				border-color: ${theme.primaryBlueLight};
				color: ${theme.primaryBlueLight};
			}
		}
	`
};

export const Wrapper = styled.div`
	position: relative;
	height: 100%;
`;

export const SettingsButton = styled(MenuButton)`
	background: transparent;
	border: none;
	height: 100%;
	${flex()}

	path,
	span {
		${transitionMain}
	}

	&:hover,
	&:focus {
		outline: none;
	}

	${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export const SettingsPopover = styled.div`
	position: absolute;
	bottom: ${({ bottom = 3.9 }) => bottom}rem;
	left: 50%;
	transform: translateX(-50%);
	border: 1px solid rgb(255, 255, 255, 0.1);
	background: ${({ theme }) => theme.blackBg};
	border-radius: 6px;
	${flex({ fd: 'column', jc: 'flex-start' })}
	padding: 1rem;
	min-height: 10rem;

	${({ width }) =>
		width
			? css`
					width: ${width}rem;
			  `
			: css`
					min-width: 15rem;
			  `}
`;

export const SettingsList = styled(MenuItems)`
	background: transparent;
`;

export const HeadingStyled = styled(Heading.Third)`
	margin: 0;
`;

export const Option = styled.div`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	margin-bottom: 0.6rem;
	transition: all 0.3 ease-in-out;
	cursor: pointer;
	color: ${({ theme, isSelected }) => (isSelected ? theme.white : '#656461')};

	${flex()}

	svg {
		visibility: hidden;
		opacity: 0;
		margin-right: 0.5rem;
	}

	&:hover,
	&:focus {
		color: ${({ theme }) => theme.primaryBlueLight};
	}
`;

import { useEffect, useRef } from 'react';

const useDelayFocus = () => {
	const timeoutIdRef = useRef(null);

	const focusElement = (elementRef) => {
		if (elementRef.focus) return elementRef.focus();
		else if (elementRef.current) return elementRef.current.focus();

		return;
	};

	const delayFocus = (elementRef, delay) => {
		// Clear previous timeout if it exists
		clearFocusTimeout();

		const delayTime = delay ? delay : 0;

		timeoutIdRef.current = setTimeout(
			() => focusElement(elementRef),
			delayTime
		);
	};

	const clearFocusTimeout = () => {
		if (timeoutIdRef.current) {
			clearTimeout(timeoutIdRef.current);
			timeoutIdRef.current = null;
		}
	};

	useEffect(() => {
		return () => {
			clearFocusTimeout();
		};
	}, []);

	return { delayFocus };
};

export default useDelayFocus;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Import styles
import {
	AlertWrapper,
	AlertText,
	CloseButton,
	StyledCloseIcon
} from './styles';

const Alert = ({
	text,
	testid,
	fixed,
	absolute,
	action,
	closeBtn,
	centered,
	...rest
}) => {
	const [isVisible, setIsVisible] = useState(true);

	const handleClick = () => {
		action();
		setIsVisible(false);
	};

	return (
		isVisible && (
			<AlertWrapper
				{...rest}
				absolute={absolute}
				fixed={fixed}
				centered={centered}
				data-testid={testid}
			>
				<AlertText role="alert">{text}</AlertText>
				{closeBtn && (
					<CloseButton onClick={handleClick}>
						<StyledCloseIcon />
					</CloseButton>
				)}
			</AlertWrapper>
		)
	);
};

Alert.defaultProps = {
	fixed: false,
	absolute: false,
	action: () => {},
	closeBtn: true,
	centered: false
};

Alert.propTypes = {
	text: PropTypes.string.isRequired,
	testid: PropTypes.string,
	fixed: PropTypes.bool,
	absolute: PropTypes.bool,
	action: PropTypes.func,
	closeBtn: PropTypes.bool,
	centered: PropTypes.bool
};

export default Alert;

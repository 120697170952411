import React from 'react';
import { func, string, shape, instanceOf, oneOfType } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { VisuallyHidden } from '@reach/visually-hidden';

// Import styled components
import {
	Wrapper,
	StyledLink,
	ProductTitle,
	ProductType,
	TrashIconStyled,
	ButtonStyled
} from '../../common_styles.js';

// Import logic
import useFavourite from './useFavourite';

// Import utilities
import { useToggle } from 'components/utilities/index.js';

// Import components
import ConfirmationDeletingModal from 'components/layout/nav/nav_toolbar/confirmation_deleting';

const Favourite = ({
	favourite,
	toggle,
	setDeletedFavouriteTitle,
	headingRef
}) => {
	const { t } = useTranslation();
	const { link, deleteFavourite, productAlias } = useFavourite({
		favourite
	});
	const { title } = favourite;

	const [isModalVisible, toggleModalVisibility] = useToggle();

	const handleFavouriteDelete = () => {
		setDeletedFavouriteTitle(title);
		deleteFavourite();
		toggleModalVisibility();
		headingRef.current.focus();
	};

	return (
		<>
			{isModalVisible && (
				<ConfirmationDeletingModal
					title={t('common_favourites_deleting_confirmation_modal_title')}
					deleteFn={handleFavouriteDelete}
					toggle={toggleModalVisibility}
				/>
			)}

			<Wrapper>
				<StyledLink to={link} onClick={toggle}>
					{title && <ProductTitle>{title}</ProductTitle>}
					<ProductType>{productAlias}</ProductType>
				</StyledLink>
				<ButtonStyled onClick={toggleModalVisibility}>
					<TrashIconStyled aria-hidden />
					<VisuallyHidden>{t('common_product_remove')}:</VisuallyHidden>
					<VisuallyHidden>{title}</VisuallyHidden>
				</ButtonStyled>
			</Wrapper>
		</>
	);
};

Favourite.propTypes = {
	toggle: func.isRequired,
	favourite: shape({
		uuid: string.isRequired,
		subtype: string.isRequired,
		title: string
	}),
	setDeletedFavouriteTitle: func.isRequired,
	headingRef: oneOfType([func, shape({ current: instanceOf(Element) })])
};

export default Favourite;

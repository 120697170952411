import React, { useRef, useEffect } from 'react';
import { array, object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import VisuallyHidden from '@reach/visually-hidden';
import { useSelector } from 'react-redux';

// Import styles
import {
	ThumbnailsWrapper,
	ThumbnailsButton,
	ThumbnailsSlider,
	ThumbnailChevron,
	ThumbnailTab,
	CustomSlider
} from './styles';

// Import components
import GalleryArrow from '../GalleryArrow/GalleryArrow';

// Import context
import useGalleryContext from 'components/elements/organisms/product_gallery/context/useGalleryContext';

// Import helpers
import { SLIDER_ARROW_TYPES } from 'helpers/variables';
import { thumbnailSliderSettings } from 'components/elements/organisms/product_gallery/helpers';

const { PREV, NEXT } = SLIDER_ARROW_TYPES;

function GalleryThumbnails({ thumbnailAssets, sliderRef, isVideo }) {
	const { t } = useTranslation();

	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	const thumbnailRef = useRef();
	const sliderWrapperRef = useRef();
	const { galleryIndex, isNavOpen, toggleNav, getAssetMetadata } =
		useGalleryContext();

	useEffect(() => {
		if (isNavOpen) {
			const slickTrack = sliderWrapperRef.current.querySelector('.slick-track');
			const slickTrackElements = slickTrack.children;

			slickTrack.setAttribute('role', 'list');

			[...slickTrackElements].forEach((element) => {
				element.setAttribute('role', 'listitem');
			});
		}
	}, [isNavOpen]);

	// There is no need for controls when one or less slides
	if (thumbnailAssets.length <= 1) return null;

	const moveSlide = (index) => () => {
		if (sliderRef?.current) {
			sliderRef.current.slickGoTo(index);
		}
		thumbnailRef.current.slickGoTo(index);
	};

	return (
		<>
			<ThumbnailsButton
				onClick={toggleNav}
				open={isNavOpen}
				aria-expanded={isNavOpen}
			>
				{isNavOpen ? t('vod_movieDetails_less') : t('vod_movieDetails_more')}
				<VisuallyHidden>{t('common_gallery_thumbnail_info')}</VisuallyHidden>
				<ThumbnailChevron open={isNavOpen} aria-hidden role="img" />
			</ThumbnailsButton>
			{isNavOpen && (
				<ThumbnailsWrapper isVideo={isVideo}>
					<ThumbnailsSlider ref={sliderWrapperRef}>
						<CustomSlider
							ref={thumbnailRef}
							initialSlide={galleryIndex}
							nextArrow={<GalleryArrow type={NEXT} tabIndex={-1} />}
							prevArrow={<GalleryArrow type={PREV} tabIndex={1} />}
							{...thumbnailSliderSettings}
						>
							{thumbnailAssets.map(({ id, url, metadata }, index) => {
								const { description } = getAssetMetadata(metadata);
								return (
									<div key={id} tabIndex={0}>
										<ThumbnailTab
											data-selected={galleryIndex === index}
											aria-current={galleryIndex === index}
											onClick={moveSlide(index)}
											title={t('aria_gallery_show_slide')}
											isContrast={isContrast}
										>
											<img src={url} alt={description} />
										</ThumbnailTab>
									</div>
								);
							})}
						</CustomSlider>
					</ThumbnailsSlider>
				</ThumbnailsWrapper>
			)}
		</>
	);
}

GalleryThumbnails.defaultProps = {
	thumbnailAssets: []
};

GalleryThumbnails.propTypes = {
	thumbnailAssets: array,
	sliderRef: object,
	isVideo: bool
};

export default React.memo(GalleryThumbnails);

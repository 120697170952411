import styled, { css } from 'styled-components';

// Import styles
import { transitionMain, flex, focusBasic } from 'components/styles';

const linkStyles = css`
	color: ${({ theme }) => theme.authentication.linkColor};
	text-decoration: underline;
	cursor: pointer;
	${transitionMain}

	&:focus-visible {
		color: ${({ theme }) => theme.authentication.linkHoverColor};
		${focusBasic}
	}

	&:hover {
		color: ${({ theme }) => theme.authentication.linkHoverColor};
		outline: none;
	}
`;

export const List = styled.ul`
	list-style: none;
	margin-top: 3rem;
`;

export const Item = styled.li`
	margin-bottom: ${({ theme }) => (theme.isWCAG ? '2em' : '1.7rem')};
	margin-left: ${({ indented }) => (indented ? '1.9rem' : '0')};
	display: grid;
	grid-template-columns: 5.7rem auto;
	align-items: ${({ alignItems }) => alignItems};
`;

export const Label = styled.label`
	color: ${({ theme }) => theme.authentication.agreements.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	text-align: left;
	cursor: pointer;
	position: relative;

	${({ isMarketingAgreement }) =>
		isMarketingAgreement &&
		css`
			padding-top: 2px;
			${flex({ fd: 'column', ai: 'flex-start' })}
		`}

	pre {
		margin: 0;
		font-family: 'Roboto', sans-serif;
	}

	a {
		${linkStyles}
	}

	/* adds prefix before obligatory agreements */
	${({ obligatory }) =>
		obligatory &&
		css`
			&::before {
				position: absolute;
				top: 0;
				left: -1rem;
				content: '*';
			}
		`}
`;

export const Button = styled.button`
	border: none;
	background: transparent;
	${linkStyles}
	margin-left: 4px;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
`;

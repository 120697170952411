// API
export const GET_PRODUCT_PRICES_API = '/products/:id/prices';
export const GET_PRODUCT_DETAILS_API = '/products/type/id';
export const GET_NEW_PRODUCT_DETAILS_API = '/products/id/details';
export const GET_AVAILABLE_PASSES_FOR_PRODUCT =
	'/products/:productId/availableProductInPackets/pass';

// ---- products list ----
export const GET_PACKET_PRODUCTS_LIST_API = '/products/packet/id/products';
export const GET_PASS_PRODUCTS_LIST_API = '/promotions/id/products';

// Queries
export const QUERY_PACKET_PRODUCTS_LIST = 'QUERY_PACKET_PRODUCTS_LIST';

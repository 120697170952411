import styled, { css } from 'styled-components';
import { customMinResponsive, size, focusBasic } from 'components/styles';

export const List = styled.ul`
	margin-bottom: 2.6rem;
	display: flex;
	list-style: none;
`;

export const Item = styled.li`
	padding: 0 1.45rem;
	position: relative;
	cursor: pointer;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		content: '';
		display: block;
		height: 100%;
		width: 1.5px;
		background-color: ${({ theme }) => theme.white};
		opacity: 0.4;
	}

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;

		&:after {
			display: none;
		}
	}
`;

export const Language = styled.button`
	cursor: pointer;
	border: none;
	outline: none;
	background: none;
	font-weight: ${({ theme }) => theme.font.normal};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;

	&:hover,
	&:focus-visible {
		color: ${({ theme }) => theme.body.footer.hover};
	}

	&:focus-visible {
		${focusBasic}
	}

	${({ isSelected }) =>
		isSelected &&
		css`
			font-weight: ${({ theme }) => theme.font.medium};
		`}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.xs}rem;
		`
	)}
`;

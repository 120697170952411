import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

// Import styled components
import { FormError } from './styles';

const ReCaptchaField = ({
	field,
	form: { touched, errors },
	theme = 'dark',
	...rest
}) => {
	const {
		i18n: { language }
	} = useTranslation();

	const isErrorVisible = touched[field.name] && errors[field.name];
	const errorText = errors[field.name] || '';

	const handleOnChange = (event) => field.onChange(field.name)(event);
	return (
		<ReCaptchaFieldStyled>
			<Box>
				<ReCAPTCHA
					sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
					onChange={handleOnChange}
					theme={theme}
					hl={language}
				/>
			</Box>
			<input {...field} {...rest} type="hidden" />

			<FormError
				isErrorVisible={isErrorVisible}
				role="alert"
				aria-atomic="true"
				id={`${field.name}Alert`}
			>
				{errorText}
			</FormError>
		</ReCaptchaFieldStyled>
	);
};

const ReCaptchaFieldStyled = styled.div`
	margin-bottom: 2.5rem;
`;

const Box = styled.div`
	display: flex;
	justify-content: center;
`;

ReCaptchaField.propTypes = {
	name: PropTypes.string,
	field: PropTypes.object.isRequired,
	form: PropTypes.shape({
		touched: PropTypes.object.isRequired,
		errors: PropTypes.object.isRequired
	}),
	theme: PropTypes.string
};

export default ReCaptchaField;

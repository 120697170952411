import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import helpers
import { PRODUCT_TYPES_MAPPED } from 'helpers';

// Import variables
import { STATUS_TYPES } from 'helpers/variables';

// Import utilities
import {
	useProductPricesQuery,
	useProductDetailsQuery,
	useSubscriberPromotionsQuery,
	useLastGatewayQuery,
	useAccountBalanceQuery
} from 'components/utilities';

// Import context actions
import { setUserPromotions, setPaymentMethods } from '../store/actions';

const { loading, error } = STATUS_TYPES;

const useResources = ({
	state: { paymentMethods },
	stateDispatch,
	isWalletPayment,
	update_subscription,
	provider
}) => {
	const { id: productId, type } = useParams();

	const userPaymentMethods = useSelector(
		({ auth }) => auth.authenticate.payment_methods
	);

	const isPaymentMethods = !!paymentMethods.length;

	const productType = PRODUCT_TYPES_MAPPED[type];

	const handleSetPaymentMethods = ({ data }) => {
		// filter methods
		const methods = data.filter(({ payment_method }) =>
			update_subscription
				? payment_method.includes(provider)
				: userPaymentMethods.includes(payment_method)
		);
		// set payment methods
		setPaymentMethods(methods)(stateDispatch);
	};

	const handleSetUserPromotions = ({ data }) => {
		// set user available promotions
		setUserPromotions(data)(stateDispatch);
	};

	const { productPrices, productPricesStatus } = useProductPricesQuery({
		uuid: productId,
		onSuccess: handleSetPaymentMethods,
		enabled: !isWalletPayment
	});

	const { productDetails, productDetailsStatus } = useProductDetailsQuery({
		uuid: productId,
		type: productType,
		enabled: !isWalletPayment
	});

	const { subscriberPromotions, subscriberPromotionsStatus } =
		useSubscriberPromotionsQuery({
			onSuccess: handleSetUserPromotions,
			enabled: !isWalletPayment
		});

	const { lastGateway, lastGatewayStatus } = useLastGatewayQuery();

	const { accountBalance, accountBalanceStatus } = useAccountBalanceQuery({
		enabled: !isWalletPayment
	});

	const statuses = [
		productPricesStatus,
		productDetailsStatus,
		subscriberPromotionsStatus,
		lastGatewayStatus,
		accountBalanceStatus
	];

	const isResourcesLoading = statuses.some((status) => status === loading);
	const isResourcesError = statuses.some((status) => status === error);

	return {
		isPaymentMethods,
		isResourcesLoading,
		isResourcesError,
		productId,
		productPrices,
		productDetails,
		subscriberPromotions,
		lastGateway,
		accountBalance
	};
};

export default useResources;

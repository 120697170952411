import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, node, string } from 'prop-types';

// Import components
import { AriaBaseModal } from 'components/elements';

// Import logic
import useAgreementsModal from './useAgreementsModal';

const AgreementsModal = ({ toggle, titleId, children }) => {
	const { t } = useTranslation();

	const { contentRef } = useAgreementsModal();

	return (
		<AriaBaseModal
			titleId={titleId}
			close={toggle}
			centerModalContent={false}
			ref={contentRef}
			closeButtonAriaLabel={t('aria_agreements_modal_close_button')}
		>
			{children}
		</AriaBaseModal>
	);
};

AgreementsModal.propTypes = {
	toggle: func.isRequired,
	children: node.isRequired,
	titleId: string
};

export default AgreementsModal;

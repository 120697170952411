import styled, { css } from 'styled-components';

//Import styles
import { customResponsive, size } from 'components/styles';

export const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&:focus-visible {
		border: 0.2rem dotted ${({ theme }) => theme.primaryBlueLight};
	}

	${customResponsive(
		size.medium,
		css`
			min-height: 60vh;
		`
	)}
`;

import styled from 'styled-components';

// Import components
import { ReactTable } from 'components/elements';

// CSS classes available on React Table
// blueonline-table
// blueonline-table__head
// blueonline-table__row
// blueonline-table__head-row
// blueonline-table__header
// blueonline-table__body
// blueonline-table__body-row
// blueonline-table__cell

export const StyledTable = styled(ReactTable)`
	width: 100%;
	line-height: 1.6rem;

	span {
		display: inline-block;
	}

	.blueonline-table {
		&__row {
			border-bottom: 1px solid ${({ theme }) => theme.line};
		}

		&__header {
			padding-bottom: 1.2rem;
			color: ${({ theme: { user } }) => user.topHeading};
			font-size: ${({ theme }) => theme.fontSize.user.table.header}rem;
		}

		&__cell {
			font-size: ${({ theme }) => theme.fontSize.user.table.cell}rem;
			padding: ${({ theme }) =>
				theme.isWCAG ? '3rem 1rem 3rem 2rem' : '3rem 1rem 3rem 0.5rem'};
			color: ${({ theme }) => theme.graySemiLight};
			font-weight: ${({ theme }) => theme.font.normal};

			&:first-child {
				color: ${({ theme: { user } }) => user.tableItem};
			}
		}
	}
`;

import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

const WatermarkImage = styled.img`
	height: 2.5rem;
	filter: grayscale(${({ isContrast }) => (isContrast ? 1 : 0)});

	${customMinResponsive(
		size.medium,
		css`
			height: 4.7rem;
		`
	)}
`;

export default WatermarkImage;
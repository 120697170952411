import React from 'react';
import { object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { Heading } from 'components/elements';
import HelmetMetaTags from 'components/layout/helmet/HelmetMetaTags';

const SearchEngineTags = ({
	metaTags,
	isTranslationKey,
	isHeadingRendered
}) => {
	const { t } = useTranslation();
	const { title } = metaTags;
	const headingTitle = isTranslationKey ? t(title) : title;

	return (
		<>
			{isHeadingRendered && (
				<Heading.First modifiers="hidden">{headingTitle}</Heading.First>
			)}
			<HelmetMetaTags isTranslationKey={isTranslationKey} {...metaTags} />
		</>
	);
};

SearchEngineTags.defaultProps = {
	metaTags: {},
	isTranslationKey: true,
	isHeadingRendered: false
};

SearchEngineTags.propTypes = {
	metaTags: object.isRequired,
	isTranslationKey: bool,
	isHeadingRendered: bool
};

export default SearchEngineTags;

// Import helpers
import { createLinkParams } from 'helpers/index';
import { getProductTypeAlias } from 'helpers/products';

// Import hooks
import useProductRatingCheck from 'components/elements/molecules/parental_control/parental_control_utils/useProductRatingCheck';

// Import variables
import { PRODUCTS_TYPES } from 'helpers/variables';

const { LIVE_EVENT } = PRODUCTS_TYPES;

export const useSearchItem = ({ product }) => {
	// REPLACE RATING WITH PC_RATING AFTER API UPDATE
	const { uuid, title, type, subtype, context, pc_rating } = product;

	const since = context?.since;

	// check program rating and block content accordingly
	const { isContentBlocked, placeholderRatingText } = useProductRatingCheck(
		pc_rating,
		since
	);

	const isLive = type === LIVE_EVENT;

	const hash = isLive ? 'live' : 'program';

	const linkParams = createLinkParams({ id: uuid, type: subtype, title, hash });

	const productTypeAlias = getProductTypeAlias(product);

	return {
		productTypeAlias,
		isContentBlocked,
		placeholderRatingText,
		title,
		linkParams
	};
};

import React, { useReducer, useRef } from 'react';
import { node, bool } from 'prop-types';
import { useLocation } from 'react-router-dom';

// Import utilities
import { useAgreements } from 'components/utilities';

// Import reducer
import reducer from './store/reducer';

// Import helpers
import { PAYMENT_AGREEMENTS, QUERY_PAYMENT_AGREEMENTS } from 'helpers';
import { INITIAL_STATE } from './helpers';

// Import hooks
import { useResources, usePayment } from './hooks';

// Create context
export const ProductPaymentContext = React.createContext();

const ProductPaymentProvider = ({ children, isWalletPayment = false }) => {
	const location = useLocation();
	// ---- state reducer ----
	const [state, stateDispatch] = useReducer(reducer, INITIAL_STATE);

	const invoiceFormRef = useRef(null);
	const walletFormRef = useRef(null);

	const initialPriceId = location.state?.priceId;
	const update_subscription = location.state?.update_subscription ?? false;
	const provider = location.state?.provider;

	const { paymentMethod, setInvoiceAgreement, setInvoiceCorrectness } =
		usePayment({
			state,
			stateDispatch,
			initialPriceId
		});

	const {
		isPaymentMethods,
		isResourcesLoading,
		isResourcesError,
		productId,
		productPrices,
		productDetails,
		subscriberPromotions,
		lastGateway,
		accountBalance
	} = useResources({
		state,
		stateDispatch,
		isWalletPayment,
		update_subscription,
		provider
	});

	const agreementsStatus = useAgreements({
		queryKey: QUERY_PAYMENT_AGREEMENTS,
		agreementsToFetch: PAYMENT_AGREEMENTS
	});

	const isPaymentValid = isWalletPayment || isPaymentMethods;

	return (
		<ProductPaymentContext.Provider
			value={{
				...state,
				...agreementsStatus,
				productPrices,
				productDetails,
				subscriberPromotions,
				lastGateway,
				accountBalance,
				productId,
				isWalletPayment,
				isPaymentValid,
				isResourcesLoading,
				isResourcesError,
				update_subscription,
				invoiceFormRef,
				walletFormRef,
				setInvoiceAgreement,
				setInvoiceCorrectness,
				dispatch: stateDispatch,
				paymentMethod
			}}
		>
			{children}
		</ProductPaymentContext.Provider>
	);
};

ProductPaymentProvider.propTypes = {
	children: node,
	isWalletPayment: bool
};

export default ProductPaymentProvider;

import React from 'react';
import { object, bool, string } from 'prop-types';
import { Trans } from 'react-i18next';

// Import components
import { AuthModal, ProductPrice, PaymentProtect } from 'components/elements';
import CommonButton from '../CommonButton';
import { RecommendedPassModal } from 'components/elements/molecules/pass';

// Import logic
import usePaymentButton from './usePaymentButton';

// Import context
import { useProductPreviewContext } from 'components/context/productPreview';

const PaymentButton = (props) => {
	const { isPreview, previewLinkProps } = useProductPreviewContext();

	const {
		theme,
		regular,
		promotion,
		redirectData,
		buttonTranslationKey,
		isAvailable,
		isDisabled,
		isLimitExceeded,
		limitMessage,
		renderAs,
		periodText,
		to,
		handleButtonPress,
		isOpened,
		toggle
	} = usePaymentButton(props);

	if (!isAvailable) return null;

	const renderButtonContent = () => {
		if (isLimitExceeded) return limitMessage;

		return (
			<>
				<Trans i18nKey={buttonTranslationKey}>
					<span aria-hidden className="vertical-separator">
						{{ separator: '|' }}
					</span>
				</Trans>
				<ProductPrice
					regular={regular}
					promotion={promotion}
					oldPriceColor={theme.payment.oldPrice}
					isSpanTag
				/>
				{periodText && <span>{periodText}</span>}
			</>
		);
	};

	if (isPreview) {
		return (
			<CommonButton
				width={238}
				isDisabled={isDisabled}
				isPrime={!isDisabled}
				{...previewLinkProps()}
			>
				{renderButtonContent()}
			</CommonButton>
		);
	}

	return (
		<>
			<AuthModal>
				<PaymentProtect>
					<CommonButton
						width={238}
						isDisabled={isDisabled}
						as={renderAs}
						to={to}
						onClick={handleButtonPress}
						isPrime={!isDisabled}
					>
						{renderButtonContent()}
					</CommonButton>
				</PaymentProtect>
			</AuthModal>
			<RecommendedPassModal
				isOpened={isOpened}
				toggle={toggle}
				redirect={redirectData}
			/>
		</>
	);
};

PaymentButton.defaultProps = {
	isSubscriptionUpdate: false
};

PaymentButton.propTypes = {
	product: object.isRequired,
	isSubscriptionUpdate: bool,
	provider: string
};

export default PaymentButton;

import React from 'react';
import FocusTrap from 'focus-trap-react';
import { number, func, bool, node } from 'prop-types';

// Import styles
import { SettingsPopover } from './styles';

// Import context
import { usePlayerFocusContext } from 'components/context/player/focus';

const Popover = ({
	children,
	height,
	width,
	toggle,
	clickOutsideDeactivates,
	returnFocusOnDeactivate
}) => {
	const { focusFreeze, focusUnfreeze } = usePlayerFocusContext();

	return (
		<FocusTrap
			focusTrapOptions={{
				onDeactivate: toggle,
				clickOutsideDeactivates,
				returnFocusOnDeactivate,
				onActivate: focusFreeze,
				onPostDeactivate: focusUnfreeze
			}}
		>
			<SettingsPopover bottom={4.5} height={height} width={width}>
				{children}
			</SettingsPopover>
		</FocusTrap>
	);
};

Popover.defaultProps = {
	toggle: () => {},
	clickOutsideDeactivates: true,
	returnFocusOnDeactivate: true
};

Popover.propTypes = {
	children: node.isRequired,
	height: number,
	width: number,
	toggle: func.isRequired,
	clickOutsideDeactivates: bool,
	returnFocusOnDeactivate: bool
};

export default Popover;

import React from 'react';
import { Formik, Form, Field } from 'formik';
import { object, func, string } from 'prop-types';

// Import components
import { Button, VoucherField } from 'components/elements';

// Import helpers
import { handleFormChange } from 'helpers/form';

const VoucherCodeForm = React.forwardRef((props, ref) => {
	const {
		initialValues,
		onSubmit,
		inputName,
		inputType,
		inputPlaceholder,
		buttonTitle,
		buttonText
	} = props;

	return (
		<Formik
			ref={ref}
			initialValues={initialValues}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={onSubmit}
		>
			{({ handleChange, setErrors, isSubmitting }) => (
				<Form
					onChange={handleFormChange({ handleChange, setErrors })}
					noValidate
				>
					<Field
						name={inputName}
						id={inputName}
						type={inputType}
						placeholder={inputPlaceholder}
						component={VoucherField}
					>
						<Button
							type="submit"
							modifiers={['secondary', 'voucherForm']}
							title={buttonTitle}
							disabled={isSubmitting}
						>
							{buttonText}
						</Button>
					</Field>
				</Form>
			)}
		</Formik>
	);
});

VoucherCodeForm.defaultProps = {
	initialValues: {},
	onSubmit: () => {},
	inputName: '',
	inputType: 'text',
	inputPlaceholder: '',
	buttonTitle: '',
	buttonText: ''
};

VoucherCodeForm.propTypes = {
	initialValues: object.isRequired,
	onSubmit: func.isRequired,
	inputName: string.isRequired,
	inputType: string,
	inputPlaceholder: string.isRequired,
	buttonTitle: string.isRequired,
	buttonText: string.isRequired
};

export default VoucherCodeForm;

import { useState, useCallback } from 'react';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import utilities
import { useEventListener } from 'components/utilities';

// Import helpers
import { EVENTS_NAMES } from 'helpers/variables';

const { TIMEUPDATE } = EVENTS_NAMES;

const useCurrentTime = (callback = null) => {
	const [currentTime, setCurrentTime] = useState(0);

	const { videoTarget } = usePlayerVodContext();

	const handleTimeUpdate = useCallback(
		({ target: { currentTime } }) => {
			const time = parseInt(currentTime, 10);
			callback && callback(time);
			setCurrentTime(time);
		},
		[callback]
	);

	useEventListener(TIMEUPDATE, handleTimeUpdate, videoTarget);

	return { currentTime };
};

export default useCurrentTime;

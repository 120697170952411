import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { getQueries } from 'helpers';

// ******************** FETCH SEARCH QUERY ********************
export const fetchSearchQuery =
	(searchQuery, filter = { limit: 100, offset: 0 }) =>
	async (dispatch) => {
		try {
			const { limit } = filter;
			// Dispatch an loading action
			dispatch({
				type: types.FETCH_SEARCH_QUERY_LOADING,
				payload: { query: searchQuery }
			});

			const url = '/products/search';

			const queries = getQueries({ searchQuery, filter, url });

			// Get tv categories
			const {
				data: { data }
			} = await services.get(`${url}${queries}`);

			// Dispatch an action
			dispatch({
				type: types.FETCH_SEARCH_QUERY_SUCCESS,
				payload: { items: data },
				total: data.length > 0 && data.length >= limit - 1
			});
		} catch (error) {
			// Dispatch an error action
			dispatch({
				type: types.FETCH_SEARCH_QUERY_ERROR,
				payload: true
			});
		}
	};

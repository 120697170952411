import styled, { css } from 'styled-components';

// Import styles
import {
	flex,
	size,
	productTextColor,
	productBackgroundColor,
	customMinResponsive,
	transitionMain,
	focusBasic
} from 'components/styles';

// Import assets
import { ReactComponent as Gallery360 } from 'assets/icons/gallery360.svg';

export const Container = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

export const FigureCaption = styled.figcaption`
	display: none;
`;

export const Figure = styled.figure`
	height: 100%;
	max-height: ${({ isNavOpen }) =>
		isNavOpen ? 'calc(100vh - 28rem)' : 'calc(100vh - 6.4rem)'};
	max-width: 100vw !important;
	${({ isFullScreen }) => !isFullScreen && 'aspect-ratio: 16 / 9'};
	margin: 0 auto;

	${({ isVideo }) =>
		!isVideo &&
		css`
			width: 100%;
			aspect-ratio: 16 / 9;
			border-radius: 5px;
			margin: 0;
			overflow: hidden;
			max-height: 100vh;
		`}
`;

export const Informations = styled.div`
	width: 100%;
	font-size: 1.2rem;
	padding-top: 5px;

	color: ${({ theme }) => theme.product.gallery.footer.color};
	font-weight: ${({ theme }) => theme.font.normal};
	${productBackgroundColor}

	${customMinResponsive(
		size.medium,
		css`
			font-size: 1.8rem;
		`
	)}
`;

export const Content = styled.div`
	${flex({ jc: 'space-between', ai: 'flex-start' })}
	flex-wrap: wrap;
`;

export const InfoBox = styled.div`
	${flex()}
	font-size: ${({ theme }) => theme.fontSize.md}rem;
	font-weight: ${({ theme }) => theme.fontSize.normal};
`;

export const Author = styled.p`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-style: italic;
	${flex()}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('xs', 0.2)}rem;
			height: unset;
		`
	)}
`;

export const Divider = styled.span`
	margin: 0 4px;
`;

export const GalleriesGrid = styled.section`
	display: grid;
	row-gap: 6rem;
	margin-bottom: 3rem;
	${({ isArticle }) =>
		isArticle
			? css`
					grid-template-columns: min(100%, 106.5rem);
			  `
			: css`
					grid-template-columns: 100%;
					${customMinResponsive(
						size.medium,
						css`
							column-gap: 3rem;
							margin-right: 3rem;
							grid-template-columns: repeat(2, min(50%, 89rem));
						`
					)};
					${customMinResponsive(
						1920,
						css`
							column-gap: 6rem;
							margin-right: 0;
						`
					)};
			  `}
`;

export const GalleryHeaderWrapper = styled.div`
	${flex({ jc: 'flex-start' })}
	${productTextColor}
	font-size: ${({ theme, isLightBackground }) =>
		isLightBackground ? theme.fontSize.xl : theme.fontSize.set('md', 0.2)}rem;
	font-weight: ${({ theme }) => theme.font.bold};
	margin-bottom: 3rem;
`;

export const GalleryHeader = styled.h3`
	${productTextColor}
	font-size: ${({ theme, isLightBackground }) =>
		isLightBackground ? theme.fontSize.xl : theme.fontSize.set('md', 0.2)}rem;
	font-weight: ${({ theme }) => theme.font.bold};
`;

export const Description = styled.p`
	word-break: break-word;
	line-height: 3rem;
	font-size: ${({ theme }) => theme.fontSize.set('md', -0.4)}rem;
	${customMinResponsive(
		size.large,
		css`
			line-height: 3.6rem;
			font-size: ${({ theme }) => theme.fontSize.md}rem;
		`
	)};
`;

export const GalleryBody = styled.div`
	position: relative;
	aspect-ratio: 16 / 9;
`;

export const Gallery360Icon = styled(Gallery360)`
	margin-left: 2rem;
	height: 0.9em;
	color: ${({ theme }) => theme.white};
	fill: ${({ theme, isLightBackground }) =>
		isLightBackground ? theme.blackBg : theme.white};
`;

export const FullScreenOpen = styled.button`
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 30;
	width: 3rem;
	height: 3rem;
	border: none;
	background: transparent;

	svg {
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		path {
			fill: ${({ theme }) => theme.white};
		}
	}

	&:hover,
	&:focus {
		cursor: pointer;
		svg {
			background-color: ${({ theme }) => theme.primaryBlueLight};
			path {
				fill: ${({ theme }) => theme.black};
			}
		}
		${transitionMain};
	}

	&:focus-visible {
		${focusBasic}
	}
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';

import { AriaBaseModal, AriaModalContent, Spinner } from 'components/elements';
import { Content, Propmpt, StyledButton } from './styles';

export default NiceModal.create(({ showLoader, prompt, onClose }) => {
	const { t } = useTranslation();
	const titleId = 'transaction_verification_modal_title';

	return (
		<AriaBaseModal
			titleId={titleId}
			close={onClose}
			style={{ padding: '6.8rem 11.2rem', width: '95vw', maxWidth: '61.2rem' }}
		>
			<AriaModalContent
				title={t('transaction_verification_modal_title')}
				titleId={titleId}
			>
				<Content>
					<Propmpt>{prompt}</Propmpt>
					{showLoader ? (
						<Spinner />
					) : (
						<StyledButton onClick={onClose} isPrime>
							{t('common_button_close')}
						</StyledButton>
					)}
				</Content>
			</AriaModalContent>
		</AriaBaseModal>
	);
});

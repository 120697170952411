import React from 'react';
import PropTypes from 'prop-types';
import { SpinnerContent } from './styles';

const Spinner = ({ size, thickness, color }) => (
	<SpinnerContent size={size} color={color} thickness={thickness} />
);

Spinner.defaultProps = {
	size: '4rem',
	thickness: '0.5rem',
	color: 'white'
};

Spinner.propTypes = {
	size: PropTypes.string,
	thickness: PropTypes.string,
	color: PropTypes.string
};

export default Spinner;

import React from 'react';
import { useMutation } from 'react-query';
import services from 'services/services';
import { useTranslation } from 'react-i18next';

// Import helpers
import { FRESH_MAIL_API } from 'helpers/api';
import {
	NEWS_LETTER_ERROR_CODES,
	DEFAULT_NEWSLETTER_ERROR_MESSAGE
} from 'helpers/newsletter';
import { handleFormSubmit } from 'helpers/form';

// Import validation
import validation from './validate';

const useNewsletter = () => {
	const { t } = useTranslation();

	// Create form ref
	const formRef = React.useRef();
	const listId = process.env.REACT_APP_FRESH_MAIL_HASH_LIST;

	const { mutateAsync, isSuccess } = useMutation(handleFetch);

	function handleFetch({ email_news_letter: email }) {
		const options = { email, list: listId };
		return services.post(FRESH_MAIL_API, options);
	}

	// Form handlers
	const submitFunction = async (values) => {
		try {
			await mutateAsync(values);
			formRef.current.resetForm();
		} catch (error) {
			formRef.current.setSubmitting(false);
			const code = error.response?.data?.errorCodeNumber;
			const text = NEWS_LETTER_ERROR_CODES[code];
			const defaultText = DEFAULT_NEWSLETTER_ERROR_MESSAGE;
			const message = text ?? defaultText;
			formRef.current.setErrors({ email_news_letter: t(message) });
		}
	};

	const handleOnSubmit = (values, { setErrors, setSubmitting }) =>
		handleFormSubmit({
			setErrors,
			setSubmitting,
			values,
			validation,
			onSubmit: submitFunction
		});

	return {
		formRef,
		isSuccess,
		onSubmit: handleOnSubmit
	};
};

export default useNewsletter;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Import styles
import { PreviewWrapper, SeekTime } from './styles';

// Import helpers
import { getTimeLineFormat } from 'helpers';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const PreviewPopover = ({ cursorPosition }) => {
	const { duration } = usePlayerVodContext();

	const cursorPos = useMemo(
		() => (cursorPosition < 0 ? 0 : cursorPosition),
		[cursorPosition]
	);

	const seekTime = useMemo(
		() => cursorPosition * duration,
		[cursorPosition, duration]
	);

	if (seekTime < 0 || seekTime > duration || cursorPos < 0) return null;

	return (
		<PreviewWrapper cursorPos={cursorPos} isSmall className="preview">
			<SeekTime isSmall>{getTimeLineFormat(seekTime)}</SeekTime>
		</PreviewWrapper>
	);
};

PreviewPopover.propTypes = {
	cursorPosition: PropTypes.number
};

export default PreviewPopover;

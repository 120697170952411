import React from 'react';
import { string, node, bool } from 'prop-types';

// Import styled components
import { Button } from './styles';

const IconButton = ({ children, ariaLabel, marginRight, ...rest }) => (
	<Button
		aria-label={ariaLabel}
		title={ariaLabel}
		marginRight={marginRight}
		{...rest}
	>
		{children}
	</Button>
);

IconButton.propTypes = {
	ariaLabel: string.isRequired,
	children: node.isRequired,
	marginRight: bool
};

export default IconButton;

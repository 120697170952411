import React, { useRef } from 'react';
import { string, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import { Video, VideoWrapper, VideoContainer } from './styles';

// Import components
import VideoControls from './VideoControls';

function Video2D({ url, placeholder, index, subtitles = {} }) {
	const videoPlayerRef = useRef();
	const { t, i18n } = useTranslation();

	const matchedSubtitlesUrl = subtitles?.[i18n.language];

	return (
		<VideoWrapper>
			<VideoContainer>
				<Video
					src={url}
					ref={videoPlayerRef}
					poster={placeholder}
					controls={false}
					preload="metadata"
					autoPlay={false}
					muted={false}
					crossOrigin="true"
				>
					{matchedSubtitlesUrl && (
						<track
							label={t('common_subtitles_language_label')}
							kind="captions"
							srcLang={i18n.language}
							src={matchedSubtitlesUrl}
							default
						/>
					)}
				</Video>
			</VideoContainer>

			<VideoControls videoPlayerRef={videoPlayerRef} index={index} />
		</VideoWrapper>
	);
}

Video2D.propTypes = {
	url: string.isRequired,
	placeholder: string,
	index: number,
	subtitles: object
};

export default Video2D;

import styled, { css } from 'styled-components';

// Import utilties
import { customMinResponsive, focusBasic, size } from 'components/styles';

export const Social = styled.ul`
	list-style: none;
	margin-bottom: 5rem;

	${customMinResponsive(
		size.medium,
		css`
			margin-bottom: 0;
		`
	)}
`;

export const activeItemStyles = css`
	&:focus-visible,
	&:hover {
		path {
			fill: ${({ theme }) => theme.body.footer.hover};
		}
	}

	&:focus-visible {
		${focusBasic}
	}
`;

export const ListItemStyled = styled.li`
	cursor: pointer;
	display: inline-block;

	&:not(:last-child) {
		margin-right: 1rem;
		${customMinResponsive(
			size.medium,
			css`
				margin-right: 1.6rem;
			`
		)}
	}

	${activeItemStyles}
`;

export const AnchorStyled = styled.a`
	display: block;

	svg {
		height: 4.8rem;
		width: 4.8rem;

		g {
			g {
				transition: fill 0.1s;
			}
		}
	}

	${activeItemStyles}
`;

import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { checkAuthorizeLoginCodeErrors } from './helpers';

// ******************** AUTHORIZE LOGIN CODE ********************
export const authorizeLoginCode =
	({ code }, setErrors, uidOfDeviceToDelete, nameOfNewDevice) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.AUTHORIZE_LOGIN_CODE_LOADING
			});

			const url = '/subscriber/authorize/code';

			let requestPayload = { code: `${code}` };

			// send info about devices only when it's available
			if (uidOfDeviceToDelete && nameOfNewDevice) {
				requestPayload = {
					...requestPayload,
					uidOfDeviceToDelete,
					nameOfNewDevice
				};
			}

			await services.post(url, requestPayload);

			dispatch({
				type: types.AUTHORIZE_LOGIN_CODE_SUCCESS
			});
		} catch (error) {
			let resources = {
				error,
				isCodeExpired: false,
				isDevicesExceeded: false,
				isChangesExceeded: false
			};

			// SET PROPER ERROR TYPES BASED ON EXEPTION TYPE
			if (error.response) {
				resources = checkAuthorizeLoginCodeErrors(error, resources, setErrors);
			}

			dispatch({
				type: types.AUTHORIZE_LOGIN_CODE_ERROR,
				payload: resources
			});
		}
	};

// ******************** CLEAR STATE ********************
export const clearLoginCodeState = () => (dispatch) => {
	dispatch({
		type: types.CLEAR_USER_LOGIN_CODE_STATE
	});
};

import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

export const Divider = styled.hr`
	display: inline-block;
	height: 0.9em;
	margin: 0 0.5rem;
	background-color: inherit;

	${customMinResponsive(
		size.medium,
		css`
			margin: 0 1rem;
		`
	)}
`;

import React, { useState, useCallback } from 'react';
import VisuallyHidden from '@reach/visually-hidden';
import { bool, string, oneOfType, arrayOf, node } from 'prop-types';

// Import variables
import {
	STATE_CHANGE_ANNOUNCE_TIMEOUT,
	STATE_CHANGE_ANNOUNCE_TIMEOUT_SHORT
} from 'helpers/wcag';

// Import utilities
import { useTimeout } from 'components/utilities';

const StateChangeAnnounce = ({
	atomic = true,
	live = 'polite',
	children,
	shortTime = false,
	role
}) => {
	const [isInfoHidden, setIsInfoHidden] = useState(false);
	const timeoutValue = shortTime
		? STATE_CHANGE_ANNOUNCE_TIMEOUT_SHORT
		: STATE_CHANGE_ANNOUNCE_TIMEOUT;

	const hideAnnouncement = useCallback(() => {
		setIsInfoHidden(true);
	}, []);

	useTimeout(hideAnnouncement, timeoutValue);

	return (
		<VisuallyHidden
			aria-atomic={role ? null : atomic}
			aria-live={role ? null : live}
			aria-hidden={isInfoHidden}
			role={role ? role : null}
		>
			{children}
		</VisuallyHidden>
	);
};

StateChangeAnnounce.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	atomic: bool,
	live: string,
	shortTime: bool,
	role: string
};

export default StateChangeAnnounce;

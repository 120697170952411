import { useState, useEffect } from 'react';
import useGalleryContext from '../context/useGalleryContext';

// Import helpers
import { OBSERVER_ACTIONS } from '../helpers';
import { EVENTS_NAMES } from 'helpers/variables';

const { PAUSE, LOADEDDATA, TIMEUPDATE, ENDED } = EVENTS_NAMES;

function useVideoPlayer({ videoPlayerRef, index }) {
	const [isPlaying, setIsPlaying] = useState(false);
	const [isMuted, setIsMuted] = useState(false);
	const [progress, setProgress] = useState(0);

	const { observer, galleryIndex, isNavOpen, showFullscreen, isMobileView } =
		useGalleryContext();

	const tabIndex = galleryIndex === index ? 0 : -1;
	const shouldNavOpen = isNavOpen && showFullscreen && !isMobileView;

	const play = async () => {
		const video = videoPlayerRef?.current;
		if (video && video.paused) {
			await video.play();
		}
	};

	const pause = () => {
		const video = videoPlayerRef?.current;
		if (video && !video.paused) {
			video.pause();
		}
	};

	const onLoad = () => {
		if (videoPlayerRef?.current) {
			pause();
			setIsMuted(false);
			videoPlayerRef.current.muted = false;
		}
	};

	const onPaused = () => setIsPlaying(false);

	const togglePlay = () => {
		if (videoPlayerRef?.current) {
			if (!isPlaying) play();
			else pause();
			setIsPlaying((current) => !current);
		}
	};

	const toggleMute = () => {
		videoPlayerRef.current.muted = !isMuted;
		setIsMuted((current) => !current);
	};

	const getProgress = (currentTime, duration) => {
		return (currentTime / duration) * 100;
	};

	const handleTimeUpdate = () => {
		const currentTime = videoPlayerRef.current?.currentTime;
		const duration = videoPlayerRef.current?.duration;
		const progress = getProgress(currentTime, duration);
		if (!isNaN(progress)) setProgress(progress.toFixed(0));
	};

	const handleVideoProgress = (event) => {
		const manualChange = Number(event.target.value);
		videoPlayerRef.current.currentTime =
			(videoPlayerRef?.current?.duration / 100) * manualChange;
		setProgress(manualChange);
	};

	const updateSeekTime = (next) => {
		const currentTime = videoPlayerRef.current?.currentTime;

		const time = next ? currentTime + 15 : currentTime - 15;

		videoPlayerRef.current.currentTime = time;
		setProgress(time);
	};

	const attatchListener = () => {
		if (videoPlayerRef?.current) {
			const video = videoPlayerRef.current;
			video.addEventListener(TIMEUPDATE, handleTimeUpdate);
			video.addEventListener(ENDED, cleanPlayer);
			video.addEventListener(LOADEDDATA, onLoad);
			video.addEventListener(PAUSE, onPaused);
		}
	};

	const detachListener = () => {
		if (videoPlayerRef?.current) {
			const video = videoPlayerRef.current;
			video.removeEventListener(TIMEUPDATE, handleTimeUpdate);
			video.removeEventListener(ENDED, cleanPlayer);
			video.removeEventListener(LOADEDDATA, onLoad);
			video.removeEventListener(PAUSE, onPaused);
		}
	};

	useEffect(() => {
		observer.attatch(onGalleryUpdate);
		return () => {
			observer.detach(onGalleryUpdate);
			cleanPlayer();
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (videoPlayerRef?.current) {
			attatchListener();
			return () => detachListener();
		}
		// eslint-disable-next-line
	}, [videoPlayerRef?.current]);

	const cleanPlayer = () => {
		if (videoPlayerRef?.current) {
			videoPlayerRef.current.pause();
			videoPlayerRef.current.currentTime = 0;
		}
	};

	const onGalleryUpdate = ({ action }) => {
		switch (action) {
			case OBSERVER_ACTIONS.BEFORE:
			case OBSERVER_ACTIONS.FULLSCREEN:
				cleanPlayer();
				break;
			default:
				break;
		}
	};

	return {
		tabIndex,
		isPlaying,
		progress,
		isMuted,
		toggleMute,
		togglePlay,
		handleTimeUpdate,
		handleVideoProgress,
		updateSeekTime,
		isNavOpen: shouldNavOpen
	};
}

export default useVideoPlayer;

import React from 'react';
import { string, node, object } from 'prop-types';
import { useDispatch } from 'react-redux';

// Import utilities
import { useToggle, useCloseFloatMode } from 'components/utilities';

// Import components
import { BigPlayButton } from 'components/elements';

// Import styles
import { Wrapper } from './styles';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';

// Import variables
import { PRODUCT_IMAGES_TYPES, PRODUCTS_TYPES } from 'helpers/variables';
import { setFloatWidgetPlayLiveEvent } from 'store/actions';

const { COVER } = PRODUCT_IMAGES_TYPES;
const { LIVE_EVENT } = PRODUCTS_TYPES;

const PlayerToggle = ({ children, type, images }) => {
	const [isPlayerVisible, toggle] = useToggle();
	const dispatch = useDispatch();

	const { closeFloatMode } = useCloseFloatMode();

	const coverImage = getImage({ images, type: COVER });

	const handlePlay = () => {
		toggle();

		if (type === LIVE_EVENT) {
			closeFloatMode();
			setFloatWidgetPlayLiveEvent(true)(dispatch);
		}
	};

	return isPlayerVisible ? (
		children
	) : (
		<Wrapper modifiers={type} cover={coverImage}>
			<BigPlayButton
				modifiers={['icon', 'catchup', 'playerIconHover']}
				onClick={handlePlay}
			/>
		</Wrapper>
	);
};

PlayerToggle.propTypes = {
	children: node.isRequired,
	type: string.isRequired,
	images: object
};

export default PlayerToggle;

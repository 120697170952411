import React from 'react';
import clsx from 'clsx';
import { VisuallyHidden } from '@reach/visually-hidden';
import { string, func, number, oneOfType, arrayOf, node } from 'prop-types';

// Import components
import SelectKeyboardScrollWrapper from '../SelectKeyboardScrollWrapper';

// Import styles
import {
	StyledListboxButton,
	StyledListboxList,
	StyledListboxInput,
	StyledListboxPopover
} from '../styles';

// Import helpers
import { convertKeyEvent } from 'helpers';

// Import variables
import { EVENTS_NAMES, KEY_CODES } from 'helpers/variables';
const { KEYDOWN } = EVENTS_NAMES;
const { SPACE, ENTER } = KEY_CODES;

const ProductListSelect = ({
	children,
	selectChange,
	selectedValue,
	title
}) => {
	const handleKeyDown = (event) =>
		convertKeyEvent({
			event,
			triggerKey: ENTER,
			targetKey: SPACE,
			eventType: KEYDOWN
		});

	return (
		<StyledListboxInput value={selectedValue} onChange={selectChange}>
			{({ isExpanded }) => (
				<>
					<VisuallyHidden>{title}</VisuallyHidden>
					<SelectKeyboardScrollWrapper>
						<StyledListboxButton
							className={clsx({
								'is-expanded': isExpanded
							})}
							arrow
							onKeyDown={handleKeyDown}
							aria-expanded={isExpanded}
						/>
						<StyledListboxPopover withRWDMargin portal={false}>
							<StyledListboxList>{children}</StyledListboxList>
						</StyledListboxPopover>
					</SelectKeyboardScrollWrapper>
				</>
			)}
		</StyledListboxInput>
	);
};

ProductListSelect.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	selectChange: func,
	selectedValue: oneOfType([string, number]),
	title: string.isRequired
};

export default ProductListSelect;

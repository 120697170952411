import { find } from 'lodash';

export const getPassSliderSettings = ({ isMobile }) => ({
	slidesToShow: isMobile ? 1 : 3,
	slidesToScroll: isMobile ? 1 : 3,
	arrows: true,
	infinite: false
});

export const filterOutUsersPasses = ({ userPasses, productPasses }) => {
	const userPassesIds = userPasses?.map(({ uuid }) => uuid);
	return productPasses?.filter(({ uuid }) => !userPassesIds.includes(uuid));
};

export const mapPassToPacket = ({ passes, packets }) =>
	passes.map(({ uuid }) => find(packets, (packet) => packet?.uuid === uuid));

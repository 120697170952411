import { useContext } from 'react';

// Import context
import { GalleryContext } from './GalleryProvider';

const useGalleryContext = () => {
	const context = useContext(GalleryContext);
	return context;
};

export default useGalleryContext;

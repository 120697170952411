import styled, { css } from 'styled-components';

// Import icons
import { ReactComponent as IconClose } from 'assets/icons/ic_close_thin.svg';

// Import styles
import {
	flex,
	fadeInUp,
	customMinResponsive,
	size,
	focusBasic
} from 'components/styles';

// Import components
import { Button } from 'components/elements';

export const Wrapper = styled.div`
	position: ${({ theme }) => (theme.isWCAG ? 'relative' : 'fixed')};
	bottom: 0;
	left: 0;
	width: 100%;
	max-height: 100%;
	overflow-y: auto;
	opacity: 0;
	background-color: ${({ theme }) => theme.cookies.background};
	border: 1px solid ${({ theme }) => theme.cookies.borderColor};
	border-radius: 3px;
	animation: ${fadeInUp} 0.5s ease-in-out forwards;
	z-index: 1200;
	padding: 3rem 2rem;
	text-align: left;

	${customMinResponsive(
		size.medium,
		css`
			padding: 5.7rem 8.3rem 4.3rem;
		`
	)}
`;

export const Header = styled.h1`
	font-size: ${({ theme }) => theme.fontSize.set('s', 0.2)}rem;
	line-height: 2.6rem;
	margin-bottom: 1.8rem;
	font-weight: ${({ theme }) => theme.font.bold};
	color: ${({ theme }) => theme.cookies.color};

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.lg}rem;
			line-height: 3.6rem;
			margin-bottom: 2.3rem;
		`
	)}
`;

export const ButtonClose = styled(Button)`
	padding: 0;
	position: absolute;
	top: 1rem;
	right: 1rem;
	border: none;

	${customMinResponsive(
		size.medium,
		css`
			top: 3rem;
			right: 3rem;
		`
	)}

	&:hover,
	&:focus {
		svg > g > g {
			fill: ${({ theme }) => theme.primaryBlueLight};
		}
	}

	&:focus-visible {
		${focusBasic}
	}
`;

export const IconCloseStyled = styled(IconClose)`
	height: 1.8rem;
	width: 1.8rem;
`;

export const Description = styled.div`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
	line-height: 2.4rem;
	color: ${({ theme }) => theme.cookies.color};
	font-weight: ${({ theme }) => theme.font.normal};

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
			line-height: 3.6rem;
		`
	)}
`;

export const ButtonsWrapper = styled.div`
	margin-top: 3.4rem;
	${flex()}

	button:not(:first-of-type) {
		margin-left: 1rem;
	}

	${customMinResponsive(
		size.medium,
		css`
			${flex({ jc: 'flex-end' })}
		`
	)}
`;

import { generatePath } from 'react-router-dom';
import getSlug from 'speakingurl';

// Import paths
import {
	VOD_PATHS,
	INSTITUTIONS_PATHS,
	MAIN_PATHS
} from 'components/routes/paths';

// Import helpers
import { PRODUCTS_TYPES } from 'helpers/variables';
import { PRODUCT_TYPES_MAPPED } from 'helpers';

const { CHANNEL, SERIES, PROVISION, VOD } = PRODUCTS_TYPES;

export const getSectionPath = (params = {}) =>
	generatePath(VOD_PATHS.SECTION_DETAIL_PAGE, params);

export const getInstitutionPath = (uuid, name) => {
	if (!uuid) return MAIN_PATHS.HOME_PAGE;

	return generatePath(INSTITUTIONS_PATHS.INSTITUTION_DETAILS, {
		institutionUuid: uuid,
		institutionName: getSlug(name)
	});
};

export const getProductPath = ({
	params = {},
	seriesUuid,
	id,
	slugTitle,
	type
}) => {
	const { pageName = 'produkt', menuId = 1 } = params;
	const productType = seriesUuid ? SERIES : type;
	const productUuid = seriesUuid ?? id;
	const typeName = PRODUCT_TYPES_MAPPED[productType] ?? VOD;

	return generatePath(VOD_PATHS.PRODUCT_DETAILS, {
		menuId,
		pageName,
		type: typeName,
		id: productUuid,
		title: slugTitle
	});
};

// CreateLink params
export const createLinkParams = ({
	id,
	type = 'program',
	title = '',
	seriesUuid,
	seriesTitle,
	params
}) => {
	const productTitle = seriesTitle ?? title;
	const slugTitle = getSlug(productTitle);

	switch (type) {
		case CHANNEL:
			return { pathname: '/tv/strona-glowna', state: { channelId: id } };

		case PROVISION:
			return { pathname: `/pakiety/pakiet/${id}/${slugTitle}` };

		default:
			const pathname = getProductPath({
				params,
				seriesUuid,
				id,
				slugTitle,
				type
			});
			return { pathname };
	}
};

import React from 'react';
import { object } from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useRouteMatch } from 'react-router-dom';

// Import components
import Nav from 'components/layout/nav/Nav';
import DisabilityNav from 'components/layout/wcag_nav';
import MobileNav from '../nav/nav_mobile/MobileNav';

// Import styled components
import { StyledHeader, DisabilityNavWrapper } from './styles';

// Import helpers
import { LOGIN_ROUTES } from './helpers';

const Header = ({ mainContainerRef }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
	const loginMatch = useRouteMatch(LOGIN_ROUTES);

	const renderMenu = () =>
		isMobile ? <MobileNav mainContainerRef={mainContainerRef} /> : <Nav />;

	return (
		<StyledHeader>
			<DisabilityNavWrapper>
				<DisabilityNav isMobile={isMobile} />
			</DisabilityNavWrapper>
			{!loginMatch && renderMenu()}
		</StyledHeader>
	);
};

Header.propTypes = {
	mainContainerRef: object.isRequired
};

export default Header;

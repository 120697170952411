import { createGlobalStyle } from 'styled-components';
import { transitionMain } from 'components/styles';
import { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

const GlobalStyle = createGlobalStyle`

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }

  html {
    font-size: 62.5%;
    background-color: ${({ theme }) => theme.body.bg.primary};
  }

  body {
    font-size: ${({ theme }) => theme.fontSize.xs}rem;
    font-family: 'Roboto', sans-serif;
    font-weight: ${({ theme }) => theme.font.medium};
    background-color: ${({ theme }) => theme.body.bg.primary};
  }

  a:hover {
    color: ${({ theme: { general } }) => general.linkHover}
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  ul, p {
    margin-bottom: 0;
  }

  svg {
    pointer-events: none;
  }

  // Styles to fulfill 1.4.12 WCAG requirements
  p, a, h1, h2, h3, h4, h5, h6, td, th, tr, ul, ol, li, label, button {
    ${({ theme }) =>
			theme.isWCAG &&
			css`
				line-height: 1.5em !important;
				letter-spacing: 0.12em !important;
				word-spacing: 0.2em !important;
				height: fit-content !important;
				max-height: fit-content !important;
			`};
  }

  // Styles to fulfill 1.4.12 WCAG requirements
  span, input {
    ${({ theme }) =>
			theme.isWCAG &&
			css`
				line-height: 1.5em !important;
				letter-spacing: 0.12em !important;
				word-spacing: 0.2em !important;
			`};
  }

  // Styles to fulfill 1.4.12 WCAG requirements (margin between "paragraphs")
  // Only selected tags, for other tags styles are added if required
  p, h1, h2, h3, h4, h5, h6, td, th, hr {
    ${({ theme }) =>
			theme.isWCAG &&
			css`
				margin-bottom: 2em !important;
			`};
  }

  /* Visually hidden span for WCAG purpose */
  .visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 0.1rem;
    margin: -0.1rem;
    overflow: hidden; padding: 0;
    position: absolute;
    width: 0.1rem;
    white-space: nowrap;
    overflow-wrap: normal;
  }

  /* Vertical text separator */
  .vertical-separator {
     margin: 0em 0.3em;
  }

  /* Dropdown */
  .ant-dropdown-menu {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.bg}
  }

  .ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider {
    background-color: ${({ theme }) => theme.line};
  }


  /* Notification menu on Popover and Program detail page */
  .ant-menu {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.bg}
  }

  .ant-menu-vertical {
    border-right: none;
  }

  .ant-menu-item {
    color: ${({ theme: { dropdown } }) => dropdown.menu.itemColor};

    &.ant-menu-item-active {
      background-color: ${({ theme: { dropdown } }) =>
				dropdown.menu.itemHoverBg};
      color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;

    &:hover {
      background-color: ${({ theme: { dropdown } }) =>
				dropdown.menu.itemHoverBg};
    }
  }


  /* Popover */
  .ant-popover-inner-content {
    padding: 0 !important;
  }

  .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
    border-bottom-color: ${({ theme: { popover } }) => popover.bg};
    border-left-color: ${({ theme: { popover } }) => popover.bg};
  }

  .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
    border-top-color: ${({ theme: { popover } }) => popover.bg};
    border-right-color: ${({ theme: { popover } }) => popover.bg};
  }


  /* Select */
  .ant-select {
    height: 4.4rem;
    width: 100%;
    color: ${({ theme: { userProfileDropdown } }) =>
			userProfileDropdown.textColor};
    font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
    font-weight: ${({ theme }) => theme.font.normal};
    user-select: none;
    background: ${({ theme: { userProfileDropdown } }) =>
			userProfileDropdown.selectBgColor};

    .ant-select-selection {
      background-color: transparent;
      border: 1px solid ${({ theme: { userProfileDropdown } }) =>
				userProfileDropdown.borderColor};
      border-radius: 5px;
    }

    .ant-select-selection--single {
      height: 100%;
      line-height: 1;
    }


    .ant-select-selection__rendered {
      position: relative;
      height: 100%;
      margin-right: 2.1rem;

      &::before {
        content: '';
        position: absolute;
        right: 2.1rem;
        height: 3.2rem;
        margin: 0.4rem 0;
        width: 1px;
        background-color: ${({ theme: { userProfileDropdown } }) =>
					userProfileDropdown.borderColor};
      }
    }


    .ant-select-arrow {
      color: ${({ theme: { userProfileDropdown } }) =>
				userProfileDropdown.textColor};
      right: 1.65rem;
      ${transitionMain}
    }

    &.ant-select-open .ant-select-selection {
      border-color: ${({ theme: { userProfileDropdown } }) =>
				userProfileDropdown.borderColor};
      box-shadow: none;
    }

    &.ant-select-open .ant-select-arrow-icon svg {
      transform: none;
    }

    &.ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      border-color: ${({ theme }) => theme.primaryBlueLight};
      box-shadow: none;
    }

    &:hover,
    &.ant-select-open {
      color: ${({ theme: { userProfileDropdown } }) =>
				userProfileDropdown.textColor};

      .ant-select-arrow {
        color: ${({ theme: { userProfileDropdown } }) =>
					userProfileDropdown.textColor};
      }
    }
  }

  &.ant-select-selection-selected-value {
    line-height: 4rem;
    max-width: calc(100% - 3rem);
  }

  .ant-select-dropdown {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.bg};
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 6px;
    z-index: 3; // select dropdown overflowed nav dropdown - take that to account when modifying
  }

  .ant-select-dropdown-menu-item-group {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }
  }

  .ant-select-dropdown-menu-item {
    color: ${({ theme: { dropdown } }) => dropdown.menu.itemColor};
    padding: 1.2rem 2.6rem !important;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }

     ${({ theme }) => theme.isWCAG && 'font-size: 2.8rem'};
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${({ theme: { dropdown } }) =>
			dropdown.menu.itemHoverBg} !important;
  }

  .ant-select-dropdown-menu-item-active {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverBg};
    background-color: ${({ theme: { userProfileDropdown } }) =>
			userProfileDropdown.listBg};
    color: ${({ theme }) => theme.primaryBlueLight} !important;
  }

  .ant-select-dropdown-menu-item {
    color: ${({ theme }) => theme.graySemiLight};
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${({ theme: { userProfileDropdown } }) =>
			userProfileDropdown.selectedItemBg};
    color: ${({ theme: { userProfileDropdown } }) =>
			userProfileDropdown.textColor};
  }

  .ant-select-dropdown-menu-item-selected:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverBg};
    color: ${({ theme }) => theme.white};
  }

  .ant-select-dropdown-menu-item-group-title {
    color: ${({ theme }) => theme.white};
    height: 4.6rem;
    padding-left: 2.6rem;
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
    ${flex({ jc: 'flex-start' })}
  }

  .ant-empty-image {
    display: none !important;
  }

  .ant-empty-description {
    color: ${({ theme: { white } }) => white}
  }

  /* Calendar */
  .ant-calendar-input-wrap {
    display: none;
  }

  .ant-calendar-header a:hover {
    &::before, &::after {
      color: ${({ theme }) => theme.white};
      border-color: ${({ theme }) => theme.white} !important;
    }
  }

  .ant-calendar-year-select,
  .ant-calendar-month-select {
    pointer-events: none;
  }

  .ant-calendar-ym-select {
    display: flex;
    justify-content: center;
  }

  .ant-calendar-year-select {
    order: 2;
  }

  .ant-calendar-month-select {
    order: 1;
  }

  .ant-calendar {
    background-color: ${({ theme }) => theme.modal.bg};
    border: 1px solid ${({ theme }) => theme.modal.bg};
  }

  .ant-calendar-prev-month-btn,
  .ant-calendar-prev-year-btn,
  .ant-calendar-next-month-btn,
  .ant-calendar-next-year-btn,
  .ant-calendar-year-select,
  .ant-calendar-month-select,
  .ant-calendar-column-header,
  .ant-calendar-cell,
  .ant-calendar-last-month-cell {
    color: ${({ theme }) => theme.white} !important;

    &:hover {
      color: ${({ theme }) => theme.white};
    }
  }

  .ant-calendar-header .ant-calendar-prev-month-btn:hover::before, .ant-calendar-header .ant-calendar-prev-month-btn:hover::after, .ant-calendar-header .ant-calendar-next-month-btn:hover::before, .ant-calendar-header .ant-calendar-next-month-btn:hover::after {
    border-color: ${({ theme }) => theme.white} !important;
  }

  .ant-calendar-header, .ant-calendar-footer {
    border: 1px solid transparent !important;
  }

  .ant-calendar-footer {
    padding-bottom: 1rem;
  }

  .ant-calendar-disabled-cell .ant-calendar-date {
    background: ${({ theme }) => theme.globalButtons.primary.bgColor};
  }

  .ant-calendar-date {
    color: ${({ theme }) => theme.white};
  }

  .ant-calendar-date:hover {
    background: transparent;
  }

  .ant-calendar-cell .ant-calendar-selected-date .ant-calendar-selected-day {
    .ant-calendar-selected-day .ant-calendar-date {
      background: transparent !important;
    }
  }

  .ant-calendar-today {
    .ant-calendar-date {
      background: inherit !important;
      color: ${({ theme: { primaryBlueLight } }) =>
				primaryBlueLight} !important;
    }
  }

  .ant-calendar-today .ant-calendar-date,
  .ant-calendar-selected-day .ant-calendar-date {
    background: ${({ theme: { primaryBlueLight } }) => primaryBlueLight};
    color: ${({ theme: { white } }) => white};
    border: 1px solid ${({ theme: { primaryBlueLight } }) => primaryBlueLight};
  }

  .ant-calendar-selected-date {
    .ant-calendar-date {
      border: 1px solid ${({ theme }) => theme.transparent} !important;
      font-weight: ${({ theme }) => theme.font.medium} !important;
    }

    &.ant-calendar-today .ant-calendar-date {
      background: inherit !important;
      color: ${({ theme: { primaryBlueLight } }) => primaryBlueLight};
    }
  }

  .ant-calendar-selected-day.ant-calendar-disabled-cell {
    .ant-calendar-date {
      background: ${({ theme }) =>
				theme.globalButtons.primary.bgColor} !important;
      font-weight: inherit !important;
      border: 1px solid transparent !important;

      &:hover {
        background: transparent !important;
      }

      &::before {
        display: none !important;
      }
    }
  }

  .ant-calendar-today-btn {
    width: 100%;
    font-weight: ${({ theme }) => theme.font.medium};
    background-image: ${({ theme: { globalButtons } }) =>
			globalButtons.primary.bgColor};
    color: ${({ theme: { globalButtons } }) => globalButtons.primary.color};
    border-color: ${({ theme: { globalButtons } }) =>
			globalButtons.primary.borderColor};
    border-radius: 0.4rem;

    &:hover {
      color: ${({ theme: { globalButtons } }) => globalButtons.primary.color};
      box-shadow: 0 0 4px ${({ theme: { globalButtons } }) =>
				globalButtons.primary.borderColor};
    }
  }

  .ant-input:hover, .ant-input:focus {
    border-color: ${({ theme: { primaryBlueLight } }) => primaryBlueLight};
  }

  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: ${({ theme: { primaryBlueLight } }) => primaryBlueLight};
  }

  .ant-calendar-selected-date .ant-calendar-date {
    border: 1px solid ${({ theme: { transparent } }) => transparent} !important
  }

  .ant-calendar-disabled-cell .ant-calendar-date {
    background: ${({ theme }) => theme.graySemiLight};
    color: ${({ theme }) => theme.white};
    cursor: pointer;
  }

  .ant-calendar-disabled-cell .ant-calendar-date:hover {
    background: ${({ theme }) => theme.graySemiLight};
    color: ${({ theme }) => theme.white};
  }

  .ant-calendar-selected-day.ant-calendar-disabled-cell .ant-calendar-date {
    background: ${({ theme }) => theme.graySemiLight} !important;
    color: ${({ theme }) => theme.white};
  }

  .ant-calendar-picker:hover .ant-calendar-picker-clear {
    display: none;
  }


  /* Payment pin input style for Safari */
  @media not all and (min-resolution: .001dpcm) {
    @media {
      input.mask {
        font-size: ${({ theme }) => theme.fontSize.lg}rem;
        line-height: 1;
        padding-bottom: 0;
      }
    }
  }


  /* React virtualized styles */
  .ReactVirtualized__Collection {
    background-color: #ffff;
    user-select: none;

    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Collection__innerScrollContainer {
    position: relative;

    &:focus {
      outline: none;
    }

    &::after {
      content: '';
      display: block;
      height: 100%;
      width: 3px;
      position: absolute;
      background-color: #85dec0;
      top: 0;
      transition: all 0.3s ease-in-out;
    }
  }

  .ReactVirtualized__List {
    overflow: hidden !important;
  }

  .ReactVirtualized__List::-webkit-scrollbar-track {
    background-color: white !important;
  }

  .ReactVirtualized__List::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  .ReactVirtualized__Grid, .ReactVirtualized__Grid__innerScrollContainer {
    &:focus {
      outline: none;
    }
  }


  /* Player sidebard list */
  .player-sidebar-list::-webkit-scrollbar-track, .ReactVirtualized__Collection::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .player-sidebar-list::-webkit-scrollbar, .ReactVirtualized__Collection::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent !important;
  }

  .player-sidebar-list, .ReactVirtualized__Collection {
    scrollbar-width: none;
  }

  /* Epg popover arrow style */
  .epg-popover {
    .ant-popover-arrow {
      border-color: ${({ theme }) => theme.popover.bg} !important;
      z-index: -1;
    }
  }

  /* Login and register form tooltips styles */
  .ant-tooltip-inner {
    color: ${({ theme }) => theme.white};
    border: 0.5px solid rgba(7, 6, 6, 0.95);
    background-color: rgba(7, 6, 6, 0.95);
  }
  /* Reach UI - menu button */
    [data-reach-menu] {
      z-index: 9999;
    }
    [data-reach-menu-button]{
      cursor: pointer;
    }
    [data-reach-menu-item][data-selected] {
      background: none;
      color: ${({ theme }) => theme.dropdown.submenu.textColorOnHover};

      &::after {
        transform: scaleX(1);
		}
  };
  /* React Focus On - elements */
    [data-focus-guard] {
      display: none;
    } 
`;

export default GlobalStyle;

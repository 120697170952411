// Import actions
import { fetchRecommendations } from 'store/slices/recommendations';
import {
	fetchPlayerConfig,
	fetchMovieDetails,
	fetchContinueWatchingData,
	fetchMovieEpisodesPrevNext
} from 'store/actions';

// Import variables
import {
	PLAYER_SELECTORS,
	QUERY_SUBTYPES,
	PRODUCTS_TYPES
} from 'helpers/variables';

const { VOD } = PRODUCTS_TYPES;

export const getIsEpisode = (type) => type === QUERY_SUBTYPES.series;

export const fetchMovieResources = async ({ id, type, dispatch }) => {
	const playerSelector = PLAYER_SELECTORS.VOD;
	const isEpisode = getIsEpisode(type);

	// Dispatch an fetchContinueWatchingData action in continue watching folder
	await fetchContinueWatchingData(id)(dispatch);
	// Dispatch an fetchMovieDetails action in movieDetails folder
	await fetchMovieDetails(id, VOD, dispatch);

	// Dispatch an fetchMovieEpisodesPrevNext action in movieDetails folder
	isEpisode && (await fetchMovieEpisodesPrevNext(id, dispatch));
	// Dispatch an fetchRecommendations action in recommendations folder
	!isEpisode && (await fetchRecommendations({ id, type: VOD })(dispatch));
	// Dispatch an fetchPlayerConfig action in player folder
	await fetchPlayerConfig({
		productID: id,
		type: playerSelector,
		videoType: VOD
	})(dispatch);
};

export const getProductType = (path) => {
	const { 3: type } = path.split('/');
	return type ?? null;
};

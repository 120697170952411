import React from 'react';
import { string } from 'prop-types';

// Import components
import Icon from './Icon';
import { IconsWrapper } from './styles';

const PaymentIcon = ({ type }) => (
	<IconsWrapper>
		<Icon type={type} />
	</IconsWrapper>
);

PaymentIcon.propTypes = {
	type: string.isRequired
};

export default PaymentIcon;

import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Import utilities
import { useAuth, useEventListener, useToggle } from 'components/utilities';

// Import actions
import { signOut } from 'store/actions';

// Import variables
import { EVENTS_NAMES, KEY_CODES } from 'helpers/variables';

const { KEYDOWN } = EVENTS_NAMES;
const { ESCAPE } = KEY_CODES;

export const useNavDropdown = ({
	isUserDropdownOpen,
	setIsUserDropdownOpen,
	setActiveSubmenuId
}) => {
	const [isMenuRendered, setIsMenuRendered] = useState(false);
	const [isTooltipVisible, toggleTooltipVisibility] = useToggle();
	const menuContainerRef = useRef(null);
	const menuButtonRef = useRef(null);
	const dispatch = useDispatch();

	const { isAuth } = useAuth();

	const handleSignOut = () => signOut()(dispatch);

	const renderPopupContainer = (containerNode) => {
		setIsMenuRendered(true);
		return containerNode;
	};

	const handleDropdownVisibleChange = () => {
		setIsUserDropdownOpen(!isUserDropdownOpen);
		setActiveSubmenuId(null);
	};

	const handlePressEscape = ({ keyCode }) => {
		if (keyCode === ESCAPE && isUserDropdownOpen) {
			setIsUserDropdownOpen(false);
			menuButtonRef?.current && menuButtonRef.current.focus();
		}
	};

	const handleDropdownItemClick = () => setIsUserDropdownOpen(false);

	useEventListener(KEYDOWN, handlePressEscape, document);

	useEffect(() => {
		if (isMenuRendered && menuContainerRef?.current) {
			const list = menuContainerRef.current.firstChild;

			// Remove unnecessary custom Antd role on list and its elements
			list.removeAttribute('role');
			[...list.children].forEach((element) => element.removeAttribute('role'));
		}
	}, [isMenuRendered]);

	return {
		isAuth,
		menuContainerRef,
		menuButtonRef,
		isTooltipVisible,
		toggleTooltip: toggleTooltipVisibility,
		handleSignOut,
		renderPopupContainer,
		handleDropdownVisibleChange,
		handleDropdownItemClick
	};
};

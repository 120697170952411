import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { PlayerElements, Button } from 'components/elements';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const { IconCast, IconCastConnected } = PlayerElements;

const CastButton = () => {
	const { t } = useTranslation();

	const { isCasting, isCastAvailable, cast } = usePlayerVodContext();

	if (!isCastAvailable) return null;

	const title = isCasting
		? t('player_cast_button_disconnect')
		: t('player_cast_button');

	return (
		<Button
			onClick={cast}
			modifiers={['icon', 'playerIconHover']}
			title={title}
			aria-label={title}
		>
			{isCasting ? <IconCastConnected aria-hidden /> : <IconCast aria-hidden />}
		</Button>
	);
};

export default CastButton;

import i18next from 'i18next';

// Import variables
import {
	ONE_TIME,
	RECURRING,
	TRANSACTION_TYPES,
	GATEWAY_TYPES
} from 'helpers/prices';

const { SUBSCRIPTION } = TRANSACTION_TYPES;

const {
	CARD,
	CARD_RECURRING,
	BLIK,
	BLIK_RECURRING,
	GOOGLE_PAY,
	GOOGLE_PAY_RECURRING,
	APPLE_PAY,
	APPLE_PAYRECURRING,
	OTHER_BLUEMEDIA,
	UNKNOWN
} = GATEWAY_TYPES;

const OTHER_BLUEMEDIA_METHOD = { id: OTHER_BLUEMEDIA, name: OTHER_BLUEMEDIA };

export const getGatewayTranslation = (type) => {
	switch (type) {
		case CARD:
		case CARD_RECURRING:
			return i18next.t(`payment_gateway_type_${CARD}`);

		case BLIK:
		case BLIK_RECURRING:
			return i18next.t(`payment_gateway_type_${BLIK}`);

		case GOOGLE_PAY:
		case GOOGLE_PAY_RECURRING:
			return i18next.t(`payment_gateway_type_${GOOGLE_PAY}`);

		case APPLE_PAY:
		case APPLE_PAYRECURRING:
			return i18next.t(`payment_gateway_type_${APPLE_PAY}`);

		case OTHER_BLUEMEDIA:
			return i18next.t(`payment_gateway_type_${OTHER_BLUEMEDIA}`);

		default:
			return i18next.t(`payment_gateway_type_${UNKNOWN}`);
	}
};

export const getAvailableGatewayList = ({ lastGateway, transactionType }) => {
	const { available_gateway_types } = lastGateway;

	const isSubscription = transactionType === SUBSCRIPTION;

	const type = isSubscription ? RECURRING : ONE_TIME;

	const gatewayList = available_gateway_types[type] ?? [];

	const filteredList = gatewayList.filter(
		({ name }) => name.includes(CARD) || name.includes(BLIK)
	);

	return isSubscription
		? filteredList
		: [...filteredList, OTHER_BLUEMEDIA_METHOD];
};

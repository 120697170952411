import React from 'react';

// Import styles
import { Divider } from './styles';

function VerticalDivider() {
	return <Divider role="separator" aria-orientation="vertical" aria-hidden />;
}

export default VerticalDivider;

import styled, { css } from 'styled-components';

// Import icons
import { ReactComponent as IconCloseSVG } from 'assets/icons/ic_close_thin.svg';

// Import components
import { Button } from 'components/elements';

// Import utilties
import { customMinResponsive, size, focusBasic } from 'components/styles';

export const ButtonClose = styled(Button)`
	position: absolute;
	top: 0.5rem;
	right: 1rem;
	z-index: 100;
	border: none;

	&:hover,
	&:focus {
		cursor: pointer;

		svg > g > g {
			fill: ${({ theme }) => theme.primaryBlueLight};
		}
	}

	&:focus-visible {
		outline: none;
		${focusBasic}
	}

	${customMinResponsive(
		size.medium,
		css`
			right: 3rem;
		`
	)};
`;

export const IconClose = styled(IconCloseSVG)`
	position: relative;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { AuthModal } from 'components/elements';
import CommonButton from './CommonButton';
import { UsePassModal } from 'components/elements/molecules/pass';

// Import utils
import { useToggle } from 'components/utilities';

const UsePassButton = () => {
	const { t } = useTranslation();
	const [isOpened, toggle] = useToggle();

	return (
		<>
			<AuthModal>
				<CommonButton onClick={toggle} isPrime>
					{t('pass_use_pass_button')}
				</CommonButton>
			</AuthModal>
			<UsePassModal isOpened={isOpened} toggle={toggle} />
		</>
	);
};

export default UsePassButton;

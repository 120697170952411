import React from 'react';
import { useParams } from 'react-router-dom';
import { object, number, bool, func, string } from 'prop-types';

// Import components
import BasicSlide from '../BasicSlide';

// Import helpers
import { checkProductSubtype } from 'helpers';
import { getAuxiliaryProductData } from 'helpers/products';

const SectionItem = ({
	product,
	index,
	renderSlideAsLink,
	onItemClick,
	spaceBetweenSlides,
	slideHeight,
	slideWidth,
	slideType,
	isActive
}) => {
	const params = useParams();

	const { isBanner } = checkProductSubtype(product.subtype);
	if (isBanner) return null;

	const { linkParams, description } = getAuxiliaryProductData({
		product,
		params
	});

	return (
		<BasicSlide
			product={product}
			index={index}
			renderSlideAsLink={renderSlideAsLink}
			onItemClick={onItemClick}
			spaceBetweenSlides={spaceBetweenSlides}
			slideHeight={slideHeight}
			slideWidth={slideWidth}
			slideType={slideType}
			description={description}
			linkParams={linkParams}
			isActive={isActive}
		/>
	);
};

SectionItem.propTypes = {
	product: object.isRequired,
	index: number.isRequired,
	renderSlideAsLink: bool,
	onItemClick: func,
	spaceBetweenSlides: number.isRequired,
	slideHeight: number.isRequired,
	slideWidth: number.isRequired,
	slideType: string,
	isActive: bool
};

export default SectionItem;

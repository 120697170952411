import styled, { css } from 'styled-components';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

// Import styles
import { flex, focusBasic } from 'components/styles';

const { SubMenu, Item } = Menu;

const baseText = css`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	line-height: 1.9rem;
	font-weight: ${({ theme }) => theme.font.normal};
	font-family: 'Roboto', sans-serif;
	color: ${({ theme: { body } }) => body.mobileNav.color};
	&::first-letter {
		text-transform: capitalize;
	}
	&:focus-visible {
		color: ${({ theme: { body } }) => body.mobileNav.colorFocus};
	}
`;

export const StyledSubMenu = styled(SubMenu)`
	.ant-menu-submenu-title {
		${baseText}
		font-size: ${({ theme }) => theme.fontSize.xs}rem;
		height: 7.5rem !important;
		${flex({ jc: 'space-between' })}
		padding: 2.7rem 2.5rem 2.9rem 3rem !important;
		margin: 0;
		border-bottom: 1px solid ${({ theme }) => theme.line};

		&:active {
			background-color: ${({ theme: { body } }) => body.mobileNav.bgActive};
		}
	}

	.ant-menu-item {
		&:active {
			background-color: ${({ theme: { body } }) => body.mobileNav.bgActive};
		}
	}

	&.ant-menu-submenu-open {
		div svg {
			transform: rotate(180deg);
		}
	}

	&.ant-menu-submenu > .ant-menu {
		background-color: ${({ theme: { body } }) => body.mobileNav.bg};
	}

	&:focus-visible {
		.ant-menu-submenu-title {
			${focusBasic}
			outline-offset: -0.5rem;
		}
	}
`;

export const StyledMenuItem = styled(Item)`
	${flex({ jc: 'flex-start' })}
	&.ant-menu-item {
		${baseText}
		height: ${({ theme }) => (theme.isWCAG ? 8 : 5.5)}rem !important;
		line-height: 5.5rem !important;
		padding: 0 0 0 6rem !important;
		border-bottom: 1px solid ${({ theme }) => theme.line};
		margin: 0 !important;
		&::after {
			border-right: none;
		}
		&.ant-menu-item-active {
			background-color: ${({ theme: { body } }) => body.mobileNav.bg};
		}
	}
	&.ant-menu-item > a {
		color: ${({ theme: { body } }) => body.mobileNav.color};
		font-size: ${({ isMainLink, theme }) =>
			isMainLink ? theme.fontSize.xs : theme.fontSize.set('xs', -0.2)}rem;

		&:focus,
		&:focus-visible {
			color: ${({ theme: { user } }) => user.menuActiveText};
		}
	}
`;

export const StyledLink = styled(Link)`
	&:link,
	&:visited {
		${baseText}
		font-size: ${({ isMainLink, theme }) =>
			isMainLink ? theme.fontSize.xs : theme.fontSize.set('xs', -0.2)}rem
	}

	&:focus-visible {
		${focusBasic}
	}
`;

export const StyledAnchorLink = styled.a`
	${baseText}
	font-size: ${({ isMainLink }) => (isMainLink ? '1.6rem' : '1.4rem')};

	&:focus-visible {
		${focusBasic}
	}
`;

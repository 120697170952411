import React from 'react';
import { useTranslation } from 'react-i18next';
import VisuallyHidden from '@reach/visually-hidden';
import { func, string } from 'prop-types';

//Import components
import IconButton from './IconButton';

// Import styled components
import {
	StyledLargeTextIcon,
	StyledMediumTextIcon,
	StyledSmallTextIcon,
	Text
} from './styles';

// Import helpers
import { AVAILABLE_FONT_SIZE } from 'helpers/wcag';

const { DEFAULT, MEDIUM, LARGE } = AVAILABLE_FONT_SIZE;

const DisabilityFontOptions = ({ setFontSize, actionInfo }) => {
	const { t } = useTranslation();

	const handleFontSizeChange = (fontSize) => setFontSize(fontSize);

	return (
		<>
			<Text marginRight>{t('common_header_font_size')}</Text>
			<IconButton
				ariaLabel={t('aria_header_resize_font_default_button')}
				onClick={handleFontSizeChange(DEFAULT)}
				marginRight
			>
				<StyledSmallTextIcon aria-hidden="true" />
			</IconButton>
			<IconButton
				ariaLabel={t('aria_header_resize_font_medium_button')}
				onClick={handleFontSizeChange(MEDIUM)}
				marginRight
			>
				<StyledMediumTextIcon aria-hidden="true" />
			</IconButton>
			<IconButton
				ariaLabel={t('aria_header_resize_font_large_button')}
				onClick={handleFontSizeChange(LARGE)}
			>
				<StyledLargeTextIcon aria-hidden="true" />
				<VisuallyHidden aria-atomic="false" aria-live="polite">
					{actionInfo}
				</VisuallyHidden>
			</IconButton>
		</>
	);
};

DisabilityFontOptions.propTypes = {
	setFontSize: func.isRequired,
	actionInfo: string
};

export default DisabilityFontOptions;

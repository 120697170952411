import React from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';

// Import components
import VideoDescriptionModal from './VideoDescriptionModal';

// Import styles
import { StyledButton } from './styles';

// Import icons
import { ReactComponent as VideoDescIcon } from 'assets/icons/ic_video_desc.svg';

// Import context
import useGalleryContext from 'components/elements/organisms/product_gallery/context/useGalleryContext';

const VideoDescription = ({ description }) => {
	const { t } = useTranslation();

	const {
		isMobileView,
		showFullscreen,
		isDescriptionModalVisible,
		toggleDescriptionModalVisibility
	} = useGalleryContext();

	return (
		<>
			{isDescriptionModalVisible && (
				<VideoDescriptionModal
					title={t('common_video_desc_modal_title')}
					toggle={toggleDescriptionModalVisibility}
					description={description}
				/>
			)}

			<StyledButton
				onClick={toggleDescriptionModalVisibility}
				isMobileView={isMobileView}
				showFullscreen={showFullscreen}
				aria-label={t('common_video_desc_modal_button')}
			>
				<VideoDescIcon aria-hidden role="img" />
			</StyledButton>
		</>
	);
};

VideoDescription.propTypes = {
	description: string.isRequired
};

export default VideoDescription;

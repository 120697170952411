import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enableParentalControl, fetchFavourites } from 'store/actions';

// Import validation
import { disabledValidation } from 'components/elements/organisms/modal/modal_form/parental_control_modal/parental_control_fields/validation';

// Import utilities
import { ToggleRPC } from 'components/utilities';

// Import components
import { Button, ParentalControlModal } from 'components/elements';

const ParentalControlButton = ({
	enableParentalControl,
	configCallback,
	fetchFavourites
}) => {
	// Get translation
	const { t } = useTranslation();

	const handleEnableParentalControl = async (values) => {
		await enableParentalControl({
			...values,
			configCallback
		});
		// fetch favourites products
		await fetchFavourites();
	};

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					<Button onClick={toggle} modifiers="default">
						{t('parental_control_btn_enabled')}
					</Button>
					<ParentalControlModal
						toggle={toggle}
						title={t('parental_control_title')}
						isEnabled={true}
						initialValues={{ pin: '' }}
						validation={disabledValidation}
						on={on}
						submitAction={handleEnableParentalControl}
					/>
				</>
			)}
		</ToggleRPC>
	);
};

ParentalControlButton.propTypes = {
	enableParentalControl: PropTypes.func.isRequired,
	configCallback: PropTypes.func.isRequired,
	fetchFavourites: PropTypes.func.isRequired
};

export default connect(null, { enableParentalControl, fetchFavourites })(
	ParentalControlButton
);

import React from 'react';
import PropTypes from 'prop-types';

// Import styles
import { ButtonLoaderItem } from './styles';

/**
 * Simple, small loader which can be added to buttons.
 * @param {number | string} size - size of a loader (same width and height) in rem
 * @param {number | string} thickness - thickness of a loader circle in px
 */
const ButtonLoader = ({
	size = 3,
	thickness = 7,
	loaderColor = 'whiteSemiTranslucent'
}) => {
	return (
		<ButtonLoaderItem
			height={size}
			width={size}
			thickness={thickness}
			loaderColor={loaderColor}
		/>
	);
};

ButtonLoader.propTypes = {
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	thickness: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	loaderColor: PropTypes.string
};

export default ButtonLoader;

import { PRODUCTS_TYPES } from 'helpers/variables';
const { VOD, AUDIO, ALL, ARTICLE, LIVE_EVENT } = PRODUCTS_TYPES;

// show genres selector in 'all products view' (sections and categories)
export const PRODUCTS_WITH_GENRES = [VOD, AUDIO, ALL];

const setUrlType = (type) => {
	switch (type) {
		case ARTICLE:
			return ARTICLE;

		case LIVE_EVENT:
			return LIVE_EVENT;

		case ALL:
			return '';

		default:
			return VOD;
	}
};

export const getUrl = ({ sectionId, isPacket, type }) => {
	if (isPacket) return `/products/packet/${sectionId}/products`;
	if (sectionId) return `/sections/${sectionId}/content`;
	return `/products/${setUrlType(type)}`;
};

import { useTranslation } from 'react-i18next';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const useSubtitlesItem = ({ language, name }) => {
	const { t } = useTranslation();
	const { selectedSubtitle, setSubtitle } = usePlayerVodContext();

	const handleSetSubtitle = () => setSubtitle(language);

	const isSelected = language === selectedSubtitle;

	const isDisabled = language === 'disabled';

	const ariaText = isDisabled
		? t('aria_player_settings_subtitles_disabled')
		: `${t('aria_player_settings_subtitles')} ${language}`;

	const optionText = name || language;

	return { isSelected, ariaText, optionText, setSubtitle: handleSetSubtitle };
};

export default useSubtitlesItem;

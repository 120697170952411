// Import assets
import placeholder from 'assets/images/placeholder.png';

// Import helpers
import { getTranslation } from 'helpers';

export const defaultSliderOptions = {
	dots: false,
	infinite: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 0,
	rows: 1,
	speed: 0,
	fade: false,
	swipe: false,
	accessibility: false
};

const common360Settings = {
	width: '100%',
	height: '100%',
	pitch: 10,
	yaw: 180,
	hfov: 110,
	compass: false,
	draggable: true
};

export const graphics360Settings = {
	...common360Settings,
	autoLoad: true,
	showControls: false,
	showZoomCtrl: false
};

export const video360Settings = {
	...common360Settings,
	controls: false,
	loop: false,
	autoplay: false
};

export const OBSERVER_ACTIONS = {
	BEFORE: 'BEFORE_SLIDE',
	AFTER: 'AFTER_SLIDE',
	FULLSCREEN: 'FULLSCREEN'
};

export const getAssetInfo = ({ galleryMetadata, metadata }) => {
	const author = metadata?.author || galleryMetadata?.author;
	const description = metadata?.description || galleryMetadata?.description;
	const title = metadata?.title || galleryMetadata?.title;
	const source = metadata?.source || galleryMetadata?.source;

	return { author, description, source, title };
};

export const getThumbnailAssets = ({ assets }) => {
	return assets.map(({ mime, url, metadata, id }) => {
		const preview = mime.includes('video') ? metadata?.preview_url : url;
		return {
			id,
			url: preview ?? placeholder,
			metadata
		};
	});
};

export const modalStyles = (isMobile) => ({
	margin: 0,
	padding: 0,
	maxHeight: '100vh',
	overflowY: isMobile ? 'auto' : 'hidden',
	overflowX: 'hidden'
});

export const thumbnailSliderSettings = {
	infinite: false,
	draggable: false,
	variableWidth: true,
	swipeToSlide: true,
	focusOnSelect: true,
	slidesToScroll: 7,
	slidesToShow: 7,
	responsive: [
		{
			breakpoint: 2000,
			settings: {
				slidesToScroll: 5,
				slidesToShow: 5
			}
		},
		{
			breakpoint: 1400,
			settings: {
				slidesToScroll: 4,
				slidesToShow: 4
			}
		},
		{
			breakpoint: 1150,
			settings: {
				slidesToScroll: 3,
				slidesToShow: 3
			}
		}
	]
};

const getAssetsTranslations = (assets) =>
	assets.map(({ metadata, translations, ...rest }) => {
		const assetMetadataTranslated = getTranslation(translations);
		const assetMetadata = { ...metadata, ...assetMetadataTranslated };

		return { metadata: assetMetadata, ...rest };
	});

export const getGalleriesTranslations = (galleries) =>
	galleries.map(({ metadata, translations, assets, ...rest }) => {
		const galleryMetadataTranslated = getTranslation(translations);
		const galleryMetadata = { ...metadata, ...galleryMetadataTranslated };

		const assetsTranslated = getAssetsTranslations(assets);

		return { assets: assetsTranslated, metadata: galleryMetadata, ...rest };
	});

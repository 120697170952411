import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, object } from 'prop-types';
import VisuallyHidden from '@reach/visually-hidden';

// Import components
import { AriaModal } from 'components/elements';
import { CommonButton } from 'components/views/vod/components/buttons';
import { ProperName } from 'components/elements';

// Import styles
import {
	Wrapper,
	ButtonClose,
	IconCloseStyled,
	Header,
	Description,
	ButtonsWrapper
} from './styles';

// Import helpers
import { COOKIES_MODAL_TYPES } from 'helpers/cookies';
import { customHtmlParser } from 'helpers';

// Import variables
import { COOKIES } from 'helpers/wcagProperNames';
import { LANGUAGES_TYPES } from 'helpers/variables';

const { BANNER } = COOKIES_MODAL_TYPES;
const { EN } = LANGUAGES_TYPES;

const CookieBanner = ({
	messageToggle,
	toggleCookies,
	acceptAllCookies,
	document
}) => {
	const { t } = useTranslation();

	const title = document?.title;
	const alias = document?.alias;
	const content = document?.content;

	return (
		<AriaModal aria-labelledby={alias} titleId={alias}>
			<Wrapper>
				<ButtonClose onClick={messageToggle}>
					<VisuallyHidden>
						{t('common_cookie_close_btn_info')}{' '}
						<ProperName text={COOKIES} lang={EN} />
					</VisuallyHidden>
					<IconCloseStyled aria-hidden role="img" />
				</ButtonClose>
				<Header id={alias}>{title}</Header>
				<Description>{customHtmlParser(content)}</Description>
				<ButtonsWrapper>
					<CommonButton onClick={toggleCookies}>
						{t('common_cookie_btn_customize')}
					</CommonButton>

					<CommonButton isPrime onClick={acceptAllCookies(BANNER)}>
						{t('common_cookie_btn_accept')}
					</CommonButton>
				</ButtonsWrapper>
			</Wrapper>
		</AriaModal>
	);
};

CookieBanner.propTypes = {
	messageToggle: func.isRequired,
	toggleCookies: func.isRequired,
	acceptAllCookies: func.isRequired,
	document: object
};

export default CookieBanner;

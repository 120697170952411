import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { customMinResponsive, size } from 'components/styles';

// Import variables
import { KEYBOARD_KEYS } from 'helpers/variables';

const { ENTER } = KEYBOARD_KEYS;

const InputRadio = ({
	name,
	value,
	onChange,
	onBlur,
	id,
	label,
	checked,
	disabled
}) => {
	const handleLabelKeyPress = (e) => e.key === ENTER && onChange();

	return (
		<Container>
			<Label tabIndex="0" onKeyPress={handleLabelKeyPress}>
				<Input
					id={id}
					type="radio"
					name={name}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					checked={checked}
					disabled={disabled}
				/>
				<Circle />
				{label}
			</Label>
		</Container>
	);
};

const Container = styled.div`
	line-height: 1;
`;

const Label = styled.label`
	display: inline-block;
	position: relative;
	padding-left: 3.2rem;
	color: ${({ theme }) => theme.graySemiLight};
	font-weight: ${({ theme }) => theme.font.normal};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	line-height: 1.4rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
			font-weight: ${({ theme }) => theme.font.medium};
			line-height: 2.1rem;
		`
	)}
`;

const Circle = styled.span`
	display: block;
	width: 1.8rem;
	height: 1.8rem;
	border-radius: 50%;
	border: ${({ theme }) => `1px solid ${theme.primaryBlueLight}`};
	position: absolute;
	left: 0;
	top: 0;
	cursor: pointer;

	&:after {
		display: none;
		content: '';
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		position: absolute;
		top: 0.3rem;
		left: 0.3rem;
		background: ${({ theme }) => theme.primaryBlueLight};
		border: 1px solid transparent;
	}
`;

const Input = styled.input`
	position: absolute;
	visibility: hidden;

	&:checked + ${Circle} {
		&:after {
			display: block;
		}
	}
`;

InputRadio.defaultProps = {
	onBlur: () => {},
	id: null,
	label: '',
	checked: null,
	disabled: false
};

InputRadio.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label: PropTypes.string,
	checked: PropTypes.bool,
	disabled: PropTypes.bool
};

export default InputRadio;

import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateWatchingInProgress,
	updateVideoSession,
	setPlayerReady
} from 'store/actions';
import throttle from 'lodash/throttle';
import styled from 'styled-components';

// Import components
import { TheoPlayerContent } from 'components/elements/organisms/player_vod/PlayerWrapper/player_content/theo_player';

// Import helpers
import {
	INITIAL_STATE_PLAYER_VOD,
	TIME_THROTTLE,
	calculateVideoIntervalTime,
	getVideoSource,
	hideTheoPlayerControlls
} from '../helpers';
import { playerDeleteSyncRequest, SHAKA_PLAYER_ERRORS } from 'helpers/player';

import { fetchPlaylistData } from '../shakaPlayer';

// Import variables
import { THEO_LIBRARY_URL, EVENTS_NAMES } from 'helpers/variables';

// Create context
export const TheoPlayerContext = createContext();

const { SHAKA_PLAYER_ERROR_MESSAGE } = SHAKA_PLAYER_ERRORS;

const { PLAY, PAUSE, ERROR, WAITING, PLAYING, LOADEDDATA, FULLSCREENCHANGE } =
	EVENTS_NAMES;

class TheoPlayerProvider extends React.Component {
	_isMounted = false;
	isPlayerReady = false;
	intervalUpdateWatchingProgress = null;
	intervalVideoSession = null;
	trackingInterval = null;
	streamStartDate = null;

	constructor(props) {
		super(props);
		this.state = INITIAL_STATE_PLAYER_VOD;
		this.timeThrottle = throttle(
			(time) => this.handleSetCurrentTime(time),
			TIME_THROTTLE
		);

		//Creating reference to store video component and video container
		this.videoComponentRef = React.createRef();
		this.videoContainerRef = React.createRef();

		// media
		this.video = null;
		this.videoContainer = null;
		this.player = null;
	}

	async componentDidMount() {
		const {
			playlist,
			isError,
			videoSessionId,
			isSiedebarVisible,
			configuration: { movieEndMargin },
			isPreview
		} = this.props;

		this._isMounted = true;
		this.setState({ isSiedebarVisible, movieEndMargin });

		// Delete video sesion
		window.addEventListener('beforeunload', this.beforeunload, false);

		const playlistData = await fetchPlaylistData(playlist);

		if (
			videoSessionId &&
			this._isMounted &&
			!this.isPlayerReady &&
			!isError &&
			playlistData
		) {
			// Getting reference to video container on DOM
			this.videoContainer = this.videoContainerRef.current;

			this.setState({ buffering: true });

			// get sources
			const sources = getVideoSource(playlistData);

			this.player = new window.THEOplayer.Player(this.videoContainer, {
				libraryLocation: THEO_LIBRARY_URL
			});

			// Getting reference to video on DOM
			this.video = document.querySelector('video');

			// add player events listeners
			this.addEventsListeners();

			// hide theo player controlls
			hideTheoPlayerControlls({
				isVodPlayer: true,
				isPreviewPlayer: isPreview
			});

			this.player.autoplay = true;
			this.player.source = sources;
		} else if (isError && videoSessionId) {
			// If there is an error and video session is not equal to null
			// Turn on buffering
			this._isMounted && this.setState({ buffering: false });
		} else if (isError && !videoSessionId) {
			// If there is an error and video session is equal to null
			// Stop player
			this.player && this.player.destroy();
			// Turn on buffering,Set player ready
			this._isMounted && this.setState({ buffering: false, isReady: false });
		}
	}

	componentWillUnmount() {
		// Update watching progress time
		this.handleFetchWatchingProgress();

		this._isMounted = false;

		// Clear intervals
		clearInterval(this.intervalVideoSession);
		clearInterval(this.intervalUpdateWatchingProgress);
		clearInterval(this.trackingInterval);

		const { isError, videoSessionId, type, deleteVideoSession } = this.props;
		// Check if player is not undefined / null, if player container exists, if is not any API errors

		if (videoSessionId) {
			// Dispatch an deleteVideoSession action in player folder
			deleteVideoSession({ type });
		}

		if (!isError && this.player && this.isPlayerReady) {
			// remove event listeners
			this.removeEventsListeners();
			// Destroy the player
			this.player.destroy();
			this.isPlayerReady = false;
		}
	}

	addEventsListeners = () => {
		this.player.addEventListener(PLAY, this.onPlay);
		this.player.addEventListener(PAUSE, this.onPause);
		this.player.addEventListener(ERROR, this.onError);
		this.player.addEventListener(WAITING, this.onWaiting);
		this.player.addEventListener(PLAYING, this.onPlaying);
		this.player.addEventListener(LOADEDDATA, this.onReady);
		document.addEventListener(FULLSCREENCHANGE, this.onFullScreenChange);
	};

	removeEventsListeners = () => {
		this.player.removeEventListener(PLAY, this.onPlay);
		this.player.removeEventListener(PAUSE, this.onPause);
		this.player.removeEventListener(ERROR, this.onError);
		this.player.removeEventListener(WAITING, this.onWaiting);
		this.player.removeEventListener(PLAYING, this.onPlaying);
		this.player.removeEventListener(LOADEDDATA, this.onReady);
		document.removeEventListener(FULLSCREENCHANGE, this.onFullScreenChange);
	};

	handleFetchWatchingProgress = (currentProgress = null) => {
		const {
			isPreview,
			productID: productUuid,
			updateWatchingInProgress
		} = this.props;
		const { isReady } = this.state;
		const isAvaialbe = this._isMounted && isReady && this.video?.currentTime;

		if (isAvaialbe && !isPreview) {
			const currentTime = currentProgress || this.video.currentTime;
			const time = Math.floor(currentTime);
			updateWatchingInProgress({ productUuid, time });
		}
	};

	beforeunload = () => {
		const { videoSessionId } = this.props;
		// delete session
		playerDeleteSyncRequest(videoSessionId);
	};

	play = () => {
		this.player.play();
		this.setState({ isPaused: false });
	};

	pause = () => {
		this.player.pause();
		this.setState({ isPaused: true });
	};

	onWaiting = () => {
		this.setState({ buffering: true });
	};

	onPlaying = () => {
		this.setState({ buffering: false });
	};

	onReady = () => {
		const {
			type,
			setPlayerReady,
			configuration: { videoSession }
		} = this.props;

		// Set player ready
		this.isPlayerReady = true;
		this.setState({ isReady: true });
		// Dispatch an setPlayerReady action in player folder
		setPlayerReady({ type });

		// Set watching in progress interval
		const watchingIntervalTime = parseInt(
			process.env.REACT_APP_WATCHING_IN_PROGRESS
		);
		this.intervalUpdateWatchingProgress = setInterval(
			this.handleFetchWatchingProgress,
			watchingIntervalTime
		);
		// Set video session interval
		const videoSessionInvervalTime = calculateVideoIntervalTime(videoSession);
		this.intervalVideoSession = setInterval(
			this.handleUpdateVideoSession,
			videoSessionInvervalTime
		);
	};

	handleUpdateVideoSession = () => {
		const { videoSessionId, type, productID, updateVideoSession } = this.props;

		updateVideoSession({ videoSessionId, productID, type }, () => {
			// When is an api error
			// Destroy the player
			this.player.destroy();
			// Turn on buffering
			this.setState({ buffering: false, isReady: false });
			// Clear intervals
			clearInterval(this.intervalVideoSession);
			clearInterval(this.intervalUpdateWatchingProgress);
		});
	};

	onPlay = () => {
		this.setState({ isPaused: false });
	};

	onPause = () => {
		this.setState({ isPaused: true });
	};

	onEnded = () => {
		this.setState({ isEnded: true, isPaused: true });
	};

	onError = (error) => {
		const isOnline = navigator.onLine;

		if (this && this.props) {
			const { setPlayerPlaybackError, type } = this.props;

			// Catch only known errors which should be handled in UI
			if (error && error.code && isOnline) {
				this.setState({ isReady: false, buffering: false });
				// Destroy the player
				this.player && this.player.destroy();
				// Set error message
				error.message = SHAKA_PLAYER_ERROR_MESSAGE;
				// Dispatch an setPlayerPlaybackError action in player folder
				setPlayerPlaybackError(error, type);
				// Set player ready / Turn on buffering
				// Clear intervals
				clearInterval(this.intervalVideoSession);
				clearInterval(this.intervalUpdateWatchingProgress);
			}
		}
	};

	restart = () => {
		this.video.currentTime = 0;
		this.setState({ isEnded: false });
	};

	setEndMargin = (value = true) => {
		this.setState({ endMarginAction: value, endMarginActionCancel: value });
	};

	updateTime = (time) => {
		this.video.currentTime = time;
	};

	setFullScreen = () => {
		if (!document.fullscreenElement) {
			this.videoContainer.requestFullscreen();
			this.setState({ isFullScreen: true });
		} else {
			document.exitFullscreen();
			this.setState({ isFullScreen: false });
		}
	};

	onFullScreenChange = () => {
		const isFullScreen = !!document.fullscreenElement;
		this.setState({ isFullScreen });
	};

	render() {
		const {
			closePreview,
			isPreview,
			isSafari,
			children,
			productID,
			configuration: { videoId },
			type
		} = this.props;

		const currentTime = this.video ? this.video.currentTime : 0;

		return (
			<TheoPlayerContext.Provider
				value={{
					...this.state,
					isPreview,
					isSafari,
					closePreview,
					mute: this.mute,
					currentTime,
					pause: this.pause,
					play: this.play,
					restart: this.restart,
					setEndMargin: this.setEndMargin,
					productID,
					videoId,
					video: this.video,
					updateTime: this.updateTime,
					setFullScreen: this.setFullScreen
				}}
			>
				<TheoPlayerWrapper
					className="theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer"
					ref={this.videoContainerRef}
				>
					{children}
				</TheoPlayerWrapper>
				<TheoPlayerContent type={type} />
			</TheoPlayerContext.Provider>
		);
	}
}

const TheoPlayerWrapper = styled.div`
	height: 100% !important;
	width: 100% !important;
`;

TheoPlayerProvider.defaultProps = {
	isSiedebarVisible: false,
	isSafari: false
};

TheoPlayerProvider.propTypes = {
	isSiedebarVisible: PropTypes.bool,
	playlist: PropTypes.string.isRequired,
	isError: PropTypes.bool.isRequired,
	videoSessionId: PropTypes.string,
	deleteVideoSession: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	productID: PropTypes.string.isRequired,
	configuration: PropTypes.object.isRequired,
	closePreview: PropTypes.func.isRequired,
	isPreview: PropTypes.bool.isRequired,
	isSafari: PropTypes.bool.isRequired,
	updateWatchingInProgress: PropTypes.func.isRequired,
	setPlayerReady: PropTypes.func.isRequired,
	updateVideoSession: PropTypes.func.isRequired,
	setPlayerPlaybackError: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired
};

const mapStateToProps = (state, { type }) => {
	const { movieDetails, continue_watching } = state;

	const {
		playlist,
		configuration,
		error: { isError },
		videoSessionId
	} = state[type];

	const { uuid: productID } = movieDetails.data;

	const { time: continueWatchingTime } = continue_watching.continueProgress;

	return {
		playlist,
		productID,
		isError,
		configuration,
		videoSessionId,
		continueWatchingTime
	};
};

export default connect(mapStateToProps, {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateWatchingInProgress,
	updateVideoSession,
	setPlayerReady
})(TheoPlayerProvider);

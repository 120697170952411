import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import components
import { Alert, Headline, Loader, Static } from 'components/elements';

// Import helpers
import { customHtmlParser } from 'helpers';

const StaticContent = ({ isUserProfile }) => {
	const { t } = useTranslation();
	const {
		data: { title, content },
		isError,
		isLoaded
	} = useSelector(({ static_content }) => static_content);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		// eslint-disable-next-line
	}, [isLoaded]);

	if (!isLoaded && !isError && isUserProfile) {
		return <Loader zIndex={900} position="absolute" background={false} />;
	}

	const StaticUserHeading = (
		<Static.UserProfileHeading as="h2">{title}</Static.UserProfileHeading>
	);

	const StaticHeading = (
		<Static.Heading as="h1" modifiers={['primary', 'big']}>
			{title}
		</Static.Heading>
	);

	if (isError)
		return (
			<Alert
				text={t('common_errors_default_error')}
				centered
				closeBtn={false}
			/>
		);

	return (
		<>
			{content && (
				<Headline spaceBetween>
					{isUserProfile ? StaticUserHeading : StaticHeading}
				</Headline>
			)}
			<Static.Content>{customHtmlParser(content)}</Static.Content>
		</>
	);
};

StaticContent.defaultProps = {
	isUserProfile: false
};

StaticContent.propTypes = {
	isUserProfile: bool
};

export default StaticContent;

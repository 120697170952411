import styled from 'styled-components';

export const StyledListBoxGroup = styled.div`
	border: 1px solid ${({ theme }) => theme.listBox.group.border};
`;

export const StyledMethodTitle = styled.p`
	color: ${({ theme }) => theme.listBox.group.titleColor};
	padding: 1.2rem 2.6rem;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
`;

import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

const GridContainer = styled.ul`
	list-style: none;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: ${({ theme }) => (theme.isWCAG ? 5 : 2)}rem;
	grid-row-gap: ${({ theme }) => (theme.isWCAG ? 28 : 7)}rem;

	${customMinResponsive(
		size.small,
		css`
			grid-template-columns: repeat(2, 1fr);
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			grid-template-columns: ${({ theme }) =>
				theme.isWCAG ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'};

			grid-row-gap: ${({ theme }) => (theme.isWCAG ? 34 : 13)}rem;
		`
	)}
`;

export default GridContainer;

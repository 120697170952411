// Import variables
import { SECTIONS_ID } from 'helpers/variables';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { NAVIGATION, SEARCH, CONTENT, FOOTER } = SECTIONS_ID;

const { LOGIN, REGISTER } = APP_CORE_PATHS;

const TAB_NAV_LIST_DEFAULT = [
	{
		href: `#${CONTENT}`,
		text: 'common_header_tab_nav_content'
	},
	{
		href: `#${NAVIGATION}`,
		text: 'common_header_tab_nav_navigation'
	},
	{
		href: `#${SEARCH}`,
		text: 'common_header_tab_nav_search'
	},
	{
		href: `#${FOOTER}`,
		text: 'common_header_tab_nav_footer'
	}
];

const TAB_NAV_LIST_LOGIN = [
	{
		href: `#${CONTENT}`,
		text: 'common_header_tab_nav_content'
	},
	{
		href: `#${FOOTER}`,
		text: 'common_header_tab_nav_footer'
	},
	{
		href: '/',
		text: 'common_header_tab_nav_home'
	}
];

export const getTabNavList = (isLoginPage) =>
	isLoginPage ? TAB_NAV_LIST_LOGIN : TAB_NAV_LIST_DEFAULT;

export const checkIsLoginPage = (pathname) =>
	pathname.includes(LOGIN) || pathname.includes(REGISTER);

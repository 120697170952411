import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { PlayerSettings } from 'components/elements';
import LanguagesItem from './LanguagesItem';

// Import icons
import { ReactComponent as IconSound } from 'assets/icons/ic_sound.svg';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const Languages = () => {
	const { t } = useTranslation();

	const { languages } = usePlayerVodContext();

	if (languages.length < 2) return null;

	return (
		<PlayerSettings.Menu
			buttonContent={<IconSound />}
			title={t('player_settings_language')}
			type="languages"
			ariaText={t('aria_player_settings_audio_button')}
		>
			{languages.map((language) => (
				<LanguagesItem key={language} language={language} />
			))}
		</PlayerSettings.Menu>
	);
};

export default Languages;

import React, { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { FullscreenModal } from 'components/elements';
import DrawerFullscreen from './DrawerFullscreen';
import GridFullscreen from './GridFullscreen';

// Import styles
import { FullscreenWrapper, FullscreenTitle } from './styles';

// Import context
import useGalleryContext from 'components/elements/organisms/product_gallery/context/useGalleryContext';

// Import helpers
import { modalStyles } from 'components/elements/organisms/product_gallery/helpers';

function GalleryFullscreen() {
	const { t } = useTranslation();

	const ref = useRef();

	const { showGallery, toggle, isMobileView, metadata, isVideo } =
		useGalleryContext();

	const underlayStyle = useMemo(
		() => modalStyles(isMobileView),
		[isMobileView]
	);

	const titleText = isVideo
		? 'aria_gallery_fullscreen_modal_video_label'
		: 'aria_gallery_fullscreen_modal_label';

	const isTitlePositionRelative = isMobileView || isVideo;

	if (!showGallery) return null;

	return (
		<FullscreenModal
			onExit={toggle}
			underlayStyle={underlayStyle}
			titleText={t(titleText)}
		>
			<FullscreenWrapper isMobile={isMobileView} isVideo={isVideo}>
				<FullscreenTitle
					ref={ref}
					isTitlePositionRelative={isTitlePositionRelative}
				>
					{metadata?.title}
				</FullscreenTitle>
				{isMobileView ? (
					<GridFullscreen titleRef={ref} />
				) : (
					<DrawerFullscreen isVideo={isVideo} />
				)}
			</FullscreenWrapper>
		</FullscreenModal>
	);
}

export default GalleryFullscreen;

import React from 'react';
import { useSelector } from 'react-redux';
import { bool, string } from 'prop-types';

// Import components
import EpisodeControllers from './episode/EpisodeControllers';
import SeekControllers from './seek/SeekControllers';
import PlaybackControllers from './playback/PlaybackControllers';
import { Languages, Subtitles, Quality } from './settings/SettingsControllers';
import VolumeController from './volume/VolumeController';

// Import styles
import { ControllersWrapper } from './styles';

// Import helpers
import { checkProductSubtype } from 'helpers';

const PlayerControllers = ({ isFloatMode, playerType }) => {
	const data = useSelector((state) => state[playerType].details.data);
	const subtype = data?.subtype;

	const { isEpisode, isLiveEvent } = checkProductSubtype(subtype);

	return (
		<>
			<ControllersWrapper>
				<PlaybackControllers />
				{!isFloatMode && (
					<>
						{!isLiveEvent && <SeekControllers />}
						{isEpisode && <EpisodeControllers />}
					</>
				)}
			</ControllersWrapper>
			<ControllersWrapper>
				<Languages />
				<Subtitles />
				<Quality />
				<VolumeController />
			</ControllersWrapper>
		</>
	);
};

PlayerControllers.propTypes = {
	isFloatMode: bool.isRequired,
	playerType: string
};

export default PlayerControllers;

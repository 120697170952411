import { useHistory, useLocation } from 'react-router-dom';

// Import helpers
import { setParametr } from 'helpers';
import { getSearchParams } from 'helpers/movieLists';
import { VOD_PARAMS, PRODUCTS_TYPES, QUERY_TYPES } from 'helpers/variables';

// Import select options
import { PRODUCT_TYPE_SELECT_OPTIONS } from './select_options';

const { VOD, AUDIO, ALL } = PRODUCTS_TYPES;

function useProductTypeSelect() {
	const history = useHistory();
	const { search } = useLocation();

	// Chnge query param genre on categories page
	const handleRedirectUrl = (typeValue) => {
		const {
			paramsUrl: { genre, order, orderDir }
		} = getSearchParams(search);

		const addGenres =
			typeValue === VOD || typeValue === AUDIO || typeValue === ALL;

		const typeParam = setParametr(VOD_PARAMS.type, QUERY_TYPES[typeValue]);

		const startQuery = addGenres ? `?${genre}&${typeParam}` : `?${typeParam}`;

		history.push(`${startQuery}${order}${orderDir}`);
	};

	const handleSelectChange = (value) => {
		const typeValue = PRODUCT_TYPE_SELECT_OPTIONS?.find(
			(item) => item.type === value
		)?.type;
		handleRedirectUrl(typeValue);
	};

	return {
		selectChange: handleSelectChange
	};
}

export default useProductTypeSelect;

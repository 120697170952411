import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';

// Import styles
import { StyledLink, Image } from './styles';

const HeaderIcon = ({ to, ariaLabel, src }) => {
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	return (
		<StyledLink to={to} aria-label={ariaLabel}>
			<Image src={src} alt="" aria-hidden isContrast={isContrast} />
		</StyledLink>
	);
};

HeaderIcon.defaultProps = {
	to: '/',
	ariaLabel: '',
	src: ''
};

HeaderIcon.propTypes = {
	to: string.isRequired,
	ariaLabel: string.isRequired,
	src: string.isRequired
};

export default HeaderIcon;

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Import helpers
import { checkIsFavourite } from 'helpers/epg';
import { addFavourite, deleteFavourite } from 'store/actions';

// Import utilities
import { useAuth, useButtonAnnouncement } from 'components/utilities';

const useFavoriteButton = ({ uuid }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { isAuth } = useAuth();
	const { data: favourites, isLoaded } = useSelector(
		({ favourites }) => favourites
	);

	const { announcement, setAnnouncement, clearAnnouncement } =
		useButtonAnnouncement();

	const isFavourite = checkIsFavourite(favourites, uuid);

	const handleClick = () => {
		if (announcement) return;
		if (isFavourite) {
			deleteFavourite(uuid)(dispatch);
			setAnnouncement(t('common_favorite_button_delete_announcement'));
			return;
		}

		addFavourite(uuid)(dispatch);
		setAnnouncement(t('common_favorite_button_add_announcement'));
	};

	const renderButtonText = () => {
		if (!announcement) {
			return isFavourite
				? t('vod_buttons_favourite_delete')
				: t('vod_buttons_favourite_add');
		}

		clearAnnouncement();
		return announcement;
	};

	const buttonText = renderButtonText();

	return {
		buttonText,
		isAuth,
		isFavourite,
		isLoaded,
		click: handleClick
	};
};

export default useFavoriteButton;

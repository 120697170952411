import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { HeaderIcon, StateChangeAnnounce } from 'components/elements';

// Import logic
import { useInstitutionLogo } from 'components/utilities';

const InstitutionLogo = () => {
	const { t } = useTranslation();
	const { logoUrl, name, uuid, institutionPath } = useInstitutionLogo();

	if (!uuid) return null;

	return (
		<>
			<HeaderIcon
				to={institutionPath}
				ariaLabel={name}
				src={logoUrl}
				role="alert"
			/>
			<StateChangeAnnounce role="alert">
				{t('common_institution_announcement', { name })}
			</StateChangeAnnounce>
		</>
	);
};

export default InstitutionLogo;

import React from 'react';
import VisuallyHidden from '@reach/visually-hidden';
import { node, func, string, bool } from 'prop-types';

//Import components
import IconButton from './IconButton';

const DisabilityNavIcon = ({
	children,
	ariaLabel,
	toggleAction,
	actionInfo,
	marginRight
}) => {
	return (
		<IconButton
			ariaLabel={ariaLabel}
			onClick={toggleAction}
			marginRight={marginRight}
		>
			{children}
			{actionInfo && (
				<VisuallyHidden aria-atomic="false" aria-live="polite">
					{actionInfo}
				</VisuallyHidden>
			)}
		</IconButton>
	);
};

DisabilityNavIcon.propTypes = {
	children: node,
	ariaLabel: string.isRequired,
	toggleAction: func.isRequired,
	actionInfo: string,
	marginRight: bool
};

export default DisabilityNavIcon;

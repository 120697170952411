import _ from 'lodash';
import { generatePath } from 'react-router-dom';

// Import services
import services from 'services/services';

// Import helpers
import { PRODUCTS_TYPE_NAMES } from 'helpers/variables';
import { GET_ACTIVE_PRODUCTS_FOR_PACKET_API } from 'helpers/api';

const { PACKET } = PRODUCTS_TYPE_NAMES;

export const getPacketPromises = (products) => {
	const packets = products.filter(({ type }) => type === PACKET);
	return packets.map(async ({ uuid, updated_at, expires_at }) => {
		const url = generatePath(GET_ACTIVE_PRODUCTS_FOR_PACKET_API, { uuid });
		const response = await services.get(url);
		const data = response?.data?.data;
		return Promise.resolve({
			...response,
			data: data.map((product) => ({
				...product,
				availability_expiration_date: expires_at,
				updated_at
			}))
		});
	});
};

export const getProductsRegardingPackets = async (products) => {
	const items = await Promise.all(getPacketPromises(products));
	const settledItems = items
		.filter(({ status }) => status === 200)
		.map(({ data }) => data);
	return _.uniqBy(_.flatten(settledItems), 'uuid');
};

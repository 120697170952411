import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Import componets
import { CommonButton } from 'components/views/vod/components/buttons';

// Import helpers
import { checkDetailsUrl } from './helpers';

const DetailsButton = ({ urlPc = null }) => {
	const { t } = useTranslation();

	if (!urlPc) return null;

	const { isInternalPath, url } = checkDetailsUrl(urlPc);

	return (
		<>
			{isInternalPath ? (
				<CommonButton
					as={Link}
					to={url}
					aria-label={t('aria_video_banner_open_content')}
				>
					{t('common_video_banner_button')}
				</CommonButton>
			) : (
				<CommonButton
					as="a"
					href={url}
					aria-label={t('aria_video_banner_open_content_external')}
					target="_blank"
					rel="noopener noreferrer"
				>
					{t('common_video_banner_button')}
				</CommonButton>
			)}
		</>
	);
};

DetailsButton.propTypes = {
	urlPc: string
};

export default DetailsButton;

import React, { useRef, useEffect } from 'react';
import { string, number } from 'prop-types';
import { PannellumVideo } from 'pannellum-react';

// Import styles
import { VideoWrapper, VideoContainer } from './styles';

// Import components
import VideoControls from './VideoControls';

// Import helpers
import { video360Settings } from 'components/elements/organisms/product_gallery/helpers';

const Video360 = ({ url, index }) => {
	const viewerRef = useRef();
	const videoPlayerRef = useRef();

	useEffect(() => {
		if (viewerRef.current) {
			videoPlayerRef.current = viewerRef.current.videoNode;
			const viewer = viewerRef.current.getViewer();
			return () => {
				viewer.destroy();
			};
		}
	}, []);

	return (
		<VideoWrapper>
			<VideoContainer>
				<PannellumVideo ref={viewerRef} video={url} {...video360Settings} />
			</VideoContainer>
			<VideoControls videoPlayerRef={videoPlayerRef} index={index} />
		</VideoWrapper>
	);
};

Video360.propTypes = {
	url: string,
	index: number
};

export default React.memo(Video360);

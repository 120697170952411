import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import FooterNav from './FooterNav';
import FooterSocial from './FooterSocial';
import FooterContact from './FooterContact';
import Copyrights from './Copyrights';
import InstitutionLogo from './InstitutionLogo/InstitutionLogo';
import PaymentInfo from './PaymentInfo';
import Languages from './Languages';

// Import styles
import {
	FooterStyled,
	Wrapper,
	NavWrapper,
	FlexContainer,
	StyledLogo,
	AppVersion
} from './styles';

// Import variables
import { SECTIONS_ID } from 'helpers/variables';

// Import helpers
import { getUserAgentInfo } from 'helpers';

const { FOOTER } = SECTIONS_ID;

const Footer = () => {
	const { isMobile } = getUserAgentInfo();

	const { t } = useTranslation();
	return (
		<FooterStyled id={FOOTER} tabIndex="-1">
			<Wrapper>
				<FlexContainer>
					<NavWrapper>
						<StyledLogo role="img" aria-label={t('common_footer_logo_title')} />
						<InstitutionLogo />
						<FooterNav />
					</NavWrapper>
					<div>
						<PaymentInfo />
						<Languages />
						<FooterContact />
					</div>
				</FlexContainer>
				<FlexContainer>
					{isMobile ? (
						<>
							<FooterSocial />
							<Copyrights />
						</>
					) : (
						<>
							<Copyrights />
							<FooterSocial />
						</>
					)}
				</FlexContainer>
			</Wrapper>

			<AppVersion>
				{t('common_app_version', { version: process.env.REACT_APP_VERSION })}
			</AppVersion>
		</FooterStyled>
	);
};

export default Footer;

import styled, { css } from 'styled-components';
import Slider from 'react-slick';

// Import styles
import {
	transitionMain,
	itemFocus,
	customMinResponsive,
	size,
	focusBasic,
	flex
} from 'components/styles';

// Import assets
import { ReactComponent as Chevron } from 'assets/icons/ic_arrow_down.svg';

const outlineStyle = css`
	outline: 0.3rem solid ${({ theme }) => theme.primaryBlueLight};
	border-radius: 0.4rem;
`;

export const DrawerContainer = styled.div`
	${({ isVideo }) =>
		isVideo &&
		css`
			display: flex;
			flex-direction: column;
			flex-grow: 1;

			.slick-slider,
			.slick-list,
			.slick-track,
			.slick-slide {
				height: 100%;
			}

			.slick-slide {
				> div:first-child {
					height: 100%;
				}
			}
		`}
`;

export const FullscreenWrapper = styled.section`
	position: relative;
	width: 100vw;
	max-height: 100vh;
	height: 100vh;
	background-color: ${({ theme }) => theme.primaryBgBlack};
	margin: 0;
	padding: 0;

	${({ isVideo }) =>
		isVideo &&
		css`
			display: flex;
			flex-direction: column;
		`}

	${({ isMobile }) =>
		isMobile
			? css`
					overflow-x: hidden;
					overflow-y: auto;
			  `
			: css`
					overflow: hidden;
			  `}
`;

export const FullscreenTitle = styled.p`
	position: ${({ isTitlePositionRelative }) =>
		isTitlePositionRelative ? 'relative' : 'absolute'};
	top: 0;
	left: 0;
	width: 100%;
	padding: 1.4rem 3rem;
	background-color: ${({ theme }) => theme.primaryBgBlack};
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) => theme.fontSize.md}rem;
	z-index: 20;
	${transitionMain};
`;

export const ThumbnailsWrapper = styled.nav`
	width: 100%;
	display: flex;
	flex-direction: column;
	position: ${({ isVideo }) => (isVideo ? 'relative' : 'absolute')};
	bottom: 0;
	left: 0;
	z-index: 12;
`;

export const ThumbnailsButton = styled.button`
	position: absolute;
	bottom: ${({ open }) => (open ? '20rem' : '0')};
	right: 5%;
	width: 12.5rem;
	background-color: ${({ theme }) => theme.primaryBgBlack};
	border: none;
	color: ${({ theme }) => theme.white};
	padding: 1.2rem 2rem;
	border-radius: 0.6rem 0.6rem 0 0;
	z-index: 14;

	${({ theme }) =>
		theme.isWCAG &&
		css`
			width: auto;
			${flex()}
		`}

	&:hover {
		cursor: pointer;
		${outlineStyle}
	}

	&:focus-visible {
		${focusBasic}
		border-radius: 0.6rem;
	}
`;

export const ThumbnailsSlider = styled.div`
	width: 100%;
	height: 21rem;
	padding: 3.3rem 0;
	background-color: ${({ theme }) => theme.primaryBgBlack};
`;

export const ThumbnailChevron = styled(Chevron)`
	transform: rotate(${({ open }) => (open ? 0 : 180)}deg);
	margin-left: 0.75rem;
	g {
		fill: ${({ theme }) => theme.white};
	}
`;

export const ThumbnailTab = styled.button`
	display: block;
	width: 90%;
	height: 90%;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: none;
	background: transparent;
	text-align: left;
	cursor: pointer;
	opacity: 0.5;
	border-radius: 0.4rem;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		border-radius: 4px;
		cursor: pointer;
		filter: ${({ isContrast }) =>
			isContrast ? 'grayscale(1)' : 'grayscale(0)'};
	}

	&:focus,
	&:hover {
		outline: none;
		opacity: 1;
		${itemFocus()}
	}

	&:focus-visible {
		${focusBasic}
	}

	&[data-selected='true'] {
		opacity: 1;
		${itemFocus()}

		&:focus-visible {
			${focusBasic}
		}
	}
`;

export const CustomSlider = styled(Slider)`
	.slick-slide {
		width: 25.8rem;
		height: 14.4rem;
		margin-right: 1.9rem;
		div {
			height: 14.4rem;
			overflow: hidden;
		}
		&:hover,
		&:focus {
			opacity: 1;
		}
	}
	.slick-track {
		margin: 0;
		margin-left: 3rem;
	}
`;

export const ThumbnailsGrid = styled.ul`
	width: 100%;
	max-width: 100%;
	display: grid;
	margin: 3rem 0;
	padding: 1rem;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	list-style: none;

	${customMinResponsive(
		size.small,
		css`
			grid-template-columns: repeat(3, 1fr);
			gap: 2rem;
			padding: 2rem;
		`
	)}

	div {
		width: 100%;
		aspect-ratio: 16 / 9;
	}
`;

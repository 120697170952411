import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import helpers
import { SLIDER_ARROW_TYPES } from 'helpers/variables';

// Import assets
import { ReactComponent as PrevIcon } from 'assets/icons/simple_arrow_prev.svg';
import { ReactComponent as NextIcon } from 'assets/icons/simple_arrow_next.svg';

// Import components
import { ArrowController } from './styles';

const { PREV } = SLIDER_ARROW_TYPES;

const PassArrow = ({ type, name, ...rest }) => {
	const { t } = useTranslation();
	const isPrevArrow = type === PREV;
	const ariaLabel = name
		? `${t(`aria_slider_arrow_button_${type}`)}: ${name}`
		: t(`aria_slider_arrow_button_${type}`);

	return (
		<ArrowController
			isPrevArrow={isPrevArrow}
			{...rest}
			aria-label={ariaLabel}
			tabIndex="-1"
		>
			{isPrevArrow ? <PrevIcon /> : <NextIcon />}
		</ArrowController>
	);
};

PassArrow.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string
};

export default PassArrow;

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Import components
import CommonButton from '../CommonButton';

// Import helpers
import usePaymentVerificationButton from './usePaymentVerificationButton';

const PaymentVerificationButton = ({ product, isLoading }) => {
	const { t } = useTranslation();
	const { handleVerificationButtonPress } = usePaymentVerificationButton({
		product
	});
	return (
		<CommonButton
			onClick={handleVerificationButtonPress}
			isPrime={!isLoading}
			disabled={isLoading}
			isLoading={isLoading}
		>
			{t('transaction_verification_modal_button')}
		</CommonButton>
	);
};

PaymentVerificationButton.propTypes = {
	product: PropTypes.object.isRequired,
	isLoading: PropTypes.bool
};

export default PaymentVerificationButton;

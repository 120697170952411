import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { Button, PlayerElements } from 'components/elements';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const PlaybackControllers = () => {
	const { t } = useTranslation();

	const { isPaused, play, pause } = usePlayerVodContext();

	const handleButtonClick = isPaused ? play : pause;

	const title = isPaused
		? t('common_player_play_title')
		: t('common_player_pause_title');

	return (
		<Button
			modifiers={['icon', 'playerIconHover']}
			onClick={handleButtonClick}
			aria-label={title}
		>
			{isPaused ? (
				<PlayerElements.IconPlay aria-hidden role="img" />
			) : (
				<PlayerElements.IconPause aria-hidden role="img" />
			)}
		</Button>
	);
};

export default PlaybackControllers;

// Import actions
import { fetchRecommendations } from 'store/slices/recommendations';
import {
	fetchPlayerConfig,
	fetchMovieDetails,
	fetchContinueWatchingData,
	fetchMovieEpisodesPrevNext,
	setFloatWidgetInitPageMode
} from 'store/actions';

// Import variables
import {
	PLAYER_SELECTORS,
	QUERY_TYPES,
	QUERY_SUBTYPES,
	PRODUCTS_TYPES
} from 'helpers/variables';

const { VOD } = PRODUCTS_TYPES;

export const fetchMovieResources =
	({ id, isArticle, type }) =>
	async (dispatch) => {
		const playerSelector = PLAYER_SELECTORS.VOD;
		const isEpisode = type === QUERY_SUBTYPES.series;

		const videoType =
			QUERY_TYPES.article === type ? QUERY_TYPES.artykul : QUERY_TYPES.vod;

		// Dispatch an fetchContinueWatchingData action in continue watching folder
		await fetchContinueWatchingData(id)(dispatch);

		// Close float player just in case it is opened
		isArticle && setFloatWidgetInitPageMode()(dispatch);

		// Dispatch an fetchMovieDetails action in movieDetails folder
		!isArticle && (await fetchMovieDetails(id, VOD, dispatch));
		// Dispatch an fetchMovieEpisodesPrevNext action in movieDetails folder
		isEpisode && (await fetchMovieEpisodesPrevNext(id, dispatch));
		// Dispatch an fetchRecommendations action in recommendations folder
		!isEpisode && (await fetchRecommendations({ id, type: VOD })(dispatch));

		// Dispatch an fetchPlayerConfig action in player folder
		await fetchPlayerConfig({
			productID: id,
			type: playerSelector,
			videoType
		})(dispatch);
	};

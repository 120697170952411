import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Import utilities
import { useResize } from 'components/utilities';

// Import variables
import { KEYBOARD_KEYS } from 'helpers/variables';

const { ARROWDOWN, ARROWUP } = KEYBOARD_KEYS;

export const useSearchResults = () => {
	const listRef = useRef(null);
	const [listElements, setListElements] = useState([]);
	const { t } = useTranslation();
	const { viewportHeight } = useResize();

	const { data, isLoaded } = useSelector(({ search }) => search);
	const {
		data: { lives: channels }
	} = useSelector(({ channels }) => channels);

	const arrowKeys = [ARROWDOWN, ARROWUP];
	const showAllSearchResults = data?.length > 0 && isLoaded;
	const isEmptySearchResults = data.length === 0;
	const resultsInformation = showAllSearchResults
		? `${t('common_search_results_count', { results_count: data?.length })}`
		: t('common_no_search_results');

	useEffect(() => {
		if (listRef.current) {
			const listElements = listRef.current?.querySelectorAll('[role="option"]');

			setListElements(listElements);
			listElements.forEach((element, index) => {
				element.dataset.index = index;
			});
		}
	}, [listRef, isEmptySearchResults]);

	const handleKeyOptionEvents = (event) => {
		const { currentTarget, key } = event;
		const targetIndex = parseInt(currentTarget.dataset.index);
		const lastElementIndex = listElements.length - 1;
		const isProperKey = arrowKeys.includes(key);

		if (isProperKey) {
			event.preventDefault();

			if (key === ARROWUP) {
				targetIndex > 0 &&
					listElements[targetIndex - 1].firstElementChild.focus();
			} else if (key === ARROWDOWN) {
				targetIndex < lastElementIndex &&
					listElements[targetIndex + 1].firstElementChild.focus();
			}
		}
	};

	return {
		isLoaded,
		isEmptySearchResults,
		listRef,
		viewportHeight,
		data,
		channels,
		resultsInformation,
		showAllSearchResults,
		handleKeyOptionEvents
	};
};

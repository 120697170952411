import { getProductDetailsApi } from 'helpers';

export const getProductRequestOptions = ({ id, type, preview }) => {
	const url = getProductDetailsApi({ id, type });
	const options = preview
		? {
				headers: {
					'X-Auth-Token': preview
				}
		  }
		: null;
	const path = preview ? `/preview${url}` : url;
	return { path, options };
};

// Import variables
import { PAYMENT_METHODS } from 'helpers/prices';

// API
export const GET_LAST_GATEWAY_API = '/payments/getLastGatewayType';
export const GET_PAYMENT_AGREEMENTS_API = '/payments/agreements';
export const GET_VOUCHER_PROMOTION_API = '/payments/id/code/promotion/prices';
export const GET_PRODUCT_PAYMENT_STATUS_API = '/payments/payment/last/:id';

export const POST_PAYMENT_INIT_API = '/payments/productId/priceId/init';
export const POST_PAYMENT_BUY_API = '/payments/productId/priceId/buy';

// subscriptions
export const POST_SUBSCRIPTION_DEACTIVATE_API =
	'/payments/subscriptions/deactivate';

// Queries
export const QUERY_VOUCHER_PROMOTION = 'QUERY_VOUCHER_PROMOTION';
export const QUERY_PAYMENT_INIT_ = 'QUERY_PAYMENT_INIT';
export const QUERY_PAYMENT_BUY = 'QUERY_PAYMENT_BUY';
export const QUERY_PRODUCT_PAYMENT_STATUS = 'QUERY_PRODUCT_PAYMENT_STATUS';
export const QUERY_WALLET_PAYMENT_RESOURCES = 'QUERY_WALLET_PAYMENT_RESOURCES';
export const QUERY_PRODUCT_PRICES = 'QUERY_PRODUCT_PRICES';
export const QUERY_PRODUCT_DETAILS = 'QUERY_PRODUCT_DETAILS';
export const QUERY_SUBSCRIBER_PROMOTIONS = 'QUERY_SUBSCRIBER_PROMOTIONS';
export const QUERY_PAYMENT_LAST_GATEWAY = 'QUERY_PAYMENT_LAST_GATEWAY';

export const getPaymentInitApi = ({ type, productId, priceId }) => {
	switch (type) {
		case PAYMENT_METHODS.DCB_SUBSCRIPTION:
			return `/payments/dcb/subscription/${productId}/${priceId}/init`;

		default:
			return `/payments/${productId}/${priceId}/init`;
	}
};

export const getProductPurchaseApi = ({
	paymentMethod,
	productId,
	priceId
}) => {
	switch (paymentMethod) {
		case PAYMENT_METHODS.DCB_SUBSCRIPTION:
			return '/payments/dcb/subscription/subscribe';

		case PAYMENT_METHODS.PAYPAL_SUBSCRIPTION:
			return `/payments/paypal/subscription/${productId}/${priceId}/subscribe`;

		default:
			return `/payments/${productId}/${priceId}/buy`;
	}
};

export const getSubscriptionCancelApi = ({ provider, id }) => {
	switch (provider) {
		case PAYMENT_METHODS.BLUEMEDIA:
			return `/payments/subscriptions/deactivate/${id}`;

		default:
			return `/payments/${provider}/subscription/${id}/cancel`;
	}
};

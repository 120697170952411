import React from 'react';
import { bool, string, node } from 'prop-types';

// Import components
import StaticPage from './StaticPage';
import StaticWrapper from './StaticWrapper';
import useStaticContentTemplate from './useStaticContentTemplate';

const StaticContentTemplate = ({ isPage, alias, title, children }) => {
	useStaticContentTemplate();

	return isPage ? (
		<StaticPage alias={alias} />
	) : (
		<StaticWrapper title={title}>{children}</StaticWrapper>
	);
};

StaticContentTemplate.defaultProps = { isPage: true, title: '', alias: '' };
StaticContentTemplate.propTypes = {
	isPage: bool,
	title: string,
	alias: string,
	children: node
};

export default StaticContentTemplate;

import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';

// Import variables
import {
	SLIDERS_CONFIG,
	DEFAULT_SLIDER_CONFIG,
	SLIDES_TO_SHOW_WCAG_PROPORTION,
	SLIDES_GAP_WCAG_PROPORTION
} from 'helpers/variables';
import { AVAILABLE_FONT_SIZE } from 'helpers/wcag';

// Import helpers
import { getSlidesToShow } from './helpers';

// Import utilities
import { useViewportResize } from 'components/utilities';

const useSlideSize = ({ type }) => {
	// get ref to slider container
	const targetRef = useRef(null);

	// Check accessability
	const { fontSize } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);
	const hasFontDefaultSize = fontSize === AVAILABLE_FONT_SIZE.DEFAULT;

	// state
	const [containerWidth, setContainerWidth] = useState(0);
	const [slidesToShow, setSlidesToShow] = useState(0);
	const [isDesktop, setIsDesktop] = useState(false);

	const sliderConfig = SLIDERS_CONFIG[type] || DEFAULT_SLIDER_CONFIG;

	const { resolutionHeight, resolutionWidth, spaceBetweenSlides, responsive } =
		sliderConfig;

	const spaceBetweenSlidesWCAG =
		hasFontDefaultSize || !isDesktop
			? spaceBetweenSlides
			: spaceBetweenSlides * SLIDES_GAP_WCAG_PROPORTION;

	// calculate single slide width depending on slides per page
	const slideWidth = containerWidth / slidesToShow - spaceBetweenSlidesWCAG;

	// calculate single slide height depending on resolution from mockup
	const slideHeight = (slideWidth / resolutionWidth) * resolutionHeight;

	const handleResize = () => {
		// set container width
		targetRef.current && setContainerWidth(targetRef.current.offsetWidth);

		const { slidesToShow, isDesktop } = getSlidesToShow(responsive);

		// Change slides number to show in case of WCAG mode on
		const slidesToShowWCAG =
			hasFontDefaultSize || !isDesktop
				? slidesToShow
				: (slidesToShow / SLIDES_TO_SHOW_WCAG_PROPORTION).toFixed(2);

		setSlidesToShow(slidesToShowWCAG);
		setIsDesktop(isDesktop);
	};

	useViewportResize({ callback: handleResize });

	useEffect(() => {
		handleResize();
		// eslint-disable-next-line
	}, [targetRef, fontSize]);

	return {
		targetRef,
		slideWidth,
		slideHeight,
		containerWidth,
		slidesToShow,
		spaceBetweenSlides: spaceBetweenSlidesWCAG,
		isDesktop
	};
};

useSlideSize.propTypes = {
	type: string.isRequired
};

export default useSlideSize;

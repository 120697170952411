import React from 'react';
import { useTranslation } from 'react-i18next';
import { number, object } from 'prop-types';

// Import components
import VideoDescription from '../VideoDescription';

// Import styles
import {
	Controls,
	IconButton,
	ProgressButtonsWrapper,
	TimeSeeker
} from './styles';

// Import assets
import { ReactComponent as PlayIcon } from 'assets/icons/ic_player_play.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/ic_pause.svg';
import { ReactComponent as MuteIcon } from 'assets/icons/ic_player_volume_off.svg';
import { ReactComponent as UnmuteIcon } from 'assets/icons/ic_player_volume_on.svg';
import { ReactComponent as SeekNext } from 'assets/icons/ic_player_plus_15.svg';
import { ReactComponent as SeekPrev } from 'assets/icons/ic_player_minus_15.svg';

// Import logic
import useVideoPlayer from 'components/elements/organisms/product_gallery/hooks/useVideoPlayer';

// Import context
import useGalleryContext from 'components/elements/organisms/product_gallery/context/useGalleryContext';

// Import helpers
import { getUserAgentInfo } from 'helpers';

const VideoControls = ({ videoPlayerRef, index }) => {
	const { t } = useTranslation();
	const { videoDescription, showFullscreen } = useGalleryContext();
	const { isSafari } = getUserAgentInfo();

	const {
		isNavOpen,
		tabIndex,
		isPlaying,
		isMuted,
		progress,
		toggleMute,
		togglePlay,
		handleVideoProgress,
		updateSeekTime
	} = useVideoPlayer({ videoPlayerRef, index });

	return (
		<Controls open={isNavOpen} showFullscreen={showFullscreen}>
			<ProgressButtonsWrapper>
				<IconButton
					onClick={togglePlay}
					tabIndex={tabIndex}
					aria-label={
						isPlaying
							? t('common_player_pause_title')
							: t('common_player_play_title')
					}
				>
					{isPlaying ? (
						<PauseIcon aria-hidden role="img" />
					) : (
						<PlayIcon aria-hidden role="img" />
					)}
				</IconButton>
				<IconButton
					onClick={() => updateSeekTime(false)}
					tabIndex={tabIndex}
					aria-label={t('common_player_seek_prev_title')}
				>
					<SeekPrev aria-hidden role="img" />
				</IconButton>
				<IconButton
					onClick={updateSeekTime}
					tabIndex={tabIndex}
					aria-label={t('common_player_seek_next_title')}
				>
					<SeekNext aria-hidden role="img" />
				</IconButton>
			</ProgressButtonsWrapper>

			<TimeSeeker
				aria-label={t('aria_player_progress_bar')}
				tabIndex={tabIndex}
				type="range"
				aria-valuemin="0"
				aria-valuemax="100"
				aria-valuenow={progress}
				value={progress}
				onChange={handleVideoProgress}
				isSafari={isSafari}
			/>

			<IconButton
				onClick={toggleMute}
				tabIndex={tabIndex}
				aria-label={
					isMuted
						? t('common_player_volume_off_title')
						: t('common_player_volume_on_title')
				}
			>
				{isMuted ? <MuteIcon /> : <UnmuteIcon />}
			</IconButton>

			{videoDescription && <VideoDescription description={videoDescription} />}
		</Controls>
	);
};

VideoControls.defaultProps = {
	index: 0
};

VideoControls.propTypes = {
	videoPlayerRef: object,
	index: number
};

export default VideoControls;

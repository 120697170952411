import i18n from 'i18next';
export default ({ email_news_letter }) => {
	let errors = {};

	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

	if (!email_news_letter) {
		errors.email_news_letter = i18n.t(
			'common_register_form_validation_email_required'
		);
	} else if (!emailRegex.test(email_news_letter)) {
		errors.email_news_letter = i18n.t(
			'common_register_form_validation_email_invalid'
		);
	}

	return errors;
};

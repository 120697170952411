import { Drawer } from 'antd';
import styled from 'styled-components';

// Import components
import { Button } from 'components/elements';

// Import icons
import { ReactComponent as IconCloseSVG } from 'assets/icons/ic_close_thin.svg';

// Import styles
import { flex } from 'components/styles';

export const DrawerStyled = styled(Drawer)`
	&& {
		.ant-drawer-wrapper-body,
		.ant-drawer-content {
			background: ${({ theme: { sidebar } }) => sidebar.bg};
		}

		.ant-drawer-header {
			background: transparent;
			border-bottom-color: ${({ theme }) => theme.line};
			padding: 2.8rem 2.6rem 2.8rem 2.2rem;
			${flex({ jc: 'space-between' })}
		}

		.ant-drawer-title {
			font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
			line-height: 2.1rem;
			font-weight: ${({ theme }) => theme.font.normal};
			color: ${({ theme }) => theme.white};
		}

		.ant-drawer-close {
			display: none;
		}

		.ant-drawer-body {
			padding: 0 0 2.8rem 0;
		}
	}
`;

export const IconClose = styled(IconCloseSVG)`
	position: relative;
	height: 1.8rem;
`;

export const CloseButton = styled(Button)`
	position: absolute;
	color: black;
	top: 1.7rem;
	right: 1rem;
	background: transparent;
	border: 0;

	&:hover,
	&:focus,
	&:focus-visible {
		cursor: pointer;

		svg {
			path {
				fill: ${({ theme }) => theme.primaryBlueLight};
			}
		}
	}
`;

export const DrawerHeading = styled.h1`
	font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
	line-height: 2.1rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.white};
	padding: 2.8rem 2.6rem 2.8rem 2.2rem;
	border-bottom: 1px solid ${({ theme }) => theme.line};
`;

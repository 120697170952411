import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';
import { getUserAgentInfo, getTranslation } from 'helpers';

// Import variables
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';
import { BANNER_RATIOS, BANNER_MODIFIER_KEYS } from '../helpers';
import { size } from 'components/styles';

const { COVER } = PRODUCT_IMAGES_TYPES;
const { ARROWS, NO_ARROWS } = BANNER_MODIFIER_KEYS;

const useBanner = ({
	images,
	context,
	isCurrent,
	type,
	hasArrows,
	isLowBanner,
	translations,
	...rest
}) => {
	const { isMobile: isMobileAgent } = getUserAgentInfo();
	const isCompact = useMediaQuery({ query: `(max-width: ${size.medium}px)` });
	const isMobile = isMobileAgent || isCompact;

	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);
	const translated = getTranslation(translations);
	const { title, short_desc } = { ...rest, ...translated };

	// Background image
	const { width, height } = BANNER_RATIOS[type];
	const coverImage = getImage({ images, type: COVER });
	const coverUrl = `url(${coverImage}=w${width}-h${height}-c)`;

	const arrows = hasArrows ? ARROWS : NO_ARROWS;
	const url = isMobile ? context?.url_mobile : context?.url_pc;

	// Focus
	const showFocus = isLowBanner && !!url;
	const buttonFocusIndex = isCurrent && !isLowBanner ? null : -1;
	const bannerFocusIndex = isCurrent && isLowBanner && url ? null : -1;

	// Content
	const hasContent = !isLowBanner || !!title || !!short_desc;
	const showButton = !isLowBanner && url;
	const bannerElementType = showFocus ? 'a' : 'div';

	const showContent = !isLowBanner ? true : hasContent && !isMobile;

	return {
		url,
		arrows,
		title,
		short_desc,
		hasContent,
		isContrast,
		coverUrl,
		showFocus,
		showButton,
		buttonFocusIndex,
		bannerFocusIndex,
		bannerElementType,
		isMobile,
		showContent
	};
};

export default useBanner;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

// Import utilities
import { useFullScreen } from 'components/utilities';

// Import context
import PlayerVodProvider from './PlayerVodProvider';

// Import helpers
import { playerVodStyles } from 'helpers/player';
import { getUserAgentInfo } from 'helpers';

// Import components
import { Portal } from 'components/utilities';
import { Loader, PlayerError, ErrorBoundary } from 'components/elements';
import PlayerContent from './player_content/PlayerContent';

// Import actions
import { clearRecommendations } from 'store/slices/recommendations';

// Import context
import { PlayerFocusProvider } from 'components/context/player/focus';

const Root = styled.div`
	flex: 1;
	background-color: ${({ theme }) => theme.black};
	width: 100%;
	height: 100%;
	position: relative;

	${({ isReady }) =>
		css`
			${playerVodStyles(isReady)}
		`}

	${({ isSafari, isPreview }) =>
		isSafari &&
		isPreview &&
		css`
			position: fixed;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 9999;
		`}
`;

const EmbedWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border: 0;
`;

export default function PlayerWrapper({
	isLoaded,
	isReady,
	type,
	error,
	errorMessage,
	toggle = () => {},
	isPreview = false,
	floatMode,
	autoplay = true
}) {
	const dispatch = useDispatch();

	const handleClosePreview = () => toggle();

	const { isSafari, isIphone } = getUserAgentInfo();

	const { setFullscreen, elementRef } = useFullScreen({
		callback: handleClosePreview,
		isIphone
	});

	React.useEffect(() => {
		// If video is a preview set fullScreen at the begining
		isPreview && setFullscreen();

		return () => clearRecommendations(dispatch);
		// eslint-disable-next-line
	}, []);

	return isSafari && isPreview ? (
		<Portal>
			<PlayerFocusProvider isReady={isReady}>
				<Root isSafari={isSafari} isPreview={isPreview} isReady={isReady}>
					<ErrorBoundary resetKeys={[type]}>
						<EmbedWrapper id={type}>
							{!isLoaded && (
								<Loader background={false} position="absolute" zIndex={1} />
							)}
							{isLoaded && !error.isError && (
								<PlayerVodProvider
									closePreview={handleClosePreview}
									isPreview={isPreview}
									isSafari={isSafari}
									type={type}
									floatMode={floatMode}
									autoplay={autoplay}
									isIphone={isIphone}
								>
									{!isIphone && <PlayerContent type={type} />}
									{error.isError && (
										<PlayerError error={{ message: errorMessage }} />
									)}
								</PlayerVodProvider>
							)}
						</EmbedWrapper>
					</ErrorBoundary>
				</Root>
			</PlayerFocusProvider>
		</Portal>
	) : (
		<PlayerFocusProvider isReady={isReady}>
			<Root isReady={isReady}>
				<EmbedWrapper ref={elementRef} id={type}>
					{!isLoaded && (
						<Loader background={false} position="absolute" zIndex={1} />
					)}
					{isLoaded && !error.isError && (
						<PlayerVodProvider
							closePreview={handleClosePreview}
							isPreview={isPreview}
							isSafari={isSafari}
							type={type}
							floatMode={floatMode}
							autoplay={autoplay}
							isIphone={isIphone}
						>
							{!isIphone && <PlayerContent type={type} />}
							{error.isError && (
								<PlayerError error={{ message: errorMessage }} />
							)}
						</PlayerVodProvider>
					)}
				</EmbedWrapper>
			</Root>
		</PlayerFocusProvider>
	);
}

PlayerWrapper.propTypes = {
	isLoaded: PropTypes.bool.isRequired,
	isReady: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	error: PropTypes.object.isRequired,
	errorMessage: PropTypes.string,
	toggle: PropTypes.func,
	isPreview: PropTypes.bool,
	floatMode: PropTypes.object,
	autoplay: PropTypes.bool
};

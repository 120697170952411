import React from 'react';
import { string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { ListBoxSelect, ListBoxOption } from 'components/elements';

// Import logic
import useFilterSelect from './useFilterSelect';

// Import select options
import {
	FILTER_SELECT_OPTIONS,
	SEARCH_FILTER_SELECT_OPTIONS
} from './select_options';

// Import helpers
import { getSelectedElementId } from 'helpers';

const FilterSelect = ({ selectValue, isSearch = false }) => {
	const { selectChange } = useFilterSelect();
	const { t } = useTranslation();

	const filters = isSearch
		? SEARCH_FILTER_SELECT_OPTIONS
		: FILTER_SELECT_OPTIONS;

	const listId = 'filters_select';

	return (
		<ListBoxSelect
			currentValue={selectValue}
			listId={listId}
			changeHandler={selectChange}
			title="common_filters_title"
		>
			{({ handleMouseOptionSelect, handleKeyOptionEvents }) => (
				<>
					{filters.map(({ name }) => {
						const isSelected = t(name) === selectValue;
						const selectedId = getSelectedElementId({ isSelected, listId });

						return (
							<ListBoxOption
								key={name}
								id={selectedId}
								role="option"
								aria-selected={isSelected}
								data-value={t(name)}
								tabIndex={0}
								onMouseUp={handleMouseOptionSelect}
								onKeyDown={handleKeyOptionEvents}
							>
								{t(name)}
							</ListBoxOption>
						);
					})}
				</>
			)}
		</ListBoxSelect>
	);
};

FilterSelect.propTypes = {
	selectValue: string,
	isSearch: bool
};
export default FilterSelect;

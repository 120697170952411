import styled from 'styled-components';

// Import styles
import { spin } from 'components/styles';

export const ButtonLoaderItem = styled.div`
	width: ${({ width }) => `${width}rem`};
	height: ${({ height }) => `${height}rem`};
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: ${({ thickness }) => `${thickness}px`} solid
		${({ theme }) => theme.whiteSemiTranslucent};
	border-radius: 50%;
	border-width: ${({ thickness }) => `${thickness}px`};
	border-style: solid;
	border-color: ${({ theme, loaderColor }) => theme[loaderColor]};

	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		width: ${({ width }) => `${width}rem`};
		height: ${({ height }) => `${height}rem`};
		top: ${({ thickness }) => `-${thickness}px`};
		left: ${({ thickness }) => `-${thickness}px`};
		animation: ${spin} 1.5s linear infinite;
		border: ${({ thickness }) => `${thickness}px`} solid transparent;
		border-radius: 50%;
		border-top-color: ${({ theme }) => theme.white};
	}
`;

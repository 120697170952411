// API
export const GET_PLAYER_CONFIGURATION_API =
	'/player/product/:productID/configuration?type=:type&videoId=:videoAssetId';

export const DELETE_VIDEO_SESSION_API = '/player/videosession/:videoSessionId';

export const UPDATE_VIDEO_SESSION_API = '/player/videosession/:videoSessionId';

//QUERIES
export const QUERY_PLAYER_CONFIGURATION = 'QUERY_PLAYER_CONFIGURATION';

import React from 'react';
import { object } from 'prop-types';
import VisuallyHidden from '@reach/visually-hidden';
import { Trans } from 'react-i18next';

// Import utilities
import { useAuth, useProductAvailability } from 'components/utilities';

const AvailabilityInfoHidden = ({ product }) => {
	const { uuid, available_in, subtype } = product;
	const { isAuth } = useAuth();
	const isAvailable = useProductAvailability(uuid, available_in, subtype);

	const authed = isAuth ? 'authed' : 'not_authed';
	const available = isAvailable ? 'available' : 'not_available';
	const availableText = `common_product_availability_${authed}_and_${available}`;

	return (
		<VisuallyHidden>
			<Trans i18nKey={availableText} />
		</VisuallyHidden>
	);
};

AvailabilityInfoHidden.propTypes = {
	product: object.isRequired
};

export default AvailabilityInfoHidden;

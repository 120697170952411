import React from 'react';
import { array, string } from 'prop-types';

// Import providers
import PlayerProvider from './PlayerProvider';

//Import hooks
import useVideoBanners from './useVideoBanners';

// Import styles
import { Wrapper, Fog } from './styles';

// Import components
import { Heading, Loader } from 'components/elements';
import PlayerContent from './PlayerContent';
import Info from './Info';
import PlayerError from './PlayerError';

// Import helpers
import { SHAKA_PLAYER_ERRORS } from 'helpers/player';

const { SHAKA_PLAYER_ERROR_MESSAGE } = SHAKA_PLAYER_ERRORS;

const VideoBanners = ({ name, content, type }) => {
	const {
		isLoading,
		isError,
		data,
		currentProduct,
		isContrast,
		isMuted,
		isActive,
		nextProduct,
		previousProduct,
		deleteVideoSession,
		updateVideoSession,
		toggleSound,
		onFocus,
		onBlur,
		onMouseOver,
		onMouseLeave
	} = useVideoBanners({
		content
	});

	return (
		<Wrapper
			onFocus={onFocus}
			onBlur={onBlur}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
		>
			<Heading.Second modifiers="hidden">{name}</Heading.Second>

			{isContrast && <Fog />}

			<Info currentProduct={currentProduct} type={type} />

			{isLoading ? (
				<Loader zIndex={900} position="absolute" background={false} />
			) : isError ? (
				<PlayerError message={SHAKA_PLAYER_ERROR_MESSAGE} />
			) : (
				<PlayerProvider
					data={data}
					nextProduct={nextProduct}
					isMuted={isMuted}
					isActive={isActive}
					deleteVideoSession={deleteVideoSession}
					updateVideoSession={updateVideoSession}
					toggleSound={toggleSound}
				>
					<PlayerContent
						currentProduct={currentProduct}
						nextProduct={nextProduct}
						previousProduct={previousProduct}
						name={name}
					/>
				</PlayerProvider>
			)}
		</Wrapper>
	);
};

VideoBanners.propTypes = {
	content: array.isRequired,
	type: string.isRequired,
	name: string.isRequired
};

export default VideoBanners;

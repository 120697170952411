import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { AriaBaseModal, AriaModalContent } from 'components/elements';
import { CommonButton } from 'components/views/vod/components/buttons';

const ConfirmationDeletingModal = ({ title, deleteFn, toggle }) => {
	const { t } = useTranslation();

	const titleId = 'deleting_confirmation_title';

	return (
		<AriaBaseModal
			titleId={titleId}
			close={toggle}
			style={{ width: '47vw', minWidth: '28rem' }}
		>
			<AriaModalContent title={title} titleId={titleId}>
				<CommonButton isPrime onClick={toggle}>
					{t('common_btn_cancel')}
				</CommonButton>

				<CommonButton isPrime onClick={deleteFn}>
					{t('common_remove')}
				</CommonButton>
			</AriaModalContent>
		</AriaBaseModal>
	);
};

ConfirmationDeletingModal.propTypes = {
	title: string.isRequired,
	deleteFn: func.isRequired,
	toggle: func.isRequired
};

export default ConfirmationDeletingModal;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { clearSelectedProgram, playerOnCancel } from 'store/actions';

// Import helpers
import { playerCatchStyles, playerStyles } from 'helpers/player';

// Import utilities
import { usePip } from 'components/utilities';

// Import components
import { Loader } from 'components/elements';
import PlayerContext from './player_context/PlayerContext';
import PlayerSidebar from 'components/elements/organisms/player/player_overlay/player_sidebar/PlayerSidebar';
import PlayerTop from 'components/elements/organisms/player/player_overlay/player_top/PlayerTop';

// Import context
import {
	PlayerFocusProvider,
	PlayerFocusContext
} from 'components/context/player/focus';

const Player = React.memo(
	({
		isSiedebarVisible = true,
		isLoaded,
		type,
		clearSelectedProgram,
		playerOnCancel,
		isReady,
		isCatchup = false
	}) => {
		const [isFullScreen, setFullScreen] = React.useState(false);
		const [playerParentElement, setPlayerParentElement] = React.useState(null);

		// Set use pip
		const { exitPip } = usePip();
		/*eslint-disable */
		React.useEffect(() => {
			return () => {
				// Exit pip
				exitPip();
				// Dispatch an playerOnCancel action in player folder
				playerOnCancel(type);
				// Dispatch anplayerOnCancel, clearSelectedProgram action in slected_program folder
				clearSelectedProgram();
			};
		}, []);
		/*eslint-enable */

		return (
			<PlayerFocusProvider isReady={isReady}>
				<PlayerFocusContext.Consumer>
					{({ focusFreeze, focusUnfreeze }) => (
						<PlayerWrapper
							isFullScreen={isFullScreen}
							isCatchup={isCatchup}
							className="player-wrapper"
						>
							<EmbededWrapper
								id={type}
								ref={(el) => setPlayerParentElement(el)}
							>
								{isLoaded ? (
									<PlayerContext
										isCatchup={isCatchup}
										setFullScreen={setFullScreen}
										isSiedebarVisible={isSiedebarVisible}
										type={type}
										playerParentElement={playerParentElement}
									/>
								) : (
									<Loader zIndex={100} position="absolute" background={false} />
								)}

								<div onMouseEnter={focusFreeze} onMouseLeave={focusUnfreeze}>
									<PlayerTop isSiedebarVisible={isSiedebarVisible} />
									{isSiedebarVisible && <PlayerSidebar />}
								</div>
							</EmbededWrapper>
						</PlayerWrapper>
					)}
				</PlayerFocusContext.Consumer>
			</PlayerFocusProvider>
		);
	}
);

const PlayerWrapper = styled.div`
	visibility: visible;
	opacity: 1;
	position: relative;
	margin: 0;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.black};

	&::before {
		content: '';
		display: block;
		height: 0;
		padding-top: 56.25%;
	}

	${({ isCatchup, isFullScreen }) =>
		isFullScreen && isCatchup ? playerCatchStyles : playerStyles}
`;

const EmbededWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border: 0;
`;

Player.propTypes = {
	isSiedebarVisible: PropTypes.bool,
	isLoaded: PropTypes.bool.isRequired,
	isReady: PropTypes.bool.isRequired,
	isCatchup: PropTypes.bool,
	videoSessionId: PropTypes.string,
	type: PropTypes.string.isRequired,
	clearSelectedProgram: PropTypes.func.isRequired,
	playerOnCancel: PropTypes.func.isRequired
};

const mapStateToProps = (state, { type }) => {
	const { isLoaded, videoSessionId, isReady } = state[type];
	return {
		isLoaded,
		videoSessionId,
		isReady
	};
};

export default connect(mapStateToProps, {
	clearSelectedProgram,
	playerOnCancel
})(Player);

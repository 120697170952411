import React from 'react';
import { Route } from 'react-router-dom';
import { string, object, bool, func, array, oneOfType } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { Heading } from 'components/elements';
import HelmetMetaTags from 'components/layout/helmet/HelmetMetaTags';

const RouteWithHelmet = (props) => {
	const { t } = useTranslation();
	const {
		isHeadingTitle,
		component: Component,
		path,
		metaTags,
		...rest
	} = props;

	const title = metaTags.title ?? 'meta_page_common_title';

	const renderRouteContent = (matchProps) => (
		<>
			<HelmetMetaTags {...metaTags} />
			{isHeadingTitle && (
				<Heading.First modifiers="hidden">{t(title)}</Heading.First>
			)}
			<Component {...matchProps} {...rest} />
		</>
	);

	return <Route path={path} render={renderRouteContent} />;
};

RouteWithHelmet.defaultProps = { metaTags: {} };

RouteWithHelmet.propTypes = {
	component: oneOfType([object, func]),
	path: oneOfType([string, array]).isRequired,
	isHeadingTitle: bool,
	metaTags: object
};

export default RouteWithHelmet;

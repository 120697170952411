import React from 'react';
import { object, string, shape, bool, node } from 'prop-types';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import { VisuallyHidden } from '@reach/visually-hidden';
import ReactHtmlParser from 'react-html-parser';

// Import components
import { Tooltip } from 'antd';

// Import styled components
import {
	Wrapper,
	FormError,
	InputWrapper,
	Label,
	BottomWrapper,
	FieldInfo,
	InputStyled
} from './styles';

const InputField = ({
	field,
	form: { touched, errors },
	placeholder,
	tooltipHintText,
	hideFieldInfo,
	fieldInfo,
	styles,
	maskSymbol = null,
	inputFormat = null,
	initTouched,
	children,
	addLabel,
	isErrorBackgroundLight,
	isBirthDateField,
	...rest
}) => {
	const { name, value } = field;
	const isErrorVisible = (initTouched || touched[name]) && errors[name];
	const errorText = errors[name] || '';

	const fieldInfoId = fieldInfo && `register-${name}-info`;

	const inputDescribedBy = fieldInfoId
		? `${name}-alert ${fieldInfoId}`.trim()
		: `${name}-alert`;

	const inputPlaceholder = addLabel ? '' : placeholder;

	const renderFieldInfo = () => {
		if (hideFieldInfo) {
			return <VisuallyHidden id={fieldInfoId}>{fieldInfo}</VisuallyHidden>;
		}

		return <FieldInfo id={fieldInfoId}>{fieldInfo}</FieldInfo>;
	};

	return (
		<Wrapper>
			<Tooltip
				title={tooltipHintText}
				visible={!!tooltipHintText}
				placement="right"
				overlayClassName="antd-customRegisterTooltip"
			>
				<InputWrapper isBirthDateField={isBirthDateField}>
					{addLabel && (
						<Label
							backgroundColor={styles.label?.background}
							className={clsx({ filled: value })}
							htmlFor={name}
							isBirthDateField={isBirthDateField}
						>
							{ReactHtmlParser(placeholder)}
						</Label>
					)}
					{maskSymbol ? (
						<NumberFormat
							mask={maskSymbol}
							format={inputFormat}
							className={clsx({ errors: isErrorVisible })}
							aria-labelledby={inputDescribedBy}
							name={name}
							id={name}
							placeholder={inputPlaceholder}
							customInput={InputStyled}
							aria-required
							{...field}
							{...rest}
							aria-invalid={null}
						/>
					) : (
						<InputStyled
							id={name}
							aria-required
							aria-labelledby={inputDescribedBy}
							className={clsx({ errors: isErrorVisible })}
							placeholder={inputPlaceholder}
							{...field}
							{...rest}
							aria-invalid={null}
						/>
					)}
				</InputWrapper>
			</Tooltip>
			<BottomWrapper>
				{children}
				{!!isErrorVisible && (
					<FormError
						isErrorVisible={!!isErrorVisible}
						id={`${name}-alert`}
						isErrorBackgroundLight={isErrorBackgroundLight}
					>
						{errorText}
					</FormError>
				)}
			</BottomWrapper>
			{fieldInfo && renderFieldInfo()}
		</Wrapper>
	);
};

InputField.defaultProps = {
	type: 'text',
	autoComplete: 'true',
	styles: {},
	isInfoTip: false,
	initTouched: false,
	addLabel: true,
	hideFieldInfo: false,
	isErrorBackgroundLight: false,
	isBirthDateField: false
};

InputField.propTypes = {
	type: string.isRequired,
	field: object.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	placeholder: string.isRequired,
	autoComplete: string,
	tooltipHintText: string,
	hideFieldInfo: bool,
	fieldInfo: string,
	styles: object,
	isPasswordReminder: bool,
	maskSymbol: string,
	inputFormat: string,
	initTouched: bool,
	children: node,
	addLabel: bool,
	isErrorBackgroundLight: bool,
	isBirthDateField: bool
};

export default InputField;

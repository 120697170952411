import styled from 'styled-components';

// Import styles
import { transitionMain, flex } from 'components/styles';

export const Wrapper = styled.div`
	flex: 1;
	${flex({ jc: 'space-between', ai: 'center' })};
`;

export const Title = styled.p`
	font-size: ${({ theme }) => theme.fontSize.set('xs', 0.1)}rem;
	line-height: 2rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.white};
	opacity: 0;
	margin: auto 0 !important;
	${transitionMain}

	&.entering,
	&.exiting {
		opacity: 0;
	}

	&.entered {
		opacity: 1;
	}
`;

export const Container = styled.div`
	${flex()};
`;

export const MenusContainer = styled.div`
	background: ${({ theme }) => theme.primaryBgBlack};
	position: absolute;
	top: ${({ theme }) => (theme.isWCAG ? 2.5 : 0)}rem;
	left: 0;
	width: 100%;
	z-index: 99999;
	cursor: pointer;
	padding-bottom: 12rem;
	transform: translateY(100vh);
	max-height: calc(100vh - ${({ theme }) => (theme.isWCAG ? 14.5 : 12)}rem);
	overflow: hidden auto;
	${transitionMain}

	&.entering,
	&.exiting {
		transform: translateY(100vh);
	}

	&.entered {
		transform: translateY(12rem);
	}
`;

import React from 'react';
import { arrayOf, number, string, shape } from 'prop-types';

import SubMenuItem from './SubMenuItem';

const SubMenuList = ({ submenu, basePath, ...props }) => {
	if (!submenu) return null;
	return submenu.map((sublink) => (
		<SubMenuItem
			key={sublink.id}
			sublink={sublink}
			basePath={basePath}
			{...props}
		/>
	));
};

SubMenuList.propTypes = {
	submenu: arrayOf(
		shape({
			id: number.isRequired,
			name: string.isRequired,
			link: string.isRequired,
			model_id: string,
			model_type: string
		})
	).isRequired,
	basePath: string.isRequired
};

export default SubMenuList;

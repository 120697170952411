import React from 'react';
import { string, array, oneOfType, number, func } from 'prop-types';

// Import components
import { NavButton } from './styles';
import { Nav } from 'components/elements';
import SubNavigation from './sub_nav/SubNavigation';
import { Menu as NavItem } from '@reach/menu-button';

// Import helpers
import { getLinkDetails } from './helpers';

// Import variables
import { KEYBOARD_KEYS } from 'helpers/variables';

const { ENTER } = KEYBOARD_KEYS;

const NavLink = ({
	submenu,
	modelId,
	modelType,
	link,
	name,
	id,
	setActiveSubmenuId,
	activeSubmenuId
}) => {
	const { path, isInternalLink } = getLinkDetails({
		modelId,
		modelType,
		name,
		id
	});

	const isSubMenu = submenu.length;
	const isActiveSubmenu = activeSubmenuId === id;

	const toggleSubmenu = () =>
		isActiveSubmenu ? setActiveSubmenuId(null) : setActiveSubmenuId(id);

	const handleKeyPress = ({ key }) => key === ENTER && toggleSubmenu();

	const renderSubMenu = () => {
		if (!isSubMenu) {
			return (
				// Direct link to internal path
				<NavButton to={path} tabIndex="0" as={Nav.Link}>
					{name}
				</NavButton>
			);
		}

		return (
			// Button which open his submenu
			<NavButton
				aria-expanded={isActiveSubmenu}
				onKeyPress={handleKeyPress}
				onMouseUp={toggleSubmenu}
			>
				{name}
			</NavButton>
		);
	};

	return (
		<NavItem portal={false}>
			<SubNavigation
				submenu={submenu}
				basePath={path}
				isOpened={isActiveSubmenu}
			>
				{isInternalLink ? (
					<>{renderSubMenu()}</>
				) : (
					// Direct link to external path
					<NavButton href={link} as={Nav.AnchorLink}>
						{name}
					</NavButton>
				)}
			</SubNavigation>
		</NavItem>
	);
};

NavLink.propTypes = {
	name: string.isRequired,
	link: string.isRequired,
	submenu: array,
	modelId: string,
	modelType: string,
	id: oneOfType([string, number]).isRequired,
	setActiveSubmenuId: func.isRequired,
	activeSubmenuId: number
};

export default NavLink;

import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import helpers
import { convertDuration } from 'helpers';
import { SEEK_KEYS, FORWARD_KEYS } from '../helpers';

const useProgressBar = (currentTime) => {
	const { t } = useTranslation();
	const { duration, updateSeekTime } = usePlayerVodContext();
	const [value, setValue] = useState(0);

	const valueText = useMemo(() => {
		const currentText = convertDuration(value, true);
		const durationText = convertDuration(duration, true);

		return t('aria_player_progress_bar_value_text', {
			currentText,
			durationText
		});
		// eslint-disable-next-line
	}, [value, duration]);

	const handleKeyDown = ({ keyCode }) => {
		const isSeek = SEEK_KEYS.includes(keyCode);
		const isForward = FORWARD_KEYS.includes(keyCode);

		if (isSeek) {
			setValue(() => updateSeekTime(isForward));
		}
	};

	useEffect(() => {
		setValue(currentTime);
	}, [currentTime]);

	return { valueText, value, duration, keyDown: handleKeyDown };
};

export default useProgressBar;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Import styles
import { size, customMinResponsive, focusBasic } from 'components/styles';

const PaymentField = ({
	type = 'text',
	label,
	passwordClassName,
	field,
	form: { touched, errors },
	placeholder,
	disabled = false,
	isFocus = false,
	maxLength = 4,
	title
}) => {
	const inputRef = React.createRef(null);
	const { name } = field;
	const errorText = errors[name] || '';
	const isErrorVisible = touched[name] && errors[name];

	React.useEffect(() => {
		// Set focus
		isFocus && inputRef.current.focus();
		// eslint-disable-next-line
	}, [isFocus]);

	const handleInputChange = (e) => {
		if (type === 'number' && e.target.value.length > maxLength) return;

		field.onChange && field.onChange(e);
	};

	return (
		<FormFieldStyled className={passwordClassName}>
			<label htmlFor="authorization_code" className="visually-hidden">
				{label}
			</label>
			<Input
				ref={inputRef}
				className={`${passwordClassName && passwordClassName} ${
					touched[name] && errors[name] ? 'errors' : ''
				}`}
				id="authorization_code"
				type={type}
				placeholder={placeholder}
				disabled={disabled}
				maxLength={maxLength}
				aria-required="true"
				aria-labelledby={`${name}-alert`}
				title={title}
				{...field}
				onChange={handleInputChange}
				aria-invalid={null}
			/>
			<FormError isErrorVisible={isErrorVisible} id={`${name}-alert`}>
				{errorText}
			</FormError>
		</FormFieldStyled>
	);
};

const FormError = styled.p`
	display: ${({ isErrorVisible }) => (!isErrorVisible ? 'none' : 'block')};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.red};
	top: 2.3rem;
	position: absolute;
	${customMinResponsive(
		size.medium,
		css`
			top: 3.5rem;
		`
	)}
`;

const FormFieldStyled = styled.div`
	margin-top: 2.8rem;
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	&.blik {
		position: relative;
		${customMinResponsive(
			size.medium,
			css`
				margin-top: 0;
				margin-bottom: 3.2rem;
			`
		)}
	}
`;

const Input = styled.input`
	width: 16rem;
	border: 1px solid transparent;
	border-bottom: 1px solid
		${({ theme }) => theme.payment.paymentOptions.paymentField.borderColor};
	text-align: center;
	background: transparent;
	color: ${({ theme }) => theme.payment.paymentOptions.paymentField.color};
	font-size: 7rem;
	padding-bottom: 2.5rem;
	height: 3.5rem;
	letter-spacing: 3.5px;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}

	&:focus,
	&:hover {
		border-bottom-color: ${({ theme }) =>
			theme.authentication.input.focusBorderColor};
	}

	&:focus-visible {
		${focusBasic}
		outline-offset: -0.3rem;
	}

	&.errors {
		border-bottom: 1px solid
			${({ theme }) =>
				theme.payment.paymentOptions.paymentField.errorBorderColor};
	}

	&.blik {
		font-size: ${({ theme }) => theme.fontSize.set('xs', -0.6)}rem;
		padding: 0;
		width: ${({ theme }) => (theme.isWCAG ? 18 : 14)}rem;
		height: 2.5rem;
		${({ theme }) => theme.payment.paymentOptions.paymentField.borderColor};

		${customMinResponsive(
			size.medium,
			css`
				font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
				width: ${({ theme }) => (theme.isWCAG ? 30 : 16)}rem;
				height: 3.5rem;
				margin-bottom: ${({ theme }) => (theme.isWCAG ? 3 : 0)}rem;
			`
		)}
	}

	&.mask {
		font-family: 'text-security-disc';
	}

	::-webkit-input-placeholder {
		color: ${({ theme }) =>
			theme.payment.paymentOptions.paymentField.placeholderColor};
	}
`;

PaymentField.propTypes = {
	type: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
	form: PropTypes.shape({
		touched: PropTypes.object.isRequired,
		errors: PropTypes.object.isRequired
	}),
	placeholder: PropTypes.string,
	label: PropTypes.string.isRequired,
	passwordClassName: PropTypes.string,
	isFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	maxLength: PropTypes.number,
	title: PropTypes.string
};

export default PaymentField;

// Import helpers
import { getFormatedDate, createLinkParams } from 'helpers';
import { DATE_FORMAT, PRODUCTS_TYPES } from 'helpers/variables';
import { useTranslation } from 'react-i18next';

import usePassesContext from 'components/context/passes/usePassesContext';

const { DAY_MONTH_YEAR_FORMAT } = DATE_FORMAT;

function usePassTile(props) {
	const { t } = useTranslation();
	const { handlePassUse, purchaseLoading } = usePassesContext();
	const {
		isPassAvailable,
		uuid,
		context,
		title,
		usage_limit,
		user_usage_limit
	} = props;

	// Redirect to pass details
	const linkParams = createLinkParams({
		id: uuid,
		type: PRODUCTS_TYPES.PASS,
		title
	});

	// Purchase product via pass
	const handleButtonClick = isPassAvailable
		? handlePassUse({ pass_uuid: uuid })
		: () => {};

	const to = isPassAvailable ? {} : linkParams;
	const availabilityDate = getFormatedDate(
		context?.till,
		DAY_MONTH_YEAR_FORMAT
	);

	const subtitle = isPassAvailable
		? t('pass_use_modal_availability', {
				availabilityDate
		  })
		: t('pass_purchase_modal_limit', {
				usage_limit: usage_limit ?? 0
		  });

	const value = t('pass_use_modal_limits', {
		current_limit: user_usage_limit ?? 0,
		usage_limit: usage_limit ?? 0
	});

	const description = isPassAvailable
		? t('pass_use_modal_used_limits')
		: t('pass_purchase_modal_per_item');

	const buttonText = isPassAvailable
		? t('vod_section_play')
		: t('common_more_details_label');

	return {
		uuid,
		title,
		subtitle,
		isPassAvailable,
		to,
		availabilityDate,
		value,
		description,
		buttonText,
		usage_limit,
		handleButtonClick,
		disabled: isPassAvailable && purchaseLoading
	};
}

export default usePassTile;

import React from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { InfoModal } from 'components/elements';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';

const ErrorModal = ({ error }) => {
	const { t } = useTranslation();

	const { codeErrorMessage } = getErrorCodeResults(error);

	const description = codeErrorMessage || t('common_errors_default_error');

	return (
		<InfoModal title={t('common_general_error')} description={description} />
	);
};

ErrorModal.propTypes = {
	error: object.isRequired
};

export default ErrorModal;

import React from 'react';
import PropTypes from 'prop-types';

// Import components
import * as Nav from './styles';
import NavItem from './components';

const NavCategoryList = ({ links, path = '', modifiers }) => (
	<Nav.List dropdown modifiers={modifiers} role="list">
		{links.map((link) => (
			<NavItem key={link.id} {...link} path={path} modifiers={modifiers} />
		))}
	</Nav.List>
);

NavCategoryList.propTypes = {
	links: PropTypes.array.isRequired,
	path: PropTypes.string,
	modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default React.memo(NavCategoryList);

import React from 'react';

// Import context
import {
	ShakaPlayerProvider,
	TheoPlayerProvider
} from 'components/context/player/player_vod';
import { usePlayerFocusContext } from 'components/context/player/focus';

// Import variables
import { DEFAULT_PLAYER, THEO_PLAYER } from 'helpers/variables';

const PlayerVodProvider = (props) => {
	const { isFocus } = usePlayerFocusContext();

	switch (DEFAULT_PLAYER) {
		case THEO_PLAYER:
			return <TheoPlayerProvider {...props} />;

		default:
			return <ShakaPlayerProvider {...props} isFocus={isFocus} />;
	}
};

export default PlayerVodProvider;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
import { langResources, getUserLang } from './translations/helpers';

const userLang = getUserLang();

i18n.use(initReactI18next).init({
	resources: langResources,
	lng: userLang,
	debug: false,

	interpolation: {
		escapeValue: false // not needed for react as it escapes by default
	}
});

i18n.on('languageChanged', (lng) => {
	document.documentElement.setAttribute('lang', lng);
});

export default i18n;

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import styles
import {
	flex,
	transitionMain,
	customMinResponsive,
	size,
	customResponsive,
	focusBasic
} from 'components/styles';

export const Container = styled.section`
	padding-top: 8.4rem;
	${flex({ jc: 'flex-start', fd: 'column' })}
	min-height: 100vh;
	position: relative;
	width: 100%;

	${customMinResponsive(
		size.medium,
		css`
			padding-top: 7.74vw;
			background-color: ${({ theme }) => theme.payment.background};
		`
	)}
`;

export const BackButton = styled.button`
	height: 3rem;
	position: absolute;
	top: 13rem;
	left: 6rem;
	background: transparent;
	border: none;
	cursor: pointer;

	svg {
		path,
		line {
			${transitionMain}
		}
	}

	&:hover,
	&:focus {
		outline: none;

		svg {
			path {
				fill: ${({ theme }) => theme.primaryBlueLight};
			}

			line {
				stroke: ${({ theme }) => theme.primaryBlueLight};
			}
		}
	}

	&:focus-visible {
		${focusBasic}
	}

	${customResponsive(
		size.medium,
		css`
			display: none;
		`
	)}
`;

export const StyledLink = styled(Link)`
	transition: all 0.3s ease-in-out;
	text-decoration: underline;
	display: block;

	&:link,
	&:visited {
		color: ${({ theme }) => theme.modal.link};
	}

	&:hover,
	&:active,
	&:focus {
		text-decoration: underline;
		color: ${({ theme }) => theme.modal.linkHover};
	}
`;

import React from 'react';
import {
	number,
	string,
	object,
	array,
	func,
	bool,
	oneOfType
} from 'prop-types';

// Import components
import { Toggler, Agreement } from 'components/elements';

// Import helpers
import { checkIsAgreementAccepted } from 'helpers/agreements';
import { customHtmlParser } from 'helpers';

// Import styles
import { AgreementName, AgreementDescription } from './styles';

const AgreementItem = ({
	id,
	name,
	version,
	obligatory,
	toggleAgreement,
	acceptedAgreementsIds,
	description
}) => {
	const handleToggleAgreement = () => toggleAgreement(id);

	const isAccepted = checkIsAgreementAccepted({ acceptedAgreementsIds, id });

	const agreementDescription = version?.description || description;

	return (
		<Agreement.Item>
			<Toggler
				id={id}
				toggleAgreement={handleToggleAgreement}
				checked={isAccepted}
				isUserApproval
			/>
			<Agreement.Label
				htmlFor={id}
				obligatory={obligatory}
				isMarketingAgreement
			>
				<AgreementName>{name}</AgreementName>
				{agreementDescription && (
					<AgreementDescription>
						{customHtmlParser(agreementDescription)}
					</AgreementDescription>
				)}
			</Agreement.Label>
		</Agreement.Item>
	);
};

AgreementItem.propTypes = {
	id: number,
	name: string,
	version: object,
	obligatory: oneOfType([bool, number]),
	toggleAgreement: func,
	acceptedAgreementsIds: array,
	description: string
};

export default AgreementItem;

// API
export const GET_SUBSCRIBER_PROMOTIONS_API = '/subscriber/promotions';
export const SUBSCRIBER_AGREEMENTS_API = '/subscriber/agreements';
export const SUBSCRIBER_ANONIMIZE_API = '/subscriber/anonymize';
export const GET_SUBSCRIBER_PRICE_PROMOTIONS_API =
	'/subscriber/promotions/available';
export const POST_SUBSCRIBER_APPROVAL_EMAIL_API =
	'subscriber/approvalEmail/send';
export const GET_SUBSCRIBER_ACTIVE_VOUCHERS_API =
	'subscriber/vouchers?active_only=true';
export const GET_SUBSCRIBER_DETAILS_API = '/subscriber/details';
export const GET_ACTIVE_PRODUCTS_FOR_PACKET_API =
	'/products/packet/:uuid/activeForUserProducts';
export const GET_SUBSCRIBER_CONTACT_SUBJECTS_API =
	'/subscriber/contact/subjects';

// QUERIES
export const QUERY_SUBSCRIBER_ALL_AGREEMENTS =
	'QUERY_SUBSCRIBER_ALL_AGREEMENTS';
export const QUERY_SUBSCRIBER_ACTIVE_AGREEMENTS =
	'QUERY_SUBSCRIBER_ACTIVE_AGREEMENTS';
export const QUERY_SUBSCRIBER_PRICE_PROMOTIONS =
	'QUERY_SUBSCRIBER_PRICE_PROMOTIONS';
export const QUERY_SUBSCRIBER_APPROVAL_EMAIL =
	'QUERY_SUBSCRIBER_APPROVAL_EMAIL';
export const QUERY_SUBSCRIBER_DETAILS = 'QUERY_SUBSCRIBER_DETAILS';
export const QUERY_SUBSCRIBER_CONTACT_SUBJECTS =
	'QUERY_SUBSCRIBER_CONTACT_SUBJECTS';

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// Import helpers
import { PREVIEW_SEARCH_PARAM, previewTokenRegex } from './helpers';

function useQueryParams() {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

function usePreviewToken() {
	const query = useQueryParams();
	const previewToken = query.get(PREVIEW_SEARCH_PARAM);
	const isPreview = previewTokenRegex.test(previewToken);
	return {
		previewToken,
		isPreview
	};
}

export default usePreviewToken;

import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import i18n from 'i18next';

export const customHtmlParser = (content) => {
	return ReactHtmlParser(content, {
		transform: function transform(node, index) {
			const { attribs, children, name, type } = node;

			if (type === 'tag' && name === 'a' && attribs.target === '_blank') {
				// Add visually-hidden span element
				const wcagInfoNode = {
					type: 'tag',
					name: 'span',
					attribs: {
						class: 'visually-hidden'
					},
					children: [
						{
							data: i18n.t('aria_external_link_information'),
							type: 'text'
						}
					]
				};

				node.children = children.concat(wcagInfoNode);
			}

			// Remove title attribute from all anchor tags
			if (type === 'tag' && name === 'a' && attribs.title) {
				delete attribs.title;
			}

			return convertNodeToElement(node, index, transform);
		}
	});
};

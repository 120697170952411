import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { func } from 'prop-types';

// Import variables
import { APP_CORE_PATHS } from 'components/routes/paths';

const { REGISTER, ADDITIONAL_INFO } = APP_CORE_PATHS;

const ProtectedRegistrationCompleted = ({ component: Component }) => {
	const { isAuthenticated, isRegisterCompleted, isFirebaseRegisterCompleted } =
		useSelector(({ auth }) => auth);

	return (
		<Route
			render={(props) =>
				isAuthenticated &&
				!isRegisterCompleted &&
				!isFirebaseRegisterCompleted ? (
					<Redirect to={`/${REGISTER}/${ADDITIONAL_INFO}`} />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

ProtectedRegistrationCompleted.propTypes = {
	component: func.isRequired
};

export default ProtectedRegistrationCompleted;

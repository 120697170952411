import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

// Import utilties
import { customMinResponsive, size } from 'components/styles';

// Import icons
import { ReactComponent as DisabilityIcon } from 'assets/icons/ic_wcag_disability.svg';
import { ReactComponent as LargeTextIcon } from 'assets/icons/ic_wcag_text_large.svg';
import { ReactComponent as MediumTextIcon } from 'assets/icons/ic_wcag_text_medium.svg';
import { ReactComponent as SmallTextIcon } from 'assets/icons/ic_wcag_text_small.svg';
import { ReactComponent as ContrastIcon } from 'assets/icons/ic_wcag_contrast.svg';
import { ReactComponent as AnimationPauseIcon } from 'assets/icons/ic_wcag_animation.svg';
import { ReactComponent as AnimationPlayIcon } from 'assets/icons/ic_wcag_animation_play.svg';
import { ReactComponent as ResetIcon } from 'assets/icons/ic_wcag_reset.svg';

const baseIcon = css`
	display: inline-block;
	svg {
		width: 1.85rem;
		height: 1.85rem;
	}
	${customMinResponsive(
		size.medium,
		css`
			svg {
				width: 2rem;
				height: 2rem;
			}
		`
	)};
`;

export const Wrapper = styled.div`
	${flex({ jc: 'space-between' })};
`;

export const Container = styled.div`
	${flex()};
	flex-wrap: ${({ theme: { isWCAG } }) => (isWCAG ? 'wrap' : 'nowrap')};
`;

export const Text = styled.p`
	display: ${({ showText }) => (showText ? 'inline-block' : 'none')};
	font-size: ${({ theme }) => theme.fontSize.wcagNav.text}rem;
	margin-bottom: 0 !important;

	${customMinResponsive(
		size.medium,
		css`
			display: inline-block;
			font-size: ${({ theme }) => theme.fontSize.wcagNav.text}rem;
			font-weight: ${({ theme }) => theme.font.normal};
			color: ${({ theme }) => theme.white};
			margin-right: ${({ marginRight }) => (marginRight ? '2.2rem' : '0')};
		`
	)};
`;

export const StyledDisabilityIcon = styled(DisabilityIcon)`
	margin-right: 1.45rem;
	svg {
		width: 1.8rem;
		height: 2.3rem;
	}
	${customMinResponsive(
		size.medium,
		css`
			margin-right: 1.6rem;
			svg {
				width: 2rem;
				height: 2.5rem;
			}
		`
	)};
`;

export const StyledLargeTextIcon = styled(LargeTextIcon)`
	svg {
		width: 4.7rem;
		height: 1.8rem
			${customMinResponsive(
				size.medium,
				css`
					width: 5.1rem;
					height: 2rem;
				`
			)};
	}
`;

export const StyledMediumTextIcon = styled(MediumTextIcon)`
	svg {
		width: 2.6rem;
		height: 1.48rem;
	}
	${customMinResponsive(
		size.medium,
		css`
			svg {
				width: 2.8rem;
				height: 1.6rem;
			}
		`
	)};
`;
export const StyledSmallTextIcon = styled(SmallTextIcon)`
	svg {
		width: 1.24rem;
		height: 1.3rem;
	}
	${customMinResponsive(
		size.medium,
		css`
			svg {
				width: 1.35rem;
				height: 1.4rem;
			}
		`
	)};
`;

export const StyledContrastIcon = styled(ContrastIcon)`
	${baseIcon}
	path {
		fill: ${({ theme }) =>
			theme.isContrast ? theme.primaryBlueLight : theme.white};
	}
	circle {
		stroke: ${({ theme }) =>
			theme.isContrast ? theme.primaryBlueLight : theme.white};
	}
`;

export const StyledAnimationPauseIcon = styled(AnimationPauseIcon)`
	${baseIcon}
`;
export const StyledAnimationPlayIcon = styled(AnimationPlayIcon)`
	${baseIcon}
`;

export const StyledResetIcon = styled(ResetIcon)`
	svg {
		width: 1.8rem;
		height: 1.8rem
			${customMinResponsive(
				size.medium,
				css`
					width: 2rem;
					height: 2rem;
				`
			)};
	}
`;

export const IconDivider = styled.span`
	display: inline-block;
	width: 0.01rem;
	height: 2.5rem;
	background-color: ${({ theme }) => theme.white};
	margin: 0 1.45rem;
	${customMinResponsive(
		size.medium,
		css`
			margin: 0 2rem;
		`
	)};
`;

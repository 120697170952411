import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { replaceStringChar } from 'helpers';

// Import api
import { QUERY_PRODUCT_PRICES, GET_PRODUCT_PRICES_API } from 'helpers/api';

const useProductPricesQuery = ({
	uuid,
	onSuccess = () => {},
	onError = () => {},
	enabled = true
} = {}) => {
	const queryKey = `${QUERY_PRODUCT_PRICES}_${uuid}`;

	const queryFn = () => {
		const url = replaceStringChar(GET_PRODUCT_PRICES_API, { ':id': uuid });
		return services.get(url);
	};

	const { data, status, isLoading, isFetching, isError, remove } = useQuery({
		queryKey,
		queryFn,
		onSuccess,
		onError,
		enabled
	});

	useEffect(() => {
		return () => remove();
		//eslint-disable-next-line
	}, []);

	const productPrices = data?.data;

	return {
		productPrices,
		productPricesStatus: status,
		isProductPricesLoading: isLoading || isFetching,
		isProductPricesError: isError
	};
};

export default useProductPricesQuery;

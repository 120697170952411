import React from 'react';
import { useParams } from 'react-router-dom';
import { string, array, shape, object, bool } from 'prop-types';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';
import { getAuxiliaryProductData } from 'helpers/products';
import { checkProductSubtype } from 'helpers';

// Import variables
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';

// Import components
import { Spacer, AvailabilityInfoHidden } from 'components/elements';
import TileImage from './TileImage';

// Import styles
import {
	TileContentWrapper,
	TileTitle,
	TileDescription,
	StyledLink
} from './styles';
import { IconTickCircle } from 'components/elements/molecules/slider/SliderItem/styles';

// Import utilities
import {
	useProductAvailability,
	useProductTranslations
} from 'components/utilities';

const { COVER } = PRODUCT_IMAGES_TYPES;

const GridTile = ({ item, isPlain }) => {
	const params = useParams();

	const product = useProductTranslations(item, isPlain);

	const { images, uuid, available_in, title, subtype } = product;

	const image = getImage({ images, type: COVER });

	const { linkParams, description } = getAuxiliaryProductData({
		product,
		params
	});

	const isAvailable = useProductAvailability(uuid, available_in, subtype);

	const { isBanner } = checkProductSubtype(subtype);
	if (isBanner) return null;

	return (
		<StyledLink to={linkParams}>
			{isAvailable && <IconTickCircle aria-hidden role="img" />}
			<TileContentWrapper>
				<TileImage src={image} alt="" />
				{title && (
					<TileTitle>
						{title}
						<AvailabilityInfoHidden product={product} />
					</TileTitle>
				)}
				{description && (
					<TileDescription>
						<Spacer elements={description} />
					</TileDescription>
				)}
			</TileContentWrapper>
		</StyledLink>
	);
};

GridTile.defaultProps = {
	isPlain: false
};

GridTile.propTypes = {
	item: shape({
		uuid: string.isRequired,
		title: string.isRequired,
		subtype: string.isRequired,
		available_in: array,
		images: object
	}).isRequired,
	isPlain: bool
};

export default GridTile;

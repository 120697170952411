import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { Button, PlayerElements } from 'components/elements';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const { IconVolumeOff, IconVolume } = PlayerElements;

const MuteButton = () => {
	const { t } = useTranslation();
	const { mute, isMuted } = usePlayerVodContext();

	const title = isMuted
		? t('common_player_volume_off_title')
		: t('common_player_volume_on_title');

	return (
		<Button
			onClick={mute}
			modifiers={['icon', 'playerIconHover']}
			title={title}
			aria-label={title}
		>
			{isMuted ? (
				<IconVolumeOff aria-hidden modifiers="volumeSmall" />
			) : (
				<IconVolume aria-hidden modifiers="volumeSmall" />
			)}
		</Button>
	);
};

export default MuteButton;

import i18n from 'i18next';
import getSlug from 'speakingurl';

// Import paths
import { APP_CORE_PATHS, MAIN_PATHS } from 'components/routes/paths';

// Import variables
import { PRODUCTS_TYPES, DATE_FORMAT } from './variables';

// Import helpers
import { SUBSCRIPTION_STATUS_TYPES } from 'helpers/prices';
import {
	getFormatedDate,
	createLinkParams,
	convertTime,
	getLanguage
} from 'helpers';

const {
	VOD,
	AUDIO,
	COLLECTION,
	PACKET,
	ARTICLE,
	LIVE_EVENT,
	SEASON,
	EPISODE,
	SERIES,
	BANNER,
	PASS,
	VOUCHER_TEMPLATE,
	EXHIBITION
} = PRODUCTS_TYPES;
const { ACTIVE, CANCELED, PENDING } = SUBSCRIPTION_STATUS_TYPES;
const { DAY_MONTH_YEAR_FORMAT } = DATE_FORMAT;

const getType = ({ type, is_audio, context }) => {
	const isAudio = context?.is_audio ?? is_audio;
	const isAudioType = type === VOD && isAudio;
	return isAudioType ? AUDIO : type;
};

export const getProductTypeAlias = ({
	subtype,
	type,
	is_audio = false,
	context = {}
}) => {
	const { isCollection, isPass } = checkProductSubtype(subtype);
	const productType = isCollection || isPass ? subtype : type;

	const translationKey = getType({ type: productType, is_audio, context });

	return i18n.t(`vod_product_type_alias_${translationKey}`);
};

export const joinArray = (array = [], delimiter = ' | ') => {
	return array.filter(Boolean).join(delimiter);
};

const getAdditionalInfo = ({ subtype, since, till, context, is_audio }) => {
	switch (subtype) {
		case LIVE_EVENT:
			const sinceValue = since || context?.since;
			const tillValue = till || context?.till;
			return convertTime(sinceValue, tillValue);
		case VOD:
		case ARTICLE:
		case EPISODE:
		case EXHIBITION:
		case SEASON:
		case SERIES:
			const type = getType({ type: subtype, is_audio, context });
			return i18n.t(`common_product_subtype_alias_${type}`);
		default:
			return null;
	}
};

export const getAuxiliaryProductData = ({ product, params }) => {
	const { images, title, subtype, uuid } = product;

	const linkParams = createLinkParams({
		id: uuid,
		type: subtype,
		title,
		params
	});

	const alias = getProductTypeAlias(product);
	const additionalInfo = getAdditionalInfo(product);
	const description = [alias, additionalInfo];

	return {
		images,
		linkParams,
		title,
		description
	};
};

export const getTranslation = (translation = {}) => {
	const lang = getLanguage();
	return translation?.[lang] ?? translation;
};

export const getProductDetailsApi = ({ id, type }) => {
	switch (type) {
		case ARTICLE:
		case LIVE_EVENT:
		case VOUCHER_TEMPLATE:
		case EXHIBITION:
			return `/products/${id}/details`;

		case COLLECTION:
		case PACKET:
		case PASS:
			return `/products/packet/${id}`;

		default:
			return `/products/${type}/${id}`;
	}
};

export const checkProductSubtype = (subtype) => {
	const isCollection = subtype === COLLECTION;
	const isPacket = subtype === PACKET;
	const isArticle = subtype === ARTICLE;
	const isVod = subtype === VOD;
	const isLiveEvent = subtype === LIVE_EVENT;
	const isSeason = subtype === SEASON;
	const isEpisode = subtype === EPISODE;
	const isSeries = subtype === SERIES;
	const isBanner = subtype === BANNER;
	const isPass = subtype === PASS;
	const isVoucherTemplate = subtype === VOUCHER_TEMPLATE;
	const isExhibition = subtype === EXHIBITION;

	return {
		isCollection,
		isPacket,
		isArticle,
		isVod,
		isLiveEvent,
		isSeason,
		isEpisode,
		isSeries,
		isBanner,
		isPass,
		isVoucherTemplate,
		isExhibition
	};
};

export const PRODUCT_TYPES_MAPPED = {
	[VOD]: 'vod',
	[AUDIO]: AUDIO,
	[ARTICLE]: 'artykul',
	artykul: ARTICLE,
	[COLLECTION]: 'kolekcja',
	kolekcja: COLLECTION,
	[LIVE_EVENT]: 'live',
	live: LIVE_EVENT,
	[SERIES]: 'serial',
	serial: SERIES,
	[SEASON]: 'sezon',
	sezon: [SEASON],
	[EPISODE]: 'odcinek',
	odcinek: EPISODE,
	seriale: SERIES,
	[PACKET]: 'pakiet',
	pakiet: PACKET,
	movies: 'vod',
	karnet: PASS,
	[PASS]: 'karnet',
	voucher: VOUCHER_TEMPLATE,
	[VOUCHER_TEMPLATE]: 'voucher',
	[EXHIBITION]: 'wystawa',
	wystawa: EXHIBITION,
	[BANNER]: BANNER
};

export const PRODUCT_LIMIT_TYPES = {
	VIEWS: 'views',
	PURCHASES: 'purchases',
	USAGES: 'usages'
};

export const getSubscriptionStatus = (subscription) => {
	if (!subscription) return '';

	const { status, next_checkout_date, expiration_date } = subscription;

	const checkoutDate = next_checkout_date ?? expiration_date;
	const date = getFormatedDate(checkoutDate, DAY_MONTH_YEAR_FORMAT);

	switch (status) {
		case ACTIVE:
			return i18n.t('subscription_automatic_renewal', { date });

		case CANCELED:
			return i18n.t('user_profile_subsciption_canceled', { date });

		case PENDING:
			return i18n.t('user_profile_subsciption_pending');

		default:
			return '';
	}
};

export const getPaymentLink = ({
	id,
	type = '',
	title,
	priceId,
	isSubscriptionUpdate = false,
	isWallet = false,
	provider,
	passId
}) => {
	const { PAYMENT } = APP_CORE_PATHS;
	const { WALLET_PAYMENT } = MAIN_PATHS;

	const productType = PRODUCT_TYPES_MAPPED[type];

	const pathname = isWallet
		? WALLET_PAYMENT
		: `/${PAYMENT}/${productType}/${id}/${getSlug(title)}`;

	return {
		pathname,
		state: {
			priceId,
			update_subscription: isSubscriptionUpdate,
			provider,
			passId
		}
	};
};

const checkVideoBannerExists = (product) =>
	!!product?.previews?.banner?.[0]?.videoAssetId;

export const filterProductsWithVideoBanner = (content) =>
	content?.filter(checkVideoBannerExists) || [];

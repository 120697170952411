import styled from 'styled-components';

export const ButtonText = styled.span`
	visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
`;

export const RequestStatus = styled.p`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.5)}rem;
	height: 2rem;
	color: ${({ theme, isError }) => (isError ? theme.red : theme.user.info)};
	visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

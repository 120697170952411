import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ReactPixel from 'react-facebook-pixel';

// Import heleprs
import {
	initGA,
	initPixel,
	initTagManager,
	logPageViewGA
} from 'helpers/analytics';

// Import variables
import { GOOGLE_TAG_MANAGER } from 'helpers/variables';

const { PAGE_VIEW } = GOOGLE_TAG_MANAGER;

const useAnalytics = () => {
	const { pathname } = useLocation();
	const { functionalAgreementsPermission } = useSelector(({ auth }) => auth);

	useEffect(() => {
		initGA();
		initTagManager();
		initPixel();
	}, []);

	useEffect(() => {
		// Google Analytics page view
		logPageViewGA();

		// Facebook pixel page view
		ReactPixel.pageView();

		// Google Tag Manager page view
		window.dataLayer.push({
			event: PAGE_VIEW
		});
	}, [pathname]);

	useEffect(() => {
		if (functionalAgreementsPermission) {
			ReactPixel.grantConsent();
		} else {
			ReactPixel.revokeConsent();
		}
	}, [functionalAgreementsPermission]);
};

export default useAnalytics;

// Import variables
import { KEYBOARD_KEYS } from 'helpers/variables';

const { ENTER } = KEYBOARD_KEYS;

export const useMobileMenu = () => {
	const handleKeyEvent = (e) => {
		// Allow submenu to react on Enter key press
		if (e.key === ENTER) {
			const menuItem = e.target;
			const itemSubmenuTitle = menuItem.querySelector(
				'.ant-menu-submenu-title'
			);
			const submenuItems = menuItem.querySelector('.ant-menu-sub');
			const isExpand =
				itemSubmenuTitle.getAttribute('aria-expanded') === 'false';

			menuItem.classList.toggle('ant-menu-submenu-open');
			itemSubmenuTitle.setAttribute('aria-expanded', isExpand);
			submenuItems.classList.toggle('ant-menu-hidden');
		}
	};

	return { handleKeyEvent };
};

import { PRODUCTS_TYPES } from 'helpers/variables';
const { ALL, VOD, AUDIO, ARTICLE, LIVE_EVENT } = PRODUCTS_TYPES;

export const PRODUCT_TYPE_SELECT_OPTIONS = [
	{
		type: ALL,
		name: 'vod_product_type_alias_all'
	},
	{
		type: VOD,
		name: 'vod_product_type_alias_vod'
	},
	{
		type: AUDIO,
		name: 'vod_product_type_alias_audio'
	},
	{
		type: ARTICLE,
		name: 'vod_product_type_alias_article'
	},
	{
		type: LIVE_EVENT,
		name: 'vod_product_type_alias_live_event'
	}
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { string } from 'prop-types';

// Import components
import OptionContent from 'components/views/product_payment/components/PaymentSelect/OptionContent';
import { ListBoxSelect, ListBoxOption } from 'components/elements';

// Import styles
import { StyledListBoxGroup, StyledMethodTitle } from './styles';

// Import context
import { useProductPaymentContext } from 'components/context/payment';

// Import context actions
import {
	setSelectedPriceId,
	clearPromotion
} from 'components/context/payment/store/actions';

// Import helpers
import { getSelectedElementId } from 'helpers';

const PaymentSelect = ({ listId }) => {
	const { t } = useTranslation();

	const { paymentMethods, paymentMethod, dispatch } =
		useProductPaymentContext();

	const handleSetSelectedMethodId = (id) => {
		setSelectedPriceId(parseInt(id))(dispatch);
		clearPromotion()(dispatch);
	};

	const groupedMethods = _.groupBy(paymentMethods, 'payment_method');
	const groupedMethodsEntries = Object.entries(groupedMethods);

	return (
		<>
			<ListBoxSelect
				currentValue={paymentMethod.id}
				listId={listId}
				changeHandler={handleSetSelectedMethodId}
				title="payment_options_select_label"
				selectButtonContent={<OptionContent option={paymentMethod} />}
				modifiers="payment_select"
				isDescription
			>
				{({ handleMouseOptionSelect, handleKeyOptionEvents }) => (
					<>
						{groupedMethodsEntries.map(([method, options]) => (
							<StyledListBoxGroup key={method}>
								<StyledMethodTitle aria-hidden>
									{t(`payment_method_${method}`)}
								</StyledMethodTitle>

								{options.map((option) => {
									const isSelected = option.id === paymentMethod.id;
									const selectedId = getSelectedElementId({
										isSelected,
										listId
									});

									return (
										<ListBoxOption
											key={option.id}
											id={selectedId}
											role="option"
											aria-selected={isSelected}
											data-value={option.id}
											tabIndex={0}
											modifiers="payment_select"
											onMouseUp={handleMouseOptionSelect}
											onKeyDown={handleKeyOptionEvents}
										>
											<OptionContent option={option} />
										</ListBoxOption>
									);
								})}
							</StyledListBoxGroup>
						))}
					</>
				)}
			</ListBoxSelect>
		</>
	);
};

PaymentSelect.propTypes = {
	listId: string
};

export default PaymentSelect;

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import images
import { ReactComponent as YouTube } from 'assets/icons/ic_youtube.svg';
import { ReactComponent as Instagram } from 'assets/icons/ic_instagram.svg';
import { ReactComponent as Facebook } from 'assets/icons/ic_facebook.svg';
import { ReactComponent as Vimeo } from 'assets/icons/ic_vimeo.svg';

// Import components
import { Heading } from 'components/elements';

// Import styled components
import { Social, AnchorStyled, ListItemStyled } from './styles';

// Import variables
import { EXTERNAL_LINKS } from 'helpers/variables';

const { FACEBOOK, VIMEO, YOUTUBE, INSTAGRAM } = EXTERNAL_LINKS;

const showSocialLink = false;

const FooterSocial = () => {
	const { t } = useTranslation();
	return (
		<>
			<Heading.Second modifiers="hidden">
				{t('common_footer_social_heading')}
			</Heading.Second>
			<Social role="list">
				<ListItemStyled>
					<AnchorStyled
						target="_blank"
						rel="noopener noreferrer"
						href={FACEBOOK}
						aria-label={t('aria_footer_social_media_facebook')}
					>
						<Facebook aria-hidden="true" />
					</AnchorStyled>
				</ListItemStyled>
				<ListItemStyled>
					<AnchorStyled
						target="_blank"
						rel="noopener noreferrer"
						href={INSTAGRAM}
						aria-label={t('aria_footer_social_media_instagram')}
					>
						<Instagram aria-hidden="true" />
					</AnchorStyled>
				</ListItemStyled>
				{showSocialLink && (
					<>
						<ListItemStyled>
							<AnchorStyled
								target="_blank"
								rel="noopener noreferrer"
								href={VIMEO}
								aria-label={t('aria_footer_social_media_vimeo')}
							>
								<Vimeo aria-hidden="true" />
							</AnchorStyled>
						</ListItemStyled>
						<ListItemStyled>
							<AnchorStyled
								target="_blank"
								rel="noopener noreferrer"
								href={YOUTUBE}
								aria-label={t('aria_footer_social_media_youtube')}
							>
								<YouTube aria-hidden="true" />
							</AnchorStyled>
						</ListItemStyled>
					</>
				)}
			</Social>
		</>
	);
};

export default FooterSocial;

import styled from 'styled-components';

export const MockedSliderWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
`;

export const SlideContent = styled.div`
	width: 100%;
	height: 100%;
`;

import React, { createContext } from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { promptIllegalAction } from './helpers';

// Import components
import ProductPreviewAlert from './ProductPreviewAlert';

// Import hooks
import usePreviewToken from './usePreviewToken';

// Context
export const ProductPreviewContext = createContext(null);

function ProductPreviewProvider({ children, fixed }) {
	const { isPreview, previewToken } = usePreviewToken();

	const onPreviewHOF = (eventHandler) =>
		isPreview ? promptIllegalAction : eventHandler;

	const previewLinkProps = () => {
		if (isPreview) {
			return {
				when: false,
				onClick: (e) => {
					e.preventDefault();
					promptIllegalAction();
				}
			};
		}
		return {};
	};

	return (
		<ProductPreviewContext.Provider
			value={{
				isPreview,
				previewToken,
				onPreviewHOF,
				previewLinkProps
			}}
		>
			{isPreview && <ProductPreviewAlert fixed={fixed} />}
			{children}
		</ProductPreviewContext.Provider>
	);
}

ProductPreviewProvider.defaultProps = {
	fixed: false
};

ProductPreviewProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	fixed: PropTypes.bool
};

export default ProductPreviewProvider;

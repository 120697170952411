// Import helpers
import { PAGE_META_TAGS } from 'helpers/meta_tags';

// Import components
import {
	Static,
	ResetPassword,
	Contact,
	EmailConfirmation,
	CookiesManagement
} from './components';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { PASSWORD_RESET, EMAIL_CONFIRMATION, CONTACT, COOKIES, DOCUMENT } =
	APP_CORE_PATHS;

const setPath = (path) => `/${path}`;
export const appRoutes = [
	{
		path: setPath(CONTACT),
		component: Contact,
		metaTags: PAGE_META_TAGS.CONTACT,
		isHeadingTitle: false
	},
	{ path: setPath(DOCUMENT), component: Static, isHeadingTitle: false },
	{
		path: setPath(PASSWORD_RESET),
		component: ResetPassword,
		isHeadingTitle: false
	},
	{
		path: setPath(EMAIL_CONFIRMATION),
		component: EmailConfirmation,
		isHeadingTitle: false
	},
	{
		path: setPath(COOKIES),
		component: CookiesManagement,
		isHeadingTitle: false
	}
];

import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	asset_groups: [],
	isError: false,
	isLoading: true
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.FETCH_ASSET_GROUPS_LOADING:
				return INITIAL_STATE;

			case types.FETCH_ASSET_GROUPS_SUCCESS:
				draft.asset_groups = action.payload;
				draft.isLoading = false;
				return;

			case types.FETCH_ASSET_GROUPS_ERROR:
				draft.isLoading = false;
				draft.isError = true;
				return;

			case types.CLEAR_PRODUCT_ASSET_GROUPS:
				return INITIAL_STATE;

			default:
				return state;
		}
	});

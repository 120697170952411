import React from 'react';
import { array } from 'prop-types';

// Import components
import { ProperContent, VerticalDivider } from 'components/elements';

// Import styles
import { Segment } from './styles';

const Spacer = ({ elements }) => {
	const filtered = elements.filter(Boolean);
	if (!filtered.length) return null;
	return (
		<>
			{filtered.map((element, index) => (
				<Segment key={`${element}-${index}`}>
					<p>
						<ProperContent name={element} />
					</p>
					{index < filtered.length - 1 && <VerticalDivider />}
				</Segment>
			))}
		</>
	);
};

Spacer.defaultProps = {
	elements: []
};

Spacer.propTypes = {
	elements: array.isRequired
};

export default Spacer;

import React from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { FocusOn } from 'react-focus-on';

// Import styled components
import { Wrapper, Title, Container, MenusContainer } from './styles';

// Import components
import MobileMenu from './MobileMenu';
import Institution from 'components/layout/nav/nav_toolbar/institution/Institution';
import Hamburger from 'components/layout/nav/hamburger/Hamburger';
import ComboBoxSearch from 'components/layout/nav/nav_search/ComboBoxSearch/ComboBoxSearch';
import NavTemplate from 'components/layout/nav/nav_template/NavTemplate';

// Import utilities
import { useMenuToggle, useMobileNav } from 'components/layout/nav/hooks';
import { useDelayFocus } from 'components/utilities';

const MobileNav = ({ mainContainerRef }) => {
	const { t } = useTranslation();
	const { isOpen, toggle } = useMenuToggle();
	const { mobileMenuRef } = useMobileNav({ isOpen });
	const { delayFocus } = useDelayFocus();

	const handleDeactivation = () => delayFocus(mainContainerRef);

	return (
		<FocusOn
			enabled={isOpen}
			scrollLock={false}
			onDeactivation={handleDeactivation}
		>
			<NavTemplate>
				<Wrapper>
					<Transition in={isOpen} timeout={0}>
						{(state) => (
							<Title className={state}>{t('common_header_nav_menu')}</Title>
						)}
					</Transition>
					<Container>
						<Institution />
						<Hamburger isOpen={isOpen} toggle={toggle} />
					</Container>
				</Wrapper>
				<Transition
					in={isOpen}
					timeout={{ enter: 0, exit: 500 }}
					mountOnEnter
					unmountOnExit
				>
					{(state) => (
						<MenusContainer className={state} ref={mobileMenuRef}>
							<ComboBoxSearch
								toggle={toggle}
								isMobileView
								autoFocus={false}
								isOpen={isOpen}
							/>
							<MobileMenu />
						</MenusContainer>
					)}
				</Transition>
			</NavTemplate>
		</FocusOn>
	);
};

MobileNav.propTypes = {
	mainContainerRef: object.isRequired
};

export default MobileNav;

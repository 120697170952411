// Import api
import {
	GET_PACKET_PRODUCTS_LIST_API,
	GET_PASS_PRODUCTS_LIST_API
} from 'helpers/api';

// Import helpers
import { PRODUCTS_TYPES } from 'helpers/variables';
import { getProductType } from 'helpers';

const { COLLECTION, PACKET, PASS, PACKET_PASS } = PRODUCTS_TYPES;

export const getProductListApi = ({ subtype, id, type }) => {
	let apiUrl = '';
	const productType = getProductType({ type, subtype });

	switch (productType) {
		case COLLECTION:
		case PACKET:
		case PACKET_PASS:
			apiUrl = GET_PACKET_PRODUCTS_LIST_API;
			break;

		case PASS:
			apiUrl = GET_PASS_PRODUCTS_LIST_API;
			break;

		default:
			break;
	}

	return apiUrl.replace('id', id);
};

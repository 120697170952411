import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { playerOnCancel } from 'store/actions';

// Import utilities
import { usePip, useAuth } from 'components/utilities';

// Import helpres
import { PLAYER_SELECTORS } from 'helpers/variables';
import {
	fetchMovieResources,
	getProductType,
	checkProductPage,
	getMatchedId
} from './helpers';

const playerSelector = PLAYER_SELECTORS.VOD;

const useVodPlayer = () => {
	const { isAuth } = useAuth();
	const { isReady, isLoaded } = useSelector((state) => state[playerSelector]);

	const { error, errorMessage } = useSelector(
		({ movieDetails }) => movieDetails
	);
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const productId = getMatchedId(pathname);
	const productType = getProductType(pathname);
	const isDetails = checkProductPage(pathname);

	// Set use pip
	const { exitPip } = usePip();

	useEffect(() => {
		fetchMovieResources({
			id: productId,
			type: productType,
			dispatch,
			isDetails,
			isAuth
		});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		return () => {
			// Clear player
			playerOnCancel(playerSelector)(dispatch);
			// Exit pip
			exitPip();
		};
		// eslint-disable-next-line
	}, []);

	return {
		isReady,
		isLoaded,
		error,
		errorMessage,
		type: playerSelector
	};
};

export default useVodPlayer;

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import variables
import { KEY_CODES, EVENTS_NAMES } from 'helpers/variables';

// Import utilities
import { useEventListener } from 'components/utilities';

const { KEYDOWN } = EVENTS_NAMES;

const { SPACE, KEY_M } = KEY_CODES;

const usePlayerShortcuts = () => {
	const { isPaused, play, pause, mute } = usePlayerVodContext();

	const handlePlayPause = () => {
		const isUIFocused = document.activeElement !== document.body;

		if (!isUIFocused) {
			isPaused ? play() : pause();
		}
	};

	const handleKeyDown = ({ keyCode }) => {
		switch (keyCode) {
			case SPACE:
				handlePlayPause();
				break;

			case KEY_M:
				mute();
				break;

			default:
				break;
		}
	};

	useEventListener(KEYDOWN, handleKeyDown, document);
};

export default usePlayerShortcuts;

import React from 'react';
import { func, oneOfType, shape, instanceOf, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Import components
import NavDropdown from './NavDropdown';
import Institution from './institution/Institution';
import Favourites from './favourites/Favourites';
import Reminders from './reminders/Reminders';
import SearchButton from './SearchButton';

// Import styles
import { Item, StyledButton } from './styles';

// Import hooks
import { useNavToolbar } from './useNavToolbar';

const NavToolbar = ({
	toggle,
	searchRef,
	isUserDropdownOpen,
	setActiveSubmenuId,
	setIsUserDropdownOpen
}) => {
	const { t } = useTranslation();

	const { isLogged, loginPath, loginClick } = useNavToolbar();

	return (
		<>
			<Item withLine>
				<Institution />
			</Item>

			<Item>
				<SearchButton toggle={toggle} searchRef={searchRef} />
			</Item>

			<Item>
				<Favourites />
			</Item>

			<Item withLine>
				<Reminders />
			</Item>

			<Item>
				{isLogged ? (
					<NavDropdown
						setActiveSubmenuId={setActiveSubmenuId}
						isUserDropdownOpen={isUserDropdownOpen}
						setIsUserDropdownOpen={setIsUserDropdownOpen}
					/>
				) : (
					<StyledButton
						as={Link}
						to={loginPath}
						onClick={loginClick}
						modifiers={['default']}
					>
						{t('common_login_btn_submit')}
					</StyledButton>
				)}
			</Item>
		</>
	);
};

NavToolbar.propTypes = {
	toggle: func.isRequired,
	searchRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
	setActiveSubmenuId: func.isRequired,
	isUserDropdownOpen: bool.isRequired,
	setIsUserDropdownOpen: func.isRequired
};

export default NavToolbar;

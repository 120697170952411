import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import helpers
import { setParametr } from 'helpers';
import { getSearchParams } from 'helpers/movieLists';
import { VOD_PARAMS, QUERY_ORDER, QUERY_ORDER_DIR } from 'helpers/variables';

// Import select options
import { FILTER_SELECT_OPTIONS } from './select_options';

function useFilterSelect() {
	const history = useHistory();
	const { search } = useLocation();
	const { t } = useTranslation();

	// Chnge query param genre on categories page
	const handleRedirectUrl = (query) => {
		const {
			paramsUrl: { genre, type }
		} = getSearchParams(search);

		let orderParam = '';
		let orderDirParam = '';

		if (query.order) {
			orderParam = `&${setParametr(
				VOD_PARAMS.order,
				QUERY_ORDER[query.order]
			)}`;
		}
		if (query.orderDir) {
			orderDirParam = `&${setParametr(
				VOD_PARAMS.orderDir,
				QUERY_ORDER_DIR[query.orderDir]
			)}`;
		}

		history.push(`?${genre}${type}${orderParam}${orderDirParam}`);
	};

	const handleSelectChange = (value) => {
		const selected = FILTER_SELECT_OPTIONS?.find(
			({ name }) => t(name) === value
		);
		handleRedirectUrl(selected?.query);
	};

	return {
		selectChange: handleSelectChange
	};
}

export default useFilterSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { Button } from 'components/elements';
import usePlayerContent from '../usePlayerContent';
import TopHeadline from 'components/elements/organisms/player_vod/PlayerWrapper/TopHeadline';

// Import styles
import { IconPlayStyled } from '../styles';

const TheoPlayerInner = ({ type }) => {
	const { t } = useTranslation();
	const { error, handlePlayPause, showBigPlayButton } = usePlayerContent({
		type
	});

	return (
		<>
			<TopHeadline isError={error.isError} />
			{showBigPlayButton && (
				<Button
					modifiers={['icon', 'catchup', 'playerIconHover', 'playerPlay']}
					onClick={handlePlayPause}
					aria-label={t('common_player_play_title')}
				>
					<IconPlayStyled aria-hidden role="img" />
				</Button>
			)}
		</>
	);
};

TheoPlayerInner.propTypes = {
	type: PropTypes.string.isRequired
};

export default TheoPlayerInner;

import React from 'react';
import { object, string } from 'prop-types';

// Import componets
import {
	BannerContent,
	Title,
	Description
} from 'components/elements/molecules/slider_banners/styles';

// Import styles
import { BannerWrapper } from './styles';

// Import utilities
import { useProductTranslations } from 'components/utilities';

const Info = ({ currentProduct, type }) => {
	const { title, short_desc } = useProductTranslations(currentProduct);

	return (
		<BannerWrapper modifiers={type}>
			<BannerContent modifiers={type}>
				<Title tabIndex={-1}>{title}</Title>
				{short_desc && <Description tabIndex={-1}>{short_desc}</Description>}
			</BannerContent>
		</BannerWrapper>
	);
};

Info.propTypes = {
	currentProduct: object.isRequired,
	type: string.isRequired
};

export default Info;

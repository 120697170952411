import React from 'react';
import { bool, number, object, string } from 'prop-types';

// Import components
import { Heading } from 'components/elements';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';
import { checkProductSubtype, getTileMetadata, joinArray } from 'helpers';

// Import variables
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';

// Import styles
import { Root, Image, TitleTextHeading, TitleTextParagraph } from './styles';

const { TITLE } = PRODUCT_IMAGES_TYPES;

const Title = ({
	images,
	metadata,
	title,
	subtype,
	hideTitle,
	isCarousel = false,
	context,
	isParagraph
}) => {
	const imageUrl = getImage({ images, type: TITLE });

	const isTitleHidden = metadata?.hide_title || hideTitle;
	const titleMetadata = getTileMetadata(context);

	const { isArticle } = checkProductSubtype(subtype);
	const isLightBackground = !isCarousel && isArticle;
	const titleElements = joinArray(
		[titleMetadata, metadata?.title ?? title],
		' '
	);

	const renderTitleText = () =>
		isParagraph ? (
			<TitleTextParagraph isLightBackground={isLightBackground}>
				{titleElements}
			</TitleTextParagraph>
		) : (
			<TitleTextHeading isLightBackground={isLightBackground}>
				{titleElements}
			</TitleTextHeading>
		);

	return (
		<Root imageUrl={imageUrl} isArticle={isArticle}>
			{!!isTitleHidden && (
				<Heading.First modifiers="hidden">
					{metadata?.title ?? title}
				</Heading.First>
			)}
			{
				// Render title image. If image is missing, render title text if it's not deactivated.
				// Otherwise don't render anything
				imageUrl ? (
					<Image imageUrl={imageUrl} />
				) : (
					!isTitleHidden && renderTitleText()
				)
			}
		</Root>
	);
};

Title.propTypes = {
	images: object,
	metadata: object,
	title: string,
	subtype: string,
	hideTitle: number,
	isCarousel: bool,
	context: object,
	isParagraph: bool
};

export default Title;

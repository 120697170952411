import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

// Import actions
import { fetchSearchQuery } from 'store/actions';

// Import helpers
import { createLinkParams } from 'helpers/index';

// Import variables
import { EVENTS_NAMES, KEY_CODES } from 'helpers/variables';
import { MIN_SEARCH_TERM_LENGTH } from './helpers';

// Import utilities
import { useEventListener } from 'components/utilities';

// Import variables
import { KEYBOARD_KEYS } from 'helpers/variables';

const { ARROWDOWN } = KEYBOARD_KEYS;

const { MOUSEDOWN, KEYDOWN } = EVENTS_NAMES;
const { ESCAPE } = KEY_CODES;

export const useComboBoxSearch = ({ isMobileView, toggle, isOpen }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [isSearchResultsClosed, setIsSearchResultsClosed] = useState(false);

	const searchRef = useRef(null);
	const searchResultsRef = useRef(null);

	const dispatch = useDispatch();
	const history = useHistory();

	const debouncedFetch = useRef(
		debounce((value) => {
			value.length >= 3 && fetchSearchQuery(value)(dispatch);
		}, 600)
	);

	const handleSearchTermChange = ({ target: { value } }) => {
		setSearchTerm(value);
		debouncedFetch.current(value);
		isSearchResultsClosed && setIsSearchResultsClosed(false);
	};

	const handleClose = () => {
		if (isMobileView) return;

		setSearchTerm('');
		toggle();
	};

	const handleSelect = ({ item, showAll }) => {
		if (showAll) return history.push(`/szukaj/${searchTerm}`);
		if (!item) return;

		const { uuid, subtype, title, hash } = item;
		const linkParams = createLinkParams({
			id: uuid,
			type: subtype,
			title,
			hash
		});
		history.push(linkParams);
	};

	const handleClickOutside = ({ target }) => {
		if (searchRef.current && !searchRef.current.contains(target)) {
			handleClose();
		}
	};

	const handlePressEscape = ({ keyCode }) => {
		if (keyCode === ESCAPE) {
			const isResultHidden = searchResultsRef?.current?.hidden;

			if (isResultHidden && !isSearchResultsClosed) {
				return setIsSearchResultsClosed(true);
			}

			if (isSearchResultsClosed || searchTerm.length < MIN_SEARCH_TERM_LENGTH) {
				return handleClose();
			}
		}
	};

	const handleInputKeyPress = (event) => {
		if (isOpen && event.key === ARROWDOWN) {
			if (searchResultsRef.current) {
				const firstItem =
					searchResultsRef.current.querySelector('[role="option"]');
				firstItem && firstItem.firstElementChild.focus();
			}
		}
	};

	useEventListener(KEYDOWN, handlePressEscape, document);

	useEffect(() => {
		document.addEventListener(MOUSEDOWN, handleClickOutside);

		return () => {
			document.removeEventListener(MOUSEDOWN, handleClickOutside);
		};
		// eslint-disable-next-line
	}, []);

	return {
		searchTerm,
		searchRef,
		searchResultsRef,
		handleSelect,
		handleClose,
		handleSearchTermChange,
		handleInputKeyPress
	};
};

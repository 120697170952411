import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';
import { usePlayerFocusContext } from 'components/context/player/focus';

const PlayController = () => {
	const { t } = useTranslation();
	const { play, pause, isPaused } = usePlayerTvContext();

	const { isFocus } = usePlayerFocusContext();

	return isPaused ? (
		<StyledButton
			modifiers={['icon', 'playerIconHover']}
			onClick={play}
			isFocus={isFocus}
			aria-label={t('common_player_play_title')}
		>
			<Player.IconPlay aria-hidden role="img" />
		</StyledButton>
	) : (
		<StyledButton
			modifiers={['icon', 'playerIconHover']}
			onClick={pause}
			isFocus={isFocus}
			title={t('common_player_pause_title')}
		>
			<Player.IconPause
				aria-hidden
				role="img"
				title={t('common_player_pause_title')}
			/>
		</StyledButton>
	);
};

const StyledButton = styled(Button)`
	margin-right: 1.5rem;
`;

export default PlayController;

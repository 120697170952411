import React, { useState } from 'react';

const useResize = () => {
	const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

	React.useEffect(() => {
		setViewportHeight(document.body.clientHeight);
		setViewportWidth(document.body.clientWidth);
	}, []);

	return { viewportHeight, viewportWidth };
};

export default useResize;

import styled, { css } from 'styled-components';
import { MenuButton } from '@reach/menu-button';

// Import style utils
import { flex, focusBasic } from 'components/styles';

// Import components
import { Nav } from 'components/elements';

export const NavStyled = styled.nav`
	position: relative;
	${flex({ jc: 'flex-end' })}
	flex: 1;
	flex-wrap: wrap;
	margin: auto 0;

	&:focus-visible {
		border: 0.2rem dotted ${({ theme }) => theme.primaryBlueLight};
	}

	&:focus-visible {
		border: 0.2rem dotted ${({ theme }) => theme.primaryBlueLight};
	}
`;

export const NavButton = styled(MenuButton)`
	text-transform: capitalize;
	background: none;
	border: none;
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 2.4rem;
	color: ${({ theme: { body } }) => body.nav.color};
	font-size: ${({ theme }) => theme.fontSize.nav.link}rem;
	transition: all 0.3s ease-in-out;

	&:hover,
	&:focus-visible {
		color: ${({ theme }) => theme.primaryBlueLight};
	}

	&:focus-visible {
		${focusBasic}
	}

	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);
		height: 2.7rem;
		width: 1px;
		background-color: white;
	}
`;

export const NavListStyled = styled(Nav.List)`
	${flex({ jc: 'flex-end' })}
	flex-wrap: wrap;
`;

export const NavLinkStyled = styled.li`
	margin: 0.75rem 0;

	${({ isMoveSubnav, theme }) => {
		if (isMoveSubnav && !theme.isWCAG) {
			return css`
				&:last-child {
					ul {
						right: 0;
					}
				}
			`;
		}

		if (theme.isWCAG) {
			return css`
				ul {
					width: 35rem;
					margin-left: auto;
					margin-right: auto;
					left: 0;
					right: 0;
					text-align: center;
				}
			`;
		}
	}}
`;

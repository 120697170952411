import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';
import { useDispatch } from 'react-redux';

// Import actions
import { fetchInstitutionsList } from 'store/actions';

// Push notification
import { initializePush } from 'helpers/push-notification';

const MainTemplate = ({ children }) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		initializePush();
		fetchInstitutionsList()(dispatch);
		// eslint-disable-next-line
	}, []);

	return children;
};

MainTemplate.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired
};

export default MainTemplate;

import { useSelector } from 'react-redux';

// Import variables
import { LOGIN_SYSTEM_TYPES } from 'helpers/variables';

const { PUREOTT, VECTRA, FIREBASE } = LOGIN_SYSTEM_TYPES;

/**
 * Custom hook that checks what is user source/type (vectra | pureott | firebase)
 * This information is crutial when selecting prices and payments methods in transaction
 */
const useUserSourceType = () => {
	const { source } = useSelector(({ auth }) => auth.authenticate);

	const isVectraUser = source === VECTRA;
	const isNexGenUser = source === PUREOTT;
	const isFirebaseUser = source === FIREBASE;

	return { userSourceType: source, isVectraUser, isNexGenUser, isFirebaseUser };
};

export default useUserSourceType;

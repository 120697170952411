import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'antd';

// Import icons
import { ReactComponent as IconPlay } from 'assets/icons/ic_play.svg';
import { ReactComponent as BellIcon } from 'assets/icons/new_ic_bell.svg';

// Import styles
import { flex } from 'components/styles';

// Import components
import { Button, ProgramRecordings } from 'components/elements';
import { RemindersMenu } from '../../common/RemindersMenu';
import Share from './share/Share';

// Import actions
import { deleteReminder } from 'store/actions';

// Import helpers
import { checkAppType } from 'helpers';

const PopoverToolbox = ({
	reminderId,
	linkDetails,
	programId,
	isUpcoming = false,
	isCatchup = false,
	isNotEnded = false,
	theme,
	startDate
}) => {
	const { data } = useSelector(({ devices }) => devices);
	const dispatch = useDispatch();
	const { isTvSmart } = checkAppType();

	const recordingConditions = isNotEnded && data.length > 0 && !isTvSmart;

	return (
		<ToolBox>
			{isUpcoming && (
				<Box>
					{reminderId ? (
						<Button onClick={() => deleteReminder(reminderId)(dispatch)}>
							<BellIcon stroke={theme.popover.iconActive} />
						</Button>
					) : (
						<Dropdown
							trigger={['hover']}
							overlay={() => (
								<RemindersMenu programId={programId} startDate={startDate} />
							)}
						>
							<Button>
								<BellIcon stroke={theme.popover.icon} />
							</Button>
						</Dropdown>
					)}
				</Box>
			)}
			{!isUpcoming && (
				<Box isCatchup={isCatchup}>
					<Button as={Link} to={linkDetails}>
						<IconPlayStyled />
					</Button>
				</Box>
			)}
			{recordingConditions && (
				<Box>
					<ProgramRecordings programId={programId} />
				</Box>
			)}
			<Box>
				<Share linkDetails={linkDetails} />
			</Box>
		</ToolBox>
	);
};

const ToolBox = styled.div`
	height: 7rem;
	border-top: 1px solid ${({ theme: { popover } }) => popover.border};
	display: flex;
	justify-content: space-between;
`;

const Box = styled.div`
	flex: 1;
	${flex()}

	&:nth-child(2) {
		border-left: 1px solid ${({ theme: { popover } }) => popover.border};
		border-right: 1px solid ${({ theme: { popover } }) => popover.border};
	}

	svg {
		height: 3rem;
		width: 3rem;
	}

	${({ isCatchup }) =>
		isCatchup &&
		css`
			svg {
				g,
				path {
					fill: ${({ theme }) => theme.orange};
				}
			}
		`}
`;

const IconPlayStyled = styled(IconPlay)`
	g,
	path {
		fill: ${({ theme: { popover } }) => popover.icon};
	}
`;

PopoverToolbox.propTypes = {
	isUpcoming: PropTypes.bool.isRequired,
	programId: PropTypes.string.isRequired,
	linkDetails: PropTypes.shape({
		pathname: PropTypes.string,
		state: PropTypes.shape({ sCatchup: PropTypes.bool }),
		hash: PropTypes.string
	}),
	reminderId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
	isCatchup: PropTypes.bool,
	isNotEnded: PropTypes.bool,
	startDate: PropTypes.string
};

export default withTheme(PopoverToolbox);

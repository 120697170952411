// Import variables
import { LOCAL_STORAGE_PLAYER_VOLUME } from 'helpers/variables';

export const INITIAL_STATE_PLAYER_VOD = {
	isReady: false,
	buffering: false,
	volume: 100,
	profiles: [],
	selectedProfile: 'auto',
	subtitles: [],
	selectedSubtitle: 'disabled',
	languages: [],
	selectedLanguage: 'pl',
	isPaused: false,
	isMuted: false,
	isSiedebarVisible: true,
	isFullScreen: false,
	isEnded: false,
	seekSec: 15,
	duration: 0,
	seekdTime: 0,
	movieEndMargin: 30,
	endMarginAction: false,
	endMarginActionCancel: false,
	videoVariants: [], // video quality variants
	movieSubtitles: [],
	isPreviewAvailable: false,
	selectedProfileBandwidth: '',
	useWatermark: false,
	isCastAvailable: false,
	isCasting: false,
	receiverName: ''
};

export const INITIAL_STATE_PLAYER_TV = {
	isReady: false,
	buffering: true,
	volume: 100,
	profiles: [],
	selectedProfile: 'auto',
	isMuted: false,
	isSiedebarVisible: true,
	isEnded: false,
	isPaused: false,
	currentTime: 0,
	isFullscreen: false,
	isOnline: true,
	playlist: null,
	seekAvailable: false,
	languages: [],
	selectedLanguage: 'pl',
	selectedProfileBandwidth: ''
};

export const TIME_THROTTLE = 600;

export const calculateVideoIntervalTime = (videoSession) => {
	const { till, now } = videoSession;
	const intervalTime = ((till - now) / 2) * 1000;
	return intervalTime;
};

export const setStripes = ({ productID, videoId }) => ({
	url: `https://r.dcs.redcdn.pl/file/o2/Vectra/cdn/public/assets/prod/vod/${productID}/${videoId}/stripes/stripe_{index}.jpg`,
	interval: 30,
	count: 32,
	width: 160,
	height: 90
});

// THEO Player

const commonPlayersElements = {
	bigPlayButton: 'vjs-big-play-button',
	speedSettings:
		'theoplayer-skin li[aria-label="Open the video speed settings menu"]',
	castButton: 'theo-cast-button',
	loadingSpinner: 'vjs-loading-spinner'
};

const livePlayerElements = {
	...commonPlayersElements,
	progressControl: 'vjs-progress-control',
	playButton: 'vjs-play-control',
	liveControl: 'theo-live-control',
	remainingTime: 'vjs-remaining-time',
	controlSpacer: 'vjs-custom-control-spacer',
	currentTime: 'vjs-current-time',
	duration: 'vjs-duration',
	timeDivider: 'vjs-time-divider'
};

const previewPlayerElements = {
	...commonPlayersElements,
	fullscreenControl: 'vjs-fullscreen-control'
};

export const hideTheoPlayerControlls = ({
	isCatchup = false,
	isVodPlayer = false,
	isPreviewPlayer = false
}) => {
	let elements = {};

	if (isCatchup || (isVodPlayer && !isPreviewPlayer)) {
		elements = commonPlayersElements;
	} else if (isPreviewPlayer) {
		elements = previewPlayerElements;
	} else {
		elements = livePlayerElements;
	}

	// hide player controlls
	Object.values(elements).forEach((className) => {
		const element = document.querySelector(`.${className}`);

		if (element) {
			element.parentNode.removeChild(element);
		}
	});
};

export const getVideoSource = ({
	playlistData: {
		sources: { HLS }
	}
}) => {
	return {
		sources: {
			src: HLS[0].src,
			type: 'application/x-mpegURL'
		}
	};
};

export const getManifestUri = ({ isSafari, hls, dash }) => {
	if (isSafari && hls?.length) {
		return hls[0]?.src ?? '';
	} else if (dash && dash?.length) {
		return dash[0]?.src ?? '';
	} else {
		return '';
	}
};

export const getUserConfigVolume = () => {
	const config = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PLAYER_VOLUME));

	if (config) {
		const { volume, isMuted } = config;
		const volumeConfig = isMuted ? 0 : volume;
		return { isMuted, volume: volumeConfig };
	}

	const { volume, isMuted } = INITIAL_STATE_PLAYER_VOD;
	return { volume, isMuted };
};

export const setUserConfigVolume = (config = {}) => {
	const localStorageConfig = JSON.parse(
		localStorage.getItem(LOCAL_STORAGE_PLAYER_VOLUME)
	);
	const storageConfig = localStorageConfig ?? {};
	const options = {
		...storageConfig,
		...config
	};
	localStorage.setItem(LOCAL_STORAGE_PLAYER_VOLUME, JSON.stringify(options));
};

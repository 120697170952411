import React from 'react';
import { useTranslation } from 'react-i18next';
import VisuallyHidden from '@reach/visually-hidden';
import { string, number, node, bool, func, object } from 'prop-types';

// Import components
import PaymentOptionPrice from './PaymentOptionPrice';
import { Heading } from 'components/elements';

// Import styles
import {
	Container,
	Name,
	Bottom,
	ArrowButton,
	FlexContainer,
	IconArrowRight
} from './styles';

// Import variables
import { GATEWAY_TYPES } from 'helpers/prices';

// Import helpers
import { getUserAgentInfo } from 'helpers';

const { OTHER_BLUEMEDIA } = GATEWAY_TYPES;

const PaymentOption = ({
	price,
	paymentName,
	children,
	addBottomWrapper,
	promotion,
	type,
	onButtonClick,
	isDisabled
}) => {
	const { t } = useTranslation();
	const { isMobile } = getUserAgentInfo();

	const isOtherBluemedia = type === OTHER_BLUEMEDIA;

	const wcagPaymentButtonTitle = t(
		`common_payment_option_rwd_button_hidden_${type}`
	);

	return (
		<Container>
			{isMobile ? (
				<>
					{!isOtherBluemedia && (
						<Heading.Third modifiers="hidden">{paymentName}</Heading.Third>
					)}
				</>
			) : (
				<Name id={type} isDesktop>
					{paymentName}
				</Name>
			)}

			{isMobile ? (
				<>
					{addBottomWrapper ? <Bottom>{children}</Bottom> : children}
					<FlexContainer isOtherBluemedia={isOtherBluemedia}>
						{isOtherBluemedia && <Name isDesktop={false}>{paymentName}</Name>}
						<PaymentOptionPrice price={price} promotion={promotion} />
						<ArrowButton disabled={isDisabled} onClick={onButtonClick}>
							<VisuallyHidden>{wcagPaymentButtonTitle}</VisuallyHidden>
							<IconArrowRight />
						</ArrowButton>
					</FlexContainer>
				</>
			) : (
				<>
					<FlexContainer isOtherBluemedia={isOtherBluemedia}>
						{isOtherBluemedia && <Name isDesktop={false}>{paymentName}</Name>}
						<PaymentOptionPrice price={price} promotion={promotion} />
						<ArrowButton disabled={isDisabled} onClick={onButtonClick}>
							<VisuallyHidden>{wcagPaymentButtonTitle}</VisuallyHidden>
							<IconArrowRight />
						</ArrowButton>
					</FlexContainer>
					{addBottomWrapper ? <Bottom>{children}</Bottom> : children}
				</>
			)}
		</Container>
	);
};

PaymentOption.defaultProps = {
	price: null,
	paymentName: '',
	addBottomWrapper: true,
	onButtonClick: () => {},
	isDisabled: false
};

PaymentOption.propTypes = {
	price: number,
	paymentName: string.isRequired,
	children: node.isRequired,
	addBottomWrapper: bool,
	type: string.isRequired,
	onButtonClick: func.isRequired,
	isDisabled: bool.isRequired,
	promotion: object
};

export default PaymentOption;

import styled, { css } from 'styled-components';

// Import styles
import { flex, customMinResponsive, size } from 'components/styles';

// Import icons
import { ReactComponent as IconBluemediaSVG } from 'assets/icons/ic_bluemedia.svg';
import { ReactComponent as IconVisaSVG } from 'assets/icons/ic_visa.svg';
import { ReactComponent as IconMastercardSVG } from 'assets/icons/ic_mastercard.svg';
import { ReactComponent as IconBlikSVG } from 'assets/icons/ic_blik.svg';
import { ReactComponent as IconWalletSVG } from 'assets/icons/ic_wallet.svg';

export const IconsWrapper = styled.div`
	${flex()}

	${customMinResponsive(
		size.medium,
		css`
			margin-bottom: 3rem;
		`
	)}
`;

const baseIconStyle = css`
	pointer-events: visible;
`;

export const IconMastercard = styled(IconMastercardSVG)`
	${baseIconStyle}
	margin: 0 1rem;
`;

export const SmallIconVisa = styled(IconVisaSVG)`
	${baseIconStyle}
	margin-right: 1.4rem;
	height: 1.2rem;
	width: 4.1rem;

	${customMinResponsive(
		size.medium,
		css`
			margin-right: 2.5rem;
			width: 9rem;
			height: 3rem;
		`
	)}
`;

export const SmallIconMastercard = styled(IconMastercardSVG)`
	${baseIconStyle}
	height: 1.9rem;
	width: 2.4rem;

	${customMinResponsive(
		size.medium,
		css`
			width: 4.4rem;
			height: 3.6rem;
		`
	)}
`;

export const IconBlik = styled(IconBlikSVG)`
	${baseIconStyle}
	height: 1.6rem;
	width: 3.2rem;

	${customMinResponsive(
		size.medium,
		css`
			width: 5.8rem;
			height: 3rem;
		`
	)}
`;

export const IconBluemedia = styled(IconBluemediaSVG)`
	${baseIconStyle}
	height: 2rem;
	width: 3.2rem;

	${customMinResponsive(
		size.medium,
		css`
			width: 5.9rem;
			height: 3.7rem;
		`
	)}
`;

export const IconWallet = styled(IconWalletSVG)`
	width: 2.75rem;
	height: 2.45rem;

	${customMinResponsive(
		size.medium,
		css`
			width: 5.58rem;
			height: 5rem;
		`
	)}
`;

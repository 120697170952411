import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { AriaBaseModal, AriaModalContent, Loader } from 'components/elements';
import PassTile from './PassTile';
import PassCarousel from './PassCarousel';
import usePassesContext from 'components/context/passes/usePassesContext';

const UsePassModal = ({ isOpened, toggle }) => {
	const { t } = useTranslation();
	const { availablePasses, hasAvailablePasses, purchaseLoading } =
		usePassesContext();
	const handleModalClose = () => !purchaseLoading && toggle();

	const titleId = 'pass_use_modal_title';

	if (!isOpened || !hasAvailablePasses) return null;
	return (
		<AriaBaseModal
			titleId={titleId}
			close={handleModalClose}
			style={{ padding: '6.8rem 11.2rem', width: 'auto', marginTop: '10vh' }}
			centerModalContent={false}
		>
			<AriaModalContent title={t('pass_use_modal_title')} titleId={titleId}>
				<PassCarousel>
					{availablePasses?.map((product) => (
						<PassTile
							isPassAvailable
							key={product?.uuid}
							disabled={purchaseLoading}
							{...product}
						/>
					))}
				</PassCarousel>
				{purchaseLoading && <Loader background={false} position="fixed" />}
			</AriaModalContent>
		</AriaBaseModal>
	);
};

UsePassModal.propTypes = {
	isOpened: PropTypes.bool,
	toggle: PropTypes.func
};

export default UsePassModal;

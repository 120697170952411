import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

//Import styles
import {
	customMinResponsive,
	customResponsive,
	size,
	flex
} from 'components/styles';

// Import components
import { Button } from 'components/elements';

const SECTION_MODIFIERS = {
	sectionsList: () => css`
		padding: 0 0 0 1.8rem;

		&:not(:last-child) {
			margin-bottom: 3.4rem;
		}

		${customMinResponsive(
			size.medium,
			css`
				padding: 0 0 0 4rem;

				&:not(:last-child) {
					margin-bottom: 6rem;
				}
			`
		)}
	`,
	carousel: () => css`
		z-index: 3;
		padding-bottom: 3.3rem;
		${customMinResponsive(
			size.medium,
			css`
				padding-bottom: 6.4rem;
			`
		)}
	`,
	collection: () => css`
		padding-bottom: 3.3rem;
		${customMinResponsive(
			size.medium,
			css`
				padding-bottom: 6.4rem;
			`
		)}
	`
};

export const Wrapper = styled.section`
	position: relative;
	width: 100%;
	background-color: transparent;
	margin: ${({ theme }) => (theme.isWCAG ? '8rem auto' : '0 auto')};
	background: ${({ backgroundImage }) =>
		backgroundImage
			? `url(${backgroundImage}) no-repeat center center`
			: 'none'};
	background-size: ${({ backgroundImage }) =>
		backgroundImage ? 'cover' : 'none'};
	z-index: 2;

	${applyStyleModifiers(SECTION_MODIFIERS)};
`;

export const Title = styled.h2`
	font-size: ${({ theme }) => theme.fontSize.xs}rem;
	line-height: 2.6rem;
	margin-bottom: 1.8rem;
	margin-top: 0 !important;
	color: ${({ theme }) => theme.white};

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.lg}rem;
			line-height: 3.6rem;
			margin-bottom: ${({ theme }) => (theme.isWCAG ? 8 : 4.2)}rem !important;
			margin-bottom: 4.2rem;
		`
	)}
`;

export const SliderHeader = styled.div`
	${flex({ jc: 'space-between', ai: 'start' })};
	padding-right: 1rem;

	${({ theme }) =>
		theme.isWCAG &&
		css`
			flex-direction: column;
			margin-bottom: 8rem;
		`}

	${customMinResponsive(
		size.medium,
		css`
			padding-right: 4rem;
		`
	)}
`;

export const ShowAllButton = styled(Button)`
	padding: 0.5rem 3.5rem;
	${({ theme }) => theme.isWCAG && 'align-self: flex-end'};

	${customResponsive(
		size.medium,
		css`
			padding: 0.5rem 1.5rem;
		`
	)}

	${({ isDesktop }) =>
		!isDesktop &&
		css`
			padding: 0;
			color: ${({ theme }) => theme.primaryBlueLight};
		`}
`;

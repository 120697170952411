import * as polished from 'polished';

// Import styles
import { colors } from './colors';
import fonts from '../fonts';
import { buttons } from './buttons';
import { pureOttTheme } from 'components/theme/pure_ott_theme/pureOttTheme';

export const pureCkfTheme = ({ isContrast }) => {
	// Set globally colors related to contrast (accessibility)
	if (!isContrast) {
		colors.red = '#C8483B';
		colors.grayLight = '#D5D4D3';
		colors.graySemiLight = '#BDBABA';
	} else {
		colors.red = colors.contrastRed;
		colors.grayLight = colors.white;
		colors.graySemiLight = colors.white;
	}

	return {
		isContrast,
		...pureOttTheme,
		...colors,
		...fonts,
		...buttons,

		contrast: {
			// first setup for dark text color
			primaryBg: colors.primaryBlueLight,
			primaryBgFocus: colors.white,
			primaryColor: colors.black,
			// second setup for white text color
			secondaryBg: colors.primaryBlueDark,
			secondaryBgFocus: colors.black,
			secondaryColor: colors.white
		},
		button: {
			primaryVod: {
				color: colors.black,
				bgColor: 'rgba(255, 255, 255, 0.7)'
			},
			parental: {
				bgColor1: '#3182b5',
				bgColor2: '#2f599c'
			}
		},
		globalButtons: {
			primary: {
				color: colors.black,
				bgColor: colors.white,
				borderColor: colors.white,
				hoverBorderColor: colors.primaryBlueLight,
				focusBgColor: colors.primaryBlueLight
			},
			secondary: {
				color: colors.black,
				bgColor: colors.grayButton,
				borderColor: colors.grayButton,
				hoverBorderColor: colors.primaryBlueLight,
				focusBgColor: colors.primaryBlueLight
			}
		},
		body: {
			bg: {
				primary: colors.primaryBgBlack,
				secondary: colors.secondaryBgDarkBrown,
				third: '#edeced'
			},
			header: {
				bgOnMobile: colors.primaryBgBlack,
				bgOnDesktop: colors.primaryBgBlack,
				icon: colors.white,
				iconActive: colors.primaryBlueLight,
				separateLine: colors.white,
				heightOnMobile: 13,
				heightOnDesktop: 15.2
			},

			nav: {
				color: polished.rgba(colors.white, 0.8),
				menuActiveText: colors.white,
				heightOnMobile: 7,
				heightOnDesktop: 9.2
			},
			mobileNav: {
				color: colors.white,
				colorFocus: colors.primaryBlueLight,
				bg: colors.primaryBgBlack,
				bgActive: polished.rgba(colors.white, 0.2)
			},
			disabilityNav: {
				height: 6,
				textColor: colors.white,
				bgColor: colors.darkBrown
			},
			footer: {
				color: colors.white,
				bg: colors.gray,
				height: 53,
				link: colors.white,
				hover: isContrast
					? colors.primaryBlueVeryLight
					: colors.primaryBlueLight
			}
		},
		general: {
			link: colors.primaryBlueLight,
			linkHover: colors.white,
			error: {
				defaultColor: colors.red,
				contrastColor: colors.redSecondary,
				lightBackgroundColor: isContrast
					? colors.lightBackgroundContrastRed
					: colors.red
			}
		},
		line: 'rgba(255, 255, 255, 0.2)',
		userProfileDropdown: {
			textColor: colors.grayLight,

			borderColor: colors.grayLight,
			selectBgColor: colors.transparent,
			listBg: '#23201d',
			selectedItemBg: 'rgba(255,255,255,0.2)'
		},
		dropdown: {
			button: {
				bg: colors.blackBg,
				color: colors.graySemiLight,
				colorHover: colors.primaryBlueLight
			},
			menu: {
				bg: colors.blackBg,
				itemColor: colors.white,
				itemHoverColor: colors.primaryBlueLight,
				itemHoverBg: 'rgba(18, 16, 11, 0.7)',
				colorHover: colors.white
			},
			submenu: {
				textColor: colors.white,
				textColorOnHover: colors.primaryBlueLight,
				bgColor: colors.blackBg
			}
		},
		listBox: {
			button: {
				bg: colors.transparent,
				border: colors.grayLight,
				borderHover: colors.primaryBlueLight,
				color: colors.grayLight,
				colorHover: colors.primaryBlueLight
			},
			menu: {
				bg: colors.blackBg,
				border: colors.gray
			},
			option: {
				color: colors.graySemiLight,
				colorHover: colors.primaryBlueLight,
				colorSelected: colors.white,
				border: colors.gray,
				borderHover: colors.primaryBlueLight
			},
			error: {
				color: colors.red,
				border: colors.red
			},
			label: {
				color: colors.grayLight,
				colorActive: colors.white,
				bg: colors.primaryBgBlack
			},
			group: {
				border: colors.gray,
				titleColor: colors.white
			}
		},

		sidebar: {
			bg: colors.blackBg,
			itemHoverBg: 'rgba(255, 255, 255, 0.05)',
			removeIcon: colors.white,
			removeIconHover: colors.primaryBlueLight,
			closeIcon: colors.white,
			closeIconHover: colors.primaryBlueLight
		},
		navSearch: {
			bg: 'transparent',
			inputBg: 'transparent'
		},
		navSearchCloseIcon: colors.white,
		modal: {
			bg: colors.blackBg,
			titleColor: colors.white,
			link: colors.primaryBlueLight,
			linkHover: colors.white
		},
		authentication: {
			contentBg: 'transparent',
			headingColor: colors.white,
			infoColor: colors.grayLight,
			linkColor: colors.primaryBlueLight,
			linkHoverColor: colors.white,
			lineColor: colors.primaryBlueLight,
			labelBgOnDesktop: colors.secondaryBgDarkBrown,
			labelBgOnMobile: colors.primaryBgBlack,
			agreements: {
				color: colors.grayLight,
				checkboxBorderColor: colors.graySemiLight,
				checkboxIconColor: colors.graySemiLight,
				obligatoryPrefixColor: colors.grayLight
			},
			input: {
				color: colors.grayLight,
				borderColor: colors.grayLight,
				focusBorderColor: colors.primaryBlueLight
			},
			calendar: {
				calendarBg: colors.secondaryBgDarkBrown,
				calendarBorderColor: colors.grayLight,
				tileHoverColor: colors.gray,
				tileActiveColor: colors.primaryBlueLight,
				tileDisabledColor: 'transparent',
				tileDisabledTextColor: colors.gray,
				todayTextColor: colors.primaryBlueLight
			},
			//LOGIN TABS TO REMOVE
			loginTabs: {
				color: colors.white,
				checkedColor: colors.white,
				borderColor: colors.white
			}
		},
		user: {
			line: 'rgba(255,255,255,0.2)',
			topHeading: colors.white,
			topHeadingFilter: colors.graySemiLight,
			tableTitle: colors.graySemiLight,
			tableItemName: colors.white,
			tableItem: colors.white,
			itemRed: colors.red,
			info: colors.graySemiLight,
			tableItemHover: colors.primaryBlueLight,
			menuText: colors.graySemiLight,
			menuContrastText: colors.grayButton,
			menuBG: colors.transparent,
			menuActiveText: colors.primaryBlueLight,
			menuVisitedText: colors.white,
			menuActiveBg: colors.transparent,
			showChannel: colors.graySemiLight,
			removeIcon: colors.white,
			removeIconHover: colors.white,
			tags: {
				vodInProfile: colors.blueDark
			},
			productItem: {
				title: colors.white,
				info: '#aaadb2'
			},
			agreements: {
				description: colors.graySemiLight
			}
		},
		payment: {
			background: colors.blackBg,
			oldPrice: polished.rgba(colors.black, 0.55),
			agreements: {
				link: colors.primaryBlueLight,
				linkHover: polished.rgba(colors.primaryBlueLight, 0.7)
			},
			verification: {
				spinner: {
					gradient:
						'conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box'
				}
			},
			paymentOptions: {
				borderColor: 'rgba(237, 236, 237, 0.15)',
				borderColorMobile: polished.rgba(colors.white, 0.2),
				paragraph: {
					color: colors.grayLight
				},
				box: {
					bg: colors.blackBg,
					color1: 'rgb(17, 44, 74)',
					color2: '#3a5e82'
				},
				name: {
					color: colors.white
				},
				info: {
					color: colors.grayLight,
					fontWeight: fonts.font.normal
				},
				price: {
					color: colors.graySemiLight,
					fontWeight: fonts.font.normal,
					opacity: '0.7'
				},
				paymentField: {
					labelColor: colors.graySemiLight,
					color: colors.white,
					placeholderColor: colors.graySemiLight,
					borderColor: colors.graySemiLight,
					errorBorderColor: colors.red
				}
			},
			voucher: {
				borderColor: colors.grayLight,
				color: isContrast ? colors.white : colors.grayLight,
				submit: { background: '#b8b7b6', color: colors.black }
			},
			emailNotConfirmed: {
				headingColor: colors.white,
				paragraph: {
					color: colors.white,
					fontWeight: fonts.font.light
				}
			}
		},
		searchBox: {
			bg: colors.blackBg,
			itemHoverBg: 'rgba(18, 16, 11, 0.8)',
			itemInfo: colors.graySemiLight,
			itemInfoHover: colors.white,
			itemTitleHover: colors.primaryBlueLight
		},
		heading: {
			first: {
				white: colors.white,
				primary: colors.white
			}
		},
		toggler: {
			bg: colors.graySemiLight,
			bgActive: colors.primaryBlueLight,
			control: isContrast ? colors.blackBg : colors.white,
			userApproval: colors.blackBg
		},

		dashedLine: colors.grayMediumDarkTheme,
		loader: {
			loaderBg: '#000000b3',
			colorStart: colors.primaryBlueLight,
			colorMiddle: colors.white,
			colorEnd: colors.primaryBlueLight
		},

		activeGradientLine: {
			colorStart: colors.primaryBlueLight,
			colorEnd: colors.primaryBlueLight
		},
		popover: {
			bg: colors.blackBg,
			border: colors.graySemiLight,
			icon: colors.graySemiLight,
			iconActive: colors.primaryBlueLight
		},

		channels: {
			gridItemLinkHover: colors.white
		},
		epg: {
			grayLight: '#fbfafc',
			bg: '#151d29',
			bgHoverColorStart: colors.primaryBlueLight,
			bgHoverColorEnd: colors.primaryBlueLight,
			gridItemShadow: '#080C12',
			gridItemCategory: '#95A1AF',
			catchup: {
				bg: 'linear-gradient(to right, #2b323e 0, #2b323e)',
				bgHover: 'linear-gradient(to right, #f8a030 0, #e36845)'
			},
			calendarHover: colors.white
		},
		programDetails: {
			heading: colors.white,
			paragraph: '#e6e3df',
			name: '#808181',
			table: {
				row: {
					bg: '#161e29',
					hoverBg: '#224BA1'
				}
			}
		},
		allMovies: {
			breadcrumbs: {
				color: 'rgba(255, 255, 255, 0.7)'
			},
			menuButton: {
				color: '#636363'
			}
		},
		slider: {
			arrow: {
				color: '#95A1AF',
				sliderControllerBgColor: 'transparent',
				sliderControllerBgHoverColor: 'rgba(33, 64, 154, 0.8)'
			},
			available: {
				bgColorStart: '#46abe6',
				bgColorEnd: '#3562a5'
			}
		},
		videoBanner: {
			bg: colors.primaryBgBlack,
			fog: {
				bg: `linear-gradient(0deg, rgba(7, 6, 6, 0.55), rgba(7, 6, 6, 0.55)),
				radial-gradient(
					77.93% 77.93% at 1.25% 14.92%,
					rgba(0, 0, 0, 0.702032) 0%,
					rgba(0, 0, 0, 0.0001) 100%
				)`
			},
			buttons: {
				bg: colors.primaryBgBlack,
				bgHover: colors.primaryBlueLight,
				border: colors.white
			}
		},

		landingPage: {
			registrationButton: {
				color: colors.white,
				bgContainerColor: '#31549b',
				borderColor: '#313131',
				bgDefault: {
					color1: '#31549b',
					color2: colors.blueAzureDark
				},
				bgHover: {
					color1: colors.blueAzureDark,
					color2: '#31549b'
				}
			},
			devices: {
				itemColor: colors.blueAzure
			}
		},
		product: {
			buttons: {
				primary: colors.white,
				secondary: '#B4B4B4'
			},
			remindersMenu: {
				background: '#12100b',
				borderColor: 'rgba(255, 255, 255, 0.15)'
			},
			lightBackground: {
				color: '#12100B',
				background: '#EDECED',
				link: '#12100B',
				linkHover: isContrast ? colors.primaryBlueDark : colors.primaryBlueLight
			},
			coverBackground: {
				color: colors.white,
				background: colors.primaryBgBlack,
				link: colors.white,
				linkHover: colors.primaryBlueLight
			},
			articleBlackout: {
				background: '#d8d6d7'
			},
			gallery: {
				footer: {
					color: '#B3B3B3'
				}
			}
		},
		cookies: {
			background: '#12100b',
			borderColor: 'rgba(237, 236, 237, 0.15)',
			color: colors.white
		},
		nestedNav: {
			menuText: colors.graySemiLight,
			menuActiveText: colors.primaryBlueLight
		},
		institution: {
			social: {
				hover: colors.primaryBlueLight
			}
		},
		cast: {
			receiver: {
				color: '#99999a'
			}
		},
		mobileSearchSelect: {
			color: colors.grayLight,
			activeColor: colors.white,
			hoverColor: colors.white,
			selectColor: colors.primaryBlueLight,
			bgColor: colors.blackBg,
			hoverBgColor: colors.blackBg,
			borderColor: polished.rgba(colors.white, 0.15),
			focusBorderColor: colors.primaryBlueLight
		},
		packetsModal: {
			itemRoundSize: 0.6,
			itemImageGradient: `linear-gradient(0deg, ${polished.rgba(
				colors.black,
				isContrast ? 0.8 : 0.6
			)}, ${polished.rgba(colors.black, isContrast ? 0.8 : 0.6)})`,
			itemDescription: colors.white
		}
	};
};

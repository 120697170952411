import * as types from 'store/actions/types';
import services from 'services/services';
import i18n from 'i18next';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import { createQueryParams } from 'helpers';

// Import api
import { getProductPurchaseApi } from 'helpers/api';

// ******************** SUBMIT PRODUCT PURCHASE ********************
export const submitProductPurchase =
	(
		{
			pin = '',
			productId,
			priceId,
			promotion = null,
			paymentMethod,
			user_payment_data_id = null,
			gateway_type = null,
			update_subscription,
			need_invoice = false,
			client = null,
			authorization_code = null,
			pass_uuid = null
		},
		isPass = false
	) =>
	async (dispatch) => {
		try {
			// Dispatch an loading action
			dispatch({
				type: types.PURCHASE_LOADING,
				payload: true
			});

			const url = getProductPurchaseApi({ paymentMethod, productId, priceId });

			const query = createQueryParams({
				language: i18n.language,
				promotion_id: promotion?.id,
				context: 'out'
			});

			const body = {
				need_invoice,
				client,
				update_subscription,
				user_payment_data_id,
				data: {
					pin,
					code: promotion?.voucherCode ?? '',
					gateway_type,
					authorization_code
				},
				pass_uuid
			};

			const { data } = await services.post(`${url}${query}`, body);

			// Dispatch an action
			dispatch({
				type: types.PURCHASE_SUCCESS,
				payload: { data, paymentMethod, isPass }
			});
		} catch (error) {
			if (error.response) {
				const { codeErrorMessage } = getErrorCodeResults(error);

				// Dispatch an error action
				error.response.data.message = codeErrorMessage;
			}

			// Dispatch an error action
			dispatch({
				type: types.PURCHASE_ERROR,
				payload: { error, isPass }
			});
		}
	};

export const purchaseSuccess = (payload) => (dispatch) => {
	dispatch({
		type: types.PURCHASE_SUCCESS,
		payload
	});
};

export const clearPurchase = (dispatch) =>
	dispatch({ type: types.PURCHASE_CLEAR });

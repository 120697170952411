import { useLocation, useParams } from 'react-router-dom';

// Import helpers
import { getDetailsResources, getGenreIdFromUrl } from 'helpers';

const useQueryUrlDetails = ({ sortingName, genresList }) => {
	const { institutionUuid } = useParams();
	const { search: locationSearch } = useLocation();

	const { genre, order, orderDir, type, sortName } = getDetailsResources({
		locationSearch,
		sortingName
	});

	const genreId = getGenreIdFromUrl({ locationSearch, genresList });

	return {
		genre,
		order,
		orderDir,
		type,
		sortName,
		genreId,
		institutionUuid
	};
};

export default useQueryUrlDetails;

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import icons
import { ReactComponent as BlueMediaIcon } from 'assets/icons/bluemedia.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/visa.svg';
import { ReactComponent as MasterIcon } from 'assets/icons/mc.svg';
import { ReactComponent as BlikIcon } from 'assets/icons/blik.svg';

// Import styles
import { Wrapper } from './styles';

const PaymentInfo = () => {
	const { t } = useTranslation();
	return (
		<Wrapper role="list">
			<li>
				<BlueMediaIcon aria-label={t('common_payments_bluemedia')} role="img" />
			</li>
			<li>
				<VisaIcon aria-label={t('common_payments_visa_card')} role="img" />
			</li>
			<li>
				<MasterIcon aria-label={t('common_payments_master_card')} role="img" />
			</li>
			<li>
				<BlikIcon aria-label={t('common_payments_blik')} role="img" />
			</li>
		</Wrapper>
	);
};

export default PaymentInfo;

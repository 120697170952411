import React from 'react';
import { useSelector } from 'react-redux';
import { string, shape } from 'prop-types';

// Import styled components
import { StyledMenuItem } from '../common_styles';

// Import components
import MenuLink from './MenuLink';

// Import helpers
import { getLinkDetails } from 'components/layout/nav/helpers';

const SubMenuItem = ({ sublink, basePath, ...props }) => {
	const genresList = useSelector(({ genresList }) => genresList.data);

	const { link, name, model_id, model_type } = sublink;

	const { path, isInternalLink } = getLinkDetails({
		modelId: model_id,
		modelType: model_type,
		name,
		basePath,
		genresList
	});

	return (
		<StyledMenuItem {...props}>
			<MenuLink
				isInternalLink={!!isInternalLink}
				path={path}
				link={link}
				name={name}
			/>
		</StyledMenuItem>
	);
};

SubMenuItem.propTypes = {
	sublink: shape({
		name: string.isRequired,
		link: string.isRequired,
		model_id: string,
		model_type: string
	}).isRequired,
	basePath: string.isRequired
};

export default SubMenuItem;

import { PRODUCTS_TYPES } from 'helpers/variables';
const { VOD, AUDIO, ALL } = PRODUCTS_TYPES;

// show genres selector in 'all products view' (sections and categories)
export const PRODUCTS_WITH_GENRES = [VOD, AUDIO, ALL];

const PRODUCTS = 'products';

const addTypeToQuery = (url, type, isSearch = false) => {
	if (!url.includes(PRODUCTS) || isSearch) {
		return type ? `&type=${type}` : '';
	}
	return '';
};

export const getQueries = ({ filter, url, searchQuery = null }) => {
	const { offset, limit, order, orderDir, genre, type, institutionUuid } =
		filter;

	let query = searchQuery
		? `?q=${searchQuery}&limit=${limit}&offset=${offset}`
		: `?limit=${limit}&offset=${offset}`;

	const sendGenres = PRODUCTS_WITH_GENRES.includes(type);

	if (order && orderDir) {
		query += `&order=${order}&orderDir=${orderDir}`;
	}
	if (sendGenres && genre && genre !== 'all') {
		query += `&genre=${genre}`;
	}
	if (type !== ALL) {
		if (type === AUDIO || type === VOD) {
			const queryType = addTypeToQuery(url, VOD, searchQuery);
			const isAudio = type === AUDIO ? 1 : 0;
			query += `${queryType}&is_audio=${isAudio}`;
		} else {
			query += `${addTypeToQuery(url, type, searchQuery)}`;
		}
	}

	if (institutionUuid) {
		query += `&products_group_uuid=${institutionUuid}`;
	}

	return query;
};

import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

const Grid = styled.div`
	display: grid;
	grid-gap: 0rem;
	grid-template-columns: ${({ columnsMobile = '1fr' }) => columnsMobile};
	grid-auto-rows: auto;
	${customMinResponsive(
		size.medium,
		css`
			grid-template-columns: ${({ columns = '1fr' }) => columns};
		`
	)}
`;

const Row = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: ${({
		columnsMobile = 'repeat(auto-fit, minmax(0px, 1fr))'
	}) => columnsMobile};
	grid-template-rows: ${({ rows = '7.2rem' }) => rows};
	font-weight: ${({ theme }) => theme.font.medium};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	color: ${({ theme: { user } }) => user.tableItem};
	transition: 0.1s ease-in-out;
	border-bottom: 1px solid ${({ theme: { user } }) => user.line};
	padding: 0 2rem;
	${customMinResponsive(
		size.medium,
		css`
			grid-template-columns: ${({
				columns = 'repeat(auto-fit, minmax(0px, 1fr))'
			}) => columns};
			padding: 0;
		`
	)}
`;

const Header = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: ${({
		columnsMobile = 'repeat(auto-fit, minmax(0px, 1fr))'
	}) => columnsMobile};
	grid-template-rows: ${({ rows = '3rem' }) => rows};
	font-weight: ${({ theme }) => theme.font.normal};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
	color: ${({ theme }) => theme.white};
	transition: 0.1s ease-in-out;
	padding: 1.2rem 2rem;
	border-bottom: 1px solid ${({ theme: { user } }) => user.line};
	${customMinResponsive(
		size.medium,
		css`
			padding: 0 0 1.2rem 0;
			font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
			grid-template-columns: ${({
				columns = 'repeat(auto-fit, minmax(0px, 1fr))'
			}) => columns};
		`
	)}
`;

const Item = styled.span`
	&:nth-child(3) {
		display: none;
	}
	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
			&:nth-child(3) {
				display: initial;
			}
		`
	)}
`;

export { Grid, Row, Header, Item };

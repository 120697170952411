import { useTranslation } from 'react-i18next';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const useLanguagesItem = ({ language }) => {
	const { t } = useTranslation();
	const { setLanguage, selectedLanguage } = usePlayerVodContext();

	const handleSetLanguage = () => setLanguage(language);

	const isSelected = language === selectedLanguage;

	const ariaText = `${t('aria_player_settings_audio_language')} ${language}`;

	return { isSelected, ariaText, setLanguage: handleSetLanguage };
};

export default useLanguagesItem;

import styled, { css } from 'styled-components';

// Import utilities
import {
	customMinResponsive,
	customResponsive,
	size,
	focusBasic
} from 'components/styles';

export const Image = styled.img`
	width: 9.2rem;
	height: 4.6rem;
	object-fit: contain;

	${customMinResponsive(
		size.medium,
		css`
			width: 12rem;
			height: 6rem;
		`
	)}

	${customMinResponsive(
		size.large,
		css`
			width: 16.6rem;
			height: 8.8rem;
		`
	)}
`;

export const StyledAnchor = styled.a`
	&:focus-visible {
		${focusBasic}
	}

	${({ theme }) =>
		theme.isWCAG &&
		customResponsive(
			size.medium,
			css`
				margin: 5rem 0;
			`
		)}
`;

import React from 'react';
import { array, object, string, number } from 'prop-types';

// Import components
import GallerySlider from './GallerySlider';
import GalleryFullscreen from './Fullscreen/GalleryFullscreen';

// Import context
import GalleryProvider, { GalleryContext } from '../context/GalleryProvider';

function Gallery({ assets, metadata, type, id }) {
	return (
		<GalleryProvider
			assets={assets}
			metadata={metadata}
			type={type}
			galleryId={id}
		>
			<GalleryContext.Consumer>
				{({ showFullscreen }) => (
					<>
						<GallerySlider />
						{showFullscreen && <GalleryFullscreen />}
					</>
				)}
			</GalleryContext.Consumer>
		</GalleryProvider>
	);
}

Gallery.propTypes = {
	assets: array.isRequired,
	metadata: object.isRequired,
	type: string.isRequired,
	id: number
};

export default Gallery;

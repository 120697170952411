import React, { useEffect } from 'react';
import { func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { AriaBaseModal, AriaModalContent } from 'components/elements';
import { CommonButton } from 'components/views/vod/components/buttons';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import helpers
import { getTimeLineFormat } from 'helpers';

const Resume = React.memo(
	({ currentTime, toggle }) => {
		const { t } = useTranslation();
		const { play, pause, restart, updateTime } = usePlayerVodContext();

		useEffect(() => {
			pause();
			// eslint-disable-next-line
		}, []);

		const handleResume = (callback) => () => {
			callback && callback(currentTime);
			play();
			toggle();
		};

		const time = getTimeLineFormat(currentTime);

		const title = `${t('player_continue_watching_modal_title')} ${time}?`;
		const titleId = 'player_continue_watching_modal_title';

		return (
			<AriaBaseModal
				titleId={titleId}
				close={handleResume()}
				style={{ width: '35vw', minWidth: '28rem' }}
			>
				<AriaModalContent title={title} titleId={titleId}>
					<CommonButton onClick={handleResume(restart)}>
						{t('player_remuse_btn_restart')}
					</CommonButton>
					<CommonButton onClick={handleResume(updateTime)} isPrime>
						{t('player_remuse_btn_remuse')}
					</CommonButton>
				</AriaModalContent>
			</AriaBaseModal>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

Resume.propTypes = {
	currentTime: number.isRequired,
	toggle: func.isRequired
};

export default Resume;

import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import { ButtonStyled } from './styles';

// Import components
import { PlayerElements as Player } from 'components/elements';

const ButtonClose = ({ close }) => {
	const { t } = useTranslation();

	return (
		<ButtonStyled
			onClick={close}
			modifiers={['icon', 'playerIconHover']}
			aria-label={t('common_button_close')}
		>
			<Player.IconCloseThin aria-hidden role="img" />
		</ButtonStyled>
	);
};

ButtonClose.propTypes = {
	close: func.isRequired
};

export default ButtonClose;

import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size, flex } from 'components/styles';

// Import images
import { ReactComponent as Logo } from 'assets/icons/logo_warszawa.svg';

export const FooterStyled = styled.footer`
	position: relative;
	background-color: ${({ theme: { body } }) => body.footer.bg};
	color: ${({ theme: { body } }) => body.footer.color};
	${flex({ fd: 'column' })}

	&:focus-visible {
		border: 0.2rem dotted ${({ theme }) => theme.primaryBlueLight};
	}
`;

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	padding: 4rem 1.6rem;
	margin: 0 auto;
	${flex({ jc: 'space-between', ai: 'flex-start', fd: 'column' })}
	${customMinResponsive(
		size.medium,
		css`
			padding: 4rem 3rem;
		`
	)}
	${customMinResponsive(
		size.large,
		css`
			padding: 5rem 8rem;
		`
	)}
`;

export const NavWrapper = styled.div`
	width: 100%;
	${flex({ jc: 'space-between' })}
	margin-bottom: 5rem;
	min-height: 17.5rem;

	${({ theme }) =>
		theme.isWCAG &&
		css`
			flex-direction: column;
		`}

	${customMinResponsive(
		size.medium,
		css`
			flex: 1;
			flex-direction: row;
			margin: 0 2rem 0 0;
			max-width: 65rem;
		`
	)}
`;

export const StyledLogo = styled(Logo)`
	width: 6.7rem;
	height: 7.6rem;
	${customMinResponsive(
		size.medium,
		css`
			width: 10.56rem;
			height: 12rem;
		`
	)}
	${customMinResponsive(
		size.large,
		css`
			width: 13.2rem;
			height: 15rem;
		`
	)}
`;

export const FlexContainer = styled.div`
	width: 100%;
	${flex({ ai: 'flex-start' })}
	flex-direction: column;

	${customMinResponsive(
		size.medium,
		css`
			flex-direction: ${({ theme: { isWCAG } }) => (isWCAG ? 'column' : 'row')};
			justify-content: space-between;
			align-items: flex-start;
			&:not(:last-child) {
				margin-bottom: 3.5rem;
			}
		`
	)}
`;

export const AppVersion = styled.p`
	color: ${({ theme }) => theme.body.footer.color};
	font-size: ${({ theme }) => theme.fontSize.footer.media.text}rem;
	font-weight: ${({ theme }) => theme.font.light};
`;

import React from 'react';
import { string, number, bool } from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { VisuallyHidden } from '@reach/visually-hidden';

// Import helpers
import { createLinkParams, checkProductSubtype } from 'helpers';
import { getDetailsButtonTranslation } from './helpers';

// Import components
import CommonButton from './CommonButton';

// Import styles
import { InfoIcon } from './styles';

const DetailsButton = ({ uuid, width, subtype, title, type, is_audio }) => {
	const { t } = useTranslation();

	const params = useParams();

	const { isPass, isVoucherTemplate } = checkProductSubtype(subtype);

	const linkRedirect = createLinkParams({
		id: uuid,
		type: subtype,
		title,
		params
	});

	const buttonTranslation = getDetailsButtonTranslation({ type, is_audio });

	const showInfoIcon = isPass || isVoucherTemplate;

	return (
		<CommonButton
			title={t(buttonTranslation)}
			isPrime
			as={Link}
			width={width}
			to={linkRedirect}
		>
			{showInfoIcon && <InfoIcon aria-hidden role="img" />}
			{t(buttonTranslation)}
			<VisuallyHidden>: {title}</VisuallyHidden>
		</CommonButton>
	);
};

DetailsButton.defaultProps = {
	width: 144,
	subtype: '',
	title: '',
	type: '',
	is_audio: false
};

DetailsButton.propTypes = {
	uuid: string.isRequired,
	width: number,
	subtype: string.isRequired,
	type: string.isRequired,
	is_audio: bool.isRequired,
	title: string
};

export default DetailsButton;

import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isPlayerOpen: false,
	isFloatMode: false,
	isPageLoaded: false,
	playLiveEvent: false,
	coordinates: {
		top: 0,
		left: 0,
		width: 0,
		height: 0
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.FLOAT_WIDGET_SET_PLAYER_OPEN:
				draft.isPlayerOpen = action.payload;
				return;

			case types.FLOAT_WIDGET_SET_FLOAT_MODE:
				draft.isFloatMode = action.payload;
				return;

			case types.FLOAT_WIDGET_SET_PAGE_LOADED:
				draft.isPageLoaded = action.payload;
				return;

			case types.FLOAT_WIDGET_SET_INIT_PAGE_MODE:
				return INITIAL_STATE;

			case types.FLOAT_WIDGET_SET_COORDINATES:
				draft.coordinates = action.payload;
				return;

			case types.FLOAT_WIDGET_PLAY_LIVE_EVENT:
				draft.playLiveEvent = action.payload;
				return;

			default:
				return state;
		}
	});

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import components
import { Paragraph, Button } from 'components/elements';

// Import Icons
import { ReactComponent as TrashIcon } from 'assets/icons/ic_delete.svg';

// Import styles
import { transitionMain, flex, focusBasic } from 'components/styles';

export const AuthWrapper = styled.div`
	${flex()}
	padding: 3rem 1.5rem;
`;

// Favourite and Reminder common styles

export const Wrapper = styled.li`
	padding: 1.5rem 2.2rem 1.7rem 2.6rem;
	height: ${({ theme }) => (theme.isWCAG ? 'fit-content' : '8.6rem')};
	border-bottom: 1px solid ${({ theme }) => theme.line};
	${flex({ jc: 'space-between', ai: 'flex-start' })}
	${transitionMain}
  &:hover {
		background-color: ${({ theme: { sidebar } }) => sidebar.itemHoverBg};
	}
`;

const baseProductStyles = css`
	font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	line-height: 2.1rem;
`;

export const ProductTitle = styled(Paragraph.Ellipsis)`
	${baseProductStyles}
	color: ${({ theme }) => theme.white};
	margin-bottom: 0.9rem;
`;

export const ProductType = styled.span`
	${baseProductStyles}
	color: ${({ theme }) => theme.graySemiLight};
`;

export const StyledLink = styled(Link)`
	display: block;
	width: 22.8rem;
	cursor: pointer;
	&:hover,
	:focus-visible {
		${ProductTitle} {
			color: ${({ theme }) => theme.primaryBlueLight};
		}
	}

	&:focus-visible {
		${ProductTitle} {
			${focusBasic}
		}
	}
`;

export const TrashIconStyled = styled(TrashIcon)`
	height: 2.5rem;
	width: 2.5rem;

	path {
		fill: ${({ theme: { sidebar } }) => sidebar.removeIcon};
		stroke: ${({ theme: { sidebar } }) => sidebar.removeIcon};
		${transitionMain};
	}
`;

export const ButtonStyled = styled(Button)`
	padding: 0;
	border: none;
	&:hover,
	&:focus-visible {
		${TrashIconStyled} {
			path {
				fill: ${({ theme: { sidebar } }) => sidebar.removeIconHover};
				stroke: ${({ theme: { sidebar } }) => sidebar.removeIconHover};
			}
		}
	}
`;

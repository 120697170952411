import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

// Import helpers
import { WCAG_PROPORTION_MULTIPLIER } from 'components/elements/molecules/slider_banners/helpers';

const GradientBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: ${({ containerScreenPercent, theme }) =>
		theme.isWCAG
			? containerScreenPercent * WCAG_PROPORTION_MULTIPLIER
			: containerScreenPercent}vh;
	overflow: hidden;
	z-index: ${({ zIndex = 2 }) => zIndex};

	&:before,
	&:after {
		display: block;
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&:before {
		background: ${({ theme }) =>
			`radial-gradient(circle at top left, ${theme.primaryBgBlack}, ${theme.transparent} 50%)`};
	}

	&:after {
		height: ${({ mobileHeight = '65vh', theme }) =>
			theme.isWCAG ? '100%' : mobileHeight};
		/* Use background color instead of pure black */
		background: ${({ theme }) => `linear-gradient(
			0deg,
			${theme.primaryBgBlack} 0%,
			${theme.primaryBgBlack} 5%,
			${theme.transparent} 30%,
			${theme.transparent} 100%
		)`};

		${customMinResponsive(
			size.medium,
			css`
				height: 100%;
			`
		)}
	}
`;

export default GradientBg;

import React from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FocusOn } from 'react-focus-on';
import {
	oneOfType,
	arrayOf,
	node,
	string,
	bool,
	func,
	number,
	shape,
	instanceOf
} from 'prop-types';

// Import styled components
import { DrawerStyled, CloseButton, IconClose, DrawerHeading } from './styles';

const DrawerRPC = ({
	children,
	position,
	isOpen,
	title,
	returnFocusRef,
	headingRef,
	onClose,
	...rest
}) => {
	const { t } = useTranslation();

	const focusDeactivation = () =>
		window.setTimeout(
			() => returnFocusRef.current && returnFocusRef.current.focus(),
			0
		);

	return (
		<DrawerStyled
			visible={isOpen}
			placement={position}
			role="dialog"
			aria-labelledby="panel-heading"
			{...rest}
		>
			<FocusOn
				onClickOutside={onClose}
				onEscapeKey={onClose}
				onDeactivation={focusDeactivation}
				scrollLock={false}
			>
				<DrawerHeading id="panel-heading" tabIndex="-1" ref={headingRef}>
					{title}
				</DrawerHeading>
				<CloseButton
					aria-label={t('common_modal_close_button_title')}
					onClick={onClose}
				>
					<IconClose aria-hidden role="img" />
				</CloseButton>
				{children}
			</FocusOn>
		</DrawerStyled>
	);
};

DrawerRPC.defaultProps = {
	position: 'right',
	width: 330,
	destroyOnClose: true
};

DrawerRPC.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	title: string.isRequired,
	onClose: func.isRequired,
	isOpen: bool.isRequired,
	position: string,
	width: number,
	destroyOnClose: bool,
	returnFocusRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
	headingRef: oneOfType([func, shape({ current: instanceOf(Element) })])
};

export default withTheme(DrawerRPC);

import { useDispatch, useSelector, useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { deleteVideoSession } from 'store/actions';

// Import helpers
import { PLAYER_SELECTORS } from 'helpers/variables';
import { EPISODE_TYPES, getProductType } from './helpers';
import {
	fetchMovieResources,
} from 'components/elements/organisms/player_vod/PlayerWrapper/helpers';

const { VOD } = PLAYER_SELECTORS;

const useEpisodeController = (type) => {
	const dispatch = useDispatch();
	const { getState } = useStore();
	const { pathname } = useLocation();

	const { episodes } = useSelector(({ movieDetails }) => movieDetails);

	const productType = getProductType(pathname);

	const isPrevious = type === EPISODE_TYPES.PREV;

	const episode = episodes[type];
	const episodeUuid = episode?.uuid;

	const handlePlayEpisode = async () => {
		// Dispatch an deleteVideoSession action in player folder
		await deleteVideoSession({ type: VOD })(dispatch, getState);
		// Fetch movie resources
		await fetchMovieResources({ id: episodeUuid, type: productType, dispatch });
	};

	return {
		isPrevious,
		episodeUuid,
		playEpisode: handlePlayEpisode
	};
};

export default useEpisodeController;

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import styles
import { Text, Container } from './styles';

// Import components
import Newsletter from './Newsletter';

const FooterContact = () => {
	const { t } = useTranslation();
	return (
		<Container>
			<Newsletter />
			<Text>{t('common_footer_contact_text')}</Text>
		</Container>
	);
};

export default FooterContact;

import * as types from 'store/actions/types';

export const setFloatWidgetPlayerOpen = (payload) => (dispatch) => {
	dispatch({ type: types.FLOAT_WIDGET_SET_PLAYER_OPEN, payload });
};

export const setFloatWidgetFloatMode = (payload) => (dispatch) => {
	dispatch({ type: types.FLOAT_WIDGET_SET_FLOAT_MODE, payload });
};

export const setFloatWidgetPageLoaded = (payload) => (dispatch) => {
	dispatch({ type: types.FLOAT_WIDGET_SET_PAGE_LOADED, payload });
};

export const setFloatWidgetInitPageMode = () => (dispatch) => {
	dispatch({ type: types.FLOAT_WIDGET_SET_INIT_PAGE_MODE });
};

export const setFloatWidgetCoordinates = (payload) => (dispatch) => {
	dispatch({ type: types.FLOAT_WIDGET_SET_COORDINATES, payload });
};

export const setFloatWidgetPlayLiveEvent = (payload) => (dispatch) => {
	dispatch({ type: types.FLOAT_WIDGET_PLAY_LIVE_EVENT, payload });
};

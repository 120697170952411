export const ELEMENT_INTERVAL_TIME = 200;
export const SPINNER_INTERVAL_TIME = 100;
export const RESIZE_THROTTLE_TIME = 100;

export const $ = (el) => document.getElementById(el);

export const getElementCoordinates = (el) => {
	const rect = el.getBoundingClientRect();
	const scrollLeft = document.documentElement.scrollLeft;
	const scrollTop = document.documentElement.scrollTop;

	const top = rect.top + scrollTop;
	const left = rect.left + scrollLeft;

	return {
		top,
		left,
		width: rect.width,
		height: rect.height
	};
};

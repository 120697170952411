import React from 'react';
import { bool, string } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { fetchPlayerConfig } from 'store/actions';
import { useSelector, useDispatch, useStore } from 'react-redux';

// Import helpers
import { getMatchedId } from './helpers/common';

const useLivePlayer = ({ isCatchup, playerType }) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { getState } = useStore();

	const { channel_uuid: productId } = useSelector(
		(state) => state.program_details.data
	);

	// ----- Variables -----
	// Get programId
	const programId = getMatchedId(pathname);

	// ----- Effects -----
	React.useEffect(() => {
		// Dispatch an fetchPlayerConfig action in player folder
		if (productId) {
			const options = {
				productID: productId,
				type: playerType,
				isCatchup,
				programID: programId
			};
			fetchPlayerConfig(options)(dispatch, getState);
		}
		// eslint-disable-next-line
	}, [productId]);
};

useLivePlayer.defaultProps = {
	isCatchup: false
};

useLivePlayer.propTypes = {
	isCatchup: bool,
	playerType: string
};

export default useLivePlayer;

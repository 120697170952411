import { useTranslation } from 'react-i18next';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const useQualityItem = ({ id, height, bandwidth, name }) => {
	const { t } = useTranslation();

	const { selectedProfile, setProfile } = usePlayerVodContext();

	const handleSetQuality = () => setProfile({ id, bandwidth });

	const isSelected = id === selectedProfile;

	const ariaText = name
		? t(`aria_player_settings_quality_${name}`)
		: `${t('aria_player_settings_quality')} ${height}p`;

	const optionText = name || `${height}p`;

	return {
		isSelected,
		ariaText,
		optionText,
		setQuality: handleSetQuality
	};
};

export default useQualityItem;

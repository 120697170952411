import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Import utilities
import { useResize } from 'components/utilities';

// Import variables
import {
	EVENTS_NAMES,
	NAVIGATION_SPARE_SPACE,
	KEY_CODES
} from 'helpers/variables';

const { MOUSEUP, KEYUP } = EVENTS_NAMES;
const { ESCAPE } = KEY_CODES;

export const useNavLinks = () => {
	const { menuLinks } = useSelector(({ menu }) => menu);
	const [activeSubmenuId, setActiveSubmenuId] = useState();
	const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
	const navRef = useRef(null);
	const { pathname } = useLocation();
	const { viewportWidth } = useResize();

	const navWidth = navRef.current && navRef.current.clientWidth;
	const isMoveSubnav = navWidth
		? viewportWidth - navWidth < NAVIGATION_SPARE_SPACE
		: false;

	const handleClickOutside = ({ target, keyCode }) => {
		if (navRef.current && !navRef.current.contains(target)) {
			setActiveSubmenuId(null);
		}

		if (keyCode === ESCAPE) {
			const navElements = navRef?.current?.children;

			if (!navElements) return;

			[...navElements].forEach((element) => {
				element.firstChild.getAttribute('aria-expanded') === 'true' &&
					element.firstChild.focus();
			});

			setActiveSubmenuId(null);
		}
	};

	const handleSubmenuIdChange = (id) => {
		setActiveSubmenuId(id);
		isUserDropdownOpen && setIsUserDropdownOpen(false);
	};

	useEffect(() => {
		document.addEventListener(MOUSEUP, handleClickOutside);
		document.addEventListener(KEYUP, handleClickOutside);

		return () => {
			document.removeEventListener(MOUSEUP, handleClickOutside);
			document.addEventListener(KEYUP, handleClickOutside);
		};
	}, []);

	useEffect(() => {
		setActiveSubmenuId(null);
	}, [pathname]);

	return {
		navRef,
		activeSubmenuId,
		isMoveSubnav,
		isUserDropdownOpen,
		menuLinks,
		setActiveSubmenuId: handleSubmenuIdChange,
		setIsUserDropdownOpen
	};
};

import React from 'react';
import { bool, string } from 'prop-types';

// Import components
import { CurrentTime } from 'components/elements/organisms/player_vod/PlayerWrapper/timers';
import ShakaSlider from 'components/elements/organisms/player_vod/PlayerWrapper/components/ShakaSlider';
import PlayerControllers from 'components/elements/organisms/player_vod/PlayerWrapper/controllers';

// Import styles
import { Container, ContainerRow } from './styles';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';
import { usePlayerFocusContext } from 'components/context/player/focus';

const PlayerBottomBar = React.memo(
	({ isFullScreen, playerType }) => {
		const {
			floatMode: { isFloatMode },
			isLiveEvent
		} = usePlayerVodContext();

		const { isFocus } = usePlayerFocusContext();

		const isPlayerModeVisible = !isFloatMode || isFullScreen;
		const isFloatModeVisible = isFloatMode && !isFullScreen;

		return (
			<Container isFloatMode={isFloatModeVisible} isFocus={isFocus}>
				{!isFloatModeVisible && (
					<ContainerRow justify="flex-end">
						<CurrentTime />
					</ContainerRow>
				)}

				{!isLiveEvent && (
					<ContainerRow>
						<ShakaSlider isVisible={!isPlayerModeVisible} />
					</ContainerRow>
				)}

				<ContainerRow justify="space-between">
					<PlayerControllers
						playerType={playerType}
						isFloatMode={isFloatModeVisible}
					/>
				</ContainerRow>
			</Container>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

PlayerBottomBar.propTypes = {
	isFullScreen: bool.isRequired,
	playerType: string
};

export default PlayerBottomBar;

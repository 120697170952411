import React from 'react';

// Import utilities
import { ImageOnLoadRPC, useInstitutionLogo } from 'components/utilities';

// Import styles
import { Image, StyledAnchor } from './styles';

const InstitutionLogo = () => {
	const { logoUrl, website, name } = useInstitutionLogo();

	if (!logoUrl) return null;

	return (
		<ImageOnLoadRPC src={logoUrl}>
			{({ setError }) => (
				<StyledAnchor href={website} aria-label={name}>
					<Image onError={setError} src={logoUrl} alt="" />
				</StyledAnchor>
			)}
		</ImageOnLoadRPC>
	);
};

export default InstitutionLogo;

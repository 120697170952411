import React from 'react';
import { useTranslation } from 'react-i18next';
import { number, string, any, bool } from 'prop-types';

// Import helpers
import { convertPrice } from 'helpers';
import { isNumber } from 'helpers/prices';

// Import styles
import { OldPrice, RegularPrice, RegularPriceSpan } from './styles';

const ProductPrice = ({
	regular,
	promotion,
	oldPriceColor,
	isUserProfile,
	isSpanTag
}) => {
	const { t } = useTranslation();

	const isPromotion = isNumber(promotion);

	const regularPrice = convertPrice(regular);
	const promotionPrice = convertPrice(promotion);

	return isPromotion ? (
		<>
			<OldPrice color={oldPriceColor}>
				{regularPrice} {t('payment_currency')}
			</OldPrice>

			{isSpanTag ? (
				<span>
					{promotionPrice} {t('payment_currency')}
				</span>
			) : (
				<p>
					{promotionPrice} {t('payment_currency')}
				</p>
			)}
		</>
	) : (
		<>
			{isSpanTag ? (
				<RegularPriceSpan isUserProfile={isUserProfile}>
					{regularPrice} {t('payment_currency')}
				</RegularPriceSpan>
			) : (
				<RegularPrice isUserProfile={isUserProfile}>
					{regularPrice} {t('payment_currency')}
				</RegularPrice>
			)}
		</>
	);
};

ProductPrice.defaultProps = {
	regular: null,
	promotion: null,
	isUserProfile: false
};

ProductPrice.propTypes = {
	regular: number.isRequired,
	promotion: any,
	oldPriceColor: string,
	isUserProfile: bool,
	isSpanTag: bool
};

export default ProductPrice;

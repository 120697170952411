import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
	Combobox,
	ComboboxInput,
	ComboboxPopover,
	ComboboxList,
	ComboboxOption
} from '@reach/combobox';

// Import components
import { Button } from 'components/elements';

// Import styles
import {
	iconSearch,
	customMinResponsive,
	size,
	flex,
	focusBasic
} from 'components/styles';

// Import icons
import { ReactComponent as SearchIcon } from 'assets/icons/ic_nav_search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ic_close.svg';

export const ComboboxStyled = styled(Combobox)`
	position: relative;
	${flex({ jc: 'space-between' })}
	margin: 0 1.8rem;
	${customMinResponsive(
		size.medium,
		css`
			width: 100%;
			margin-left: 7rem;
		`
	)}

	[data-reach-combobox-popover] {
		background-color: ${({ theme: { searchBox } }) => searchBox.bg};
	}
`;

export const SearchFormStyled = styled.form`
	position: relative;
	${flex({ jc: 'space-between' })}
	margin: 0 1.8rem;
	${customMinResponsive(
		size.medium,
		css`
			width: 100%;
			margin-left: 7rem;
		`
	)}
`;

export const SearchInputStyled = styled.div`
	flex: 1;
	margin-left: 1.4rem;
	${customMinResponsive(
		size.medium,
		css`
			margin-left: 1.7rem;
		`
	)}
`;

export const ComboboxInputStyled = styled(ComboboxInput)`
	width: 100%;
	border: 1px solid transparent;
	border-bottom: 2px solid ${({ theme }) => theme.white};
	padding: 0.7rem 1rem;
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
	background-color: ${({ theme: { navSearch } }) => navSearch.inputBg};

	&:focus,
	&:focus-visible {
		outline: none;
		border-bottom: 2px solid ${({ theme }) => theme.primaryBlueLight};
	}

	&::placeholder {
		color: ${({ theme }) => theme.graySemiLight};
		font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
		${customMinResponsive(
			size.medium,
			css`
				font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
			`
		)}
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.white};
		background-color: ${({ theme: { navSearch } }) => navSearch.inputBg} inset;
		transition: background-color 5000s ease-in-out 0s;
	}

	${customMinResponsive(
		size.medium,
		css`
			border-bottom: 1px solid ${({ theme }) => theme.line};
			&:focus,
			&:focus-visible {
				border-bottom: 1px solid ${({ theme }) => theme.white};
			}
		`
	)}
`;

export const ComboboxPopoverStyled = styled(ComboboxPopover)`
	position: absolute;
	top: ${({ theme }) => (theme.isWCAG ? '7' : '4.2')}rem;
	left: 0;
	width: 28.3rem;
	min-height: ${({ isLoaded }) => (isLoaded ? 10 : 20)}rem;
	background-color: ${({ theme: { searchBox } }) => searchBox.bg};
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
	border-radius: 0.4rem;
	z-index: 9000;
	margin-bottom: 2rem;

	${customMinResponsive(
		size.medium,
		css`
			width: 50rem;
			left: 5.8rem;
		`
	)}
`;

export const ComboboxListStyled = styled(ComboboxList)`
	background-color: ${({ theme: { searchBox } }) => searchBox.bg};

	[data-reach-combobox-option] {
		&:hover {
			background-color: ${({ theme: { searchBox } }) => searchBox.bg};

			a {
				color: ${({ theme: { searchBox } }) =>
					searchBox.itemTitleHover} !important;
				background-color: inherit;
			}
		}
	}

	[data-reach-combobox-option][data-highlighted] {
		background-color: inherit;
		a {
			${focusBasic}
		}
		a,
		a > p:nth-child(1) {
			color: ${({ theme: { searchBox } }) =>
				searchBox.itemTitleHover} !important;
		}
		&:hover {
			background-color: inherit;
		}
	}
`;

export const ComboboxOptionStyled = styled(ComboboxOption)`
	:not(:first-child) {
		height: 8.4rem;
		border-top: 1px solid ${({ theme }) => theme.line};
	}
`;

export const SearchIconStyled = styled(SearchIcon)`
	${iconSearch(2.4, 2.4)}
	${customMinResponsive(
		size.medium,
		css`
			margin-right: 0.5rem;
		`
	)}
`;

export const CloseIconStyled = styled(CloseIcon)`
	margin-left: 1.2rem;
	${iconSearch(2.5, 2.5, 'navSearchCloseIcon')}
`;

export const ButtonStyledClose = styled(Button)`
	display: none;
	border: none;
	${customMinResponsive(
		size.medium,
		css`
			display: block;
			padding: 0rem 0.6rem;
		`
	)}

	&:hover,
    &:focus,
	&:focus-visible {
		cursor: pointer;

		svg > g > path {
			fill: ${({ theme }) => theme.primaryBlueLight};
		}
	}
`;

export const LinkStyled = styled(Link)`
	${flex({ jc: 'flex-end' })}
	padding: 2.2rem 3rem 2.2rem 0;
	width: 100%;

	&:link,
	&:visited {
		font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
		color: ${({ theme }) => theme.white};
		font-weight: ${({ theme }) => theme.font.medium};
		transition: all 0.2s ease-in-out;
	}
	&:hover,
	&:active {
		color: ${({ theme }) => theme.primaryBlueLight};
	}

	&:focus-visible {
		${focusBasic}
		outline-offset: -0.3rem;
		color: ${({ theme }) => theme.primaryBlueLight};
	}

	${customMinResponsive(
		size.medium,
		css`
			&:link,
			&:visited {
				font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
			}
		`
	)}
`;

export const EmptySearchResults = styled.p`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	color: ${({ theme }) => theme.white};
	text-align: center;
	padding: 4rem 0;

	${customMinResponsive(
		size.medium,
		css`
			&:link,
			&:visited {
				font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
			}
		`
	)}
`;

export const ListStyled = styled.ul`
	list-style: none;
`;

export const OptionStyled = styled.li`
	:not(:first-child) {
		height: 8.4rem;
		border-top: 1px solid ${({ theme }) => theme.line};
	}
`;

import styled, { css } from 'styled-components';

// Import components
import { NavLink as RouterNavLink } from 'react-router-dom';

// Import utilities
import { customResponsive, size, focusBasic } from 'components/styles';

export const List = styled.ul`
	list-style: none;
	display: flex;
	${({ dropdown }) =>
		dropdown &&
		css`
			${customResponsive(
				size.medium,
				css`
					display: none;
				`
			)}
		`}
`;

export const Item = styled.li`
	display: flex;
	position: relative;
`;

export const NavLink = styled(RouterNavLink)`
	&:link,
	&:visited {
		position: relative;
		display: flex;
		align-items: center;
		padding: 0 2.7rem;
		color: ${({ theme: { nestedNav } }) => nestedNav.menuText};
		font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
		transition: all 0.3s ease-in-out;
	}

	&.active-nav {
		&:link,
		&:visited {
			color: ${({ theme: { nestedNav } }) => nestedNav.menuActiveText};
			&::after {
				transform: scaleX(1);
			}
		}
	}

	&:hover,
	&:focus-visible {
		color: ${({ theme: { nestedNav } }) => nestedNav.menuActiveText};
	}

	&:focus-visible {
		${focusBasic}
	}
`;

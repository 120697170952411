import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Import components
import { Input } from 'components/elements';

const LoginFormField = ({
	type = 'text',
	field,
	name = 'pin',
	autoComplete = 'true',
	form: { touched, errors },
	placeholder
}) => {
	const isErrorVisible = touched[field.name] && errors[field.name];
	const errorText = errors[field.name] || '';
	return (
		<LoginFormFieldStyled>
			<InputStyled
				className={`${
					touched[field.name] && errors[field.name] ? 'errors' : ''
				}`}
				type={type}
				name={name}
				autoComplete={autoComplete}
				placeholder={placeholder}
				{...field}
			/>
			<FormError isErrorVisible={isErrorVisible}>{errorText}</FormError>
		</LoginFormFieldStyled>
	);
};

const LoginFormFieldStyled = styled.div`
	margin-bottom: 1.9rem;
	position: relative;
`;

const InputStyled = styled(Input)`
	border: 1px solid ${({ theme }) => theme.authentication.input.borderColor};
	color: ${({ theme }) => theme.authentication.input.color};

	&:focus {
		border-color: ${({ theme }) => theme.authentication.input.focusBorderColor};
	}

	&.errors {
		border: 1px solid ${({ theme }) => theme.redSecondary};
		color: ${({ theme }) => theme.redSecondary};

		&:focus {
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
				0 0 8px rgba(255, 0, 0, 0.6);
		}
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.authentication.input.color};
		-webkit-box-shadow: 0 0 0px 1000px
			${({ theme }) => theme.authentication.contentBg} inset;
		transition: background-color 5000s ease-in-out 0s;
	}
`;

const FormError = styled.p`
	position: absolute;
	top: 4rem;
	right: 0;
	visibility: ${({ isErrorVisible }) =>
		!isErrorVisible ? 'hidden' : 'visible'};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.6)}rem;
	color: ${({ theme }) => theme.redSecondary};
	text-align: left;
`;

LoginFormField.propTypes = {
	type: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
	form: PropTypes.shape({
		touched: PropTypes.object.isRequired,
		errors: PropTypes.object.isRequired
	}),
	placeholder: PropTypes.string.isRequired,
	autoComplete: PropTypes.string,
	name: PropTypes.string
};

export default LoginFormField;

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import EpisodeController from './EpisodeController';

// Import helpres
import { SERIES_CONTROLLERS } from './helpers';

const EpisodeControllers = () => {
	const { t } = useTranslation();
	return SERIES_CONTROLLERS.map(({ title, type }) => (
		<EpisodeController key={type} title={t(title)} type={type} />
	));
};

export default EpisodeControllers;

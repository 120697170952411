import React from 'react';
import { useSelector } from 'react-redux';

// Import components
import NavMenuItem from './NavMenuItem';

const NavMenu = ({ ...restProps }) => {
	const { menuLinks } = useSelector(({ menu }) => menu);

	return menuLinks.map(({ submenu, model_id, model_type, link, name, id }) => (
		<NavMenuItem
			key={id}
			submenu={submenu}
			modelId={model_id}
			modelType={model_type}
			link={link}
			name={name}
			id={id}
			{...restProps}
			tabIndex="0"
		/>
	));
};

export default NavMenu;

import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import icons
import { ReactComponent as IconBlikSVG } from 'assets/icons/ic_blik.svg';
import { ReactComponent as IconVisaSVG } from 'assets/icons/ic_visa.svg';
import { ReactComponent as IconPayPalSVG } from 'assets/icons/ic_paypal.svg';

// Import variables
import {
	OTHER_PAYMENT_METHODS,
	GATEWAY_TYPES,
	PAYMENT_METHODS
} from 'helpers/prices';

// Import styles
import {
	IconMastercard,
	SmallIconVisa,
	SmallIconMastercard,
	IconBlik,
	IconBluemedia,
	IconWallet
} from './styles';

const { CARD, CARD_RECURRING, BLIK, BLIK_RECURRING, OTHER_BLUEMEDIA } =
	GATEWAY_TYPES;

const { WALLET, PAYPAL, PAYPAL_SUBSCRIPTION } = PAYMENT_METHODS;

const Icon = ({ type }) => {
	const { t } = useTranslation();
	switch (type) {
		case CARD:
		case CARD_RECURRING:
			return (
				<>
					<SmallIconVisa
						aria-label={t('common_payments_visa_card')}
						role="img"
					/>
					<SmallIconMastercard
						aria-label={t('common_payments_master_card')}
						role="img"
					/>
				</>
			);

		case BLIK:
		case BLIK_RECURRING:
			return <IconBlik aria-label={t('common_payments_blik')} role="img" />;

		case OTHER_BLUEMEDIA:
			return (
				<IconBluemedia aria-label={t('common_payments_bluemedia')} role="img" />
			);

		case OTHER_PAYMENT_METHODS:
			return (
				<>
					<IconVisaSVG
						width={60}
						height={50}
						aria-label={t('common_payments_visa_card')}
						role="img"
					/>
					<IconMastercard
						width={30}
						height={50}
						aria-label={t('common_payments_master_card')}
						role="img"
					/>
					<IconBlikSVG
						width={40}
						height={50}
						aria-label={t('common_payments_blik')}
						role="img"
					/>
				</>
			);

		case WALLET:
			return <IconWallet aria-label={t('common_payments_wallet')} role="img" />;

		case PAYPAL:
		case PAYPAL_SUBSCRIPTION:
			return (
				<IconPayPalSVG
					height={30}
					aria-label={t('common_payments_paypal')}
					role="img"
				/>
			);

		default:
			return null;
	}
};

Icon.propTypes = {
	type: string.isRequired
};

export default Icon;

import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';

// Import components
import PortalLogo from './PortalLogo';
import InstitutionLogo from './InstitutionLogo';

// Import styled components
import { Wrapper } from './styles';

const NavTemplate = ({ children }) => {
	return (
		<Wrapper>
			<PortalLogo />
			<InstitutionLogo />
			{children}
		</Wrapper>
	);
};

NavTemplate.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired
};

export default NavTemplate;

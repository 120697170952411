import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledMenu = styled(Menu)`
	margin-top: 2.65rem;
	&.ant-menu {
		background-color: ${({ theme }) => theme.primaryBgBlack};
	}
	&.ant-menu-inline,
	.ant-menu-vertical,
	.ant-menu-vertical-left {
		border-right: none;
	}
`;

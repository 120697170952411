import React from 'react';
import { bool, array, func, string } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import VisuallyHidden from '@reach/visually-hidden';

// Import components
import {
	Loader,
	GridContainer,
	GridTile,
	Heading,
	DelayRender
} from 'components/elements';
import NoContentMessage from 'components/elements/organisms/sections/NoContentMessage';

// Import styles
import { InfiniteLoader } from './styles';
import { getNoContentMessage } from './helpers';

// Import utilities
import { useInstitutionSelect } from 'components/utilities';

const ProductListInfinityScroll = ({
	totalMovies,
	moviesList,
	getMoreMovies,
	productsLoaded,
	showEmptyMessage = true,
	isSearch = false,
	type,
	sortingName,
	genreName
}) => {
	const { t } = useTranslation();
	const { selectedInstitutionName } = useInstitutionSelect();
	const { title, description } = getNoContentMessage(isSearch);

	const emptyMessageCondition =
		showEmptyMessage && productsLoaded && !moviesList.length;
	const typeTranslation = t(`vod_product_type_alias_${type}`);
	const sortName = t(sortingName);

	return (
		<>
			{!productsLoaded && <Loader background={false} />}

			{!emptyMessageCondition && (
				<Heading.Second modifiers="hidden">
					{t('common_filters_results_heading')}
				</Heading.Second>
			)}

			{emptyMessageCondition && (
				<NoContentMessage
					paddingTopDesktop="5rem"
					heightOnMobile="max-content"
					title={title}
					description={description}
					role="alert"
				/>
			)}

			<InfiniteScroll
				pageStart={0}
				loadMore={getMoreMovies}
				hasMore={totalMovies}
				loader={
					<InfiniteLoader
						key="loader"
						position="static"
						background={false}
						height={10}
						width={10}
					/>
				}
				threshold={1500}
			>
				<GridContainer role="list">
					{moviesList.map((product) => (
						<li key={product.uuid}>
							<GridTile item={product} />
						</li>
					))}
				</GridContainer>
				{productsLoaded && !emptyMessageCondition && (
					<DelayRender delay={2000}>
						<VisuallyHidden role="alert">
							{t('common_products_filters_results_count', {
								results_count: moviesList.length,
								institution: selectedInstitutionName,
								sortType: sortName,
								materialsType: typeTranslation,
								genreType: genreName
							})}
						</VisuallyHidden>
					</DelayRender>
				)}
			</InfiniteScroll>
		</>
	);
};

ProductListInfinityScroll.propTypes = {
	totalMovies: bool.isRequired,
	moviesList: array.isRequired,
	getMoreMovies: func.isRequired,
	productsLoaded: bool.isRequired,
	showEmptyMessage: bool,
	isSearch: bool,
	type: string,
	sortingName: string,
	genreName: string
};

export default ProductListInfinityScroll;

import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { PlayerSettings } from 'components/elements';

// Import logic
import useLanguagesItem from './useLanguagesItem';

const LanguagesItem = ({ language }) => {
	const { isSelected, ariaText, setLanguage } = useLanguagesItem({ language });

	return (
		<PlayerSettings.Option
			onSelect={setLanguage}
			isSelected={isSelected}
			ariaText={ariaText}
		>
			{language}
		</PlayerSettings.Option>
	);
};

LanguagesItem.propTypes = {
	language: PropTypes.string.isRequired
};

export default LanguagesItem;

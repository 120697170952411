import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toInteger } from 'lodash';

// Import helpers
import { convertPrice } from 'helpers';
import { isNumber } from 'helpers/prices';

// Import utilities
import { useLowestPrice } from 'components/utilities';

// Import styles
import { OldPrice, RegularPrice } from './styles';

const PassPrice = ({ product, usage_limit }) => {
	const { t } = useTranslation();
	const { regular, promotion } = useLowestPrice({ product });

	if (!usage_limit || !regular) return null;
	const isPromotion = isNumber(promotion);
	const regularPrice = convertPrice(regular / toInteger(usage_limit));
	const promotionPrice = convertPrice(promotion / toInteger(usage_limit));

	return (
		<>
			{isPromotion && (
				<OldPrice>
					{regularPrice} {t('payment_currency')}
				</OldPrice>
			)}
			<RegularPrice>
				{isPromotion ? promotionPrice : regularPrice} {t('payment_currency')}
			</RegularPrice>
		</>
	);
};

PassPrice.propTypes = {
	product: PropTypes.object,
	usage_limit: PropTypes.number
};

export default PassPrice;

// Import utilities
import { useAnalytics } from 'components/utilities';

const Analitics = () => {
	// Google analitics
	useAnalytics();
	return null;
};

export default Analitics;

import { colors } from './colors';
import fonts from '../fonts';
import { buttons } from './buttons';

export const pureOttTheme = {
	...colors,
	...fonts,
	...buttons,
	darkMode: true,
	grayMedium: colors.grayMediumDarkTheme,
	general: {
		body: 'linear-gradient(180deg,#050709 0%,#0B111B 17%,#0B111B 100%)',
		linkHover: colors.white
	},
	buttonDefault: {
		bg: 'linear-gradient(to bottom,#3182b5 0,#2f599c 100%)',
		text: colors.white,
		border: '#183e58'
	},
	heading: {
		first: {
			white: colors.white,
			primary: colors.white
		}
	},
	line: colors.blackGray,
	dashedLine: colors.grayMediumDarkTheme,
	activeGradientLine: {
		colorStart: colors.primaryLight,
		colorEnd: colors.primary,
		tvsmart: {
			colorStart: '#FFEB00',
			colorMiddle: '#EAB036',
			colorEnd: '#CB931E'
		}
	},
	loaderBg: 'transparent',
	body: {
		bg: colors.blackPearl
	},
	header: {
		bg: colors.blackPearl,
		icon: colors.grayMediumDarkTheme,
		iconActive: colors.white,
		separateLine: colors.grayMediumDarkTheme
	},
	navSearch: {
		bg: colors.blackPearl,
		inputBg: 'transparent'
	},
	navSearchCloseIcon: colors.grayMediumDarkTheme,
	searchBox: {
		bg: '#101621',
		itemHoverBg: colors.blueAzureDark,
		itemInfo: '#95A1AF',
		itemInfoHover: colors.white,
		itemTitleHover: colors.white
	},
	nav: {
		height: 6.5,
		menuActiveText: colors.white
	},
	sidebar: {
		bg: '#101621',
		itemHoverBg: colors.blueAzureDark,
		removeIcon: colors.grayMediumDarkTheme,
		removeIconHover: colors.white,
		closeIcon: colors.grayMediumDarkTheme
	},
	popover: {
		bg: '#101621',
		border: '#2d343d',
		icon: colors.grayMediumDarkTheme,
		iconActive: colors.primary
	},
	button: {
		primaryVod: {
			color: colors.white,
			beforeBgColor1: '#3562a5',
			beforeBgColor2: '#4387d0'
		},
		parental: {
			bgColor1: '#3182b5',
			bgColor2: '#2f599c'
		}
	},
	dropdown: {
		button: {
			bg: 'transparent',
			color: colors.grayMediumDarkTheme,
			colorHover: colors.white
		},
		menu: {
			bg: '#101621',
			itemColor: colors.grayMediumDarkTheme,
			itemHoverColor: colors.white,
			itemHoverBg: colors.blueAzureDark
		}
	},
	modal: {
		bg: '#101621',
		titleColor: colors.grayMediumDarkTheme
	},
	channels: {
		gridItemLinkHover: colors.white
	},
	epg: {
		grayLight: '#fbfafc',
		bg: '#151d29',
		bgHoverColorStart: colors.primaryLight,
		bgHoverColorEnd: colors.primary,
		gridItemShadow: '#080C12',
		gridItemCategory: '#95A1AF',
		catchup: {
			bg: 'linear-gradient(to right, #2b323e 0, #2b323e)',
			bgHover: 'linear-gradient(to right, #f8a030 0, #e36845)'
		},
		calendarHover: colors.white
	},
	programDetails: {
		heading: colors.white,
		paragraph: '#e6e3df',
		name: '#808181',
		table: {
			row: {
				bg: '#161e29',
				hoverBg: '#224BA1'
			}
		}
	},
	user: {
		line: colors.blackGray,
		topHeading: colors.grayMediumDarkTheme,
		topHeadingFilter: colors.grayMediumDarkTheme,
		tableTitle: colors.grayMediumDarkTheme,
		tableItem: '#6C7076',
		tableItemName: colors.blueAzureDark,
		tableItemNameHover: colors.white,
		menuText: colors.grayMediumDarkTheme,
		menuBG: colors.transparent,
		menuActiveText: colors.white,
		menuActiveBg: colors.blueAzureDark,
		showChannel: colors.grayMediumDarkTheme,
		removeIcon: colors.grayMediumDarkTheme,
		removeIconHover: colors.white,
		tags: {
			vodInProfile: colors.blueDark
		},
		productItem: {
			title: colors.white,
			info: '#aaadb2'
		}
	},
	allMovies: {
		breadcrumbs: {
			color: 'rgba(255, 255, 255, 0.7)'
		},
		menuButton: {
			color: '#636363'
		}
	},
	authentication: {
		contentBg: colors.blackPearl,
		headingColor: colors.white,
		infoColor: colors.whiteTransparent07,
		linkColor: colors.blueAzure,
		linkHoverColor: colors.graySoft,
		lineColor: colors.blackGray,
		agreements: {
			color: colors.whiteTransparent07,
			checkboxBorderColor: colors.graySoft,
			checkboxIconColor: colors.graySoft,
			obligatoryPrefixColor: colors.whiteTransparent07
		},
		input: {
			color: '#858585',
			borderColor: '#2C343D',
			focusBorderColor: colors.blueAzure
		},
		loginTabs: {
			color: '#dadada80',
			checkedColor: colors.graySoft,
			borderColor: '#242830'
		}
	},
	slider: {
		arrow: {
			color: '#95A1AF',
			sliderControllerBgColor: 'transparent',
			sliderControllerBgHoverColor: 'rgba(33, 64, 154, 0.8)'
		},
		available: {
			bgColorStart: '#46abe6',
			bgColorEnd: '#3562a5'
		}
	},
	payment: {
		bgColor: 'rgba(0, 0, 0, 0.73)',
		gradientBox: {
			color1: 'rgba(47, 215, 255, 1)',
			color2: 'rgba(23, 129, 247, 1)'
		},
		gradientRadial: {
			color1: 'rgb(22, 56, 99)',
			color2: colors.black
		},
		paymentOptions: {
			gradientBox: {
				color1: '#cbd3da',
				color2: '#45748c',
				color3: '#32559a'
			},
			paragraph: {
				color: colors.white
			},
			box: {
				color1: 'rgb(17, 44, 74)',
				color2: '#3a5e82'
			},
			name: {
				color: colors.white,
				fontWeight: fonts.font.medium
			},
			info: {
				color: colors.grayLight,
				fontWeight: fonts.font.normal
			},
			price: {
				color: colors.white,
				fontWeight: fonts.font.normal,
				opacity: '0.7'
			},
			paymentField: {
				labelColor: '#cbd6e0',
				color: colors.white,
				placeholderColor: '#cbd6e0',
				borderColor: '#819bb2',
				errorBorderColor: colors.red
			}
		},
		voucher: {
			wrapper: {
				borderColor: '#e5e2e124'
			},
			text: {
				color: colors.white
			},
			input: {
				color: colors.white,
				borderColor: 'rgba(105, 114, 135, 0.29)'
			}
		},
		emailNotConfirmed: {
			headingColor: colors.white,
			paragraph: {
				color: colors.white,
				fontWeight: fonts.font.light
			}
		}
	},
	landingPage: {
		registrationButton: {
			color: colors.white,
			bgContainerColor: '#31549b',
			borderColor: '#313131',
			bgDefault: {
				color1: '#31549b',
				color2: colors.blueAzureDark
			},
			bgHover: {
				color1: colors.blueAzureDark,
				color2: '#31549b'
			}
		},
		devices: {
			itemColor: colors.blueAzure
		}
	}
};

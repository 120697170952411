import React from 'react';
import { array, string, number, oneOfType } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import SubMenuList from './SubMenuList';
import MenuLink from './MenuLink';

// Import styled components
import { StyledSubMenu, StyledMenuItem } from '../common_styles';

// Import helpers
import { getLinkDetails } from 'components/layout/nav/helpers';
import { useMobileMenu } from '../MobileMenu/useMobileMenu';

const NavMenuItem = ({
	submenu,
	modelId,
	modelType,
	link,
	name,
	id,
	...restProps
}) => {
	const { t } = useTranslation();

	const { path, isInternalLink } = getLinkDetails({
		modelId,
		modelType,
		name,
		id
	});

	const { handleKeyEvent } = useMobileMenu();

	const eventKey = `item_${id}`;
	const subMenuKey = `${id}-menu-`;

	return (
		<StyledSubMenu
			title={name}
			{...restProps}
			eventKey={eventKey}
			subMenuKey={subMenuKey}
			onKeyPress={handleKeyEvent}
			forceSubMenuRender
		>
			<StyledMenuItem>
				<MenuLink
					isInternalLink={isInternalLink}
					path={path}
					link={link}
					name={t('common_header_nav_mobile_show_all')}
					isMainLink={true}
				/>
			</StyledMenuItem>
			<SubMenuList submenu={submenu} basePath={path} />
		</StyledSubMenu>
	);
};

NavMenuItem.propTypes = {
	submenu: array,
	modelId: string,
	modelType: string,
	link: string,
	name: string,
	id: oneOfType([string, number]).isRequired
};

export default NavMenuItem;

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

// Import components
import { Button, InputField, ParentalControlSelect } from 'components/elements';

const ParentalControlFields = ({ isSubmitting, isEnabled }) => {
	// Get translation
	const { t } = useTranslation();

	return (
		<React.Fragment>
			{isEnabled ? (
				<Field
					placeholder={t('parental_control_pin')}
					name="pin"
					type="text"
					component={InputField}
				/>
			) : (
				<Field
					placeholder={t('parental_control_title_enable')}
					name="rating"
					component={ParentalControlSelect}
				/>
			)}

			<Button
				type="submit"
				disabled={isSubmitting}
				width="100%"
				modifiers="default"
			>
				{isEnabled
					? t('parental_control_btn_enabled')
					: t('parental_control_btn_active')}
			</Button>
		</React.Fragment>
	);
};

ParentalControlFields.propTypes = {
	isSubmitting: PropTypes.bool.isRequired,
	isEnabled: PropTypes.bool.isRequired
};

export default React.memo(ParentalControlFields);

import React from 'react';
import { number, string } from 'prop-types';

// Import context
import { useProductPreviewContext } from 'components/context/productPreview';

// Import utilities
import { useFavoriteButton } from 'components/utilities';

// Import components
import { AuthModal } from 'components/elements';
import CommonButton from './CommonButton';

// Import styles
import { IconFavorite, IconFavoriteFull } from './styles';

const FavouriteButton = ({ uuid, width }) => {
	const { onPreviewHOF, isPreview } = useProductPreviewContext();
	const { click, buttonText, isFavourite, isAuth, isLoaded } =
		useFavoriteButton({
			uuid
		});

	if (isPreview) {
		return (
			<CommonButton onClick={onPreviewHOF(click)} width={width}>
				<IconFavorite aria-hidden role="img" />
				{buttonText}
			</CommonButton>
		);
	}

	return (
		<AuthModal>
			<CommonButton
				onClick={click}
				width={width}
				isLoading={!isLoaded && isAuth}
			>
				{isFavourite ? (
					<IconFavoriteFull aria-hidden role="img" />
				) : (
					<IconFavorite aria-hidden role="img" />
				)}
				{buttonText}
			</CommonButton>
		</AuthModal>
	);
};

FavouriteButton.defaultProps = {
	width: 250
};

FavouriteButton.propTypes = {
	uuid: string.isRequired,
	width: number
};

export default FavouriteButton;

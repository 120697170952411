import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';

// Import styles
import { AlertWrapper, AlertContent } from './styles';

function ProductPreviewAlert({ fixed }) {
	const { t } = useTranslation();
	return (
		<AlertWrapper fixed={fixed}>
			<AlertContent>
				<Alert
					message={t('common_preview_banner_title')}
					description={t('common_preview_banner_description')}
					type="info"
					showIcon
					closable
					banner
				/>
			</AlertContent>
		</AlertWrapper>
	);
}

ProductPreviewAlert.propTypes = {
	fixed: PropTypes.bool
};

export default ProductPreviewAlert;

import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { LOCALSTORAGE_USER } from 'helpers/variables';
import { getErrorCodeResults } from 'helpers/error_code_api';

// ******************** ENABLE PARENTL CONTROL ********************
export const enableParentalControl =
	({ values, isEnabled, callback = () => {}, configCallback = () => {} }) =>
	async (dispatch) => {
		try {
			// Dispatch an loading action
			dispatch({
				type: types.PARENTAL_CONTROL_LOADING,
				payload: true
			});

			const options = isEnabled
				? { pin: values.pin }
				: { rating: +values.rating };
			const type = isEnabled ? 'disable' : 'enable';

			// Get program details
			const {
				data: { token }
			} = await services.post(`/subscriber/parental/${type}`, options);

			const parentalControlEnabled = !isEnabled;

			// Dispatch an action to change in uthenticate parentalControl
			const rating = !isEnabled ? +values.rating : null;

			// Get user from local storage
			const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));
			// Set updated user data to local storage with accepted agreements
			localStorage.setItem(
				LOCALSTORAGE_USER,
				JSON.stringify({
					...user,
					parentalControlEnabled,
					token,
					rating
				})
			);

			dispatch({
				type: types.ENABLE_USER_PARENTAL_CONTROL,
				payload: { parentalControlEnabled, rating }
			});

			dispatch({
				type: types.PARENTAL_CONTROL_SUCCESS,
				payload: parentalControlEnabled
			});
			// Close form modal callback
			callback();
			// Fetch player configuration when parental control is enabled
			configCallback();
		} catch (error) {
			if (error.response) {
				const { codeErrorMessage } = getErrorCodeResults(error);

				error.response.data.message = codeErrorMessage;

				// Dispatch an error action
				dispatch({
					type: types.PARENTAL_CONTROL_ERROR,
					payload: error.response
				});
			}
		}
	};

export const clearParentalControl = () => ({
	type: types.CLEAR_PARENTAL_CONTROL_LOADING,
	payload: true
});

import styled, { css } from 'styled-components';

// Import styles
import { size, customResponsive, flex, textEllipsis } from 'components/styles';

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 5rem 4rem;
	display: flex;
	justify-content: space-between;
	z-index: 99;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	visibility: ${({ isFocus }) => (isFocus ? 'visible' : 'hidden')};
	transition: all 0.3s ease-in-out;

	${customResponsive(
		size.small,
		css`
			justify-content: flex-end;
			padding: 1rem;
		`
	)}

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			padding: 2rem;
			button {
				svg {
					height: 2.6rem;
					width: 2.6rem;
				}
			}
		`}
`;

export const Title = styled.span`
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) => theme.fontSize.md}rem;
	padding: 0;
	margin: 0;
	height: 100%;
	display: inline-block;
	${textEllipsis}

	${customResponsive(
		size.small,
		css`
			display: none;
		`
	)}
`;

export const Controller = styled.div`
	${flex({ ai: 'start', jc: 'flex-end' })}
	width: 100%;
	column-gap: 2.6rem;

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			justify-content: space-between;
		`}

	${({ isFullScreen }) =>
		isFullScreen &&
		css`
			width: auto;
		`}
`;

export const TitleWrapper = styled.div`
	${flex({ jc: 'flex-start' })}
	column-gap: 2rem;
	width: ${({ theme }) => (theme.isWCAG ? '100%' : '70%')};
`;

import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { bool, func, object } from 'prop-types';

// Import utilities
import { useNextProgramParentalControl } from 'components/utilities';

// Import components
import {
	Loader,
	Button,
	PlayerError,
	PlayerElements as Player,
	PlayerPip,
	useFloatWidget
} from 'components/elements';
import PlayerControllers from '../player_overlay/player_controllers/PlayerControllers';
import { PlayerStickyButtons } from 'components/elements/organisms/player/PlayerStickyButtons';
import PlayerDestroy from '../player_context/PlayerDestroy';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';
import { usePlayerFocusContext } from 'components/context/player/focus';

// Import variables
import { DEFAULT_PLAYER, THEO_PLAYER } from 'helpers/variables';

const isStickyControllerMode = false;

const PlayerTvContent = ({
	error,
	setPlayerCustomError,
	isFullScreen,
	isCatchup
}) => {
	const { t } = useTranslation();
	const {
		isReady,
		isPaused,
		play,
		pause,
		destroy,
		setFullScreen: openPlayerFullScreen,
		isEnded,
		catchupRepeat,
		showLoader,
		isSiedebarVisible
	} = usePlayerTvContext();

	const { isFocus } = usePlayerFocusContext();

	const { isFloatMode, closeFloatMode } = useFloatWidget();

	const { playerType } = useNextProgramParentalControl();

	const isFloatModeScreen = isFloatMode && !isFullScreen;

	const closeButtonCondition = !isSiedebarVisible && isFullScreen && isFocus;

	const isTheoPlayer = DEFAULT_PLAYER === THEO_PLAYER;

	const handleBigPlayButtonClick = (params) => {
		const { play, pause, isPaused, isEnded, catchupRepeat } = params;
		const videoPlayPause = () => (isPaused ? play() : pause());

		// repeat stream if catchup has ended
		// else play/pause stream
		isEnded ? catchupRepeat() : videoPlayPause();
	};

	const renderClosebutton = (close = () => {}) => (
		<ButtonCloseFullScreen
			onClick={close}
			isFocus={isFocus}
			modifiers={['icon', 'playerIconHover']}
			isFloatMode={isFloatModeScreen}
		>
			<Player.IconCloseThin isFloatMode={isFloatModeScreen} />
		</ButtonCloseFullScreen>
	);

	const renderPlayerControllers = () => (
		<PlayerControllers isCatchup={isCatchup} isFloatMode={isFloatModeScreen} />
	);

	return (
		<>
			<PlayerDestroy
				errorAction={setPlayerCustomError}
				destroyPlayer={destroy}
				error={error}
				isCatchup={isCatchup}
			/>
			{showLoader && (
				<Loader zIndex={1} position="absolute" background={false} />
			)}

			{!isTheoPlayer && (
				<>
					{closeButtonCondition &&
						isCatchup &&
						renderClosebutton(openPlayerFullScreen)}
					{closeButtonCondition && renderClosebutton(openPlayerFullScreen)}

					{isFloatModeScreen && renderClosebutton(closeFloatMode)}

					{isReady && (isFullScreen || isCatchup || !isStickyControllerMode) ? (
						renderPlayerControllers()
					) : (
						<PlayerStickyButtons>
							{renderPlayerControllers()}
						</PlayerStickyButtons>
					)}
				</>
			)}

			{isReady && isCatchup && isPaused && (
				<Button
					isCatchupControllers={false}
					modifiers={['icon', 'catchup', 'playerIconHover', 'playerPlay']}
					onClick={() =>
						handleBigPlayButtonClick({
							play,
							pause,
							isPaused,
							isEnded,
							catchupRepeat
						})
					}
					aria-label={t('common_player_play_title')}
				>
					<IconPlayStyled aria-hidden role="img" />
				</Button>
			)}

			<PlayerPip isReady={isReady} />

			{error.isError && <PlayerError error={error} playerType={playerType} />}
		</>
	);
};

PlayerTvContent.propTypes = {
	error: object.isRequired,
	setPlayerCustomError: func.isRequired,
	isFullScreen: bool.isRequired,
	isCatchup: bool.isRequired
};

const ButtonCloseFullScreen = styled(Button)`
	position: absolute;
	top: 5rem;
	right: 4rem;
	z-index: 10;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	visibility: ${({ isFocus }) => (isFocus ? 'visible' : 'hidden')};

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			top: 0.5rem;
			right: 1.5rem;
		`}
`;

const IconPlayStyled = styled(Player.IconPlay)`
	width: 15rem;
	height: 15rem;
`;

export default PlayerTvContent;

import React from 'react';
import { number, string, oneOfType } from 'prop-types';

// Import components
import { PlayerSettings } from 'components/elements';

// Import logic
import useQualityItem from './useQualityItem';

const QualityItem = (props) => {
	const { isSelected, ariaText, optionText, setQuality } =
		useQualityItem(props);

	return (
		<PlayerSettings.Option
			onSelect={setQuality}
			isSelected={isSelected}
			ariaText={ariaText}
		>
			{optionText}
		</PlayerSettings.Option>
	);
};

QualityItem.propTypes = {
	id: oneOfType([number, string]).isRequired,
	height: number,
	bandwidth: number,
	name: string
};

export default QualityItem;

import { useTranslation } from 'react-i18next';

// Import variables
import { LOCAL_STORAGE_LANGUAGE } from 'helpers/variables';

const useLanguage = () => {
	const { i18n } = useTranslation();

	const updateLanguage = (value) => () => {
		// change language in i18n
		i18n.changeLanguage(value);
		// set new language value in local storage
		localStorage.setItem(LOCAL_STORAGE_LANGUAGE, value);
	};

	return { language: i18n.language, updateLanguage };
};

export default useLanguage;

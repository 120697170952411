import React from 'react';
import VisuallyHidden from '@reach/visually-hidden';
import clsx from 'clsx';
import { ListboxInput } from '@reach/listbox';

import {
	object,
	string,
	func,
	shape,
	oneOfType,
	arrayOf,
	node,
	bool
} from 'prop-types';

import { StyledListboxButton, SelectWrapper } from './styles';

import { StyledListboxList, StyledListboxPopover } from '../styles';

import SelectKeyboardScrollWrapper from '../SelectKeyboardScrollWrapper';

const UserFormSelect = ({
	field,
	form: { setFieldTouched },
	placeholder,
	children,
	isEditing
}) => {
	const { name, value } = field;
	const handleOnChange = (event) => field.onChange(name)(event);
	const handleOnBlur = (value) => {
		setFieldTouched(name);
		field.onBlur && field.onBlur(value);
	};

	return (
		<SelectWrapper>
			<VisuallyHidden id={name}>{placeholder}</VisuallyHidden>
			<ListboxInput
				value={value ?? ''}
				onChange={handleOnChange}
				onBlur={handleOnBlur}
				aria-labelledby={name}
				disabled={!isEditing}
			>
				{({ isExpanded }) => (
					<SelectKeyboardScrollWrapper>
						<StyledListboxButton
							className={clsx({
								'is-expanded': isExpanded,
								'is-disabled': !isEditing
							})}
							isEditing={isEditing}
							arrow
						/>
						<StyledListboxPopover portal={false}>
							<StyledListboxList>{children}</StyledListboxList>
						</StyledListboxPopover>
					</SelectKeyboardScrollWrapper>
				)}
			</ListboxInput>
		</SelectWrapper>
	);
};

UserFormSelect.propTypes = {
	field: object.isRequired,
	placeholder: string,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired,
		setFieldTouched: func.isRequired
	}),
	children: oneOfType([arrayOf(node), node]).isRequired,
	isEditing: bool
};

export default UserFormSelect;

import React from 'react';
import { func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styled components
import { HamburgerBtn } from './styles';

// Import variables
import { SECTIONS_ID } from 'helpers/variables';

const { NAVIGATION } = SECTIONS_ID;

const Hamburger = ({ isOpen, toggle }) => {
	const { t } = useTranslation();

	return (
		<HamburgerBtn
			onClick={toggle}
			isOpen={isOpen}
			aria-label={t('aria_header_nav_label_hidden')}
			aria-expanded={isOpen}
			id={NAVIGATION}
		>
			<span />
			<span />
			<span />
		</HamburgerBtn>
	);
};

Hamburger.propTypes = {
	isOpen: bool.isRequired,
	toggle: func.isRequired
};

export default Hamburger;

import React from 'react';
import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

// Import helpers
import { createLinkParams } from 'helpers';
import { PRODUCTS_TYPES } from 'helpers/variables';

// Import components
import CommonButton from './CommonButton';

// Import styles
import { MoreEpisodesIcon } from './styles';

// Import context
import { useProductPreviewContext } from 'components/context/productPreview';

const { SERIES } = PRODUCTS_TYPES;

const MoreEpisodesButton = ({ width }) => {
	const params = useParams();
	const { t } = useTranslation();
	const { previewLinkProps } = useProductPreviewContext();
	const { context } = useSelector(({ movieDetails }) => movieDetails.data);

	if (!context?.series) return null;
	const { series, seasonNumber } = context;

	const { uuid, translations } = series;
	const { pathname } = createLinkParams({
		id: uuid,
		type: SERIES,
		title: translations?.title,
		params
	});

	const to = { pathname, state: { seasonNumber } };

	return (
		<CommonButton as={Link} to={to} width={width} {...previewLinkProps()}>
			<MoreEpisodesIcon aria-hidden role="img" />
			{t('vod_buttons_more_episodes')}
		</CommonButton>
	);
};

MoreEpisodesButton.defaultProps = {
	width: 250
};

MoreEpisodesButton.propTypes = {
	width: number
};

export default MoreEpisodesButton;

import styled, { css } from 'styled-components';

// Import styles
import {
	flex,
	customResponsive,
	customMinResponsive,
	size
} from 'components/styles';

// Import icons
import { ReactComponent as IconInfo } from 'assets/icons/ic_content_info.svg';

export const Wrapper = styled.div`
	margin-bottom: 4rem;
`;

export const NoContentWrapper = styled.section`
	${flex({ fd: 'column' })}

	${customResponsive(
		size.medium,
		css`
			height: ${({ heightOnMobile }) => heightOnMobile};
			padding: 0 1.4rem;
		`
	)}

    ${customMinResponsive(
		size.medium,
		css`
			padding-top: ${({ paddingTopDesktop }) => paddingTopDesktop};
		`
	)}
`;

export const InfoIcon = styled(IconInfo)`
	${customResponsive(
		size.medium,
		css`
			width: 4.4rem;
			height: 4.4rem;
		`
	)}
`;

export const NoContentHeader = styled.h2`
	opacity: 0.6;
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) => theme.fontSize.set('md', 0.2)}rem;
	text-align: center;
	font-weight: ${({ theme }) => theme.font.normal};
	margin-top: 4rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.xl}rem;
		`
	)}
`;

export const NoContentDescription = styled.p`
	opacity: 0.6;
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
	text-align: center;
	font-weight: ${({ theme }) => theme.font.normal};
	margin-top: 2rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.md}rem;
		`
	)}
`;

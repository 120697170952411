import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

// Import components
import { Button } from 'components/elements';

// Import styles utilities
import { transitionMain, focusBasic } from 'components/styles';

export const NavToolbarStyled = styled.ul`
	list-style: none;
	display: flex;
`;

export const Item = styled.li`
	display: flex;
	align-items: center;
	color: ${({ theme: { body } }) => body.header.icon};
	margin: auto 0;
	${({ withLine }) =>
		withLine &&
		css`
			position: relative;
			&::after {
				content: '';
				position: absolute;
				right: 0;
				top: 50%;
				-webkit-transform: translate(0, -50%);
				-ms-transform: translate(0, -50%);
				transform: translate(0, -50%);
				height: 2.7rem;
				width: 0.1rem;
				background-color: white;
			}
		`}
`;

export const StyledToolbarLink = styled(Link)`
	margin: 0 2rem;
`;

export const StyledButton = styled(Button)`
	margin-left: 2.1rem;
	max-width: ${({ theme }) => (theme.isWCAG ? 'auto' : '11.8rem')};
	font-weight: ${({ theme }) => theme.font.medium};
	line-height: 2.3rem;
	border-radius: 0.6rem;
	padding: 0.7rem 1.4rem;
`;

export const StyledMenuButton = styled(Button)`
	margin-right: 0;
`;

export const StyledMenu = styled(Menu)`
	margin-top: 2.5rem;

	&.ant-menu {
		padding: 1.5rem 3rem 3rem 3rem;
	}

	.ant-menu-item-divider {
		background-color: ${({ theme }) => theme.line};
	}
`;

export const ButtonLogout = styled(Button)`
	font-size: ${({ theme }) => theme.fontSize.nav.logOutBtn}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	background-color: transparent;
	color: ${({ theme: { dropdown } }) => dropdown.menu.itemColor};
	padding: 0.5rem 1.2rem;
	width: 100%;
	text-align: left;
	border: none;

	&:hover,
	&:focus {
		color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
	}
`;

export const MenuDivider = styled.li`
	width: 100%;
	height: 0.1rem;
	margin: 0.4rem 0;
	background-color: ${({ theme }) => theme.line};
`;

export const ItemStyled = styled(Menu.Item)`
	&.ant-menu-item {
		${transitionMain}
		overflow: visible;

		a {
			color: ${({ theme }) => theme.white};
			margin: -0.5rem -1.2rem;
			padding: 0.5rem 1.2rem;

			&:focus,
			&:focus-visible {
				color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
				${focusBasic}
			}

			&:hover {
				background-color: ${({ theme: { dropdown } }) =>
					dropdown.menu.itemHoverBg};
				color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
			}
		}
	}

	${({ theme }) =>
		theme.isWCAG &&
		css`
			margin-bottom: 1.5em;
		`}
`;

export const LogoutItem = styled(ItemStyled)`
	padding: 0 !important;
`;

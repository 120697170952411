import styled from 'styled-components';

export const Wrapper = styled.section`
	position: relative;
	background-color: ${({ theme }) => theme.videoBanner.bg};
	width: 100%;
	/* 16:9 aspect ratio */
	height: 56.25vw;
	margin-bottom: 3.2rem;
`;

export const Video = styled.video`
	width: 100%;
`;

export const Fog = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.videoBanner.fog.bg};
`;

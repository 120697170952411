import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Import heleprs
import { FLOAT_WIDGET } from 'helpers/variables';
import { $, RESIZE_THROTTLE_TIME, getElementCoordinates } from './helpers';

import {
	setFloatWidgetPlayerOpen,
	setFloatWidgetFloatMode,
	setFloatWidgetPageLoaded,
	setFloatWidgetInitPageMode,
	setFloatWidgetCoordinates
} from 'store/actions';

// Import utilities
import { useViewportResize } from 'components/utilities';

const useFloatWidget = () => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const {
		isPlayerOpen,
		isFloatMode,
		isPageLoaded,
		coordinates,
		playLiveEvent
	} = useSelector(({ float_widget }) => float_widget);

	useViewportResize({
		callback: handleWindowResize,
		throttleTime: RESIZE_THROTTLE_TIME
	});

	useEffect(() => {
		const wrapper = $(FLOAT_WIDGET.WRAPPER);
		const spinner = $(FLOAT_WIDGET.SPINNER);
		const element = $(FLOAT_WIDGET.ELEMENT);
		const isOpenPlayerLive = playLiveEvent && element && !isPageLoaded;
		const isOpenPlayer = wrapper && !spinner && element && !isPageLoaded;

		if (isOpenPlayerLive || isOpenPlayer) {
			handleSetElementCoordinates(element);
			setFloatWidgetPageLoaded(true)(dispatch);
		}
	});

	useEffect(() => {
		const element = $(FLOAT_WIDGET.ELEMENT);

		if (isPlayerOpen) {
			setFloatWidgetFloatMode(true)(dispatch);
		}

		// Close float mode if returned to product details player
		if (element) {
			handleCloseFloatMode();
		}

		// eslint-disable-next-line
	}, [pathname]);

	useEffect(() => {
		isPageLoaded && setFloatWidgetPlayerOpen(true)(dispatch);
		// eslint-disable-next-line
	}, [isPageLoaded]);

	const handleSetElementCoordinates = (element) => {
		const elementCoordinates = getElementCoordinates(element);
		setFloatWidgetCoordinates(elementCoordinates)(dispatch);
	};

	const handleCloseFloatMode = () => setFloatWidgetInitPageMode()(dispatch);

	function handleWindowResize() {
		const element = $(FLOAT_WIDGET.ELEMENT);
		if (element) {
			const elementCoordinates = getElementCoordinates(element);
			setFloatWidgetCoordinates(elementCoordinates)(dispatch);
		}
	}

	return {
		coordinates,
		isFloatMode,
		isPlayerOpen,
		closeFloatMode: handleCloseFloatMode
	};
};

export default useFloatWidget;

import styled, { css } from 'styled-components';

export const OldPrice = styled.del`
	margin: 0 0.5rem;
	color: ${({ color }) => (color ? color : 'inherit')};
`;

const regularPriceBasic = () => css`
	margin: ${({ isUserProfile }) => (isUserProfile ? 0 : '0 0.5rem')};
`;

export const RegularPrice = styled.p`
	${regularPriceBasic}
`;

export const RegularPriceSpan = styled.span`
	${regularPriceBasic}
`;

import * as types from 'store/actions/types';
import services from 'services/services';
import axios from 'axios';

// Import helpers
import {
	setTvProgramsTime,
	convertParamsDateFormat,
	machtChannelsEpg
} from 'store/helpers/epg';

let source = axios.CancelToken.source();

// ******************** FETCH EPG PROGRAMMS ********************
export const fetchEpgPrograms = ({ date = null } = {}) => async (
	dispatch,
	getState
) => {
	try {
		// Cancel config request
		source.cancel();
		source = axios.CancelToken.source();
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_EPG_PROGRAMS_LOADING,
			payload: true
		});

		// Get program time, start , end
		const { since, till, isToday } = setTvProgramsTime(date);
		// Get tiemstamps
		const startDate = convertParamsDateFormat(since);
		const endDate = convertParamsDateFormat(till);

		// Fetch programs
		const { data: epg } = await services.get(
			`/epg?startDate=${startDate}&endDate=${endDate}`,
			{
				cancelToken: source.token
			}
		);

		const {
			epg: {
				data: { lives }
			}
		} = getState();

		// Match epg to channel
		const matchedChannelsEpg = machtChannelsEpg(lives, epg);

		// Dispatch an action
		dispatch({
			type: types.FETCH_EPG_PROGRAMS_SUCCESS,
			payload: {
				data: { now: since, lives: matchedChannelsEpg },
				since,
				till,
				isToday
			}
		});
	} catch (error) {
		if (error.response) {
			// Dispatch an error action
			dispatch({
				type: types.FETCH_EPG_PROGRAMS_ERROR,
				payload: true
			});
		}
	}
};

export const clearEpgPrograms = () => ({
	type: types.CLEAR_EPG_PROGRAMS,
	payload: true
});

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import helpers
import { PAGE_META_TAGS } from 'helpers/meta_tags';
import { DOCUMENTS_PATHS } from 'components/routes/paths';

// Import images
import COMMON_LOGO from 'assets/images/ckf_logo.png';

const { COMMON } = PAGE_META_TAGS;

const useHelemtMetaTags = ({
	title,
	description,
	keywords,
	image,
	isTranslationKey
}) => {
	const { t } = useTranslation();

	const setTranslation = (str) => (isTranslationKey ? t(str) : str);

	const titleMeta = title || COMMON.title;
	const nameMeta = title || COMMON.title;
	const descriptionMeta = description || COMMON.description;
	const keywordsMeta = keywords || COMMON.keywords;
	const imageMeta = image || COMMON_LOGO;

	const accessibilityStatement = {
		name: t('common_accessibility_statement_meta_tag_name'),
		url: `${process.env.REACT_APP_WEBSITE_URL}${DOCUMENTS_PATHS.ACCESSIBILITY_STATEMENT}`
	};

	return {
		titleMeta: setTranslation(titleMeta),
		nameMeta: setTranslation(nameMeta),
		descriptionMeta: setTranslation(descriptionMeta),
		keywordsMeta: setTranslation(keywordsMeta),
		imageMeta,
		accessibilityStatement
	};
};
useHelemtMetaTags.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	keywords: PropTypes.string,
	image: PropTypes.string,
	isTranslationKey: PropTypes.bool
};

export default useHelemtMetaTags;

import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	data: [],
	isLoaded: false,
	isError: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.FETCH_WALLET_TRANSACTIONS_LOADING:
				return INITIAL_STATE;

			case types.FETCH_WALLET_TRANSACTIONS_SUCCESS:
				draft.isLoaded = true;
				draft.data = action.payload;
				return;

			case types.FETCH_WALLET_TRANSACTIONS_ERROR:
				draft.isError = true;
				draft.isLoaded = true;
				return;

			default:
				return state;
		}
	});

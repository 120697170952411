import React from 'react';
import { bool } from 'prop-types';

// Import components
import GalleryThumbnails from './GalleryThumbnails';
import SlideProvider from '../SlideProvider';

// Import styles
import { DrawerContainer } from './styles';

// Import logic
import { useGallery } from 'components/elements/organisms/product_gallery/hooks';

const DrawerFullscreen = ({ isVideo = false }) => {
	const {
		sliderRef,
		thumbnailAssets,
		type,
		afterSlideChange,
		beforeSlideChange
	} = useGallery();

	return (
		<DrawerContainer isVideo={isVideo}>
			<SlideProvider
				sliderRef={sliderRef}
				type={type}
				afterSlideChange={afterSlideChange}
				beforeSlideChange={beforeSlideChange}
				settings={{ arrows: false }}
				isFullScreen
			/>
			<GalleryThumbnails
				sliderRef={sliderRef}
				thumbnailAssets={thumbnailAssets}
				isVideo={isVideo}
			/>
		</DrawerContainer>
	);
};

DrawerFullscreen.propTypes = {
	isVideo: bool
};

export default DrawerFullscreen;

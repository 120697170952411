import styled, { css } from 'styled-components';

// Import styles
import { focusBasic } from 'components/styles';

const CIRCLE_SIZE = '1.8rem';
const CIRCLE_MARGIN = '0.3rem';

const pseudoElementStyles = ({ size = '100%', radius = '50%', color }) => css`
	content: '';
	display: inline-block;
	transition: 0.2s ease-in-out;
	width: ${size};
	height: ${size};
	border-radius: ${radius};
	background: ${({ theme: { toggler } }) => toggler[color]};
	border: 1px solid transparent;
`;

export const Checkbox = styled.input`
	position: relative;
	width: 4.2rem;
	height: 2.4rem;
	cursor: pointer;
	${({ theme: { isWCAG } }) => isWCAG && 'font-size: initial !important'};

	&:disabled {
		opacity: 0.4;
		cursor: not-allowed;
	}

	&:focus-visible {
		${focusBasic}
	}

	&::before {
		${({ isUserApproval }) =>
			isUserApproval
				? pseudoElementStyles({ size: CIRCLE_SIZE, color: `userApproval` })
				: pseudoElementStyles({ size: CIRCLE_SIZE, color: `control` })}
		position: absolute;
		top: 50%;
		left: ${CIRCLE_MARGIN};
		transform: translateY(-50%);
		box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
	}

	&::after {
		${pseudoElementStyles({ radius: '1.5rem', color: 'bg' })}
	}

	&:checked {
		&::before {
			left: calc(100% - ${CIRCLE_SIZE} - ${CIRCLE_MARGIN});
		}

		&::after {
			background: ${({ theme }) => theme.toggler.bgActive};
		}
	}
`;

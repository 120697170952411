import styled from 'styled-components';

export const StyledNav = styled.div`
	display: block;
	position: fixed;
	top: 0;
	left: -10001px;
	z-index: 99999;
	a {
		color: ${({ theme }) => theme.black};
		text-align: center;
		&:focus-visible {
			top: 1.75rem;
			left: 10016px;
			position: absolute;
			display: block;
			width: 25rem;
			background-color: ${({ theme }) => theme.white};
			padding: 1.5rem;
			border-radius: 0.6rem;
			border: 3px solid ${({ theme }) => theme.primaryBlueLight};
			box-shadow: 0 0 4px ${({ theme }) => theme.primaryBlueLight};
			background-color: ${({ theme }) => theme.primaryBlueLight};
		}
	}
`;

export const StyledList = styled.ul`
	list-style: none;
`;

import styled from 'styled-components';
import { Listbox, ListboxOption } from '@reach/listbox';

export const StyledListbox = styled(Listbox)`
	color: ${({ theme: { mobileSearchSelect } }) => mobileSearchSelect.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};

	[data-reach-listbox-button] {
		width: 100%;
		height: 4.4rem;
		border-radius: 5px;
		cursor: pointer;

		&:focus,
		&:focus-visible {
			border-color: ${({ theme: { mobileSearchSelect } }) =>
				mobileSearchSelect.focusBorderColor};
			box-shadow: none;
			outline: none;
		}
	}

	[data-reach-listbox-arrow] {
		font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
		border-left: 1px solid
			${({ theme: { mobileSearchSelect } }) => mobileSearchSelect.color};
		padding: 0.6rem 0.6rem 0.4rem 1.2rem;
	}
`;

export const StyledListboxOption = styled(ListboxOption)`
	background-color: ${({ theme: { mobileSearchSelect } }) =>
		mobileSearchSelect.bgColor};
	border: 1px solid
		${({ theme: { mobileSearchSelect } }) => mobileSearchSelect.borderColor};

	color: ${({ theme: { mobileSearchSelect } }) => mobileSearchSelect.color};
	font-weight: ${({ theme }) => theme.font.normal};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	height: 4.4rem;
	padding: 1.2rem 2.6rem !important;
	cursor: pointer;

	&[data-current-selected] {
		font-weight: ${({ theme }) => theme.font.bold};
		background-color: ${({ theme: { mobileSearchSelect } }) =>
			mobileSearchSelect.bgColor};
		color: ${({ theme: { mobileSearchSelect } }) =>
			mobileSearchSelect.activeColor};
	}

	&[data-current-nav] {
		background-color: ${({ theme: { mobileSearchSelect } }) =>
			mobileSearchSelect.bgColor};
		color: ${({ theme: { mobileSearchSelect } }) =>
			mobileSearchSelect.selectColor};
	}

	&:first-child {
		border-radius: 5px 5px 0 0;
	}

	&:last-child {
		border-radius: 0 0 5px 5px;
	}

	&:hover,
	&:focus,
	&:focus-visible {
		background-color: ${({ theme: { mobileSearchSelect } }) =>
			mobileSearchSelect.hoverBgColor};
		color: ${({ theme: { mobileSearchSelect } }) =>
			mobileSearchSelect.selectColor};
	}
`;

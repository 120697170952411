import React from 'react';
import { array, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Import context
import useGalleryContext from 'components/elements/organisms/product_gallery/context/useGalleryContext';

// Import styles
import { ThumbnailsGrid, ThumbnailTab } from './styles';

const ThumbnailsList = ({ thumbnailAssets, sliderRef, titleRef }) => {
	const { t } = useTranslation();

	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	const { galleryIndex, getAssetMetadata } = useGalleryContext();

	const moveSlide = (index) => () => {
		if (sliderRef?.current) {
			sliderRef.current.slickGoTo(index);
		}
		if (titleRef?.current) {
			titleRef.current.scrollIntoView({
				behavior: 'smooth'
			});
		}
	};

	if (thumbnailAssets.length <= 1) return null;

	return (
		<ThumbnailsGrid role="list">
			{thumbnailAssets.map(({ id, url, metadata }, index) => {
				const { description } = getAssetMetadata(metadata);
				return (
					<li key={id}>
						<ThumbnailTab
							data-selected={galleryIndex === index}
							aria-current={galleryIndex === index}
							onClick={moveSlide(index)}
							title={t('aria_gallery_show_slide')}
							isContrast={isContrast}
						>
							<img src={url} alt={description} />
						</ThumbnailTab>
					</li>
				);
			})}
		</ThumbnailsGrid>
	);
};

ThumbnailsList.defaultProps = {
	thumbnailAssets: []
};

ThumbnailsList.propTypes = {
	thumbnailAssets: array,
	sliderRef: object,
	titleRef: object
};

export default React.memo(ThumbnailsList);

import React from 'react';

// Import helpres
import { PLAYER_SELECTORS } from 'helpers/variables';

// Import components
import { FloatWidget } from 'components/elements';
import FloatPlayer from './FloatPlayer';

const playerVodType = PLAYER_SELECTORS.VOD;

const FloatPlayerWidget = () => (
	<FloatWidget>
		<FloatPlayer playerType={playerVodType} />
	</FloatWidget>
);

export default FloatPlayerWidget;

/* eslint-disable */
import React from 'react';
import { object, number, bool } from 'prop-types';

// Import components
import { Video2D, Video360, Image, Graphics360 } from '../components/Slides';

// Import assets
import placeholder from 'assets/images/placeholder.png';

// Import styles
import { Figure } from '../styles';

// Import context
import useGalleryContext from '../context/useGalleryContext';

// Import helpers
import { ASSET_GROUP_TYPES } from 'helpers/variables';

const { GRAPHIC_2D, VIDEO_2D, VIDEO_360, GRAPHIC_360 } = ASSET_GROUP_TYPES;

const renderFrame = ({ type, asset, index }) => {
	const { url, metadata, subtitles } = asset;
	switch (type) {
		case GRAPHIC_2D:
			return <Image src={url} alt={metadata?.description} />;
		case GRAPHIC_360:
			return <Graphics360 url={url} />;
		case VIDEO_2D:
			return (
				<Video2D
					url={url}
					index={index}
					placeholder={metadata?.preview_url ?? placeholder}
					subtitles={subtitles}
				/>
			);
		case VIDEO_360:
			return <Video360 url={url} index={index} />;
		default:
			return null;
	}
};

const GalleryFrame = ({ asset, index, isFullScreen }) => {
	const { type, isVideo, isNavOpen } = useGalleryContext();

	return (
		<>
			<Figure
				isFullScreen={isFullScreen}
				isVideo={isVideo}
				isNavOpen={isNavOpen}
			>
				{renderFrame({ type, asset, index })}
			</Figure>
		</>
	);
};

GalleryFrame.propTypes = {
	asset: object,
	index: number,
	isFullScreen: bool
};

export default GalleryFrame;

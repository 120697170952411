import styled, { css } from 'styled-components';

// Import components
import { Button } from 'components/elements';

// Import styles
import {
	flex,
	customMinResponsive,
	size,
	customResponsive,
	focusBasic
} from 'components/styles';

// Import icons
import { ReactComponent as ArrowRightSVG } from 'assets/icons/arrow_xl_r.svg';

export const Container = styled.div`
	position: relative;
	text-align: center;
	${flex({ jc: 'space-between', fd: 'column' })}

	${({ theme }) => theme.isWCAG && 'min-height: 10rem '}

	${customResponsive(
		size.medium,
		css`
			flex-direction: row;
			height: 5.8rem;
			padding-left: 1.8rem;

			&:nth-of-type(even) {
				border-top: 1px solid;
				border-bottom: 1px solid;
				border-color: ${({ theme }) =>
					theme.payment.paymentOptions.borderColorMobile};
			}
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			min-height: ${({ theme }) => (theme.isWCAG ? 'fit-content' : '3rem')};
			width: ${({ theme }) => (theme.isWCAG ? '80vw' : '37rem')};
			padding: 4rem 0 2.5rem 0;
			flex: 1 1 0px;

			&:nth-of-type(even) {
				border-left: 1px solid;
				border-right: 1px solid;
				border-color: ${({ theme }) =>
					theme.payment.paymentOptions.borderColor};

				${({ theme }) =>
					theme.isWCAG &&
					css`
						border: none;
						border-top: 1px solid;
						border-bottom: 1px solid;
						border-color: ${({ theme }) =>
							theme.payment.paymentOptions.borderColor};
					`}
			}
		`
	)}
`;

export const Name = styled.h3`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	color: ${({ theme }) => theme.payment.paymentOptions.name.color};
	line-height: 1.2;
	${({ theme }) => theme.isWCAG && 'margin-bottom: 0 !important'}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.md}rem;
			display: ${({ isDesktop }) => (!isDesktop ? 'none' : 'block')};
			${({ theme }) => theme.isWCAG && 'margin-bottom: 2em !important'}
		`
	)}

	${customResponsive(
		size.medium,
		css`
			display: ${({ isDesktop }) => (isDesktop ? 'none' : 'block')};
			flex: 1;
			text-align: left;
			padding-left: 2.3rem;
			font-weight: ${({ theme }) => theme.font.normal};
		`
	)}
`;

export const Info = styled.p`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	color: ${({ theme }) => theme.payment.paymentOptions.info.color};
	font-weight: ${({ theme }) => theme.payment.paymentOptions.info.fontWeight};
	margin-bottom: 2rem;
`;

export const Price = styled.p`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	color: ${({ theme }) => theme.payment.paymentOptions.price.color};
	display: inline-block;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
			margin-top: 0.8rem;
			margin-bottom: 2.1rem;
		`
	)}
`;

export const Bottom = styled.div`
	text-align: center;
`;

export const PromotionPrice = styled.strong`
	color: ${({ theme }) => theme.promotionPrice};
	margin-left: 1rem;
`;

export const ArrowButton = styled(Button)`
	padding: 1.8rem;
	border-radius: 0;

	&:hover {
		background-color: ${({ theme }) => theme.gray};
	}

	&:focus-visible {
		${focusBasic}
	}

	${customMinResponsive(
		size.medium,
		css`
			display: none;
		`
	)}
`;

export const FlexContainer = styled.div`
	width: ${({ isOtherBluemedia }) => (isOtherBluemedia ? '100%' : 'auto')};

	${customResponsive(
		size.medium,
		css`
			${flex()}
		`
	)}
`;

export const IconArrowRight = styled(ArrowRightSVG)`
	width: 8.25px;
	height: 1.45rem;
`;

import React from 'react';
import { string, func, object, bool } from 'prop-types';

// Import helpers
import { ASSET_GROUP_TYPES } from 'helpers/variables';
import GallerySlickSlider from './Sliders/GallerySlickSlider';
import Gallery360Slider from './Sliders/Gallery360Slider';

const { GRAPHIC_360, VIDEO_360 } = ASSET_GROUP_TYPES;

const SlideProvider = ({
	type,
	afterSlideChange,
	beforeSlideChange,
	settings,
	sliderRef,
	arrowPrevRef,
	arrowNextRef,
	isFullScreen = false
}) => {
	switch (type) {
		case VIDEO_360:
		case GRAPHIC_360:
			return (
				<Gallery360Slider
					type={type}
					settings={settings}
					sliderRef={sliderRef}
					arrowPrevRef={arrowPrevRef}
					arrowNextRef={arrowNextRef}
					isFullScreen={isFullScreen}
				/>
			);
		default:
			return (
				<GallerySlickSlider
					type={type}
					afterSlideChange={afterSlideChange}
					beforeSlideChange={beforeSlideChange}
					settings={settings}
					sliderRef={sliderRef}
					arrowPrevRef={arrowPrevRef}
					arrowNextRef={arrowNextRef}
					isFullScreen={isFullScreen}
				/>
			);
	}
};

SlideProvider.defaultProps = {
	afterSlideChange: () => {},
	beforeSlideChange: () => {},
	settings: {}
};

SlideProvider.propTypes = {
	type: string,
	afterSlideChange: func,
	beforeSlideChange: func,
	settings: object,
	sliderRef: object,
	arrowPrevRef: object,
	arrowNextRef: object,
	isFullScreen: bool
};

export default SlideProvider;

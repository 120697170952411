import sliderReducer from './sliderReducer';
import * as TYPES from './sliderActionTypes';
import {
	STRIPES_URL,
	FRAMES_PER_STRIPE,
	TIME_PER_FRAME,
	KEY_CODES
} from 'helpers/variables';

const { ARROW_LEFT, ARROW_RIGHT, ARROW_UP, ARROW_DOWN } = KEY_CODES;

// initial state for useSlider
const initialState = {
	contWidth: 0,
	draggable: false,
	translateX: 0,
	shownPercent: 0,
	cursorPosition: 0,
	isPopoverVisible: false
};

// get preview stripe url and current preview frame
const getPreviewData = ({ seekTime, productID, videoId }) => {
	// display time of single stripe
	const timePerStripe = TIME_PER_FRAME * FRAMES_PER_STRIPE;
	// current stripe index based on cursor position
	const currentIndex = getCurrentStripeIndex(seekTime, timePerStripe);
	// number of current frame to display
	const currentFrame =
		parseInt(seekTime / TIME_PER_FRAME) - currentIndex * FRAMES_PER_STRIPE;
	// preview image url
	const imgUrl = `${STRIPES_URL}/${productID}/${videoId}/stripes/stripe_${currentIndex}.jpg`;

	return { imgUrl, currentFrame };
};

// get current stripe index
const getCurrentStripeIndex = (seekTime, timePerStripe) => {
	// current stripe index based on cursor position
	const index = Math.ceil(seekTime / timePerStripe - 1);
	return index < 1 ? 0 : index;
};

const FORWARD_KEYS = [ARROW_RIGHT, ARROW_UP];
const SEEK_KEYS = [ARROW_LEFT, ARROW_RIGHT, ARROW_UP, ARROW_DOWN];

export {
	initialState,
	sliderReducer,
	TYPES,
	getPreviewData,
	FORWARD_KEYS,
	SEEK_KEYS
};

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Import helpers
import { DEFAULT_PRICE_VALUES } from 'helpers/prices';

// Import utilities
import { useAuth } from 'components/utilities';

const useProductPrices = ({ product }) => {
	const { isAuth } = useAuth();

	const userPaymentMethods = useSelector(
		({ auth }) => auth?.authenticate?.payment_methods ?? []
	);

	const { prices_by_payment_methods: productPrices } = product;

	return useMemo(() => {
		const prices = Object.entries(productPrices).map(([type, methods]) => {
			if (methods) {
				const availableMethods = isAuth
					? userPaymentMethods
							.map((method) => methods[method])
							.filter((method) => method)
					: methods;

				const [lowestPrice] = _.sortBy(availableMethods, 'price');

				return [type, lowestPrice ?? DEFAULT_PRICE_VALUES];
			}

			return [type, DEFAULT_PRICE_VALUES];
		});

		return Object.fromEntries(prices);
	}, [isAuth, productPrices, userPaymentMethods]);
};

export default useProductPrices;

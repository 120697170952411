import React from 'react';
import { object } from 'prop-types';

// Import logic
import { useGallery } from 'components/elements/organisms/product_gallery/hooks';

// Import components
import SlideProvider from '../SlideProvider';
import ThumbnailsList from './ThumbnailsList';

function GridFullscreen({ titleRef }) {
	const {
		sliderRef,
		thumbnailAssets,
		type,
		afterSlideChange,
		beforeSlideChange
	} = useGallery();

	return (
		<div>
			<SlideProvider
				sliderRef={sliderRef}
				type={type}
				afterSlideChange={afterSlideChange}
				beforeSlideChange={beforeSlideChange}
				settings={{
					arrows: false
				}}
			/>
			<ThumbnailsList
				sliderRef={sliderRef}
				thumbnailAssets={thumbnailAssets}
				titleRef={titleRef}
			/>
		</div>
	);
}

GridFullscreen.propTypes = {
	titleRef: object
};

export default GridFullscreen;

import styled, { css } from 'styled-components';

// Import components
import { Button } from 'components/elements';

// Import styles
import { customMinResponsive, size } from 'components/styles';

export const ButtonStyled = styled(Button)`
	position: relative;
	margin-bottom: 2rem;
	width: 100%;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.1)}rem;
	padding: 0.5rem;
	min-height: 4.1rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.xs}rem;
		`
	)}
`;

export const Title = styled.h2`
	font-size: ${({ theme }) => theme.fontSize.xs}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	line-height: 2.1rem;
	color: ${({ theme }) => theme.body.footer.color};
	text-transform: uppercase;
	margin-bottom: ${({ theme }) => (theme.isWCAG ? 3 : 1.3)}rem;
`;

export const SuccessMessage = styled.p`
	color: ${({ theme }) => theme.white}
	font-size: ${({ theme }) => theme.fontSize.s};
	margin-bottom: 2rem;
`;

import React, { useEffect, useRef } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

// Import routes
import AppRoutes from 'components/routes/app_routes/AppRoutes';

// Import components
import { AppTemplate, ContentTemplate } from 'components/templates';
import TabNav from 'components/layout/tab_nav/TabNav';
import Header from 'components/layout/header/Header';
import Footer from 'components/layout/footer/Footer';

// Import styles
import { Wrapper } from './styles';

// Import variables
import { SECTIONS_ID } from 'helpers/variables';

// Import helpers
import { LOGIN_ROUTES } from 'components/layout/header/helpers';

// Import utilities
import { usePrevious } from 'components/utilities';

const App = () => {
	const loginMatch = useRouteMatch(LOGIN_ROUTES);
	const autoFocusRef = useRef(null);
	const { pathname } = useLocation();
	const previousPath = usePrevious(pathname || '');

	useEffect(() => {
		// Sets focus on main content
		if (previousPath !== '/' && autoFocusRef.current) {
			autoFocusRef.current.focus();
		}

		// Eliminates focus scroll (which is equal to Header height)
		window.scrollTo(0, 0);

		// eslint-disable-next-line
	}, [pathname]);

	return (
		<AppTemplate>
			<TabNav />
			<Header mainContainerRef={autoFocusRef} />
			<Wrapper
				id={SECTIONS_ID.CONTENT}
				tabIndex={-1}
				ref={autoFocusRef}
				hideNav={loginMatch}
			>
				<ContentTemplate>
					<NiceModal.Provider>
						<AppRoutes />
					</NiceModal.Provider>
				</ContentTemplate>
			</Wrapper>
			<Footer />
		</AppTemplate>
	);
};

export default App;

import localePl from 'date-fns/locale/pl';
import i18n from 'i18next';
import RelativeTimeFormat from 'relative-time-format';
import en from 'relative-time-format/locale/en.json';
import pl from 'relative-time-format/locale/pl.json';
import ru from 'relative-time-format/locale/ru.json';
import uk from 'relative-time-format/locale/uk.json';
import {
	getTime,
	isWithinInterval,
	isValid,
	format,
	differenceInYears,
	isBefore,
	parse
} from 'date-fns';
import { enGB } from 'date-fns/locale';

// Import variables
import {
	DATE_FORMAT,
	ADULT_AGE,
	INTL_STYLES,
	PERIOD_UNITS
} from 'helpers/variables';

const { HOURS_MIN } = DATE_FORMAT;

const RELATIVE_TIME_FORMAT_LOCALES = { en, pl, ru, uk };

// convert time to MS
export const convertTimeToMiliseconds = (date) => getDate(date).getTime();

// convert MS to time - days, hours, minutes, seconds
export const convertMsToTime = (duration) => {
	const seconds = Math.floor((duration / 1000) % 60);
	const minutes = Math.floor((duration / (1000 * 60)) % 60);
	const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
	const days = Math.floor(duration / (1000 * 60 * 60 * 24));

	return { days, hours, minutes, seconds };
};

// Convert time unit format
export const unitTimeFormat = (time) => (time < 10 ? `0${time}` : time);

// Convert time to since and till
export const convertTime = (since, till) => {
	if (!since || !till) return '';

	const sinceHours = format(new Date(since), HOURS_MIN);
	const tillHours = format(new Date(till), HOURS_MIN);

	return `${sinceHours} - ${tillHours}`;
};

// Format vod timeline / progress time
export const getTimeLineFormat = (currentTime) => {
	const formatTime = (time) => (time >= 10 ? time : `0${time}`);
	const hours = Math.floor(currentTime / 60 / 60);
	const minutes = Math.floor(currentTime / 60) - hours * 60;
	const seconds = Math.floor(currentTime % 60);

	return `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
};

// Convert time to h:mm
export const convertDateTimeFormat = (since) => {
	const date = new Date(since);
	const minutes = date.getMinutes();
	const checkMinutes = date.getMinutes() > 9 ? minutes : `0${minutes}`;
	return `${date.getHours()}:${checkMinutes}`;
};

// Convert Date to  yyyy-mm-dd format
export const convertDateFormat = (since) => {
	const date = new Date(since);
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) month = `0${month}`;

	if (day < 10) day = `0${day}`;

	return `${date.getFullYear()}-${month}-${day}`;
};

export const formatedDate = (since) =>
	format(
		// new Date must get date in format '1995-12-17T03:24:00' (safari)
		new Date(since.replace(/\s/g, 'T')),
		'Do MMMM YYYY',
		{
			locale: localePl
		}
	);

// Parse DD/MM/YYYY date format to valid Date instance
export const parseStringToDate = (stringDate) =>
	parse(stringDate, 'P', new Date(), { locale: enGB });

export const convertFromMomentDateToLocale = (date) =>
	new Date(date).toLocaleDateString().replace(/\//g, '-');

export const getDate = (date) => new Date(date);

export const calculateTime = (since, till) =>
	(getTime(till) - getTime(since)) / 1000 / 60;

export const convertSecondsToHours = (seconds) => {
	const hours = Math.floor(seconds / 3600);
	const min = Math.floor((seconds % 3600) / 60);

	return `${hours} godz. ${min} min.`;
};

export const isAdult = (birthDate) => {
	const today = new Date();
	const userAge = differenceInYears(today, new Date(birthDate));
	const isAdult = userAge >= ADULT_AGE;

	return isAdult;
};

export const isDateValid = (date) => (date ? isValid(new Date(date)) : false);

export const checkIsWithinTimePeriod = ({ since, till }) => {
	const isValidDates = isDateValid(since) && isDateValid(till);

	if (isValidDates) {
		return isWithinInterval(new Date(), {
			start: new Date(since),
			end: new Date(till)
		});
	}

	return false;
};

export const checkIsBeforeAvailability = ({ since }) => {
	return since ? isBefore(new Date(), new Date(since)) : false;
};

export const getFormatedDate = (date, formatString) => {
	if (date && formatString) {
		// IS REQUIRED FOR OLD SAFARI BROWSERS
		const dateToFormat =
			typeof date === 'string' ? date.replace(/-/g, '/') : date;

		return format(new Date(dateToFormat), formatString);
	}

	return '';
};

export const convertDuration = (duration = 0, isPlayer = false) => {
	const divider = 60;
	const hoursKey = i18n.t('vod_movieDetails_hours');
	const minutesKey = i18n.t('vod_movieDetails_minutes');
	const secondsKey = i18n.t('vod_movieDetails_seconds');

	let hours = 0;
	let minutes = 0;
	let seconds = 0;

	if (duration) {
		seconds = parseInt(duration % divider, 10);

		const min = Math.ceil(duration / divider);

		if (min) {
			minutes = min % divider;

			const isHours = min >= divider;

			if (isHours) {
				hours = parseInt(min / divider, 10);
			}
		}
	}

	const hoursString = hours ? `${hours} ${hoursKey}` : '';
	const minutesString = minutes ? `${minutes} ${minutesKey}` : '';
	const secondsString = isPlayer ? `${seconds} ${secondsKey}` : '';

	return `${hoursString} ${minutesString} ${secondsString}`.trim();
};

export const convertDateToLocale = (dateString) => {
	const date = dateString.replace(/-/g, '/');

	const newDate = new Date(date);

	// get time zone offset in minutes and convert to hours
	const timezoneOffset = newDate.getTimezoneOffset() / 60;

	// get hours from date to convert
	const hours = newDate.getHours();

	// subtract offest from hours to get locale date
	newDate.setHours(hours - timezoneOffset);

	return newDate;
};

export const getUnitLocale = ({
	period,
	period_unit = PERIOD_UNITS.HOUR,
	style = INTL_STYLES.SHORT
}) => {
	const { language } = i18n;

	const locale = RELATIVE_TIME_FORMAT_LOCALES[language];

	RelativeTimeFormat.addLocale(locale);

	if (period && period_unit) {
		const rtf1 = new RelativeTimeFormat(language, { style });
		const { 2: unitLocale } = rtf1.format(period, period_unit).split(' ');
		return unitLocale;
	}

	return '';
};

export const getPeriodWithUnit = ({
	period,
	period_unit = PERIOD_UNITS.HOUR,
	style = INTL_STYLES.SHORT
}) => {
	if (!period) return '';
	const unitLocale = getUnitLocale({ period, period_unit, style });
	return `${period} ${unitLocale}`;
};

import styled, { css } from 'styled-components';

// Import styles
import { transitionMain, size, customMinResponsive } from 'components/styles';

export const ArrowController = styled.button`
	position: absolute;
	display: flex !important;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	width: 4rem;
	height: 4rem;
	top: 50%;

	${customMinResponsive(
		size.medium,
		css`
			width: 9rem;
			height: 9rem;
		`
	)}

	svg {
		height: 100%;
		width: 100%;
		circle,
		path {
			${transitionMain}
		}
	}

	${({ isPrevArrow, theme }) =>
		isPrevArrow
			? css`
					left: 0;
					margin-left: ${theme.isWCAG ? 0 : 1}rem;
			  `
			: css`
					right: 0;
					margin-right: ${theme.isWCAG ? 0 : 1}rem;
			  `};

	${customMinResponsive(
		size.medium,
		css`
			${({ isPrevArrow, theme }) =>
				isPrevArrow
					? css`
							left: 0;
							margin-left: ${theme.isWCAG ? 0 : 3}rem;
					  `
					: css`
							right: 0;
							margin-right: ${theme.isWCAG ? 0 : 3}rem;
					  `};
		`
	)}

	&:hover,
	&:focus {
		cursor: pointer;
		svg {
			circle:nth-child(1) {
				fill: ${({ theme }) => theme.primaryBlueLight};
				fill-opacity: 1;
			}
			path {
				stroke: ${({ theme }) => theme.black};
			}
		}
	}

	&:focus-visible {
		circle:nth-child(2) {
			stroke: ${({ theme }) => theme.primaryBlueLight};
			stroke-width: 0.3rem;
			stroke-dasharray: 6, 6;
			stroke-linejoin: round;
			stroke-dashoffset: 1.2rem;
			${({ theme }) => theme.isContrast && 'display: none'}
		}

		circle:nth-child(3) {
			stroke: ${({ theme }) => theme.black};
			stroke-width: 0.3rem;
			stroke-dasharray: 6, 6;
			stroke-linejoin: round;
			stroke-dashoffset: 1.2rem;
			${({ theme }) => !theme.isContrast && 'display: none'}
		}
	}

	&.slick-disabled,
	&:disabled,
	&::before {
		display: none !important;
	}
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import styles
import {
	Informations,
	InfoBox,
	Content,
	Divider,
	Author,
	Description
} from '../styles';

// Import context
import useGalleryContext from '../context/useGalleryContext';

// Import helpers
import { customHtmlParser } from 'helpers';

const GalleryFooter = () => {
	const { t } = useTranslation();
	const { assets, getAssetFooter, numberOfAssets, isArticle, galleryIndex } =
		useGalleryContext();
	const { metadata } = assets[galleryIndex];

	const { author, source, description } = getAssetFooter(metadata);
	const showAssetNumber = numberOfAssets > 1;

	return (
		<Informations isLightBackground={isArticle}>
			<Content>
				<InfoBox>
					{showAssetNumber && (
						<p>
							{galleryIndex + 1} / {numberOfAssets}
						</p>
					)}
				</InfoBox>

				<Author>
					{author && (
						<>
							<span>
								{t('vod_movieDetails_gallery_author')} {author}
							</span>
							{source && <Divider>/</Divider>}
						</>
					)}
					{source && <span>{customHtmlParser(source)}</span>}
				</Author>
			</Content>
			<Description>{description}</Description>
		</Informations>
	);
};

export default GalleryFooter;

import React, { useImperativeHandle, useMemo } from 'react';
import { object } from 'prop-types';

// Import styles
import { MockedSliderWrapper, SlideContent } from './styles';

// Import helpers
import { ASSET_GROUP_TYPES, SLIDER_ARROW_TYPES } from 'helpers/variables';
import useGalleryContext from 'components/elements/organisms/product_gallery/context/useGalleryContext';

// Import component
import GalleryArrow from '../GalleryArrow/GalleryArrow';
import GalleryFrame from '../GalleryFrame';

const { PREV, NEXT } = SLIDER_ARROW_TYPES;

// A mocked slick slider, renders a slingular slide into DOM, removes from DOM on slide change
const Gallery360Slider = ({
	sliderRef,
	settings,
	arrowPrevRef,
	arrowNextRef
}) => {
	const { galleryIndex, setGalleryIndex, assets, numberOfAssets } =
		useGalleryContext();
	const slides = useMemo(
		() =>
			assets.map((asset, index) => (
				<GalleryFrame key={asset.id} asset={asset} index={index} />
			)),
		[assets]
	);

	const hidePrev = galleryIndex <= 0;
	const hideNext = galleryIndex >= slides?.length - 1;
	const showArrows = settings?.arrows ?? true;

	const onPrevClick = () => {
		const isFirstSlide = galleryIndex === 1;

		if (isFirstSlide && arrowNextRef.current) {
			arrowNextRef.current.focus();
		}

		setGalleryIndex((prevIndex) => prevIndex - 1);
	};
	const onNextClick = () => {
		const isLastSlide = galleryIndex + 1 === numberOfAssets - 1;

		if (isLastSlide && arrowPrevRef.current) {
			arrowPrevRef.current.focus();
		}

		setGalleryIndex((prevIndex) => prevIndex + 1);
	};

	// Mock slick slider fuctionalities
	useImperativeHandle(sliderRef, () => ({
		slickGoTo: setGalleryIndex
	}));

	return (
		<MockedSliderWrapper ref={sliderRef}>
			{showArrows && (
				<GalleryArrow
					type={PREV}
					isPrevArrow
					onClick={onPrevClick}
					className="slick-arrow slick-prev"
					disabled={hidePrev}
					ref={arrowPrevRef}
				/>
			)}
			<SlideContent>{slides?.[galleryIndex]}</SlideContent>
			{showArrows && (
				<GalleryArrow
					type={NEXT}
					onClick={onNextClick}
					className="slick-arrow slick-next"
					disabled={hideNext}
					ref={arrowNextRef}
				/>
			)}
		</MockedSliderWrapper>
	);
};

Gallery360Slider.defaultProps = {
	type: ASSET_GROUP_TYPES.GRAPHIC_360,
	settings: {}
};

Gallery360Slider.propTypes = {
	settings: object,
	sliderRef: object,
	arrowPrevRef: object,
	arrowNextRef: object
};

export default Gallery360Slider;

import styled, { css } from 'styled-components';
import {
	SliderInput,
	SliderTrack,
	SliderRange,
	SliderHandle
} from '@reach/slider';

export const Wrapper = styled.div`
	position: relative;

	${({ isOpened }) =>
		isOpened &&
		css`
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 100%;
				transform: translateY(-50%);
			}
		`}
`;

export const InputSlider = styled(SliderInput)`
	height: 8rem !important;
	width: 0.5rem !important;
	margin-bottom: 1rem;
`;

export const Track = styled(SliderTrack)`
	background: rgba(255, 255, 255, 0.3);
	cursor: pointer;
`;

export const Range = styled(SliderRange)`
	background: ${({ theme }) => theme.primaryBlueLight};
	width: 100%;
`;

export const Handle = styled(SliderHandle)`
	cursor: pointer;
	background: ${({ theme }) => theme.white};

	&:focus-visible,
	&:hover {
		background: ${({ theme }) => theme.primaryBlueLight};
	}
`;

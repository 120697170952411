import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Popover as AntdPopover } from 'antd';
import { useSelector } from 'react-redux';

// Import components
import Tools from './tools/Tools';

const Popover = ({ children, program, isUpcoming = false, startDate = '' }) => {
	const reminders = useSelector((store) => store.reminders.data);
	// If there is not any program, return null
	if (!program) return null;
	// Get program's images and screenshots
	const { uuid: programId } = program;

	// gets reminder object for its program
	const reminderForProgram = reminders.find(
		({ product_uuid }) => product_uuid === program.uuid
	);

	const reminderId = reminderForProgram ? reminderForProgram.id : false;

	const content = (
		<PopoverWrapper>
			<Container>
				<Tools
					reminderId={reminderId}
					programId={programId}
					isUpcoming={isUpcoming}
					startDate={startDate}
				/>
			</Container>
		</PopoverWrapper>
	);

	return (
		<AntdPopover placement="right" content={content} trigger="hover">
			{children}
		</AntdPopover>
	);
};

const PopoverWrapper = styled.div`
	display: flex;
	color: ${({ theme }) => theme.graySemiLight};
	overflow: hidden;
	background-color: ${({ theme: { popover } }) => popover.bg};

	${({ dragged }) =>
		dragged &&
		css`
			display: none;
		`};
`;

const Container = styled.div`
	width: 28rem;
	display: flex;
	flex-direction: column;
`;

Popover.propTypes = {
	program: PropTypes.object.isRequired,
	isUpcoming: PropTypes.bool
};

export default Popover;

import styled from 'styled-components';

// Import components
import { Button } from 'components/elements';

export const StyledButton = styled(Button)`
	svg {
		path,
		line {
			fill: none;
		}
	}

	&:hover,
	&:focus {
		svg {
			path,
			line {
				fill: none;
				stroke: ${({ theme }) => theme.primaryBlueLight};
			}
		}
	}
`;

import React from 'react';
import { oneOfType, string, number } from 'prop-types';

// Import styles
import {
	NoContentWrapper,
	InfoIcon,
	NoContentHeader,
	NoContentDescription
} from './styles';

const NoContentMessage = ({
	paddingTopDesktop,
	heightOnMobile,
	title,
	description
}) => (
	<NoContentWrapper
		heightOnMobile={heightOnMobile}
		paddingTopDesktop={paddingTopDesktop}
	>
		<InfoIcon aria-hidden="true" />
		<NoContentHeader role="alert">{title}</NoContentHeader>
		{description && <NoContentDescription>{description}</NoContentDescription>}
	</NoContentWrapper>
);

NoContentMessage.defaultProps = {
	paddingTopDesktop: '15.7vw',
	heightOnMobile: '100vh',
	description: ''
};

NoContentMessage.propTypes = {
	paddingTopDesktop: oneOfType([string, number]),
	heightOnMobile: oneOfType([string, number]),
	title: string.isRequired,
	description: string
};

export default NoContentMessage;

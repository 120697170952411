import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import icons
import { ReactComponent as IconArrowBack } from 'assets/icons/ic_back.svg';

// Import components
import { Button } from 'components/elements';
import {
	ButtonClose,
	FullScreenButton,
	CastButton
} from './components/Buttons';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';
import { usePlayerFocusContext } from 'components/context/player/focus';

// Import styles
import { Wrapper, Title, Controller, TitleWrapper } from './styles';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const TopHeadline = React.memo(
	({ isError }) => {
		const history = useHistory();
		const { t } = useTranslation();
		const playerMatch = useRouteMatch(APP_CORE_PATHS.WATCH);

		const movie = useSelector(({ movieDetails }) => movieDetails.data);

		const { isFocus } = usePlayerFocusContext();

		const title = movie?.metadata?.title;

		const { isReady, isPreview, isFullScreen, closePreview, floatMode } =
			usePlayerVodContext();

		const { isFloatMode, closeFloatMode } = floatMode;

		const handleBack = () => (isPreview ? closePreview() : history.goBack());

		const isPlayerPage = !!playerMatch;

		const showTitle = isPlayerPage || isFullScreen || isPreview;

		return (
			<Wrapper isFloatMode={isFloatMode} isFocus={isFocus || isError}>
				{isFloatMode && isFullScreen && <Title>{title}</Title>}

				{!isFloatMode && (
					<TitleWrapper>
						{isPlayerPage && (
							<Button
								modifiers={['icon', 'playerIconHover']}
								onClick={handleBack}
								title={t('common_back')}
								aria-label={t('common_back')}
							>
								<IconArrowBack aria-hidden="true" />
							</Button>
						)}
						{showTitle && <Title>{title}</Title>}
					</TitleWrapper>
				)}

				<Controller isFullScreen={isFullScreen} isFloatMode={isFloatMode}>
					{!isError && isReady && (
						<>
							{!isFloatMode && <CastButton />}
							{!isPreview && <FullScreenButton />}
						</>
					)}

					{isPreview && <ButtonClose close={handleBack} />}

					{isFloatMode && !isFullScreen && (
						<ButtonClose close={closeFloatMode} />
					)}
				</Controller>
			</Wrapper>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

TopHeadline.propTypes = {
	isError: PropTypes.bool.isRequired
};

export default TopHeadline;

import { useState, useCallback, useEffect } from 'react';

// Import helpers
import { getObligatoryAgreementsIDs } from './helpers';

const useAgreementsStatus = ({
	agreements = [],
	initialAcceptedAgreements = []
}) => {
	const [acceptedAgreementsIds, setAcceptedAgreements] = useState([]);
	const [isCustomAgreementAccepted, setIsCustomAgreementAccepted] =
		useState(true);

	const checkIsAgreementAccepted = ({ id }) =>
		acceptedAgreementsIds.includes(id);

	// check is all agrements accepted
	const isAllAgreementsAccepted =
		agreements.every(checkIsAgreementAccepted) && isCustomAgreementAccepted;

	// check is all obligatory agrements accepted
	const isObligatoryAgreementsAccepted = agreements
		.filter(({ obligatory }) => obligatory)
		.every(checkIsAgreementAccepted);

	const acceptedAgreements = agreements.filter(checkIsAgreementAccepted);
	const acceptedAgreementsVersions = acceptedAgreements.map(
		({ version }) => version?.id
	);

	const handleSetIsCustomAgreementAccepted = () => {
		setIsCustomAgreementAccepted((prev) => !prev);
	};

	const handleToggleAgreement = useCallback(
		(id) => {
			const isAlreadyAccepted = acceptedAgreementsIds.includes(id);
			const filteredAgreements = acceptedAgreementsIds.filter(
				(item) => item !== id
			);

			const newAccepted = isAlreadyAccepted
				? filteredAgreements
				: [...acceptedAgreementsIds, id];

			setAcceptedAgreements(newAccepted);
		},
		[acceptedAgreementsIds]
	);

	const handleToggleAllAgreements = useCallback(() => {
		const allAgreements = agreements.map(({ id }) => id);

		const newAccepted = isAllAgreementsAccepted ? [] : allAgreements;

		setAcceptedAgreements(newAccepted);
		handleSetIsCustomAgreementAccepted();
	}, [agreements, isAllAgreementsAccepted]);

	useEffect(() => {
		if (agreements.length) {
			const obligatory = getObligatoryAgreementsIDs(agreements);

			const initialAgreements = new Set([
				...obligatory,
				...initialAcceptedAgreements
			]);

			// set initial accepted agreements
			setAcceptedAgreements([...initialAgreements]);
		}
		// eslint-disable-next-line
	}, [agreements.length, initialAcceptedAgreements.length]);

	return {
		isAllAgreementsAccepted,
		isObligatoryAgreementsAccepted,
		acceptedAgreementsIds,
		acceptedAgreements,
		acceptedAgreementsVersions,
		toggleAgreement: handleToggleAgreement,
		toggleAllAgreements: handleToggleAllAgreements,
		setAcceptedAgreements,
		isCustomAgreementAccepted,
		setIsCustomAgreementAccepted: handleSetIsCustomAgreementAccepted
	};
};

export default useAgreementsStatus;

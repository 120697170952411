import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Import actions
import {
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	playerOnCancel
} from 'store/actions';

// Import styles
import { LinkStyled, Preview, Image, Content, ParagraphStyled } from './styles';

// Import helpers
import { createLinkParams, setValidChannelLogo } from 'helpers';

// Import utilities
import { ImageOnLoadRPC } from 'components/utilities';

const SearchItemChannel = ({
	product,
	channels,
	videoSessionId,
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	playerOnCancel
}) => {
	const { pathname } = useLocation();
	const channel = channels.find((channel) => channel.uuid === product.uuid);

	if (!channel) return null;

	const { title, images, uuid, type, epgProgrammes, currentLiveIndex } =
		channel;

	// Check image url, check if img's url exists
	const { imgUrl, isImgUrlValid } = setValidChannelLogo(images);

	const handleLinkRedirect = async (e) => {
		const regex = /\b(\w*strona-glowna\w*)\b/i;
		if (regex.test(pathname)) {
			e.preventDefault();
			// Delete request
			// Dispatch an playerOnCancel action in player folder
			playerOnCancel();
			// Delete video live session
			// Dispatch an deleteVideoSession action in player folder
			videoSessionId && (await deleteVideoSession());
			// Set title in player overlay content
			// Dispatch an setSelectedProgram action in channel folder
			const program = epgProgrammes[currentLiveIndex];
			program &&
				setSelectedProgram({
					title: program.title,
					id: program.uuid,
					channel: title,
					channelID: uuid
				});
			// Fetch new video session config
			// Dispatch an fetchPlayerConfig action in player folder
			fetchPlayerConfig({ productID: uuid });
		}
	};

	const link = createLinkParams({ id: uuid, type });

	return (
		<LinkStyled onClick={handleLinkRedirect} to={link}>
			<Preview>
				{isImgUrlValid && (
					<ImageOnLoadRPC>
						{({ setError }) => (
							<Image onError={setError} src={imgUrl} alt={title} />
						)}
					</ImageOnLoadRPC>
				)}
			</Preview>
			<Content>{title && <ParagraphStyled>{title}</ParagraphStyled>}</Content>
		</LinkStyled>
	);
};

SearchItemChannel.propTypes = {
	product: PropTypes.object.isRequired,
	channels: PropTypes.array.isRequired,
	videoSessionId: PropTypes.string.isRequired,
	fetchPlayerConfig: PropTypes.func.isRequired,
	deleteVideoSession: PropTypes.func.isRequired,
	setSelectedProgram: PropTypes.func.isRequired,
	playerOnCancel: PropTypes.func.isRequired
};

const mapStateToProps = ({ player: { videoSessionId } }) => ({
	videoSessionId
});

export default connect(mapStateToProps, {
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	playerOnCancel
})(SearchItemChannel);

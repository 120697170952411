import styled, { css } from 'styled-components';

// Import components
import { Button } from 'components/elements';

// Import styles
import { iconSearch, customMinResponsive, size, flex } from 'components/styles';

// Import icons
import { ReactComponent as SearchIcon } from 'assets/icons/ic_nav_search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ic_close.svg';

export const Search = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	z-index: 900;
	background-color: ${({ theme: { navSearch } }) => navSearch.bg};
`;

export const FormStyled = styled.form`
	position: relative;
	${flex({ jc: 'space-between' })}
	margin: 0 1.8rem;
	${customMinResponsive(
		size.medium,
		css`
			width: 100%;
			margin-left: 7rem;
		`
	)}
`;

export const ButtonStyledClose = styled(Button)`
	display: none;
	border: none;
	${customMinResponsive(
		size.medium,
		css`
			display: block;
			padding: 0rem 0.6rem;
		`
	)}

	&:hover,
  &:focus,
	&:focus-visible {
		cursor: pointer;

		svg > g > path {
			fill: ${({ theme }) => theme.primaryBlueLight};
		}
	}
`;

export const SearchIconStyled = styled(SearchIcon)`
	${iconSearch(2.4, 2.4)}
	${customMinResponsive(
		size.medium,
		css`
			margin-right: 0.5rem;
		`
	)}
`;

export const CloseIconStyled = styled(CloseIcon)`
	margin-left: 1.2rem;
	${iconSearch(2.5, 2.5, 'navSearchCloseIcon')}
`;

import { useRef } from 'react';

// Import utilities
import { useToggle, useEventListener } from 'components/utilities';

// Import variables
import { KEYBOARD_KEYS, EVENTS_NAMES, KEY_CODES } from 'helpers/variables';

const { ENTER, SPACE, ARROWDOWN, ARROWUP, TAB } = KEYBOARD_KEYS;
const { KEYUP, MOUSEUP } = EVENTS_NAMES;
const { ESCAPE } = KEY_CODES;

export const useListBoxSelect = ({ changeHandler, errorMessage }) => {
	const listBoxWrapperRef = useRef(null);
	const listBoxButtonRef = useRef(null);
	const listBoxRef = useRef(null);
	const [isOpen, toggleOpen] = useToggle();

	const arrowKeys = [ARROWDOWN, ARROWUP];
	const actionKeys = [ENTER, SPACE];

	const optionsElements =
		listBoxRef.current?.querySelectorAll('[role="option"]');

	optionsElements &&
		optionsElements.forEach((element, index) => {
			// Important for Tab and arrow keys movement
			element.dataset.index = index;
		});

	// Set focus on button when validation fail
	errorMessage && listBoxButtonRef.current.focus();

	const handleToggleOpenWithFocus = () => {
		toggleOpen();
		isOpen && listBoxButtonRef.current.focus();
	};

	const handleButtonKeyPress = (event) => {
		const { key, shiftKey } = event;
		const isAvailableKey = [...actionKeys, ...arrowKeys].includes(key);

		if (key === TAB && shiftKey && isOpen) return toggleOpen();
		if (key === TAB) return;

		event.preventDefault();

		if (isAvailableKey) {
			if (isOpen && key === ARROWDOWN) {
				optionsElements.length > 0 && optionsElements[0].focus();
				return;
			}
			toggleOpen();
		}
	};

	const handleEscapeClose = ({ keyCode }) => {
		if (keyCode === ESCAPE && isOpen) handleToggleOpenWithFocus();
	};

	const handleClickOutside = (e) => {
		if (!listBoxWrapperRef.current.contains(e.target) && isOpen) toggleOpen();
	};

	const handleMouseOptionSelect = ({ currentTarget }) => {
		changeHandler(currentTarget.dataset.value);
		handleToggleOpenWithFocus();
	};

	const handleKeyOptionEvents = (event) => {
		const { currentTarget, key, shiftKey } = event;
		const targetValue = currentTarget.dataset.value;
		const targetIndex = parseInt(currentTarget.dataset.index);
		const lastElementIndex = optionsElements.length - 1;

		if (actionKeys.includes(key)) {
			changeHandler(targetValue);
			handleToggleOpenWithFocus();
		}

		if (arrowKeys.includes(key)) {
			event.preventDefault();

			if (key === ARROWUP) {
				targetIndex > 0 && optionsElements[targetIndex - 1].focus();
			} else if (key === ARROWDOWN) {
				targetIndex < lastElementIndex &&
					optionsElements[targetIndex + 1].focus();
			}
		}

		if (
			(key === TAB && !shiftKey && targetIndex === lastElementIndex) ||
			(key === TAB && shiftKey && targetIndex === 0)
		) {
			toggleOpen();
		}
	};

	useEventListener(KEYUP, handleEscapeClose, document);
	useEventListener(MOUSEUP, handleClickOutside, document);

	return {
		listBoxWrapperRef,
		listBoxButtonRef,
		listBoxRef,
		isOpen,
		toggleOpen,
		handleMouseOptionSelect,
		handleKeyOptionEvents,
		handleButtonKeyPress
	};
};

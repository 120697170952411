import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import {
	flex,
	transitionMain,
	customMinResponsive,
	size,
	customResponsive,
	focusBasic
} from 'components/styles';

const iconNavBase = (theme) => css`
	${flex()}
	margin: 0 2rem;
	border: none;
	padding: 0.3rem;
	svg {
		height: 2.4rem;
		width: 2.4rem;
		path {
			fill: ${theme.body.header.icon};
		}
	}
`;

const vodColor = {
	darkBlue: '#3562a5',
	lightBlue: '#4387d0'
};

const BUTTON_MODIFIERS = {
	default: ({
		theme: {
			globalButtons: { primary },
			primaryBlueLight,
			black
		}
	}) => css`
		background-color: ${primary.bgColor};
		border: 3px solid ${primary.borderColor};
		color: ${primary.color};

		&:hover,
		&:focus,
		&:focus-visible {
			border-color: ${primaryBlueLight};
			color: ${black};
			box-shadow: 0 0 4px ${primaryBlueLight};
			background-color: ${primaryBlueLight};
		}
	`,
	secondary: ({
		theme: {
			globalButtons: { secondary },
			primaryBlueLight,
			black
		}
	}) => css`
		background-color: ${secondary.bgColor};
		border: 3px solid ${secondary.borderColor};
		color: ${secondary.color};

		&:hover,
		&:focus,
		&:focus-visible {
			border-color: ${primaryBlueLight};
			color: ${black};
			box-shadow: 0 0 4px ${primaryBlueLight};
			background-color: ${primaryBlueLight};
		}
	`,
	dropdown: ({ theme }) => css`
		${flex({ jc: 'space-between' })}

		background: ${theme.dropdown.button.bg};
		border-color: 3px solid ${theme.line};
		color: ${theme.grayLight};
		text-align: left;

		&:hover {
			color: ${theme.primaryBlueLight};
		}
	`,
	icon: () => css`
		background-color: transparent;
		padding: 0.3rem;
		border: none;
	`,

	primaryVod: ({ theme, cancel, isNotAvailable, borderColor, resume }) => css`
		border-radius: 3px;
		color: ${theme.button.primaryVod.color};
		background: transparent;
		border: 1px solid ${borderColor ? `${vodColor.lightBlue}` : 'transparent'};
		width: 12.7rem;
		padding: ${resume ? 1.15 : 1.4}rem 0;
		font-size: ${theme.fontSize.set('xs', -0.5)}rem;
		position: relative;
		z-index: 10;
		overflow: hidden;
		box-shadow: 0px 1px 0px 0px ${theme.black};
		transition: all 0.2s ease-in-out;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background: ${theme.button.primaryVod.bgColor};
			z-index: -1;
		}

		${isNotAvailable &&
		css`
			color: #9ba7b3;
			cursor: not-allowed;
			&:before {
				background: linear-gradient(0deg, 'transparent' 12%, 'transparent' 75%);
			}
		`}

		${cancel &&
		css`
			border: 1px solid ${resume ? '#48484a' : '#6b747d'};
			background: ${resume ? '#303133' : '#455b72'};
			color: ${resume ? '#797979' : '#9ba7b3'};
			&:before {
				background: linear-gradient(0deg, transparent 12%, transparent 75%);
			}
		`}

		&:focus-visible {
			outline: none;
			border-color: ${theme.primaryBlueLight};
			color: ${theme.black};
			box-shadow: 0 0 4px ${theme.primaryBlueLight};
			background-color: ${theme.primaryBlueLight};
		}
	`,

	playerIconHover: ({ theme }) => css`
		line-height: 0 !important;

		&:hover,
		&:focus,
		&:focus-visible {
			svg {
				path,
				text {
					fill: ${theme.primaryBlueLight};
				}
				circle {
					stroke: ${theme.primaryBlueLight};
				}
			}
		}
	`,

	playerPlay: () => css`
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		transform: translate(-50%, -50%);
	`,
	navIcon: ({ theme }) => css`
		${iconNavBase(theme)}
		svg {
			width: ${theme.fontSize.md}rem !important;
			height: ${theme.fontSize.md}rem !important;
		}

		&:hover,
		&:focus-visible {
			color: ${theme.body.header.iconActive};

			svg {
				path {
					fill: ${theme.body.header.iconActive};
				}
			}
		}
	`,
	videoBanner: ({ theme }) => css`
		svg {
			width: ${theme.fontSize.s}rem !important;
			height: ${theme.fontSize.s}rem !important;
		}

		padding: 0;
		width: ${theme.isWCAG ? 6.1 : 4.1}rem !important;
		height: ${theme.isWCAG ? 6.1 : 4.1}rem !important;
		${flex()}
		border-radius: 0.6rem;
		border: 1px solid ${theme.videoBanner.buttons.border};
		background-color: ${theme.videoBanner.buttons.bg};

		&:hover,
		&:focus-visible {
			background-color: ${theme.videoBanner.buttons.bgHover};
		}

		${theme.isContrast &&
		css`
			background-color: ${theme.contrast.secondaryBg};

			&:hover,
			&:focus-visible {
				background-color: ${theme.contrast.secondaryBgFocus};
			}
		`}
	`,
	pipIcon: ({ theme, isAvailable }) => css`
		${iconNavBase(theme)}
		cursor: ${isAvailable ? 'pointer' : 'not-allowed'};
		svg {
			path {
				fill: ${isAvailable ? theme.primary : theme.grayMedium};
			}
		}
	`,
	parental: ({ theme }) => css`
		width: 19rem;
		height: 5rem;
		border-radius: 2px;
		font-size: ${({ theme }) => theme.fontSize.xs}rem;
		font-weight: ${theme.font.medium};
		background: ${theme.white};
	`,
	userProfile: ({ marginBottom, theme }) => css`
		font-size: ${theme.fontSize.xs}rem;
		padding: 0 3.9rem;
		height: 4.1rem;
		font-weight: 400;
		border: 3px solid inherit;
		&:not(:last-child) {
			margin-bottom: ${marginBottom ? marginBottom : '0'};
			margin-right: 1.4rem;
		}
		${customMinResponsive(
			size.medium,
			css`
				font-size: ${theme.fontSize.set('xs', 0.3)}rem;
			`
		)}

		&:hover,
		&:focus-visible {
			border-color: ${theme.primaryBlueLight};
		}
	`,
	voucher: ({ theme: { payment, primaryBlueLight, white } }) => css`
		background-color: ${payment.voucher.submit.background};
		color: ${payment.voucher.submit.color};
		border: 3px solid ${payment.voucher.submit.background};
		padding: 0 2rem;
		white-space: nowrap;
		height: 100%;
		border-radius: inherit;
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
		transition: outline 0s, background 0.3s ease-in-out;

		&:focus,
		&:hover:not(&:disabled) {
			background: ${white};
		}

		&:focus-visible {
			outline: 2px solid ${primaryBlueLight};
		}
	`,
	payment: () => css`
		min-width: 17rem;
		height: 4rem;
		${flex()}

		${customResponsive(
			size.medium,
			css`
				display: none;
			`
		)}
	`,
	mobile: () => css`
		border: none;
		padding: 0.3rem;
	`,
	link: () => flex(),
	voucherForm: ({ theme }) =>
		css`
			border-radius: 0;
			height: 4.4rem;
			${flex()}

			${customMinResponsive(
				size.medium,
				css`
					font-size: ${theme.fontSize.set('xs', 0.3)}rem;
				`
			)}

			&:focus-visible {
				outline-color: ${theme.black};
				outline-offset: -0.6rem;
			}
		`,
	seek: () =>
		css`
			${customResponsive(
				480,
				css`
					display: none;
				`
			)}
		`,
	fullscreen: ({ theme }) => css`
		&:hover,
		&:focus {
			g > g {
				stroke: ${theme.primaryBlueLight};
			}
		}
	`,
	userTable: ({ theme }) => css`
		width: 100%;
		padding: 0;
		height: 3.5rem;

		${customResponsive(
			size.medium,
			css`
				color: ${theme.primaryBlueLight};
				background-color: transparent;
				border: none;
			`
		)}
	`
};

const Button = styled.button`
	padding: ${({ padding }) => padding || '1rem 1.2rem'};
	font-size: ${({ theme }) => theme.fontSize.xs}rem;
	line-height: 1.8rem;
	border-radius: 5px;
	font-weight: ${({ theme }) => theme.font.medium};
	cursor: ${({ isFocus = true }) => (isFocus ? 'pointer' : 'none')};
	user-select: none;
	width: ${({ width = 'auto' }) => width};
	background-color: transparent;
	${transitionMain}

	svg {
		path,
		line {
			${transitionMain}
		}
	}

	&:focus {
		outline: none;
	}

	&:focus-visible {
		${focusBasic}
	}

	&:disabled {
		opacity: 0.8 !important;
		cursor: not-allowed !important;
	}

	${({ isCatchupControllers }) =>
		isCatchupControllers &&
		css`
			svg {
				height: 3rem;
				width: 3rem;
			}
		`}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.user.logOutBtn}rem;
			line-height: 2.5rem;
		`
	)}

	${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export default Button;

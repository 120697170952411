import {
	startOfTomorrow,
	startOfToday,
	addDays,
	startOfDay,
	getYear,
	getMonth,
	getHours,
	getMinutes,
	getDate,
	isToday as isTodayFns
} from 'date-fns';
import _ from 'lodash';

const createDate = (date) =>
	`${getYear(date())}-${getMonth(date()) + 1}-${getDate(date())}`;

const defaultProgram = {
	uuid: '1',
	channel_uuid: null,
	title: 'Brak programu',
	since: `${createDate(startOfToday)} 00:00:00`,
	till: `${createDate(startOfTomorrow)} 00:00:00`,
	description_short: 'Brak programu',
	rating: null,
	category: '',
	images: null,
	catchup: false,
	isDisabled: true
};

export const convertParamsDateFormat = (date) => {
	const month = getMonth(date) + 1;
	const day = getDate(date);
	const checkMonth = month < 10 ? `0${month}` : month;
	const chekDay = day < 10 ? `0${day}` : day;
	return `${getYear(date)}${checkMonth}${chekDay}0${getHours(
		date
	)}0${getMinutes(date)}00`;
};

export const setTvProgramsTime = (date) => {
	// Declare variables
	let since,
		till,
		isToday = isTodayFns(date || new Date());

	if (isToday) {
		// Get since and till timestamp fro since
		since = startOfToday();
		till = startOfTomorrow();
	} else {
		isToday = false;
		// Get since and till timestamp for another day
		since = startOfDay(date);
		till = addDays(startOfDay(date), 1);
	}
	return {
		since,
		till,
		isToday
	};
};

const formatChannelData = (channel, subscriberLocalActive) => {
	const {
		context: { catch_up_active, subscriberLocalLimited }
	} = channel;

	channel.id = channel.uuid;
	channel.catchupAvailable = catch_up_active === 1 ? true : false;
	channel.subscriberLocalLimited =
		subscriberLocalLimited === 1 && !subscriberLocalActive ? true : false;
	return channel;
};

export const formatChannels = (
	channels,
	products,
	subscriberLocalActive = false
) => {
	const { avaiable, blocked } = channels.reduce(
		(acc, next) => {
			const channelIds = [next.uuid, ...next.available_in];
			const findId = _.intersection(channelIds, products);
			const channel = formatChannelData(next, subscriberLocalActive);

			if (findId.length > 0 && !channel.subscriberLocalLimited) {
				acc.avaiable.push({ ...channel, avaiable: true, inPackage: true });
			} else if (findId.length > 0 && channel.subscriberLocalLimited) {
				acc.blocked.push({ ...channel, avaiable: false, inPackage: true });
			} else {
				acc.blocked.push({ ...channel, avaiable: false, inPackage: false });
			}

			return acc;
		},
		{ avaiable: [], blocked: [] }
	);
	return [...avaiable, ...blocked];
};

export const formatChannelsByLocal = (channels, subscriberLocalActive) => {
	const formatedChannels = channels.map((channel) => {
		const { inPackage, subscriberLocalLimited } = channel;

		if (inPackage && subscriberLocalLimited && subscriberLocalActive) {
			return { ...channel, avaiable: true };
		} else {
			return { ...channel };
		}
	});

	const { avaiable, blocked } = formatedChannels.reduce(
		(acc, next) => {
			next.avaiable ? acc.avaiable.push(next) : acc.blocked.push(next);
			return acc;
		},
		{ avaiable: [], blocked: [] }
	);
	return [
		..._.orderBy(avaiable, ['rank'], ['asc']),
		..._.orderBy(blocked, ['rank'], ['asc'])
	];
};

export const findCurrentLiveProgram = (epgProgrammes) =>
	// Get the index of current live program
	epgProgrammes.findIndex((program) => {
		// Get timestamps
		const now = new Date().getTime();
		const since = new Date(program.since.replace(/-/g, '/')).getTime();
		const till = new Date(program.till.replace(/-/g, '/')).getTime();
		// Check if program is live now
		return since < now && now < till;
	});

export const machtChannelsEpg = (channels, epg) =>
	channels.reduce((acc, next) => {
		const channelsEpg = epg.find(
			({ channel_uuid }) => channel_uuid === next.uuid
		);

		if (channelsEpg) {
			const lives = channelsEpg.programs;
			next.epgProgrammes = lives;
			next.currentLiveIndex = findCurrentLiveProgram(lives);
			next.noPrograms = false;
			acc.push(next);
		} else {
			next.epgProgrammes = [defaultProgram];
			next.epgProgrammes[0].channel_uuid = next.uuid;
			next.currentLiveIndex = 0;
			next.noPrograms = true;
			acc.push(next);
		}

		return acc;
	}, []);

// Sort avaiable programs
export const sortPrograms = (lives) =>
	lives.reduce(
		(acc, next) => {
			// Check if subscriberLocalActive is active or channel is avaiable in user's tv package
			if (!next.status.paid) {
				const currentLiveIndex = findCurrentLiveProgram(next.epgProgrammes);
				acc.blocked.push({ ...next, currentLiveIndex });
			} else if (next.subscriberLocalLimited) {
				const currentLiveIndex = findCurrentLiveProgram(next.epgProgrammes);
				acc.blocked.push({ ...next, currentLiveIndex });
			} else {
				const currentLiveIndex = findCurrentLiveProgram(next.epgProgrammes);
				acc.avaiable.push({ ...next, currentLiveIndex });
			}
			return acc;
		},
		{ avaiable: [], blocked: [] }
	);

export const updateCurrentLiveProgram = (channels) =>
	channels.reduce((acc, next) => {
		const currentLiveIndex = findCurrentLiveProgram(next.epgProgrammes);
		acc.push({ ...next, currentLiveIndex });
		return acc;
	}, []);

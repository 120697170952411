import React from 'react';
import { useTranslation } from 'react-i18next';
import { node } from 'prop-types';

// Import components
import { Loader, SearchEngineTags } from 'components/elements';
import PaymentError from './PaymentError';
import TransactionError from './TransactionError';

// Import styles
import { Container, BackButton } from './styles';

// Import icons
import { ReactComponent as IconTick } from 'assets/icons/ic_back.svg';

// Import context
import { useProductPaymentContext } from 'components/context/payment';

// Import logic
import useProductPaymentTemplate from './useProductPaymentTemplate';

const ProductPaymentTemplate = ({ children }) => {
	const { t } = useTranslation();
	const { productDetails } = useProductPaymentContext();
	const {
		isPaymentError,
		paymentErrorMessage,
		isPurchaseError,
		purchaseErrorMessage,
		showLoader,
		historyGoBack,
		isResourcesError
	} = useProductPaymentTemplate();

	const productTitle = productDetails?.metadata?.title || productDetails?.title;
	const pageTitle = productTitle
		? t('meta_page_payment_product_title', { productTitle })
		: t('meta_page_payment_title');

	return (
		<>
			<SearchEngineTags
				metaTags={{ title: pageTitle }}
				isTranslationKey={false}
			/>
			<Container>
				<BackButton
					onClick={historyGoBack}
					title={t('payment_button_back')}
					aria-label={t('payment_button_back')}
				>
					<IconTick aria-hidden="true" />
				</BackButton>

				{showLoader && <Loader zIndex={900} position="absolute" background />}

				{isPaymentError && <PaymentError message={paymentErrorMessage} />}

				{isPurchaseError && <PaymentError message={purchaseErrorMessage} />}

				{isResourcesError ? <TransactionError /> : children}
			</Container>
		</>
	);
};

ProductPaymentTemplate.propTypes = {
	children: node.isRequired
};

export default ProductPaymentTemplate;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { string, bool } from 'prop-types';
import { VisuallyHidden } from '@reach/visually-hidden';

// Import components
import { CommonButton } from 'components/views/vod/components/buttons';
import BannerGradient from './BannerGradient';

// Import logic
import { useBanner } from './hooks';

// Import styles
import {
	BannerWrapper,
	BannerContent,
	Title,
	Description,
	InfoIcon
} from './styles';

const Banner = (props) => {
	const { t } = useTranslation();
	const { type, isLowBanner } = props;
	const {
		url,
		arrows,
		title,
		coverUrl,
		showFocus,
		isContrast,
		short_desc,
		showContent,
		showButton,
		bannerFocusIndex,
		buttonFocusIndex,
		bannerElementType
	} = useBanner(props);

	return (
		<BannerWrapper
			href={url}
			cover={coverUrl}
			isContrast={isContrast}
			showFocus={showFocus}
			hasContent={showContent}
			as={bannerElementType}
			modifiers={[type, arrows]}
			tabIndex={bannerFocusIndex}
		>
			<BannerGradient
				isContrast={isContrast}
				hasContent={showContent}
				isLowBanner={isLowBanner}
			/>
			{showContent ? (
				<BannerContent modifiers={type}>
					{title && <Title>{title}</Title>}
					{short_desc && <Description>{short_desc}</Description>}
					{showButton && (
						<CommonButton as="a" href={url} tabIndex={buttonFocusIndex}>
							<InfoIcon aria-hidden role="img" />
							{t('common_more_info_button')}
							<VisuallyHidden>: {title}</VisuallyHidden>
						</CommonButton>
					)}
				</BannerContent>
			) : (
				<VisuallyHidden>{title}</VisuallyHidden>
			)}
		</BannerWrapper>
	);
};

Banner.propTypes = {
	type: string,
	isLowBanner: bool
};

export default Banner;

import React, {
	useState,
	useCallback,
	createContext,
	useEffect,
	useMemo
} from 'react';
import _ from 'lodash';
import { node, bool } from 'prop-types';

// Import variables
import { EVENTS_NAMES } from 'helpers/variables';

// Import utilities
import { useEventListener, useTimeout } from 'components/utilities';

const { MOUSEMOVE, KEYDOWN } = EVENTS_NAMES;

// Create context
export const PlayerFocusContext = createContext();

const FOCUS_TIME = 10 * 1000;

const PlayerFocusProvider = ({ isReady, children }) => {
	const [isFocus, setFocus] = useState(true);
	const [isFocusFreeze, setFocusFreeze] = useState(false);
	const [focusTime, setFocusTime] = useState(null);

	const eventsTarget = useMemo(
		() => (isFocusFreeze ? null : window),
		[isFocusFreeze]
	);

	const handleSetTimeout = () => setFocusTime(FOCUS_TIME);
	const handleClearTimeout = () => setFocusTime(null);

	const playerFocus = useCallback((isClear = false) => {
		setFocus(true);
		isClear ? handleClearTimeout() : handleSetTimeout();
	}, []);

	const playerUnfocus = useCallback(() => {
		setFocus(false);
		handleClearTimeout();
	}, []);

	const handleFocusFreeze = useCallback(() => {
		playerFocus(true);
		setFocusFreeze(true);
	}, [playerFocus]);

	const handleFocusUnfreeze = useCallback(() => {
		setFocusFreeze(false);
		handleSetTimeout();
	}, []);

	const handleEvent = _.debounce(() => {
		// clear old timeout
		handleClearTimeout();
		playerFocus();
	}, 100);

	useTimeout(playerUnfocus, focusTime);
	useEventListener(MOUSEMOVE, handleEvent, eventsTarget);
	useEventListener(KEYDOWN, handleEvent, eventsTarget);

	useEffect(() => {
		isReady && handleSetTimeout();
	}, [isReady]);

	return (
		<PlayerFocusContext.Provider
			value={{
				isFocus,
				focusFreeze: handleFocusFreeze,
				focusUnfreeze: handleFocusUnfreeze
			}}
		>
			{children}
		</PlayerFocusContext.Provider>
	);
};

PlayerFocusProvider.propTypes = {
	isReady: bool,
	children: node
};

export default PlayerFocusProvider;

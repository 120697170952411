import { useEffect, useRef } from 'react';

// Import context
import useGalleryContext from '../context/useGalleryContext';

// Import helpers
import { OBSERVER_ACTIONS } from '../helpers';
import { ASSET_GROUP_TYPES } from 'helpers/variables';

const { VIDEO_2D, VIDEO_360 } = ASSET_GROUP_TYPES;

function useGallery() {
	const sliderRef = useRef();
	const arrowPrevRef = useRef(null);
	const arrowNextRef = useRef(null);

	const {
		galleryId,
		assets,
		metadata,
		type,
		galleryIndex,
		setGalleryIndex,
		showGallery,
		isArticle,
		toggle,
		showFullscreen,
		getAssetMetadata,
		is360Gallery,
		observer,
		thumbnailAssets,
		numberOfAssets
	} = useGalleryContext();

	const afterSlideChange = (index) => {
		const isFirstSlide = index === 0;
		const isLastSlide = index === numberOfAssets - 1;

		if (isFirstSlide && arrowNextRef.current) {
			arrowNextRef.current.focus();
		}

		if (isLastSlide && arrowPrevRef.current) {
			arrowPrevRef.current.focus();
		}

		setGalleryIndex(index);
		observer.notify({
			action: OBSERVER_ACTIONS.AFTER,
			data: { index }
		});
	};

	const beforeSlideChange = (oldIndex, newIndex) => {
		observer.notify({
			action: OBSERVER_ACTIONS.BEFORE,
			data: { oldIndex, newIndex }
		});
	};

	const toggleFullscreen = () => {
		observer.notify({
			action: OBSERVER_ACTIONS.FULLSCREEN,
			data: {
				isOpened: showFullscreen,
				index: galleryIndex
			}
		});
		toggle();
	};

	const isVideo = type === VIDEO_2D || type === VIDEO_360;

	useEffect(() => {
		if (sliderRef?.current) {
			sliderRef.current.slickGoTo(galleryIndex);
		}
		// eslint-disable-next-line
	}, [galleryIndex]);

	return {
		galleryId,
		sliderRef,
		arrowPrevRef,
		arrowNextRef,
		afterSlideChange,
		beforeSlideChange,
		assets,
		metadata,
		type,
		showGallery,
		isArticle,
		toggle: toggleFullscreen,
		getAssetMetadata,
		galleryIndex,
		setGalleryIndex,
		is360Gallery,
		thumbnailAssets,
		isVideo
	};
}

export default useGallery;

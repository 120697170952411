import { useContext } from 'react';

// Import context
import { PlayerFocusContext } from './PlayerFocusProvider';

const usePlayerFocusContext = () => {
	const context = useContext(PlayerFocusContext);
	return context;
};

export default usePlayerFocusContext;

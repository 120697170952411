import React from 'react';
import VisuallyHidden from '@reach/visually-hidden';
import { ListboxInput } from '@reach/listbox';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
	object,
	string,
	func,
	shape,
	oneOfType,
	arrayOf,
	node
} from 'prop-types';

// Import styled components
import {
	Wrapper,
	FormError,
	BottomWrapper
} from 'components/elements/molecules/form/field/styles/index';

import {
	StyledListboxButton,
	StyledListboxList,
	SelectLabel,
	SelectWrapper,
	StyledListboxPopover
} from '../styles';

import SelectKeyboardScrollWrapper from '../SelectKeyboardScrollWrapper';

const RegisterFormSelect = ({
	field,
	form: { touched, errors, setFieldTouched },
	placeholder,
	children,
	styles
}) => {
	const { t } = useTranslation();

	const { name, value } = field;
	const isErrorVisible = touched[name] && errors[name];
	const errorText = errors[name] || '';
	const handleOnChange = (event) => field.onChange(name)(event);
	const handleOnBlur = (value) => {
		setFieldTouched(name);
		field.onBlur && field.onBlur(value);
	};

	return (
		<Wrapper>
			<SelectWrapper>
				<VisuallyHidden id={name}>{placeholder}</VisuallyHidden>
				<ListboxInput
					value={value}
					onChange={handleOnChange}
					onBlur={handleOnBlur}
					aria-labelledby={name}
				>
					{({ isExpanded }) => (
						<SelectKeyboardScrollWrapper>
							<SelectLabel
								backgroundColor={styles?.label?.background}
								className={clsx({ filled: value || isExpanded })}
								htmlFor={name}
							>
								{placeholder}
							</SelectLabel>
							<StyledListboxButton
								withLabel
								className={clsx({
									'has-value': value,
									errors: !isExpanded && isErrorVisible,
									'is-expanded': isExpanded
								})}
								arrow
								aria-required
							>
								{t(`country_name_${value}`)}
							</StyledListboxButton>
							<StyledListboxPopover portal={false}>
								<StyledListboxList>{children}</StyledListboxList>
							</StyledListboxPopover>
						</SelectKeyboardScrollWrapper>
					)}
				</ListboxInput>
			</SelectWrapper>
			<BottomWrapper>
				<FormError
					role="alert"
					aria-atomic="true"
					id={`${name}-alert`}
					isErrorVisible={isErrorVisible}
				>
					{errorText}
				</FormError>
			</BottomWrapper>
		</Wrapper>
	);
};
RegisterFormSelect.defaultProps = {
	autoComplete: 'true',
	styles: {}
};

RegisterFormSelect.propTypes = {
	field: object.isRequired,
	placeholder: string.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired,
		setFieldTouched: func.isRequired
	}),
	children: oneOfType([arrayOf(node), node]).isRequired,
	autoComplete: string,
	styles: object
};

export default RegisterFormSelect;

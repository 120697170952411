import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

// Import utilities
import { customMinResponsive, size, focusBasic } from 'components/styles';

export const Nav = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	color: ${({ theme: { body } }) => body.footer.link};
	width: 15rem;
	${customMinResponsive(
		size.medium,
		css`
			width: 21rem;
		`
	)}
`;

export const Item = styled.li`
	color: ${({ theme: { body } }) => body.footer.color};
	${({ theme: { isWCAG } }) => isWCAG && 'margin: 2.5rem 0'};
`;

export const linkStyle = css`
	&:link,
	&:visited {
		color: ${({ theme: { body } }) => body.footer.color};
		text-decoration: none;
		font-size: ${({ theme }) => theme.fontSize.footer.media.link}rem;
		line-height: 2.5rem;
		transition: 0.2s ease-in-out;
		font-weight: 400;
		${customMinResponsive(
			size.medium,
			css`
				font-size: ${({ theme }) => theme.fontSize.s}rem;
				line-height: 3rem;
			`
		)}
	}

	&:hover,
	&:active,
	&:focus-visible {
		color: ${({ theme: { body } }) => body.footer.hover};
	}

	&:focus-visible {
		${focusBasic}
	}
`;

export const StyledLink = styled(RouterLink)`
	${linkStyle}
`;

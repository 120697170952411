import React from 'react';
import { useTranslation } from 'react-i18next';

// Import assets
import { ReactComponent as FullscreenIcon } from 'assets/icons/fullscreen.svg';

// Import components
import GalleryFooter from './GalleryFooter';
import SlideProvider from './SlideProvider';

// Import styles
import {
	Container,
	GalleryHeader,
	GalleryHeaderWrapper,
	FullScreenOpen,
	GalleryBody,
	Gallery360Icon
} from '../styles';

// Import logic
import { useGallery } from '../hooks';

const GallerySlider = () => {
	const { t } = useTranslation();
	const {
		sliderRef,
		arrowPrevRef,
		arrowNextRef,
		galleryId,
		showGallery,
		type,
		afterSlideChange,
		beforeSlideChange,
		toggle,
		metadata,
		isArticle,
		is360Gallery,
		isVideo
	} = useGallery();
	if (!showGallery) return null;

	return (
		<Container isLightBackground={isArticle}>
			<GalleryHeaderWrapper>
				<GalleryHeader isLightBackground={isArticle}>
					{metadata?.title}
				</GalleryHeader>
				{is360Gallery && (
					<Gallery360Icon isLightBackground={isArticle} aria-hidden />
				)}
			</GalleryHeaderWrapper>
			<GalleryBody data-gallery={galleryId}>
				<FullScreenOpen
					onClick={toggle}
					aria-label={
						isVideo
							? t('aria_gallery_video_fullscreen_tooltip')
							: t('aria_gallery_fullscreen_tooltip')
					}
				>
					<FullscreenIcon />
				</FullScreenOpen>
				<SlideProvider
					type={type}
					afterSlideChange={afterSlideChange}
					beforeSlideChange={beforeSlideChange}
					sliderRef={sliderRef}
					arrowPrevRef={arrowPrevRef}
					arrowNextRef={arrowNextRef}
				/>
			</GalleryBody>
			<GalleryFooter />
		</Container>
	);
};

export default GallerySlider;

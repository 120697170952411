import React from 'react';
import { useTranslation } from 'react-i18next';

// Import styles
import { ButtonText, RequestStatus } from './styles';

// Import components
import { CommonButton } from 'components/views/vod/components/buttons';

// Import utilities
import { useEmailConfirmation } from 'components/utilities';

const EmailConfirmation = () => {
	const { t } = useTranslation();

	const { resendConfirmation, isError, isLoading, requestStatusText } =
		useEmailConfirmation();

	return (
		<>
			<CommonButton
				onClick={resendConfirmation}
				isLoading={isLoading}
				title={t('user_profile_general_confrimation_link')}
			>
				<ButtonText isLoading={isLoading}>
					{t('user_profile_general_confrimation_link')}
				</ButtonText>
			</CommonButton>
			<RequestStatus isVisible={!!requestStatusText} isError={isError}>
				{requestStatusText}
			</RequestStatus>
		</>
	);
};

export default EmailConfirmation;

import React from 'react';
import { bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';
import { usePlayerFocusContext } from 'components/context/player/focus';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

const SettingsController = ({ isCatchupControllers }) => {
	// Get player methods from Player context
	const {
		profiles,
		selectedProfile,
		setProfile,
		languages,
		selectedLanguage,
		setLanguage,
		isSafari
	} = usePlayerTvContext();

	const { isFocus } = usePlayerFocusContext();

	// Define the state
	const [isVisible, setIsVisible] = React.useState(false);

	const { t } = useTranslation();

	// Set profile in player provider
	const handleSetProfile = React.useCallback(
		(values) => () => setProfile(values),
		[setProfile]
	);

	// Update the isVisible state
	const handleSetVisible = () => {
		setIsVisible((prev) => !prev);
	};

	const hasVideoProfile = profiles && selectedLanguage;
	const hasVideoLanguages = languages?.length > 1;

	React.useEffect(() => {
		!isFocus && setIsVisible(false);
	}, [isFocus]);

	const renderProfiles = () => {
		return (
			<ProfilesBox>
				<ProfileName>{t('player_settings_profiles')}</ProfileName>
				<Profile
					onClick={handleSetProfile({ id: 'auto' })}
					profile="auto"
					selected={selectedProfile}
				>
					<span>auto</span>
				</Profile>
				{hasVideoProfile &&
					profiles[selectedLanguage].map(({ id, height, bandwidth }) => (
						<Profile
							onClick={handleSetProfile({ id, bandwidth })}
							profile={id}
							selected={selectedProfile}
							key={id}
						>
							<span>{`${height}p`}</span>
						</Profile>
					))}
			</ProfilesBox>
		);
	};

	const renderLanguages = () => {
		return (
			<ProfilesBox>
				<ProfileName>{t('player_settings_language')}</ProfileName>
				{languages.map((language, index) => (
					<Profile
						onClick={() => setLanguage(language)}
						profile={language}
						selected={selectedLanguage}
						key={index}
					>
						<span>{language.toUpperCase()}</span>
					</Profile>
				))}
			</ProfilesBox>
		);
	};

	return (
		<Wrapper>
			<Button
				isCatchupControllers={isCatchupControllers}
				onClick={handleSetVisible}
				modifiers={['icon', 'playerIconHover']}
				isFocus={isFocus}
			>
				<Player.IconSettings />
			</Button>
			<Content isVisible={isVisible}>
				<Profiles role="list">
					{!isSafari && renderProfiles()}
					{hasVideoLanguages && renderLanguages()}
				</Profiles>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
	margin: 0 0.5rem 0 1rem;
`;
const Content = styled.div`
	position: absolute;
	bottom: 55px;
	left: 50%;
	transform: translateX(-50%);
	padding: 1.2rem 1.8rem;
	background: rgba(0, 0, 0, 0.4);
	opacity: 0;
	visibility: hidden;

	${({ isVisible }) =>
		isVisible &&
		css`
			opacity: 1;
			visibility: visible;
		`}
`;

const Profiles = styled.ul`
	list-style: none;
	display: flex;
`;

const Profile = styled.li`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	margin-bottom: 0.6rem;
	color: ${({ theme }) => theme.gray};
	transition: all 0.3 ease-in-out;
	cursor: pointer;

	&:hover {
		color: ${({ theme }) => theme.white};
	}

	${({ profile, selected }) =>
		profile === selected &&
		css`
			color: ${({ theme }) => theme.white};
		`}
`;

const ProfilesBox = styled.div`
	text-align: center;

	&:nth-child(odd) {
		margin-right: 0.9rem;
	}

	&:nth-child(even) {
		margin-left: 0.9rem;
	}
`;

const ProfileName = styled.p`
	color: ${({ theme }) => theme.grayMedium};
`;

SettingsController.propTypes = {
	isCatchupControllers: bool
};

export default SettingsController;

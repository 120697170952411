import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import uuidv4 from 'uuid/v4';
import { useTranslation } from 'react-i18next';
import { fetchMenuNavLinks } from 'store/actions';

// Import variables
import { GA_API } from 'helpers/api';

// Import helpers
import { getUserAgentInfo } from 'helpers';
import { initializeFirebaseApp } from 'helpers/firebase';
import { ANALYTICS_KEYS } from 'helpers/analytics';

// Import utilities
import { useSelectTheme, useScript } from 'components/utilities';
import { useFocusScroll } from './useFocusScroll';

// Import variables
import { COOKIE_EXPIRES, COOKIE_UID } from 'helpers/variables';

export const useAppTemplate = () => {
	const dispatch = useDispatch();
	const { isPortalAvailable } = useSelector(({ portal }) => portal);
	const { functionalAgreementsPermission } = useSelector(({ auth }) => auth);
	const { isLoaded: isMenuLoaded } = useSelector(({ menu }) => menu);

	// Set focus scroll on keyboard and screen reader movement
	useFocusScroll();

	const {
		i18n: { language }
	} = useTranslation();

	const { isMobile } = getUserAgentInfo();

	const gaScriptUrl = `${GA_API}?id=${ANALYTICS_KEYS.GA}`;
	useScript({
		url: gaScriptUrl,
		isActive: functionalAgreementsPermission,
		id: 'ga-tracking-js'
	});

	// select theme or get one based on project env
	const selectedTheme = useSelectTheme();

	const checkUID = () => {
		// Get uid
		const cookies = Cookies.get(COOKIE_UID);
		// If uid is nod defined, create uid's cookie
		if (!cookies) {
			const createUID = uuidv4();
			Cookies.set(COOKIE_UID, createUID, { expires: COOKIE_EXPIRES });
		}
	};

	useEffect(() => {
		fetchMenuNavLinks()(dispatch);
		// eslint-disable-next-line
	}, [language]);

	useEffect(() => {
		// Initialize firebase
		initializeFirebaseApp();
		// Check uid
		checkUID();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const gaUuid = process.env.REACT_APP_GTM_ID;
		// Disable Google Analytics measurement
		window[`ga-disable-${gaUuid}`] = !functionalAgreementsPermission;

		if (!functionalAgreementsPermission) {
			const gaCodeId = gaUuid.replace('G-', '');
			const cookieName = '_ga';
			Cookies.remove(`${cookieName}_${gaCodeId}`);
			Cookies.remove(cookieName);
		}
		// eslint-disable-next-line
	}, [functionalAgreementsPermission]);

	return {
		isPortalAvailable,
		isMobile,
		selectedTheme,
		isFunctionalCookieAccepted: functionalAgreementsPermission,
		isMenuLoaded
	};
};

import i18n from 'i18n';

// Import components
import { notification } from 'antd';

export const PREVIEW_SEARCH_PARAM = 'preview';

export const previewTokenRegex = new RegExp('^[A-Za-z0-9]{40}$');

export const promptIllegalAction = () =>
	notification.warn({
		message: i18n.t('common_preview_alert_title'),
		description: i18n.t('common_preview_alert_description'),
		placement: 'topRight',
		duration: 5
	});

export const defaultContext = {
	isPreview: false,
	previeToken: null,
	previewLinkProps: () => {},
	onPreviewHOF: (eventHandler) => eventHandler
};

import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

// Import utilities
import {
	useProductAvailability,
	useProductPaymentStatus,
	useAuth
} from 'components/utilities';
import { fetchUserPackets } from 'store/actions';

// Import helpers
import { PRODUCT_STATUS } from 'helpers/variables';

// Import api
import { QUERY_PRODUCT_PAYMENT_STATUS } from 'helpers/api';

const { INIT, PENDING, PURCHASE, SUCCESS } = PRODUCT_STATUS;

function useProductTransactionStatus({ product }) {
	const { uuid, available_in } = product;
	const { isAuth } = useAuth();
	const queryClient = useQueryClient();

	const dispatch = useDispatch();
	const { isFetched } = useSelector(({ auth }) => auth.packets);

	const initialStatus = isAuth ? INIT : PURCHASE;
	const [status, setStatus] = useState(initialStatus);

	const isProductAvailable = useProductAvailability(uuid, available_in);
	const { isPaymentPending, isLoading } = useProductPaymentStatus({
		uuid
	});

	const reset = () => {
		setStatus(initialStatus);
		queryClient.invalidateQueries(`${QUERY_PRODUCT_PAYMENT_STATUS}_${uuid}`);
	};

	const checkTransactionStatus = () => {
		if (!isAuth) return PURCHASE;
		let newStatus = status;
		if (isLoading || !isFetched) newStatus = INIT;
		else if (isPaymentPending) newStatus = PENDING;
		else {
			newStatus = isProductAvailable ? SUCCESS : PURCHASE;
		}
		return newStatus;
	};

	const handleFetchUserPackets = async () => await fetchUserPackets()(dispatch);

	const isInit = status === INIT;
	const isPending = status === PENDING;
	const isSuccess = status === SUCCESS;
	const isPurchase = status === PURCHASE;

	useEffect(() => {
		isAuth && handleFetchUserPackets();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const newStatus = checkTransactionStatus();
		if (newStatus !== status) setStatus(newStatus);
		// eslint-disable-next-line
	}, [isPaymentPending, isLoading, isProductAvailable, isFetched, isAuth]);

	return { status, reset, isInit, isPending, isSuccess, isPurchase };
}

export default useProductTransactionStatus;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Import styled components
import { Wrapper, LogInButton, LogOutButton } from './styles';

// Import logic
import { useAuthenticationButton } from './useAuthenticationButton';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';
const { LOGIN } = APP_CORE_PATHS;

const AuthenticationButton = () => {
	const { t } = useTranslation();

	const { isLogged, signOut } = useAuthenticationButton();

	const logButton = isLogged ? (
		<LogOutButton modifiers={['default']} onClick={signOut}>
			{t('common_static_btn_sign_out')}
		</LogOutButton>
	) : (
		<LogInButton as={Link} modifiers={['default']} to={`/${LOGIN}`}>
			{t('common_login_btn_submit')}
		</LogInButton>
	);

	return <Wrapper isLogged={isLogged}>{logButton}</Wrapper>;
};

export default AuthenticationButton;

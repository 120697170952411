export const getMatchedId = (str) => {
	const idRegex =
		/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
	const id = str.match(idRegex);
	return id ? id[0] : null;
};

export const getProductType = (path) => {
	const type = path.split('/')[4];
	return type ?? null;
};

// TODO base on url types
export const checkProductPage = (path) => /filmy|live|artykuly/gi.test(path);

import { useSelector } from 'react-redux';
import { useNoAuthPathSave } from 'components/utilities';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { LOGIN } = APP_CORE_PATHS;

export const useNavToolbar = () => {
	const { isLogged } = useSelector(({ auth }) => auth);

	const { savePath } = useNoAuthPathSave();

	const loginPath = `/${LOGIN}`;

	return {
		loginClick: savePath,
		loginPath,
		isLogged
	};
};

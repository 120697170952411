import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { handleFormChange } from 'helpers/form';
import { LANGUAGES_TYPES } from 'helpers/variables';

// Import styles
import { Title, SuccessMessage } from './styles';

// Import components
import Fields from './Fields';
import useNewsletter from './useNewsletter';

const { PL, EN } = LANGUAGES_TYPES;

const Newsletter = () => {
	const { t, i18n } = useTranslation();

	const { onSubmit, isSuccess, formRef } = useNewsletter();

	const currentLanguage = i18n.language;
	const titleLanguage = currentLanguage === PL ? EN : currentLanguage;

	return (
		<>
			<Title lang={titleLanguage}>{t('common_footer_newsletter')}</Title>
			<Formik
				ref={formRef}
				initialValues={INITIAL_VALUES}
				onSubmit={onSubmit}
				validateOnChange={false}
				validateOnBlur={false}
			>
				{({ handleSubmit, isSubmitting, handleChange, setErrors }) => (
					<form
						onSubmit={handleSubmit}
						onChange={handleFormChange({ handleChange, setErrors })}
						noValidate
					>
						<Fields isSubmitting={isSubmitting} isSuccess={isSuccess} />
					</form>
				)}
			</Formik>
			{isSuccess && (
				<SuccessMessage small role="alert">
					{t('common_footer_newsletter_message_success')}
				</SuccessMessage>
			)}
		</>
	);
};

export default Newsletter;

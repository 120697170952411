export const convertKeyEvent = ({
	event,
	triggerKey,
	targetKey,
	eventType
}) => {
	if (event.keyCode === triggerKey) {
		event.preventDefault();
		const targetEvent = new KeyboardEvent(eventType, {
			bubbles: true,
			cancelable: true,
			keyCode: targetKey
		});
		event.target.dispatchEvent(targetEvent);
	}
};

import React from 'react';

// Import components
import { ListBoxSelect, ListBoxOption } from 'components/elements';

// Import utilities
import { useInstitutionSelect } from 'components/utilities';

// Import helpers
import { getSelectedElementId } from 'helpers';

const InstitutionSelect = () => {
	const {
		institutionsList,
		selectChange,
		selectedValue,
		selectedInstitutionName,
		forceFocus
	} = useInstitutionSelect();

	const listId = 'institution_select';

	return (
		<>
			{institutionsList && (
				<ListBoxSelect
					currentValue={selectedValue}
					listId={listId}
					changeHandler={selectChange}
					title="common_institution_title"
					selectButtonContent={selectedInstitutionName}
					forceButtonFocus={forceFocus}
				>
					{({ handleMouseOptionSelect, handleKeyOptionEvents }) => (
						<>
							{institutionsList.map(({ uuid, name }) => {
								const isSelected = uuid === selectedValue;
								const selectedId = getSelectedElementId({ isSelected, listId });

								return (
									<ListBoxOption
										key={uuid}
										id={selectedId}
										role="option"
										aria-selected={isSelected}
										data-value={uuid}
										tabIndex={0}
										onMouseUp={handleMouseOptionSelect}
										onKeyDown={handleKeyOptionEvents}
									>
										{name}
									</ListBoxOption>
								);
							})}
						</>
					)}
				</ListBoxSelect>
			)}
		</>
	);
};

export default InstitutionSelect;

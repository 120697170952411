import React, { useEffect } from 'react';
import { string, oneOfType, arrayOf, node, bool } from 'prop-types';
import { MenuPopover, MenuItems } from '@reach/menu-button';

// Import styles
import {
	SettingsButton,
	SettingsList,
	SettingsPopover,
	HeadingStyled
} from './styles';

// Import context
import { usePlayerFocusContext } from 'components/context/player/focus';

const PlayerSettingsContent = ({
	title,
	children,
	buttonContent,
	type,
	ariaText,
	isExpanded
}) => {
	const { focusFreeze, focusUnfreeze } = usePlayerFocusContext();

	useEffect(() => {
		isExpanded ? focusFreeze() : focusUnfreeze();
		// eslint-disable-next-line
	}, [isExpanded]);

	return (
		<>
			<SettingsButton title={ariaText} modifiers={type} aria-label={ariaText}>
				{buttonContent}
			</SettingsButton>

			<MenuPopover portal={false} as={SettingsPopover}>
				<HeadingStyled aria-hidden>{title}</HeadingStyled>
				<MenuItems as={SettingsList}>{children}</MenuItems>
			</MenuPopover>
		</>
	);
};

PlayerSettingsContent.propTypes = {
	title: string.isRequired,
	children: oneOfType([arrayOf(node), node]).isRequired,
	buttonContent: node.isRequired,
	ariaText: string.isRequired,
	type: string.isRequired,
	isExpanded: bool.isRequired
};

export default PlayerSettingsContent;

import React from 'react';
import { func, string, node } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FocusOn } from 'react-focus-on';

// Import components
import { AriaModal } from 'components/elements';

// Import styles
import { ButtonClose, IconClose } from './styles';

const FullscreenModal = ({
	titleText,
	titleId,
	onExit,
	children,
	...restProps
}) => {
	const { t } = useTranslation();
	return (
		<AriaModal
			titleText={titleText}
			titleId={titleId}
			onExit={onExit}
			{...restProps}
		>
			<FocusOn scrollLock={false}>
				<ButtonClose
					onClick={onExit}
					aria-label={t('common_modal_close_button_title')}
				>
					<IconClose aria-hidden role="img" />
				</ButtonClose>
				{children}
			</FocusOn>
		</AriaModal>
	);
};

FullscreenModal.defaultProps = {
	onExit: () => {},
	titleText: 'Dialog'
};

FullscreenModal.propTypes = {
	onExit: func.isRequired,
	titleText: string.isRequired,
	titleId: string,
	children: node.isRequired
};

export default FullscreenModal;

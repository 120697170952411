import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import actions
import {
	toggleAppContrast,
	toggleAnimationsAvailability,
	resetToDefaultSettings,
	setFontSize
} from 'store/actions';

// Import styled components
import { StyledAnimationPauseIcon, StyledAnimationPlayIcon } from './styles';

// Import variables
import {
	LOCAL_STORAGE_DISABILITY_SETTINGS,
	DISABILITY_ANIMATION_KEY,
	DISABILITY_CONTRAST_KEY,
	DISABILITY_FONT_SIZE_KEY
} from 'helpers/variables';
import { AVAILABLE_FONT_SIZE } from 'helpers/wcag';

// Import helpers
import { saveLocalStorageData } from './helpers';

// Import variables
const { DEFAULT } = AVAILABLE_FONT_SIZE;

const useDisabilityNav = ({ isMobile }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { areAnimationsAvailable, isContrast, fontSize } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	useEffect(() => {
		isMobile && changeFontSize(DEFAULT)();
		// eslint-disable-next-line
	}, [isMobile]);

	const disabilityStorageSettings = JSON.parse(
		localStorage.getItem(LOCAL_STORAGE_DISABILITY_SETTINGS)
	);

	// ==== ANIMATION ====
	const changeAnimationsAvailability = () => {
		saveLocalStorageData({
			key: DISABILITY_ANIMATION_KEY,
			value: !(disabilityStorageSettings?.[DISABILITY_ANIMATION_KEY] ?? true)
		});
		toggleAnimationsAvailability()(dispatch);
	};

	const animationActionInfo = areAnimationsAvailable
		? t('common_header_play_animation_button_on_info')
		: t('common_header_play_animation_button_off_info');

	const animationIcon = areAnimationsAvailable ? (
		<StyledAnimationPauseIcon
			title={t('aria_header_pouse_animation_button')}
			aria-hidden="true"
		/>
	) : (
		<StyledAnimationPlayIcon
			title={t('aria_header_play_animation_button')}
			aria-hidden="true"
		/>
	);

	const animationIconAriaLabel = areAnimationsAvailable
		? t('aria_header_pouse_animation_button')
		: t('aria_header_play_animation_button');

	// ==== CONTRAST ====
	const changeAppContrast = () => {
		saveLocalStorageData({
			key: DISABILITY_CONTRAST_KEY,
			value: !disabilityStorageSettings?.[DISABILITY_CONTRAST_KEY]
		});
		toggleAppContrast()(dispatch);
	};

	const contrastActionInfo = isContrast
		? t('common_header_play_contrast_button_on_info')
		: t('common_header_play_contrast_button_off_info');

	const contrastIconAriaLabel = isContrast
		? t('aria_header_contrast_button_off')
		: t('aria_header_contrast_button_on');

	// ==== FONT SIZE ====
	const changeFontSize = (fontSize) => () => {
		saveLocalStorageData({
			key: DISABILITY_FONT_SIZE_KEY,
			value: fontSize
		});
		setFontSize(fontSize)(dispatch);
	};

	const fontChangeActionInfo = t(
		`common_header_resize_font_${fontSize}_button_info`
	);

	// ==== RESET TO DEFAULT ====
	const handleResetToDefaultSettings = () => {
		localStorage.removeItem(LOCAL_STORAGE_DISABILITY_SETTINGS);
		resetToDefaultSettings()(dispatch);
	};

	return {
		animationIcon,
		animationIconAriaLabel,
		contrastIconAriaLabel,
		areAnimationsAvailable,
		animationActionInfo,
		contrastActionInfo,
		fontChangeActionInfo,
		setFontSize: changeFontSize,
		toggleAppContrast: changeAppContrast,
		toggleAnimationsAvailability: changeAnimationsAvailability,
		resetToDefaultSettings: handleResetToDefaultSettings
	};
};

export default useDisabilityNav;

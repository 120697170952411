import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import {
	Button,
	PlayerElements as Player,
	PlayerSettings
} from 'components/elements';
import Slider from './Slider';
import MuteButton from './MuteButton';

// Import styles
import { Wrapper } from './styles';

// Import utilities
import { useToggle } from 'components/utilities';

const VolumeController = () => {
	const { t } = useTranslation();
	const [isOpened, toggle] = useToggle();

	return (
		<Wrapper isOpened={isOpened}>
			<Button
				onClick={toggle}
				modifiers={['icon', 'playerIconHover']}
				title={t('aria_player_settings_volume_button')}
				aria-label={t('aria_player_settings_volume_button')}
				aria-haspopup
			>
				<Player.IconVolume aria-hidden />
			</Button>
			{isOpened && (
				<PlayerSettings.Popover toggle={toggle} width={5}>
					<Slider />
					<MuteButton />
				</PlayerSettings.Popover>
			)}
		</Wrapper>
	);
};

export default VolumeController;

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import components
import { Paragraph } from 'components/elements';

// Import styles
import {
	transitionMain,
	flex,
	customMinResponsive,
	size,
	focusBasic
} from 'components/styles';

export const SearchItemStyled = styled.li`
	height: 8.4rem;
	border-top: 1px solid ${({ theme }) => theme.line};
`;

export const ParagraphStyled = styled(Paragraph.Ellipsis)`
	width: 100%;
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	${transitionMain}

	${({ theme }) =>
		theme.isWCAG &&
		css`
			margin-bottom: 0 !important;
		`}


	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
		`
	)}
`;

export const ProductType = styled(Paragraph.Ellipsis)`
	color: ${({ theme }) => theme.graySemiLight};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	&:first-letter {
		text-transform: capitalize;
	}

	${({ theme }) => theme.isWCAG && 'margin-top: 1.5em;'}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('s', -0.2)}rem;
		`
	)}
`;

export const StyledLink = styled(Link)`
	&:link,
	&:visited {
		height: 100%;
		width: 100%;
		padding: 1.5rem 2.6rem;
		${flex({ fd: 'column', ai: 'flex-start' })}
		${transitionMain}
	}
	&:hover,
	&:active {
		${ParagraphStyled} {
			color: ${({ theme: { searchBox } }) => searchBox.itemTitleHover};
		}
	}

	&:focus-visible {
		${focusBasic}
		outline-offset: -0.3rem;
		${ParagraphStyled} {
			color: ${({ theme: { searchBox } }) => searchBox.itemTitleHover};
		}
	}
`;

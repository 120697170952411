import React from 'react';
import { Link } from 'react-router-dom';
import { oneOfType, bool, string, array, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { Slider, SliderItem } from 'components/elements';
import { VisuallyHidden } from '@reach/visually-hidden';

// Import styles
import { Wrapper, Title, SliderHeader, ShowAllButton } from './styles';

// Import utilities
import { useSlideSize } from 'components/utilities';
import useSliderSection from './useSliderSection';

// Import helpers
import { checkIsActive } from './helpers';

const SliderSection = ({
	content,
	type,
	modifiers,
	showAll,
	renderSlideAsLink,
	onItemClick,
	id,
	name,
	slideType,
	setSelected,
	selected
}) => {
	const { t } = useTranslation();

	const {
		targetRef,
		containerWidth,
		isDesktop,
		spaceBetweenSlides,
		slideHeight,
		slideWidth,
		slidesToShow
	} = useSlideSize({ type });

	const {
		sliderContentUrl,
		showAllButtonModifiers,
		showHeader,
		activeIndexes,
		setCurrentSlide
	} = useSliderSection({
		id,
		name,
		isDesktop,
		type,
		slidesCount: content.length,
		slidesToShow
	});

	return (
		<Wrapper modifiers={modifiers}>
			<div ref={targetRef}>
				{containerWidth && (
					<>
						{showHeader && (
							<SliderHeader>
								<Title className="slider__name">{name}</Title>
								{showAll && (
									<ShowAllButton
										as={Link}
										to={sliderContentUrl}
										modifiers={showAllButtonModifiers}
										isDesktop={isDesktop}
									>
										{t('common_general_all')}
										<VisuallyHidden>- {name}</VisuallyHidden>
									</ShowAllButton>
								)}
							</SliderHeader>
						)}
						<Slider
							slidesToShow={slidesToShow}
							slideHeight={slideHeight}
							isDesktop={isDesktop}
							name={name}
							setCurrentSlide={setCurrentSlide}
							activeIndexes={activeIndexes}
							slidesCount={content?.length}
							renderSlideAsLink={renderSlideAsLink}
						>
							{content.map((product, index) => {
								const isActive = checkIsActive({ activeIndexes, index });

								return (
									<SliderItem
										key={product.uuid || product.id}
										product={product}
										index={index}
										onItemClick={onItemClick}
										renderSlideAsLink={renderSlideAsLink}
										spaceBetweenSlides={spaceBetweenSlides}
										slideHeight={slideHeight}
										slideWidth={slideWidth}
										slideType={slideType}
										setSelected={setSelected}
										selected={selected}
										isActive={isActive}
									/>
								);
							})}
						</Slider>
					</>
				)}
			</div>
		</Wrapper>
	);
};

SliderSection.defaultProps = {
	renderSlideAsLink: true,
	onItemClick: () => {},
	modifiers: [],
	showAll: true,
	setSelected: () => {}
};

SliderSection.propTypes = {
	content: array.isRequired,
	renderSlideAsLink: bool,
	onItemClick: func,
	type: string,
	slideType: string,
	modifiers: oneOfType([string, array]),
	showAll: bool,
	id: oneOfType([number, string]).isRequired,
	name: string,
	setSelected: func,
	selected: number
};

export default SliderSection;

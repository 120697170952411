import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';

// Import api
import {
	GET_SUBSCRIBER_PROMOTIONS_API,
	QUERY_SUBSCRIBER_PROMOTIONS
} from 'helpers/api';

const useSubscriberPromotionsQuery = ({
	onSuccess = () => {},
	onError = () => {},
	enabled = true
} = {}) => {
	const queryFn = () => services.get(GET_SUBSCRIBER_PROMOTIONS_API);

	const { data, status, isLoading, isFetching, isError, remove } = useQuery({
		queryKey: QUERY_SUBSCRIBER_PROMOTIONS,
		queryFn,
		onSuccess,
		onError,
		enabled
	});

	useEffect(() => {
		return () => remove();
		//eslint-disable-next-line
	}, []);

	const subscriberPromotions = data?.data;

	return {
		subscriberPromotions,
		subscriberPromotionsStatus: status,
		isSubscriberPromotionsLoading: isLoading || isFetching,
		isSubscriberPromotionsError: isError
	};
};

export default useSubscriberPromotionsQuery;

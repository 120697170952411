import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import services from 'services/services';

const INITIAL_STATE = {
	isLoading: false,
	isError: false,
	data: []
};

const fetchRecommendations = createAsyncThunk(
	'recommendations',
	async ({ id, type }) => {
		const url = `/recommendations/${type}/${id}`;
		const { data } = await services.get(url);
		return data;
	}
);

const recommendationsSlice = createSlice({
	name: 'recommendations',
	initialState: INITIAL_STATE,
	reducers: {
		clear: () => INITIAL_STATE
	},
	extraReducers: {
		[fetchRecommendations.pending]: (state) => {
			state.isLoading = true;
			state.isError = false;
			state.data = [];
		},
		[fetchRecommendations.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		},
		[fetchRecommendations.rejected]: (state) => {
			state.isLoading = false;
			state.isError = true;
			state.data = [];
		}
	}
});

const {
	reducer,
	actions: { clear }
} = recommendationsSlice;

export {
	reducer as default,
	clear as clearRecommendations,
	fetchRecommendations
};

import { APP_CORE_PATHS } from './app';

const {
	HOME_PAGE,
	PAGE,
	USER,
	SEARCH,
	TRANSACTION,
	PACKETS,
	PAYMENT,
	INSTITUTIONS
} = APP_CORE_PATHS;

export const MAIN_PATHS = {
	HOME_PAGE,
	PAGE: `/${PAGE}`,
	USER: `/${USER}`,
	SEARCH: `/${SEARCH}/:query/:institutionUuid?`,
	TRANSACTION: `/${TRANSACTION}/:query`,
	PACKETS: `/${PACKETS}`,
	PAYMENT: `/${PAYMENT}/:type/:id/:title`,
	WALLET_PAYMENT: `/${PAYMENT}/portmonetka`,
	INSTITUTIONS: `/${INSTITUTIONS}`
};

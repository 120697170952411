export const EPISODE_TYPES = {
	PREV: 'prev',
	NEXT: 'next'
};

export const SERIES_CONTROLLERS = [
	{ title: 'common_player_prev_episode_title', type: EPISODE_TYPES.PREV },
	{ title: 'common_player_next_episode_title', type: EPISODE_TYPES.NEXT }
];

export const getProductType = (path) => {
	const type = path.split('/')[3];
	return type ?? null;
};

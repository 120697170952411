import styled, { css } from 'styled-components';
import { StyleSheet } from '@react-pdf/renderer';

// Import components
import { buttonsCommonStyles } from 'components/views/vod/components/buttons/styles';

export const useStyles = () =>
	StyleSheet.create({
		view: {
			color: '#070606',
			margin: 37,
			fontFamily: 'Roboto',
			textAlign: 'left',
			marginTop: 0
		},
		page: {
			paddingTop: 37,
			paddingBottom: 50
		},
		header: {
			marginTop: 63,
			marginBottom: 30,
			fontSize: 24
		},
		content: {
			fontSize: 14
		},
		logo: {
			width: 45,
			height: 29,
			top: 0,
			right: 0,
			position: 'absolute'
		},
		footer: {
			bottom: 20,
			left: 0,
			position: 'absolute',
			width: '100%',
			textAlign: 'center',
			fontSize: 10,
			opacity: 0.5
		}
	});

export const ButtonContent = styled.span`
	${buttonsCommonStyles}
	margin-bottom: 0;
	font-size: ${({ theme }) => theme.fontSize.xs}rem !important;

	${({ isFocus }) =>
		isFocus &&
		css`
			outline: none;
			border-color: ${({ theme }) => theme.primaryBlueLight};
			color: ${({ theme }) => theme.black};
			box-shadow: 0 0 4px ${({ theme }) => theme.primaryBlueLight};
			background-color: ${({ theme }) => theme.primaryBlueLight};
		`}
`;

import { useQuery } from 'react-query';
import { useParams, generatePath } from 'react-router-dom';
import services from 'services/services';

// Import api
import { GET_SECTIONS_LIST_API, QUERY_SECTIONS_LIST } from 'helpers/api';

const useSectionsQuery = (label) => {
	const queryKey = `${QUERY_SECTIONS_LIST}_${label}`;
	const { institutionUuid } = useParams();

	const queryFn = () => {
		const url = generatePath(GET_SECTIONS_LIST_API, { label: `${label}` });
		return services.get(url, {
			params: { products_group_uuid: institutionUuid }
		});
	};

	const { data, isLoading, isError } = useQuery({
		queryKey,
		queryFn
	});

	const sections = data?.data || [];

	const isSections = !!sections.length;

	return {
		isLoading,
		sections,
		isError,
		isSections
	};
};

export default useSectionsQuery;

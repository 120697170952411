import React from 'react';
import { bool } from 'prop-types';
import styled, { css } from 'styled-components';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';
import { usePlayerFocusContext } from 'components/context/player/focus';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';
import VolumeController from './VolumeController';
import SettingsController from './SettingsController';
import PlayController from './PlayController';
import ShakaSlider from 'components/elements/organisms/player_vod/PlayerWrapper/components/ShakaSlider';

const PlayerControllers = ({ isCatchup, isFloatMode }) => {
	// Get player methods from Player context
	const {
		setFullScreen,
		isReady,
		isFullScreen,
		seekAvailable,
		duration,
		updateTime,
		currentTimeProgress
	} = usePlayerTvContext();

	const { isFocus } = usePlayerFocusContext();

	return (
		isReady && (
			<Wrapper
				isCatchupControllers={isCatchup}
				isFloatMode={isFloatMode}
				isFocus={isFocus}
			>
				{seekAvailable && <PlayController />}
				<Button
					modifiers={['icon', 'catchup', 'playerIconHover']}
					onClick={setFullScreen}
					isFocus={isFocus}
				>
					{isFullScreen ? (
						<Player.IconExitFullScreen />
					) : (
						<Player.IconFullScreen />
					)}
				</Button>
				<SettingsController />
				<VolumeController />
				{seekAvailable && (
					<ShakaSlider
						duration={duration}
						updateTime={updateTime}
						currentTimeProgress={currentTimeProgress}
						seekAvailable={seekAvailable}
						isCatchup={isCatchup}
					/>
				)}
			</Wrapper>
		)
	);
};

const Wrapper = styled.div`
	position: absolute;
	padding: 2rem 0 2rem 3rem;
	display: flex;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	visibility: ${({ isFocus }) => (isFocus ? 'visible' : 'hidden')};
	transition: all 0.3s ease-in-out;
	z-index: 9999;
	left: 0;
	bottom: ${({ isCatchupControllers }) => (isCatchupControllers ? -1 : 0.4)}rem;
	width: 100%;

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			padding-bottom: 0;
		`}
`;

PlayerControllers.propTypes = {
	isCatchup: bool.isRequired,
	isFloatMode: bool.isRequired
};

export default PlayerControllers;

import React from 'react';
import { string, bool, func } from 'prop-types';

// Import styled components
import { StyledLink, StyledAnchorLink } from '../common_styles';

const MenuLink = ({ isInternalLink, path, name, link, isMainLink }) => {
	if (isInternalLink)
		return (
			<StyledLink to={path} isMainLink={isMainLink}>
				{name}
			</StyledLink>
		);
	return (
		<StyledAnchorLink href={link} isMainLink={isMainLink}>
			{name}
		</StyledAnchorLink>
	);
};

MenuLink.defaultProps = {
	isMainLink: false
};

MenuLink.propTypes = {
	isInternalLink: bool.isRequired,
	name: string.isRequired,
	toggle: func.isRequired,
	link: string.isRequired,
	path: string,
	isMainLink: bool
};

export default MenuLink;

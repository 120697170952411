import React from 'react';
import { func, bool } from 'prop-types';

// Import components
import ComboBoxSearch from './ComboBoxSearch/ComboBoxSearch';

// Import styles
import { Search } from './styles';

const NavSearch = ({ toggle, isOpen }) => (
	<Search>
		<ComboBoxSearch toggle={toggle} isOpen={isOpen} autoFocus />
	</Search>
);

NavSearch.propTypes = {
	toggle: func.isRequired,
	isOpen: bool.isRequired
};

export default NavSearch;

import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

// Import styles
import { transitionMain, focusBasic } from 'components/styles';

export const Nav = styled(NavLink)`
	color: ${({ theme }) => theme.graySemiLight};
	font-size: ${({ theme }) => theme.fontSize.nav.link}rem;
	line-height: 3.4rem;
`;

export const ItemStyled = styled(Menu.Item)`
	> ${Nav} {
		color: ${({ theme }) => theme.white};
		${transitionMain}

		${({ theme }) =>
			theme.isWCAG &&
			css`
				margin-bottom: 1.5em;
			`}

		&.active-drop-nav {
			background-color: ${({ theme: { dropdown } }) =>
				dropdown.menu.itemHoverBg};
			color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
		}
		&:focus,
		&:focus-visible {
			color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
			${focusBasic}
		}
	}

	&.ant-dropdown-menu-item-active {
		background: none;

		> ${Nav} {
			background-color: ${({ theme: { dropdown } }) =>
				dropdown.menu.itemHoverBg};
			color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
		}
	}
`;

export const MenuDivider = styled.li`
	width: 100%;
	height: 0.1rem;
	margin: 0.4rem 0;
	background-color: ${({ theme }) => theme.line};
`;

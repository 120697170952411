import * as types from 'store/actions/types';
import services from 'services/services';
import i18next from 'i18next';
import { openNotification } from 'components/utilities/notification/notification';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';

// Import api
import { GET_SUBSCRIBER_ACTIVE_VOUCHERS_API } from 'helpers/api';

// ******************** FETCH SUBSCRIBER VOUCHERS ********************
export const fetchSubscriberVouchers = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_SUBSCRIBER_VOUCHERS_LOADING,
			payload: true
		});

		const { data } = await services.get(GET_SUBSCRIBER_ACTIVE_VOUCHERS_API);

		dispatch({
			type: types.FETCH_SUBSCRIBER_VOUCHERS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SUBSCRIBER_VOUCHERS_ERROR,
			payload: true
		});

		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: i18next.t('common_products_error_title'),
				description: i18next.t('common_products_error_description')
			});
	}
};

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styled components
import { ArrowController } from './styles';

// Import assets
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow_xl_l_contrast.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow_xl_r_contrast.svg';

// Import helpers
import { SLIDER_ARROW_TYPES } from 'helpers/variables';

const { PREV } = SLIDER_ARROW_TYPES;

const GalleryArrow = forwardRef(({ type, ...rest }, ref) => {
	const { t } = useTranslation();
	const isPrevArrow = type === PREV;

	return (
		<ArrowController
			isPrevArrow={isPrevArrow}
			aria-label={t(`aria_slider_arrow_button_${type}`)}
			title={t(`aria_slider_arrow_button_${type}`)}
			ref={ref}
			{...rest}
		>
			{isPrevArrow ? <IconArrowLeft /> : <IconArrowRight />}
		</ArrowController>
	);
});

GalleryArrow.propTypes = {
	type: PropTypes.string.isRequired
};

export default GalleryArrow;

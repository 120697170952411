import { useContext } from 'react';

// Import helpers
import { defaultContext } from './helpers';

// Import context
import { ProductPreviewContext } from './ProductPreviewProvider';

function useProductPreviewContext() {
	const context = useContext(ProductPreviewContext);
	return context ?? defaultContext;
}

export default useProductPreviewContext;

import services from 'services/services';
import * as types from 'store/actions/types';

export const fetchAssetGroups = (id) => async (dispatch) => {
	try {
		dispatch({ type: types.FETCH_ASSET_GROUPS_LOADING });

		const url = `/assets/groups/${id}/list`;

		const {
			data: { data }
		} = await services.get(url, { headers: { 'Cache-Control': 'no-cache' } });

		const galleries = data?.length ? data : [];

		dispatch({
			type: types.FETCH_ASSET_GROUPS_SUCCESS,
			payload: galleries
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ASSET_GROUPS_ERROR
		});
	}
};

export const clearAssetGroups = () => async (dispatch) => {
	dispatch({ type: types.CLEAR_PRODUCT_ASSET_GROUPS });
};

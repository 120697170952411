import React from 'react';
import { func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import SearchResults from './SearchResults';

// Import styles
import {
	ComboboxStyled,
	SearchInputStyled,
	ComboboxInputStyled,
	SearchIconStyled,
	ButtonStyledClose,
	CloseIconStyled
} from './styles';

// Import custom hooks
import { useComboBoxSearch } from './useComboBoxSearch';

// Import helpers
import { SECTIONS_ID } from 'helpers/variables';
import { MIN_SEARCH_TERM_LENGTH } from './helpers';

const { SEARCH_INPUT } = SECTIONS_ID;

const ComboBoxSearch = ({ toggle, isOpen, autoFocus, isMobileView }) => {
	const { t } = useTranslation();
	const {
		searchTerm,
		searchRef,
		searchResultsRef,
		handleSelect,
		handleClose,
		handleSearchTermChange,
		handleInputKeyPress
	} = useComboBoxSearch({ isMobileView, toggle, isOpen });

	const listId = 'nav_search_list';

	return (
		<ComboboxStyled onSelect={handleSelect} ref={searchRef}>
			<SearchIconStyled role="img" aria-hidden />
			<SearchInputStyled>
				<ComboboxInputStyled
					onChange={handleSearchTermChange}
					autocomplete={false}
					value={searchTerm}
					placeholder={t('common_header_nav_search_placeholder')}
					autoFocus={autoFocus}
					id={SEARCH_INPUT}
					aria-label={t('aria_search_bar')}
					aria-controls={`listbox${listId}`}
					aria-expanded={isOpen}
					aria-haspopup="listbox"
					onKeyDown={handleInputKeyPress}
				/>
			</SearchInputStyled>

			{searchTerm.length >= MIN_SEARCH_TERM_LENGTH && (
				<SearchResults
					searchTerm={searchTerm}
					searchResultsRef={searchResultsRef}
					listId={listId}
				/>
			)}

			<ButtonStyledClose
				aria-label={t('aria_search_close_button')}
				type="button"
				onClick={handleClose}
			>
				<CloseIconStyled />
			</ButtonStyledClose>
		</ComboboxStyled>
	);
};

ComboBoxSearch.propTypes = {
	toggle: func.isRequired,
	isOpen: bool.isRequired,
	autoFocus: bool,
	isMobileView: bool
};

export default ComboBoxSearch;

import React from 'react';

// Import styles
import { Timer } from '../controllers/styles';

// Import utilities
import { useCurrentTime } from 'components/utilities';

// Import helpers
import { getTimeLineFormat } from 'helpers';

const CurrentTime = () => {
	const { currentTime } = useCurrentTime();

	const time = getTimeLineFormat(currentTime);

	return <Timer>{time}</Timer>;
};

export default CurrentTime;

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import CommonButton from './CommonButton';

const ProductAvailableButton = () => {
	const { t } = useTranslation();

	return (
		<CommonButton isDisabled style={{ opacity: 0.8 }}>
			{t('vod_buttons_product_available')}
		</CommonButton>
	);
};

export default ProductAvailableButton;

import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

// Import heleprs
import { GOOGLE_TAG_MANAGER } from 'helpers/variables';

const { PAGE_VIEW } = GOOGLE_TAG_MANAGER;

export const ANALYTICS_KEYS = {
	GA: process.env.REACT_APP_GA_ID,
	GTAG: process.env.REACT_APP_GTM_ID,
	PIXEL: process.env.REACT_APP_FACEBOOK_PIXEL_ID
};

function gtag() {
	window.dataLayer.push(arguments);
}

export const initGA = () => {
	window.dataLayer = window.dataLayer || [];

	gtag('js', new Date());
	gtag('config', ANALYTICS_KEYS.GA);
};

export const initPixel = () => {
	const options = {
		autoConfig: true,
		debug: false
	};
	ReactPixel.init(ANALYTICS_KEYS.PIXEL, {}, options);
};

export const initTagManager = () => {
	const options = {
		gtmId: ANALYTICS_KEYS.GTAG
	};
	TagManager.initialize(options);
};

export const logPageViewGA = (props) => {
	// GA
	const { pathname, search } = window.location;

	const locationPath = pathname + search;

	const eventOptions = {
		locationPath,
		js: new Date(),
		...props
	};
	gtag('event', PAGE_VIEW, eventOptions);
};

import React from 'react';
import { func, string, number, oneOfType, bool } from 'prop-types';

// Import styles
import { Checkbox } from './styles';

const Toggler = ({
	id,
	toggleAgreement,
	checked,
	disabled,
	obligatory,
	isUserApproval
}) => {
	const handleToggleAgreement = () => toggleAgreement(id);

	return (
		<Checkbox
			id={id}
			type="checkbox"
			checked={checked}
			onChange={handleToggleAgreement}
			disabled={disabled}
			aria-required={obligatory}
			isUserApproval={isUserApproval}
		/>
	);
};

Toggler.defaultProps = {
	toggleAgreement: () => {},
	checked: false,
	disabled: false,
	obligatory: false,
	isUserApproval: false
};

Toggler.propTypes = {
	id: oneOfType([string, number]).isRequired,
	toggleAgreement: func.isRequired,
	checked: bool,
	disabled: bool,
	obligatory: bool,
	isUserApproval: bool
};

export default Toggler;

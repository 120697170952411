import styled, { css } from 'styled-components';

// Import styles
import {
	flex,
	transitionMain,
	customResponsive,
	size,
	textEllipsis
} from 'components/styles';

export const Wrapper = styled.div`
	position: absolute;
	left: 0;
	bottom: 10.5rem;
	padding: 1.5rem;
	background-color: #12100b;
	border-radius: 6px;
	z-index: 1;
	column-gap: 1.5rem;
	transform: translateX(-100%);
	${flex()}
	${transitionMain}

	&.entering,
	&.exiting {
		transform: translateX(-100%);
	}

	&.entered {
		transform: translateX(3rem);
	}

	${customResponsive(
		size.medium,
		css`
			width: 100%;
			bottom: 12rem;

			&.entered {
				transform: translateX(0);
			}
		`
	)}
`;

export const Name = styled.p`
	color: ${({ theme }) => theme.cast.receiver.color};
	font-weight: ${({ theme }) => theme.font.medium};
	font-size: ${({ theme }) => theme.fontSize.s}rem;
	flex: 1;
	${textEllipsis}
`;

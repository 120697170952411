import React from 'react';
import { array, oneOfType, string, number, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import helpers
import { convertPrice, getSelectedElementId } from 'helpers';

// Import components
import { ListBoxOption, ListBoxSelect } from 'components/elements';

const PromotionSelect = ({
	promotions,
	promotionId,
	onPromotionChange,
	labelledBy
}) => {
	const { t } = useTranslation();

	const listId = 'payment_promotion_options_select';

	const selectButtonContent = promotions.find(
		({ id }) => id === promotionId
	)?.name;

	return (
		<ListBoxSelect
			labelledBy={labelledBy}
			listId={listId}
			currentValue={promotionId}
			changeHandler={onPromotionChange}
			selectButtonContent={selectButtonContent}
			modifiers="payment_promotion"
		>
			{({ handleMouseOptionSelect, handleKeyOptionEvents }) => (
				<>
					{promotions.map(({ id, name, type, price }) => {
						const isSelected = id === promotionId;
						const selectedId = getSelectedElementId({
							isSelected,
							listId
						});

						return (
							<ListBoxOption
								key={id}
								id={selectedId}
								role="option"
								aria-selected={isSelected}
								data-value={id}
								tabIndex={0}
								onMouseUp={handleMouseOptionSelect}
								onKeyDown={handleKeyOptionEvents}
							>
								{name}
								{type && ` ${convertPrice(price)} ${t('payment_currency')}`}
							</ListBoxOption>
						);
					})}
				</>
			)}
		</ListBoxSelect>
	);
};

PromotionSelect.propTypes = {
	promotions: array.isRequired,
	promotionId: oneOfType([string, number]).isRequired,
	onPromotionChange: func.isRequired,
	labelledBy: string.isRequired
};

export default PromotionSelect;

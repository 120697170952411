import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const base = css`
	color: ${({ theme }) => theme.white};
	margin-bottom: 1.8rem;
	line-height: 1.4;
	font-weight: ${({ theme }) => theme.font.medium};
`;

// Hidden heading modifiers
const HIDDEN_HEADING_MODIFIERS = {
	hidden: () => css`
		position: absolute;
		clip: rect(0, 0, 0, 0);
		border: 0;
		height: 0.1rem;
		width: 0.1rem;
		margin: -0.1rem;
		overflow: hidden;
		padding: 0;
		white-space: nowrap;
		overflow-wrap: normal;
	`
};

// Heading First
const HEADING_FIRST_MODIFIERS = {
	...HIDDEN_HEADING_MODIFIERS,

	primary: ({ theme: { heading } }) => css`
		color: ${heading.first.primary};
	`,

	details: ({ theme }) => css`
		margin-bottom: 2.2rem;
		color: ${theme.grayMedium};
	`,

	normal: ({ theme }) => css`
		font-size: ${theme.fontSize.md}rem;
	`,

	big: ({ theme }) => css`
		font-size: ${theme.fontSize.lg}rem;
	`
};

const First = styled.h1`
	${base}
	margin-top: 1.8rem;
	color: ${({ theme: { heading } }) => heading.first.white};
	font-weight: ${({ theme }) => theme.font.normal};
	line-height: 1.2;
	${applyStyleModifiers(HEADING_FIRST_MODIFIERS)};
`;

// Heading Second
const HEADING_SECOND_MODIFIERS = {
	...HIDDEN_HEADING_MODIFIERS,

	small: ({ theme }) => css`
		font-size: ${theme.fontSize.set('xs', -0.2)}rem;
		color: ${theme.primary};
	`
};

const Second = styled.h2`
	${base}

	${applyStyleModifiers(HEADING_SECOND_MODIFIERS)};
`;

// Heading Third
const HEADING_THIRD_MODIFIERS = {
	...HIDDEN_HEADING_MODIFIERS,

	big: ({ theme }) => css`
		font-size: ${theme.fontSize.md}rem;
	`
};

const Third = styled.h3`
	${base}
	font-weight:${({ theme }) => theme.font.normal};
	${applyStyleModifiers(HEADING_THIRD_MODIFIERS)};
`;

export { Second, Third, First };

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import SeekController from './SeekController';

// Import helpers
import { SEEK_CONTROLLERS } from './helpers';

const SeekControllers = () => {
	const { t } = useTranslation();

	return SEEK_CONTROLLERS.map(({ type, title }) => (
		<SeekController key={type} type={type} title={t(title)} />
	));
};

export default SeekControllers;

// Font size for WCAG
export const AVAILABLE_FONT_SIZE = {
	DEFAULT: 'default',
	MEDIUM: 'large',
	LARGE: 'xl_large'
};

const { DEFAULT, MEDIUM, LARGE } = AVAILABLE_FONT_SIZE;

export const getFontSize = (fontSize) => {
	switch (fontSize) {
		case DEFAULT:
			return 1;
		case MEDIUM:
			return 1.5;
		case LARGE:
			return 2;
		default:
			throw new Error('Unsupported font size');
	}
};

export const STATE_CHANGE_ANNOUNCE_TIMEOUT = 10000;
export const STATE_CHANGE_ANNOUNCE_TIMEOUT_SHORT = 4000;

export const handleSlickSliderArrowAriaUpdate = ({ prev, next }) => {
	if (prev && next) {
		[prev, next].forEach((arrow) =>
			arrow.classList.contains('slick-disabled')
				? arrow.setAttribute('aria-hidden', 'true')
				: arrow.removeAttribute('aria-hidden')
		);
	}
};

import { useEffect } from 'react';
import { fetchStaticContent, clearStaticContent } from 'store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import helpers
import { PAGE_META_TAGS } from 'helpers/meta_tags';

const { USER } = APP_CORE_PATHS;

const useStaticPage = (pageAlias) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { alias } = useParams();
	const { t } = useTranslation();

	const { isLoaded, isError, data } = useSelector(
		({ static_content }) => static_content
	);

	const documentAlias = alias || pageAlias || pathname.split('/')[2];

	const isUserProfile = pathname.includes(USER);

	const showLoader = !isLoaded && !isError && !isUserProfile;

	const defaultDocumentTitle = t(PAGE_META_TAGS.COMMON.title);

	const staticContentTitle = data?.title;

	useEffect(() => {
		documentAlias && fetchStaticContent(documentAlias)(dispatch);
		// eslint-disable-next-line
	}, [documentAlias]);

	useEffect(() => {
		if (staticContentTitle && document.title === defaultDocumentTitle) {
			document.title = `${defaultDocumentTitle} - ${staticContentTitle}`;
		}
		// eslint-disable-next-line
	}, [staticContentTitle]);

	useEffect(() => {
		return () => clearStaticContent()(dispatch);
		// eslint-disable-next-line
	}, []);

	return {
		isUserProfile,
		showLoader
	};
};

export default useStaticPage;

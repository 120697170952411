import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router-dom';
import services from 'services/services';
import { useTranslation } from 'react-i18next';

// Import helpers
import {
	getAvailablePasses,
	getRecommendedPasses,
	getPassPriceId
} from './helpers';
import { PRODUCTS_TYPES } from 'helpers/variables';
import {
	GET_AVAILABLE_PASSES_FOR_PRODUCT,
	GET_PRODUCT_PRICES_API,
	PRICES_FOR_PASS_QUERY_KEY,
	RECOMMENDED_PASSES_QUERY_KEY
} from 'helpers/api';

// Import utilities
import { useAuth } from 'components/utilities';
import { openNotification } from 'components/utilities/notification/notification';

// Import actions
import {
	submitProductPurchase,
	fetchInitPayment,
	fetchUserPackets,
	clearPurchase
} from 'store/actions';

// Context
export const PassesContext = createContext(null);

function PassesProvider({ children }) {
	const { isAuth } = useAuth();
	const { t } = useTranslation();
	const { id: productId } = useParams();
	const dispatch = useDispatch();

	const [priceId, setPriceId] = useState(null);
	const [purchaseLoading, setPurchaseLoading] = useState(false);

	// Passes that can be bought and used on the product, without those already in possession
	const [recommendedPasses, setRecommendedPasses] = useState([]);
	// Passes in users possession that can be used
	const [availablePasses, setAvailablePasses] = useState([]);

	// Redux state
	const { data: userPasses } = useSelector(({ auth }) => auth.passes);
	const packets = useSelector(({ auth }) => auth.allPackets);
	const { data: productData } = useSelector(({ movieDetails }) => movieDetails);
	const {
		isLoaded,
		isSuccess,
		isPurchaseError,
		purchaseErrorMessage,
		isPassTransaction
	} = useSelector(({ purchase }) => purchase);

	// Fetch available passes for a given product
	const fetchProductsPasses = async () => {
		const url = generatePath(GET_AVAILABLE_PASSES_FOR_PRODUCT, { productId });
		return services.get(url);
	};

	// Fetch available prices for a given product - must have a 'pass' method
	const fetchProductPrices = async () => {
		const url = generatePath(GET_PRODUCT_PRICES_API, { id: productId });
		return services.get(url);
	};

	const handleProductPassesSuccess = (data) => {
		const productPasses = data?.data ?? [];

		const recommended = getRecommendedPasses({
			allProducts: packets,
			productPasses,
			userPasses
		});

		const available = getAvailablePasses({
			allProducts: packets,
			productPasses,
			userPasses
		});

		setRecommendedPasses(recommended);
		setAvailablePasses(available);
	};

	const handleProductPassesError = () => {
		setRecommendedPasses([]);
		setAvailablePasses([]);
	};

	const handleProductPricesSuccess = (data) => {
		const prices = data?.data ?? [];
		setPriceId(getPassPriceId(prices));
	};

	const handleProductPricesError = () => {
		setPriceId(null);
	};

	const { isLoading, refetch } = useQuery({
		queryKey: RECOMMENDED_PASSES_QUERY_KEY.replace(':productId', productId),
		queryFn: fetchProductsPasses,
		onSuccess: handleProductPassesSuccess,
		onError: handleProductPassesError,
		enabled: isAuth && !!priceId
	});

	useQuery({
		queryKey: PRICES_FOR_PASS_QUERY_KEY.replace(':productId', productId),
		queryFn: fetchProductPrices,
		onSuccess: handleProductPricesSuccess,
		onError: handleProductPricesError
	});

	const hasRecommendedPasses =
		!!priceId && !isLoading && !!recommendedPasses?.length;
	const hasAvailablePasses =
		!!priceId && !isLoading && !!availablePasses?.length;
	const productTitle = productData?.title || productData?.metadata?.title;

	const handlePassUse =
		({ pass_uuid }) =>
		async () => {
			const resources = {
				productId,
				priceId,
				paymentMethod: PRODUCTS_TYPES.PASS,
				pass_uuid
			};
			setPurchaseLoading(true);

			try {
				await fetchInitPayment(resources)(dispatch);
				await submitProductPurchase(resources, true)(dispatch);
			} catch {
				setPurchaseLoading(false);
			}
		};

	const refetchUserData = async () => {
		await fetchUserPackets()(dispatch);
		await refetch();
	};

	const notifySuccess = () =>
		openNotification({
			type: 'success',
			title: t('user_profile_vouchers_form_success_title'),
			description: t('pass_use_pass_success_title', { productTitle })
		});

	const notifyError = () =>
		openNotification({
			type: 'error',
			title: t('pass_use_pass_failure_title', { productTitle }),
			description: purchaseErrorMessage
		});

	useEffect(() => {
		if (isLoaded && isSuccess && isPassTransaction) {
			refetchUserData()
				.then(notifySuccess)
				.catch(notifyError)
				.finally(() => {
					clearPurchase(dispatch);
					setPurchaseLoading(false);
					window.scrollTo(0, 0);
				});
		} else if (isLoaded && isPurchaseError && isPassTransaction) {
			notifyError();
			setPurchaseLoading(false);
			clearPurchase(dispatch);
		}
		// eslint-disable-next-line
	}, [isLoaded, isSuccess, isPurchaseError]);

	return (
		<PassesContext.Provider
			value={{
				productId,
				recommendedPasses,
				availablePasses,
				hasAvailablePasses,
				hasRecommendedPasses,
				productData,
				purchaseLoading,
				handlePassUse
			}}
		>
			{children}
		</PassesContext.Provider>
	);
}

PassesProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};

export default PassesProvider;

import i18n from 'i18next';

// Import helpers
import { DEFAULT_COUNTRY_CODE } from 'helpers/countries';

const { PL } = DEFAULT_COUNTRY_CODE;

export const convertFormApiErrors = (error) => {
	const errors = error?.response?.data?.validator?.errors;
	if (errors) {
		return Object.entries(errors).reduce((acc, [key, value]) => {
			acc[key] = value[0];
			return acc;
		}, {});
	}

	return {};
};

export const getDefaultSortedCountries = (countries, defaultCode = PL) => {
	const countryCodes = Object.keys(countries);
	const defaultCountry = countryCodes.find((code) => code === defaultCode);
	const filteredCountries = countryCodes.filter((code) => code !== defaultCode);
	const countriesMapped = filteredCountries.map((countryCode) => ({
		name: i18n.t(`country_name_${countryCode}`),
		value: countryCode
	}));
	const sortedCountries = countriesMapped.sort((a, b) =>
		a.name.localeCompare(b.name)
	);

	return [
		{ name: i18n.t(`country_name_${defaultCountry}`), value: defaultCountry },
		...sortedCountries
	];
};

export const formikReferenceSubmit = (forwardRef) => {
	const formik = forwardRef?.current;

	if (formik) {
		const { state, getFormikComputedProps, handleSubmit } = formik;

		handleSubmit();

		const { isValid } = getFormikComputedProps();

		return { values: state.values, isValid };
	}

	return {
		values: null,
		isValid: false
	};
};

export const handleFormChange =
	({ handleChange, setErrors }) =>
	() => {
		setErrors({});
		handleChange();
	};

export const handleFormSubmit = async ({
	validation,
	setErrors,
	setSubmitting,
	onSubmit,
	values
}) => {
	setSubmitting(true);
	const errors = validation(values) || {};
	const isError = Object.keys(errors).length !== 0;
	setErrors(errors);

	if (isError) {
		setSubmitting(false);
		const errorInput = document.querySelector(`#${Object.keys(errors)[0]}`);

		errorInput && errorInput.focus();

		return;
	}

	if (!isError) {
		await onSubmit(values, { setErrors, setSubmitting });
		setSubmitting(false);
	}
};

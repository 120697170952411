import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Tooltip } from 'antd';

// Import styled components
import { TextareaFieldStyled, TextareaStyled, LabelStyled } from './styles';
import { FormError, InputWrapper } from '../styles';

const TextareaField = ({
	type,
	field,
	name,
	autoComplete,
	form: { touched, errors },
	placeholder,
	tooltipHintText,
	styles,
	...rest
}) => {
	const isErrorVisible = touched[field.name] && errors[field.name];
	const errorText = errors[field.name] || '';

	return (
		<TextareaFieldStyled>
			<Tooltip
				title={tooltipHintText}
				trigger="focus"
				placement="bottomRight"
				overlayClassName="antd-customRegisterTooltip"
			>
				<InputWrapper>
					<LabelStyled
						backgroundColor={styles.label?.background}
						className={field.value && 'filled'}
						htmlFor={field.name}
					>
						{placeholder}
					</LabelStyled>
					<TextareaStyled
						id={field.name}
						aria-required="true"
						aria-invalid={touched[field.name] && errors[field.name]}
						aria-labelledby={`${field.name}Alert`}
						className={`${
							touched[field.name] && errors[field.name] ? 'errors' : ''
						}`}
						type={type}
						name={name}
						autoComplete={autoComplete}
						{...field}
						{...rest}
					/>
				</InputWrapper>
			</Tooltip>
			<FormError isErrorVisible={isErrorVisible} id={`${field.name}Alert`}>
				{errorText}
			</FormError>
		</TextareaFieldStyled>
	);
};

TextareaField.defaultProps = {
	type: 'text',
	name: 'pin',
	autoComplete: 'true',
	styles: {}
};

TextareaField.propTypes = {
	type: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
	form: PropTypes.shape({
		touched: PropTypes.object.isRequired,
		errors: PropTypes.object.isRequired
	}),
	placeholder: PropTypes.string.isRequired,
	autoComplete: PropTypes.string,
	tooltipHintText: PropTypes.string,
	styles: PropTypes.object,
	name: PropTypes.string
};

export default TextareaField;

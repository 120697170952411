import styled from 'styled-components';

export const FormFieldStyled = styled.div`
	margin-bottom: 1.7rem;
	position: relative;
`;

export const FormError = styled.p`
	display: block;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.6)}rem;
	color: ${({ theme }) => theme.red};
	text-align: left;
	position: absolute;
	right: 0;
	top: 4rem;
`;

import React from 'react';
import Slider from 'react-slick';
import { object, string, func, bool } from 'prop-types';

// Import helpers
import { defaultSliderOptions } from 'components/elements/organisms/product_gallery/helpers';
import { ASSET_GROUP_TYPES, SLIDER_ARROW_TYPES } from 'helpers/variables';

// Import component
import GalleryArrow from '../GalleryArrow/GalleryArrow';
import useGalleryContext from 'components/elements/organisms/product_gallery/context/useGalleryContext';
import GalleryFrame from '../GalleryFrame';

const { PREV, NEXT } = SLIDER_ARROW_TYPES;
const { GRAPHIC_2D } = ASSET_GROUP_TYPES;

// A standard slick slider, renders all slides into DOM, either eagerly or on demand
// Slides stay inside DOM on slide change
const GallerySlickSlider = ({
	type,
	afterSlideChange,
	beforeSlideChange,
	settings,
	sliderRef,
	arrowPrevRef,
	arrowNextRef,
	isFullScreen
}) => {
	const { assets, galleryIndex } = useGalleryContext();
	const lazyLoad = type !== GRAPHIC_2D ? 'ondemand' : null;

	return (
		<Slider
			{...defaultSliderOptions}
			{...settings}
			ref={sliderRef}
			lazyLoad={lazyLoad}
			initialSlide={galleryIndex}
			afterChange={afterSlideChange}
			beforeChange={beforeSlideChange}
			nextArrow={<GalleryArrow type={NEXT} ref={arrowNextRef} />}
			prevArrow={<GalleryArrow type={PREV} ref={arrowPrevRef} />}
		>
			{assets.map((asset, index) => (
				<GalleryFrame
					key={asset.id}
					asset={asset}
					index={index}
					isFullScreen={isFullScreen}
				/>
			))}
		</Slider>
	);
};

GallerySlickSlider.defaultProps = {
	type: GRAPHIC_2D,
	afterSlideChange: () => {},
	beforeSlideChange: () => {},
	settings: {}
};

GallerySlickSlider.propTypes = {
	type: string.isRequired,
	afterSlideChange: func,
	beforeSlideChange: func,
	settings: object,
	sliderRef: object,
	arrowPrevRef: object,
	arrowNextRef: object,
	isFullScreen: bool
};

export default GallerySlickSlider;

import styled, { css } from 'styled-components';

// Import utilities
import { customMinResponsive, size, focusBasic } from 'components/styles';

export const Container = styled.div`
	color: ${({ theme }) => theme.body.footer.color};
	font-size: ${({ theme }) => theme.fontSize.footer.media.text}rem;
	line-height: 2.3rem;
	font-weight: ${({ theme }) => theme.font.normal};

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.xs}rem;
		`
	)}
`;

export const StyledLink = styled.a`
	text-decoration: underline;
	color: ${({ theme }) => theme.white};

	&:hover {
		text-decoration: underline;
		color: ${({ theme: { body } }) => body.footer.hover};
	}

	&:focus-visible {
		${focusBasic}
		color: ${({ theme: { body } }) => body.footer.hover};
	}
`;

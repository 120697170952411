import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Import icons
import { ReactComponent as IconArrowLeft } from 'assets/icons/ic_arrow_l.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/ic_arrow_r.svg';

const SliderController = ({ type, onClick, defaultBtn }) => (
	<ButtonController
		defaultBtn={defaultBtn}
		type={type}
		onClick={onClick}
		className={`button button-${type}`}
	>
		{type === 'prev' ? <IconArrowLeft /> : <IconArrowRight />}
	</ButtonController>
);

const ButtonController = styled.button`
	position: absolute;
	top: 0;
	bottom: 6px;
	width: 4rem;
	background-color: ${({ theme }) =>
		theme.slider.arrow.sliderControllerBgColor};
	border: none;
	height: 98%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	${({ defaultBtn }) =>
		!defaultBtn &&
		css`
			&:hover {
				background-color: ${({ theme }) =>
					theme.slider.arrow.sliderControllerBgHoverColor};
			}
		`}

	&:focus {
		outline: none;
	}

	${({ type, defaultBtn }) =>
		type === 'prev'
			? css`
					left: ${defaultBtn ? '-5rem' : 0};
					&:focus {
						outline: none;
					}
			  `
			: css`
					right: ${defaultBtn ? '-3rem' : 0};
					&:focus {
						outline: none;
					}
			  `}
`;

SliderController.propTypes = {
	type: PropTypes.string.isRequired,
	defaultBtn: PropTypes.bool.isRequired,
	onClick: PropTypes.func
};

export default SliderController;

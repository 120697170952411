import React from 'react';
import { func, shape, string, object, bool } from 'prop-types';

// Import styled components
import { Wrapper, Description, ButtonsContainer } from './styles';

// Import components
import Preview from 'components/views/vod/components/sliders/Carousel/components/Info/preview/Preview';
import { Title } from 'components/elements';
import {
	DetailsButton,
	FavouriteButton
} from 'components/views/vod/components/buttons';

// Import helpers
import { getPreviewAssetId } from 'helpers';

// Import utilities
import { useProductTranslations } from 'components/utilities';

const BasicInfo = ({ currentItem, kids, startStopCarousel }) => {
	const { uuid, previews, images, subtype, hide_title } = currentItem;

	const { title, short_desc, context } = useProductTranslations(currentItem);

	const videoID = getPreviewAssetId(previews);

	return (
		<Wrapper>
			<Title
				title={title}
				images={images}
				subtype={subtype}
				hideTitle={hide_title}
				isCarousel={true}
				context={context}
				isParagraph
			/>
			<Description>{short_desc}</Description>
			<ButtonsContainer isPreview={videoID}>
				<DetailsButton {...currentItem} />
				<FavouriteButton kids={kids} uuid={uuid} />
				{videoID && (
					<Preview
						uuid={uuid}
						videoID={videoID}
						startStopCarousel={startStopCarousel}
					/>
				)}
			</ButtonsContainer>
		</Wrapper>
	);
};

BasicInfo.propTypes = {
	startStopCarousel: func.isRequired,
	currentItem: shape({
		uuid: string.isRequired,
		title: string.isRequired,
		short_desc: string.isRequired,
		subtype: string.isRequired,
		previews: object
	}),
	kids: bool.isRequired
};

export default BasicInfo;

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import actions
import { clearPurchase, clearPayment, fetchUserDetails } from 'store/actions';

// Import helpers
import { getTransactionType, PAYMENT_METHOD } from '../helpers';

// Import context actions
import {
	setInvoiceAgreement,
	setInvoiceCorrectness,
	setSelectedPriceId,
	setTransactionType
} from '../store/actions';

const usePayment = ({
	state: { paymentMethods, selectedPriceId },
	stateDispatch,
	initialPriceId
}) => {
	const dispatch = useDispatch();

	const { email_approved } = useSelector(({ auth }) => auth.authenticate);

	const handleFetchUserDetails = async () => {
		await fetchUserDetails()(dispatch);
	};

	const handleSetInvoiceAgreement = () => setInvoiceAgreement()(stateDispatch);
	const handleSetInvoiceCorrectness = (payload) =>
		setInvoiceCorrectness(payload)(stateDispatch);

	const paymentMethod = useMemo(() => {
		const method = paymentMethods.find(({ id }) => id === selectedPriceId);
		return method ?? paymentMethods[0] ?? PAYMENT_METHOD;
		// eslint-disable-next-line
	}, [selectedPriceId, paymentMethods.length]);

	useEffect(() => {
		const type = getTransactionType(paymentMethod);
		setTransactionType(type)(stateDispatch);
		// eslint-disable-next-line
	}, [paymentMethod.id]);

	useEffect(() => {
		// set selected method id
		initialPriceId && setSelectedPriceId(initialPriceId)(stateDispatch);
		// eslint-disable-next-line
	}, [initialPriceId]);

	useEffect(() => {
		window.scroll({ top: 0, behavior: 'smooth' });

		!email_approved && handleFetchUserDetails();

		return () => {
			clearPayment(dispatch);
			clearPurchase(dispatch);
		};
		// eslint-disable-next-line
	}, []);

	return {
		paymentMethod,
		setInvoiceAgreement: handleSetInvoiceAgreement,
		setInvoiceCorrectness: handleSetInvoiceCorrectness
	};
};

export default usePayment;

import React from 'react';
import { useTranslation } from 'react-i18next';

// Import styles
import { List, Item, Language } from './styles';

// Import helpers
import { languagesOptions } from 'helpers/languages';

// Import components
import useLanguage from './useLanguage';

const Languages = () => {
	const { t } = useTranslation();
	const { language, updateLanguage } = useLanguage();

	return (
		<List role="list">
			{languagesOptions.map(({ value, name }) => (
				<Item key={value} onClick={updateLanguage(value)}>
					<Language isSelected={value === language} lang={value}>
						{t(`user_profile_language_${name}`)}
					</Language>
				</Item>
			))}
		</List>
	);
};

export default Languages;

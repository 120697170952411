import styled, { css } from 'styled-components';

// Import utilties
import {
	responsive,
	customMinResponsive,
	customResponsive
} from 'components/styles';

const Row = styled.div`
	width: ${({ width = 117 }) => width}rem;
	height: 100%;
	margin: 0 auto;
	padding: 0 1.5rem;

	${customMinResponsive(
		3000,
		css`
			width: 160rem;
		`
	)}

	${customMinResponsive(
		2000,
		css`
			width: 140rem;
		`
	)}

	${customResponsive(
		1480,
		css`
			width: 110rem;
		`
	)}

	${responsive.large(css`
		width: 90rem;
	`)}

	${responsive.medium(css`
		width: 75rem;
	`)}

	${customResponsive(
		700,
		css`
			width: 45rem;
		`
	)}
	${customResponsive(
		550,
		css`
			width: 90%;
		`
	)}
`;

export default Row;

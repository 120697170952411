import React from 'react';
import { string } from 'prop-types';

const ProperName = ({ text, lang }) => <span lang={lang}>{text}</span>;

ProperName.propTypes = {
	text: string.isRequired,
	lang: string.isRequired
};

export default ProperName;

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import componens
import { SliderSection } from 'components/elements';

const Recommendations = () => {
	const { t } = useTranslation();
	const { data } = useSelector(({ recommendations }) => recommendations);

	if (!data.length) return null;

	return (
		<SliderSection
			showAll={false}
			name={t('vod_recommendations')}
			content={data}
			type="recommendations"
			id="recommendations"
		/>
	);
};

export default Recommendations;

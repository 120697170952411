import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import icons
import { ReactComponent as IconVolumeOffSVG } from 'assets/icons/ic_player_volume_off.svg';
import { ReactComponent as IconVolumeSVG } from 'assets/icons/ic_player_volume_on.svg';
import { ReactComponent as IconFullScreenSVG } from 'assets/icons/ic_player_fullscreen.svg';
import { ReactComponent as IconSettingsSVG } from 'assets/icons/ic_player_settings.svg';
import { ReactComponent as IconPauseSVG } from 'assets/icons/ic_pause.svg';
import { ReactComponent as IconPlaySVG } from 'assets/icons/ic_player_play.svg';
import { ReactComponent as IconSeekPrevSVG } from 'assets/icons/ic_player_minus_15.svg';
import { ReactComponent as IconSeekNextSVG } from 'assets/icons/ic_player_plus_15.svg';
import { ReactComponent as IconPreviousSVG } from 'assets/icons/previous.svg';
import { ReactComponent as IconNextSVG } from 'assets/icons/next.svg';
import { ReactComponent as IconTickSVG } from 'assets/icons/ic_tick.svg';
import { ReactComponent as IconCloseThinSVG } from 'assets/icons/ic_close_thin.svg';
import { ReactComponent as IconLockSVG } from 'assets/icons/ic_lock.svg';
import { ReactComponent as IconExitFullScreenSVG } from 'assets/icons/ic_player_fullscreen_close.svg';
import { ReactComponent as IconPortalSVG } from 'assets/icons/wajda_logo.svg';
import { ReactComponent as IconPlayXlSVG } from 'assets/icons/ic_play_xl.svg';
import { ReactComponent as IconCastSVG } from 'assets/icons/ic_cast.svg';
import { ReactComponent as IconCastConnectedSVG } from 'assets/icons/ic_cast_connected.svg';

// Import styles
import {
	iconController,
	flex,
	customMinResponsive,
	size
} from 'components/styles';

// Import components
import { Heading } from 'components/elements/atoms';

const PLAYER_MODIFIERS = {
	primaryRange: ({ theme }) => css`
		background: ${theme.primaryBlueLight};
	`,
	iconOpacity: () => css`
		opacity: 0.7;
	`,
	volumeSmall: () => css`
		width: 1.5rem;
		height: 1.5rem;
	`,
	receiver: ({ theme }) => css`
		path {
			fill: ${theme.cast.receiver.color};
		}
	`
};

const Volume = styled.div`
	position: relative;
	height: 0.5rem;
	width: 12.9rem;
	display: flex;
	align-items: center;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.5);

	opacity: 0;
	visibility: hidden;
	transition: all 0.2s ease-in-out;

	${({ isMuted }) =>
		isMuted &&
		css`
			background-color: transparent;
		`}
`;

const RangeWrapper = styled.div`
	height: 5rem;
	position: absolute;
	top: -10rem;
	left: -5rem;
	transform: rotate(-90deg);
	width: 12.9rem;
	opacity: 0;
	visibility: hidden;

	&:hover {
		${Volume} {
			opacity: 1;
			visibility: visible;
		}
	}
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	&:hover {
		${Volume} {
			opacity: 1;
			visibility: visible;
		}

		${RangeWrapper} {
			opacity: 1;
			visibility: visible;
		}
	}
`;

const thumbSliderStyles = css`
	appearance: none;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: transparent;
	border: 1px solid transparent;
	cursor: pointer;
`;

const Range = styled.input`
	position: relative;
	z-index: 100;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: transparent;
	cursor: pointer;

	&:focus {
		outline: none;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		${thumbSliderStyles}
	}

	&::-moz-range-thumb {
		-moz-appearance: none;
		${thumbSliderStyles};
	}

	${({ isMuted }) =>
		isMuted &&
		css`
			display: none;
		`}
`;

const RangeValue = styled.span`
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 5px;
	height: 0.5rem;
	width: ${({ value }) => value}%;
	background: ${({ theme }) => theme.primaryBlueLight};
	z-index: 1;

	${({ isMuted }) =>
		isMuted &&
		css`
			display: none;
		`}

	${applyStyleModifiers(PLAYER_MODIFIERS)};
`;

const baseIconStyle = css`
	${iconController}

	&:hover {
		opacity: 1;
	}
	${applyStyleModifiers(PLAYER_MODIFIERS)};
`;

const Profiles = styled.ul`
	list-style: none;
`;

const Profile = styled.li`
	position: relative;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	margin-bottom: 0.6rem;
	transition: all 0.3 ease-in-out;
	cursor: pointer;

	${flex()}

	span {
		color: ${({ theme }) => theme.white};
		opacity: 0.4;

		&:hover {
			opacity: 1;
			color: ${({ theme }) => theme.primaryBlueLight};
		}
	}

	svg {
		visibility: hidden;
		opacity: 0;
		margin-right: 0.5rem;
	}

	${({ profile, selected }) =>
		profile === selected &&
		css`
			span {
				opacity: 1;
				color: ${({ theme }) => theme.primaryBlueLight};
			}

			svg {
				visibility: visible;
				opacity: 1;

				path,
				g {
					polyline {
						stroke: ${({ theme }) => theme.primaryBlueLight};
					}
				}
			}
		`}
`;

// Player Error
const Error = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.8);
	${flex()};
	z-index: 10;

	${({ isVideoBanner, theme }) =>
		isVideoBanner &&
		css`
			${flex({ ai: 'flex-start' })}
			background-color: ${theme.black}
		`}

	${({ isDoubleErrors = false }) =>
		isDoubleErrors &&
		css`
			background-color: rgb(0, 0, 0);
			z-index: 1000;
		`}
`;

const ErrorContent = styled.div`
	max-width: 85.5rem;

	${({ isVideoBanner, theme }) =>
		isVideoBanner &&
		css`
			${customMinResponsive(
				size.medium,
				css`
					padding-top: ${theme.isWCAG ? '10.5rem' : '9.5vw'};
				`
			)}
		`}
`;

const ErrorHeading = styled(Heading.Second)`
	color: #697184;
	font-size: ${({ theme }) => theme.fontSize.s}rem;
	margin: 1rem 0;
	font-weight: ${({ theme }) => theme.font.light};

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			font-size: ${({ theme }) => theme.fontSize.xs}rem;
		`}
`;

// Player Icons
const baseIconControllerStyle = css`
	${iconController}

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			height: 2.2rem;
			width: 2.2rem;
		`}

	&:hover {
		g,
		path {
			opacity: 1;
		}
	}
	${applyStyleModifiers(PLAYER_MODIFIERS)};
`;

const IconVolume = styled(IconVolumeSVG)`
	${baseIconStyle}
`;

const IconVolumeOff = styled(IconVolumeOffSVG)`
	${baseIconStyle}
`;

const IconSettings = styled(IconSettingsSVG)`
	${baseIconStyle}
`;

const IconFullScreen = styled(IconFullScreenSVG)`
	${baseIconStyle}
`;

const IconExitFullScreen = styled(IconExitFullScreenSVG)`
	${baseIconStyle}

	g {
		opacity: 1;
	}
`;

const IconCloseThin = styled(IconCloseThinSVG)`
	${baseIconStyle}
	height: 3rem;
	width: 3rem;
`;

const IconTick = styled(IconTickSVG)`
	${baseIconStyle}
	height: 1.2rem;
	width: 1.2rem;
`;

const IconPlay = styled(IconPlaySVG)`
	${baseIconControllerStyle};
`;

const IconPause = styled(IconPauseSVG)`
	${baseIconControllerStyle}
`;

const IconSeekPrev = styled(IconSeekPrevSVG)`
	${baseIconControllerStyle}
`;

const IconSeekNext = styled(IconSeekNextSVG)`
	${baseIconControllerStyle}
`;

const IconPrevious = styled(IconPreviousSVG)`
	${baseIconControllerStyle}
`;

const IconNext = styled(IconNextSVG)`
	${baseIconControllerStyle}
`;

const IconPortal = styled(IconPortalSVG)`
	height: 3rem;
	width: 15rem;
`;

const IconLock = styled(IconLockSVG)`
	height: 3.6rem;
	width: 3.6rem;
	margin-right: 1rem;
	position: relative;
	top: 4px;
`;

const IconPlayXl = styled(IconPlayXlSVG)`
	${baseIconControllerStyle}
`;

const IconCast = styled(IconCastSVG)`
	${baseIconControllerStyle}
`;

const IconCastConnected = styled(IconCastConnectedSVG)`
	${baseIconControllerStyle}
`;

export {
	Wrapper,
	Range,
	Volume,
	RangeValue,
	IconVolume,
	IconVolumeOff,
	IconSettings,
	IconFullScreen,
	IconCloseThin,
	IconPlay,
	IconPause,
	IconSeekPrev,
	IconSeekNext,
	IconNext,
	IconPrevious,
	IconTick,
	IconLock,
	Profiles,
	Profile,
	Error,
	ErrorContent,
	ErrorHeading,
	IconExitFullScreen,
	IconPortal,
	RangeWrapper,
	IconPlayXl,
	IconCast,
	IconCastConnected
};

import styled, { css } from 'styled-components';

// Import utilties
import { customMinResponsive, size } from 'components/styles';

export const Container = styled.div`
	width: 100%;
	margin-bottom: 5rem;
	${customMinResponsive(
		size.medium,
		css`
			width: ${({ theme }) => (theme.isWCAG ? 'auto' : '34rem')};
			margin-bottom: 0;
		`
	)}
`;

export const Text = styled.p`
	font-size: ${({ theme }) => theme.fontSize.footer.media.text}rem;
	line-height: 2.1rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.body.footer.color};
	margin-bottom: 0 !important;

	${customMinResponsive(
		size.medium,
		css`
			max-width: ${({ theme }) => (theme.isWCAG ? '100%' : '34,3rem')};
			font-size: ${({ theme }) => theme.fontSize.xs}rem;
		`
	)}
`;

import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

// Import helpers
import { getInstitutionPath } from 'helpers';
import { getInstitutionData } from 'helpers/institutions';

// Import paths
import {
	VOD_PATHS,
	INSTITUTIONS_PATHS,
	MAIN_PATHS
} from 'components/routes/paths';

const { INSTITUTION_DETAILS } = INSTITUTIONS_PATHS;
const { CATEGORY_PAGE, SECTION_DETAIL_PAGE } = VOD_PATHS;
const { SEARCH } = MAIN_PATHS;

const useInstitutionLogo = () => {
	const { institutions } = useSelector(({ institutions }) => institutions);

	const match = useRouteMatch([
		INSTITUTION_DETAILS,
		CATEGORY_PAGE,
		SECTION_DETAIL_PAGE,
		SEARCH
	]);

	const uuid = match?.params?.institutionUuid;
	const institution = institutions.find((item) => item.uuid === uuid);
	const { logoUrl, website, name } = getInstitutionData(institution);
	const institutionPath = getInstitutionPath(uuid, name);

	return {
		logoUrl,
		website,
		name,
		uuid,
		institutionPath
	};
};

export default useInstitutionLogo;

import styled, { css } from 'styled-components';

// Import components
import CommonButton from 'components/views/vod/components/buttons/CommonButton';

// Import styles
import { customMinResponsive, flex, size } from 'components/styles';

export const StyledButton = styled(CommonButton)`
	${customMinResponsive(
		size.medium,
		css`
			width: 38.7rem;
		`
	)}
`;

export const Content = styled.div`
	${flex({ fd: 'column' })}
	margin: 0 auto;
	gap: 3.4rem;
`;

export const Propmpt = styled.p`
	color: ${({ theme }) => theme.secondaryGray};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
`;

import _ from 'lodash';

// Import helpers
import { DEVICES_LIMIT_EXCEEDED } from 'helpers/variables';

export const checkUserResources = (user, isLocalStorageData) => {
	// Check if there is a device limit
	let isDeviceLimited = false;
	if (user.status.limits) {
		const findLimits = user.status.limits.find(
			({ name }) => name === DEVICES_LIMIT_EXCEEDED
		);
		if (findLimits) {
			isDeviceLimited = true;
		}
	}

	const missingAgreements = user.status.missingAgreements.map((agreement) => ({
		...agreement,
		value: false
	}));

	// Check if device exists
	let deviceExists = user.status.deviceName ? true : false;
	// Check if user accepts all agreements
	const accepetedAgreements = missingAgreements.length > 0 ? false : true;
	// If there is a device limit, get the message
	const limitMessage = isDeviceLimited ? user.status.limits[0].message : '';
	// If there is device limit or device does not exists or all agreements are not accepted
	const isLogged =
		isDeviceLimited ||
		!deviceExists ||
		!accepetedAgreements ||
		user.need_complete_registration
			? false
			: true;

	// Create user data
	let userData = {
		limitMessage,
		isAuthenticated: true,
		isDeviceLimited,
		isLogged,
		deviceExists,
		accepetedAgreements,
		raiting: null,
		...user
	};

	if (isLocalStorageData) {
		userData = _.omit(userData, ['system', 'raiting']);
	}

	return userData;
};

export const checkUserPackets = (packets, products) =>
	products.reduce((acc, next) => {
		const findPacket = packets.find(({ uuid }) => uuid === next);
		findPacket && acc.push(findPacket);
		return acc;
	}, []);

export const splitPacketsBySubtype = ({ userPackets, subtype }) => {
	const packetsWithGivenSubtype = userPackets?.filter(
		(packet) => packet?.subtype === subtype
	);
	const packetsWithoutGivenSubtype = userPackets?.filter(
		(packet) => packet?.subtype !== subtype
	);
	return { packetsWithGivenSubtype, packetsWithoutGivenSubtype };
};

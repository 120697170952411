import { useEffect, useRef } from 'react';

// Import helpers
import { checkIsActive } from 'components/elements/organisms/slider_section/helpers';
import { handleSlickSliderArrowAriaUpdate } from 'helpers/wcag';

const useSliderAccessibility = ({
	setCurrentSlide,
	activeIndexes,
	slidesCount = 0,
	slidesToShow,
	renderSlideAsLink
}) => {
	const sliderRef = useRef();
	const prevArrowRef = useRef(null);
	const nextArrowRef = useRef(null);
	const slideQuerySelector = renderSlideAsLink ? 'a' : 'button';

	// Update roles accordingly to WCAG requirements
	useEffect(() => {
		const slider = sliderRef.current.innerSlider.list;

		const sliderTrack = slider.querySelector('.slick-track');
		const sliderSlides = sliderTrack.querySelectorAll('.slick-slide');

		sliderTrack.setAttribute('role', 'list');
		sliderSlides.forEach((slide) => slide.setAttribute('role', 'listitem'));
	}, []);

	// Set keyboard and screen reader accessability only to visible slides
	const handleReInit = () => {
		const slider = sliderRef.current.innerSlider.list;
		const sliderSlides = slider.querySelectorAll('.slick-slide');

		[...sliderSlides].forEach((slide, index) => {
			const isActive = checkIsActive({ activeIndexes, index });
			if (isActive) {
				slide.classList.add('slick-active');
				slide.setAttribute('aria-hidden', false);
			} else {
				slide.classList.remove('slick-active');
				slide.setAttribute('aria-hidden', true);
			}
		});

		handleSlickSliderArrowAriaUpdate({
			prev: prevArrowRef.current,
			next: nextArrowRef.current
		});
	};

	// Set focus to slide element after slides change
	const handleAfterChange = (current) => {
		const slider = sliderRef.current.innerSlider.list;
		const sliderSlides = slider.querySelectorAll('.slick-slide');
		const firstSliderItem = sliderSlides[0].querySelector(slideQuerySelector);
		const lastSliderItem =
			sliderSlides[sliderSlides.length - 1].querySelector(slideQuerySelector);
		const isLastSlide = current + slidesToShow >= slidesCount;
		const isFirstSlide = current === 0;

		// Focus on first element if begin of slider
		isFirstSlide && firstSliderItem.focus();

		// Focus on last element if end of slider
		isLastSlide && lastSliderItem.focus();

		// Update current slide for activeIndexes calculation
		setCurrentSlide(current);
	};

	return {
		sliderRef,
		prevArrowRef,
		nextArrowRef,
		handleReInit,
		handleAfterChange
	};
};

export default useSliderAccessibility;

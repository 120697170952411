import React from 'react';
import { string } from 'prop-types';

// Import components
import { SectionItem, EpisodeItem } from './components';

// Import variables
import { SLIDER_SLIDE_TYPES } from 'helpers/variables';

const { EPISODE } = SLIDER_SLIDE_TYPES;

const SliderItem = ({ slideType, ...restProps }) => {
	switch (slideType) {
		case EPISODE:
			return <EpisodeItem {...restProps} />;

		default:
			return <SectionItem {...restProps} />;
	}
};

SliderItem.propTypes = {
	slideType: string
};

export default SliderItem;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
	enableParentalControl,
	fetchPlayerConfig,
	fetchFavourites
} from 'store/actions';

// Import helpres
import { PLAYER_SELECTORS } from 'helpers/variables';

// Import validation
import { disabledValidation } from 'components/elements/organisms/modal/modal_form/parental_control_modal/parental_control_fields/validation';

// Import utilities
import { ToggleRPC } from 'components/utilities';

// Import components
import { Button, ParentalControlModal } from 'components/elements';

const { DETAILS, VOD } = PLAYER_SELECTORS;

const ParentalControlButton = ({
	enableParentalControl,
	fetchPlayerConfig,
	channelID,
	programDetailsChannelID,
	movieDetails,
	playerType,
	fetchFavourites
}) => {
	// Get translation
	const { t } = useTranslation();
	const { type } = useParams();

	const isProgramDetailsPlayer = playerType === DETAILS;

	const getPlayerConfiguration = async () => {
		if (type && movieDetails && movieDetails.uuid) {
			await fetchPlayerConfig({
				productID: movieDetails.uuid,
				type: VOD,
				videoType: 'vod'
			});
		} else {
			// Fetch new video session config for enabled channel
			// Dispatch an fetchPlayerConfig action in player folder
			await fetchPlayerConfig({
				productID: isProgramDetailsPlayer ? programDetailsChannelID : channelID,
				type: playerType
			});
		}
	};

	const handleEnableParentalControl = async (values) => {
		await enableParentalControl({
			...values,
			configCallback: getPlayerConfiguration
		});
		// fetch favourites products
		await fetchFavourites();
	};

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					<Button onClick={toggle} modifiers="default">
						{t('parental_control_btn_enabled')}
					</Button>
					<ParentalControlModal
						toggle={toggle}
						title={t('parental_control_title')}
						isEnabled={true}
						initialValues={{ pin: '' }}
						validation={disabledValidation}
						on={on}
						submitAction={handleEnableParentalControl}
					/>
				</>
			)}
		</ToggleRPC>
	);
};

ParentalControlButton.propTypes = {
	enableParentalControl: PropTypes.func.isRequired,
	fetchPlayerConfig: PropTypes.func.isRequired,
	movieDetails: PropTypes.object,
	channelID: PropTypes.oneOfType([PropTypes.string]),
	programDetailsChannelID: PropTypes.string,
	playerType: PropTypes.string.isRequired,
	fetchFavourites: PropTypes.func.isRequired
};

const mapStatetoProps = ({
	selected_program: { channelID },
	movieDetails: { data },
	program_details
}) => ({
	channelID,
	movieDetails: data,
	programDetailsChannelID: program_details.data?.channel_uuid
});

export default connect(mapStatetoProps, {
	enableParentalControl,
	fetchPlayerConfig,
	fetchFavourites
})(ParentalControlButton);

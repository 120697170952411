import { useState, useRef, useEffect } from 'react';

const useButtonAnnouncement = () => {
	const [announcement, setAnnouncement] = useState('');
	const timeoutRef = useRef(null);

	const timeoutId = timeoutRef?.current;

	const clearAnnouncement = () => {
		timeoutRef.current = setTimeout(() => setAnnouncement(''), 2000);
	};

	useEffect(() => {
		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line
	}, []);

	return { setAnnouncement, announcement, clearAnnouncement };
};

export default useButtonAnnouncement;

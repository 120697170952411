import { useState } from 'react';
import { arrayOf, oneOfType, node, number } from 'prop-types';

// Import utilities
import { useTimeout } from 'components/utilities';

const DelayRender = ({ children, delay = 1000 }) => {
	const [isShown, setIsShown] = useState(false);

	useTimeout(() => setIsShown(true), delay);

	return isShown ? children : null;
};

DelayRender.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	delay: number
};

export default DelayRender;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { VisuallyHidden } from '@reach/visually-hidden';
import {
	oneOfType,
	node,
	arrayOf,
	string,
	func,
	bool,
	number
} from 'prop-types';

// Import utilities
import { useListBoxSelect } from './useListBoxSelect';

// Import styles
import {
	ListBoxContainer,
	ListBoxWrapper,
	ListBoxButton,
	ListBoxMenu,
	ListBoxError,
	ListBoxLabel
} from './styles';

const ListBoxSelect = ({
	children,
	currentValue,
	listId,
	changeHandler,
	title,
	selectButtonContent = '',
	errorMessage,
	modifiers,
	isLabelVisible = false,
	isFormField = false,
	forceButtonFocus = false,
	isDescription = false,
	labelledBy = null
}) => {
	const { t } = useTranslation();

	const {
		listBoxWrapperRef,
		listBoxButtonRef,
		listBoxRef,
		isOpen,
		toggleOpen,
		handleMouseOptionSelect,
		handleKeyOptionEvents,
		handleButtonKeyPress
	} = useListBoxSelect({
		changeHandler,
		errorMessage
	});

	const renderButtonContent = () => {
		return (
			<>
				{isLabelVisible ? (
					<>
						<ListBoxLabel
							id={`listbox-${listId}-label`}
							isOpen={isOpen}
							currentValue={currentValue}
						>
							{t(title)}
						</ListBoxLabel>
						{currentValue}
					</>
				) : (
					<>{selectButtonContent || currentValue}</>
				)}
			</>
		);
	};

	const defaultLabels = `listbox-${listId}-label listbox-${listId}-selected listbox-${listId}-error`;

	const ariaLabelledBy = labelledBy
		? `${labelledBy} ${defaultLabels}`
		: defaultLabels;

	return (
		<ListBoxContainer modifiers={modifiers}>
			{!isLabelVisible && (
				<VisuallyHidden id={`listbox-${listId}-label`}>
					{t(title)}
				</VisuallyHidden>
			)}
			<ListBoxWrapper ref={listBoxWrapperRef} modifiers={modifiers}>
				<ListBoxButton
					ref={listBoxButtonRef}
					aria-controls={`listbox-${listId}`}
					aria-expanded={isOpen}
					aria-haspopup="listbox"
					aria-labelledby={ariaLabelledBy}
					aria-describedby={isDescription ? `${listId}_info` : null}
					onMouseUp={toggleOpen}
					onKeyDown={handleButtonKeyPress}
					type={isFormField ? 'button' : null}
					modifiers={modifiers}
					autoFocus={forceButtonFocus}
					isError={!!errorMessage}
				>
					{renderButtonContent()}
				</ListBoxButton>
				<ListBoxMenu
					id={`listbox-${listId}`}
					ref={listBoxRef}
					role="listbox"
					aria-labelledby={labelledBy || `listbox-${listId}-label`}
					isOpen={isOpen}
				>
					<>{children({ handleMouseOptionSelect, handleKeyOptionEvents })}</>
				</ListBoxMenu>
			</ListBoxWrapper>
			{errorMessage && (
				<ListBoxError id={`listbox-${listId}-error`}>
					{errorMessage}
				</ListBoxError>
			)}
		</ListBoxContainer>
	);
};

ListBoxSelect.propTypes = {
	children: oneOfType([node, arrayOf(node), func]),
	currentValue: oneOfType([string, number]),
	listId: string.isRequired,
	changeHandler: func.isRequired,
	title: string,
	selectButtonContent: oneOfType([string, node]),
	errorMessage: string,
	modifiers: string,
	isLabelVisible: bool,
	isFormField: bool,
	forceButtonFocus: bool,
	isDescription: bool,
	labelledBy: string
};
export default ListBoxSelect;

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// Import helpers
import { setParametr } from 'helpers';
import { getSearchParams } from 'helpers/movieLists';

// Import variables
import { VOD_PARAMS } from 'helpers/variables';

// Import select options
import { GENRES_SELECT_OPTIONS } from './select_options';

function useGenresSelect() {
	const { data: genresList } = useSelector((store) => store.genresList);

	const history = useHistory();
	const location = useLocation();

	const genresSelectOptions = [...GENRES_SELECT_OPTIONS, ...genresList];

	// Chnge query param genre on categories page
	const handleRedirectUrl = (name) => {
		const item = genresList.find(({ name: itemName }) => itemName === name);
		const genreSlug = item && item.slug ? item.slug : 'all';

		const genreParam = setParametr(VOD_PARAMS.genre, genreSlug);

		const {
			paramsUrl: { order, orderDir, type }
		} = getSearchParams(location.search);

		history.push(`?${genreParam}${type}${order}${orderDir}`);
	};

	const handleSelectChange = (name) => handleRedirectUrl(name);

	return {
		genresList: genresSelectOptions,
		selectChange: handleSelectChange
	};
}

export default useGenresSelect;

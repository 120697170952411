import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { HeaderIcon } from 'components/elements';

// Import helpers
import { WATERMARK_URL } from 'helpers/variables';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { HOME_PAGE } = APP_CORE_PATHS;

const PortalLogo = () => {
	const { t } = useTranslation();

	return (
		<HeaderIcon
			to={HOME_PAGE}
			ariaLabel={t('aria_header_logo_link')}
			src={WATERMARK_URL}
		/>
	);
};

export default PortalLogo;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Popover as AntdPopover } from 'antd';
import { useSelector } from 'react-redux';

// Import helpers
import { getImgUrl } from 'helpers/index';

// Import utilities
import { ImageOnLoadRPC } from 'components/utilities';

// Import components
import PopoverContent from './PopoverContent';
import PopoverToolbox from './toolbox/PopoverToolbox';
import NoImage from 'components/views/vod/images/placeholder.svg';
import { Heading } from 'components/elements';

// Import assets
import PortalLogo from 'assets/icons/nexgentv.svg';

const popoverSettings = {
	placement: 'bottomLeft',
	trigger: 'hover',
	overlayClassName: 'epg-popover'
};

const Popover = ({
	linkDetails,
	children,
	program,
	dragged = false,
	isUpcoming = false,
	isNotEnded = false,
	noPrograms,
	isCatchup,
	startDate = '',
	isLive = true,
	isContentBlocked,
	placeholderRatingText
}) => {
	const reminders = useSelector((store) => store.reminders.data);
	// If there is not any program, return null
	if (!program) return null;
	// Get program's images and screenshots
	const { images, screenshots, uuid: programId } = program;

	// Check image url, check if img's url exists
	const { imgUrl, isImgUrlValid } = getImgUrl({
		screenshots,
		images,
		isLive
	});

	// gets reminder object for its program
	const reminderForProgram = reminders.find(
		({ product_uuid }) => product_uuid === program.uuid
	);

	const reminderId = reminderForProgram ? reminderForProgram.id : false;

	const content = (
		<PopoverWrapper dragged={dragged}>
			<Container>
				{isContentBlocked ? (
					<PlaceholderContainer>
						<PlaceholderText>{placeholderRatingText}</PlaceholderText>
					</PlaceholderContainer>
				) : (
					<PopoverContent isCatchup={isCatchup} {...program} />
				)}
				<PopoverToolbox
					reminderId={reminderId}
					linkDetails={linkDetails}
					programId={programId}
					isUpcoming={isUpcoming}
					isCatchup={isCatchup}
					isNotEnded={isNotEnded}
					startDate={startDate}
				/>
			</Container>
			{isImgUrlValid && (
				<ImageOnLoadRPC>
					{({ setError, setLoaded, isLoaded }) => (
						<Preview>
							<Image
								isLoaded={isLoaded}
								onError={setError}
								src={isContentBlocked ? NoImage : imgUrl}
								onLoad={setLoaded}
								alt="Preview"
							/>
						</Preview>
					)}
				</ImageOnLoadRPC>
			)}
		</PopoverWrapper>
	);

	return !noPrograms ? (
		<AntdPopover content={content} {...popoverSettings}>
			{children}
		</AntdPopover>
	) : (
		children
	);
};

const PopoverWrapper = styled.div`
	height: 26rem;
	display: flex;
	color: ${({ theme }) => theme.graySemiLight};
	overflow: hidden;
	background-color: ${({ theme: { popover } }) => popover.bg};

	${({ dragged }) =>
		dragged &&
		css`
			display: none;
		`};
`;

const Container = styled.div`
	width: 28rem;
	display: flex;
	flex-direction: column;
`;

const PlaceholderContainer = styled.div`
	flex: 1;
	padding: 1.5rem;
`;

const PlaceholderText = styled(Heading.Third)`
	color: ${({ theme }) => theme.graySemiLight};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.medium};
	margin-bottom: 0.4rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const Preview = styled.div`
	width: 26rem;
	background: url(${PortalLogo}) 50% /80% auto ${({ theme }) =>
			theme.primaryDark} no-repeat;
`;
const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
	display: ${({ isLoaded }) => (isLoaded ? 'block' : 'none')};
`;

Popover.propTypes = {
	program: PropTypes.object.isRequired,
	dragged: PropTypes.bool,
	isUpcoming: PropTypes.bool,
	isCatchup: PropTypes.bool,
	isNotEnded: PropTypes.bool,
	linkDetails: PropTypes.shape({
		pathname: PropTypes.string,
		state: PropTypes.shape({ sCatchup: PropTypes.bool }),
		hash: PropTypes.string
	}),
	startDate: PropTypes.string,
	isContentBlocked: PropTypes.bool.isRequired,
	placeholderRatingText: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	isLive: PropTypes.bool,
	noPrograms: PropTypes.bool
};

export default Popover;

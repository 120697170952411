import React from 'react';
import { string, number, func, object } from 'prop-types';

// Import components
import { AuthModal } from 'components/elements';
import CommonButton from '../CommonButton';

// Import styles
import { PlayIcon } from '../styles';

// Import logic
import usePlayButton from './usePlayButton';

// Import context
import { useProductPreviewContext } from 'components/context/productPreview';

const PlayButton = ({ width, redirect, ...restProps }) => {
	const { onPreviewHOF, isPreview } = useProductPreviewContext();
	const {
		linkRedirect,
		buttonText,
		isAvailableToPlay,
		opacity,
		renderAs,
		showPlayIcon
	} = usePlayButton(restProps);

	if (isPreview) {
		return (
			<CommonButton
				isPrime={isAvailableToPlay}
				width={width}
				onClick={onPreviewHOF(redirect)}
				style={{ opacity }}
			>
				{showPlayIcon && <PlayIcon aria-hidden role="img" />}
				{buttonText}
			</CommonButton>
		);
	}

	return (
		<AuthModal>
			<CommonButton
				isPrime={isAvailableToPlay}
				as={renderAs}
				onClick={onPreviewHOF(redirect)}
				width={width}
				to={linkRedirect}
				isDisabled={!isAvailableToPlay}
				style={{ opacity }}
			>
				{showPlayIcon && <PlayIcon aria-hidden role="img" />}
				{buttonText}
			</CommonButton>
		</AuthModal>
	);
};

PlayButton.defaultProps = {
	width: 144,
	subtype: '',
	uuid: '',
	redirect: () => {},
	product: {}
};

PlayButton.propTypes = {
	uuid: string,
	width: number,
	subtype: string,
	redirect: func,
	product: object.isRequired
};

export default PlayButton;

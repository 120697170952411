import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import VisuallyHidden from '@reach/visually-hidden';

// Import utilities
import { DrawerRPC, ToggleRPC } from 'components/utilities';

// Import icons
import { ReactComponent as RemindersIcon } from 'assets/icons/ic_nav_reminder.svg';

// Import components
import { Button, Loader, AuthModal } from 'components/elements';
import Reminder from './Reminder';

// Import logic
import useReminders from './useReminders';

const Reminders = () => {
	const { t } = useTranslation();
	const navButtonRef = useRef(null);
	const headingRef = useRef(null);
	const [deletedProductTitle, setDeletedProductTitle] = useState('');
	const { isLoaded, sortedReminderProducts } = useReminders();

	const navItemTitle = t('common_reminders_title');

	const renderReminders = (toggle) =>
		sortedReminderProducts.map((reminder) => (
			<Reminder
				key={reminder.id}
				toggle={toggle}
				reminder={reminder}
				setDeletedReminderTitle={setDeletedProductTitle}
				headingRef={headingRef}
			/>
		));

	useEffect(() => {
		setDeletedProductTitle('');
	}, [sortedReminderProducts]);

	const isAnnouncementAvailable = deletedProductTitle && isLoaded;

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					<AuthModal>
						<Tooltip placement="bottom" onClick={toggle} title={navItemTitle}>
							<Button
								modifiers={['navIcon']}
								spaceLeft="1rem"
								aria-label={navItemTitle}
								ref={navButtonRef}
							>
								<RemindersIcon aria-hidden="true" title={navItemTitle} />
							</Button>
						</Tooltip>
					</AuthModal>

					<DrawerRPC
						onClose={toggle}
						title={navItemTitle}
						isOpen={on}
						returnFocusRef={navButtonRef}
						headingRef={headingRef}
					>
						{isLoaded ? (
							<>
								{/* eslint-disable-next-line  */}
								<ul role="list">{renderReminders(toggle)}</ul>
							</>
						) : (
							<Loader
								zIndex={900}
								position="relative"
								background={false}
								width={8}
								height={8}
								top="2rem"
							/>
						)}
						{isAnnouncementAvailable && (
							<VisuallyHidden role="alert">
								{t('common_header_nav_reminders_product_delete', {
									deletedProductTitle
								})}
							</VisuallyHidden>
						)}
					</DrawerRPC>
				</>
			)}
		</ToggleRPC>
	);
};

export default Reminders;

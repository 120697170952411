import React from 'react';
import { useTranslation } from 'react-i18next';
import { string, object } from 'prop-types';
import VisuallyHidden from '@reach/visually-hidden';

// Import components
import { Loader } from 'components/elements';
import SearchItem from 'components/layout/nav/nav_search/search_box/SearchItem/SearchItem';
import SearchItemChannel from 'components/layout/nav/nav_search/search_box/SearchItemChennel/SearchItemChannel';

// Import styles
import {
	ComboboxPopoverStyled,
	LinkStyled,
	EmptySearchResults,
	ListStyled,
	OptionStyled
} from './styles';

// Import helpers
import { getNumberOfItems } from 'components/layout/nav/nav_search/search_box/helpers';

// Import utilities
import { useSearchResults } from './useSearchResults';

const SearchResults = ({ searchTerm, searchResultsRef, listId }) => {
	const { t } = useTranslation();

	const {
		isLoaded,
		isEmptySearchResults,
		listRef,
		viewportHeight,
		data,
		channels,
		resultsInformation,
		showAllSearchResults,
		handleKeyOptionEvents
	} = useSearchResults();

	return (
		<ComboboxPopoverStyled
			portal={false}
			isLoaded={isLoaded}
			ref={searchResultsRef}
		>
			{!isLoaded ? (
				<Loader
					width={10}
					height={10}
					zIndex={900}
					position="absolute"
					background={false}
				/>
			) : (
				<>
					{isEmptySearchResults && (
						<EmptySearchResults>
							{t('common_no_search_results')}
						</EmptySearchResults>
					)}

					{!isEmptySearchResults && (
						<ListStyled
							role="listbox"
							id={`listbox${listId}`}
							ref={listRef}
							aria-label={t('aria_nav_search_list', { searchTerm })}
						>
							<OptionStyled
								key="common_search_see_all"
								role="option"
								onKeyDown={handleKeyOptionEvents}
							>
								{showAllSearchResults && (
									<LinkStyled to={`/szukaj/${searchTerm}`}>
										{t('common_search_see_all')}
									</LinkStyled>
								)}
							</OptionStyled>

							{data.slice(0, getNumberOfItems(viewportHeight)).map((item) =>
								item.type === 'channel' ? (
									<OptionStyled
										key={item.uuid}
										role="option"
										onKeyDown={handleKeyOptionEvents}
									>
										<SearchItemChannel product={item} channels={channels} />
									</OptionStyled>
								) : (
									<OptionStyled
										key={item.uuid}
										role="option"
										onKeyDown={handleKeyOptionEvents}
									>
										<SearchItem product={item} />
									</OptionStyled>
								)
							)}
						</ListStyled>
					)}

					<VisuallyHidden role="alert">{resultsInformation}</VisuallyHidden>
				</>
			)}
		</ComboboxPopoverStyled>
	);
};

SearchResults.propTypes = {
	searchTerm: string.isRequired,
	searchResultsRef: object.isRequired,
	listId: string.isRequired
};

export default SearchResults;

import { useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import services from 'services/services';

// Import helpers
import { getTransactionStatusInfo } from './helpers';

import {
	GET_PRODUCT_PAYMENT_STATUS_API,
	QUERY_PRODUCT_PAYMENT_STATUS
} from 'helpers/api';

// Import components
import { PaymentVerificationModal } from 'components/modals';

function usePaymentVerificationButton({ product }) {
	const { uuid } = product;
	const modal = useModal(PaymentVerificationModal);
	const [isInitiated, setIsInitiated] = useState(false); // Has user manually tried to check
	const [transactionStatus, setTransactionStatus] = useState(null);

	const queryKey = `${QUERY_PRODUCT_PAYMENT_STATUS}_${uuid}`;

	const handleFetchData = async () => {
		const url = generatePath(GET_PRODUCT_PAYMENT_STATUS_API, { id: uuid });
		return services.get(url);
	};

	const handleCheckPaymentStatusSuccess = ({ data }) => {
		const status = data?.status;
		if (isInitiated) {
			modal.show({
				onClose,
				showLoader: false,
				prompt: getTransactionStatusInfo(status)
			});
		}
		setTransactionStatus(status);
	};

	const { isLoading, refetch } = useQuery({
		queryKey,
		refetchOnWindowFocus: false,
		queryFn: handleFetchData,
		onSuccess: handleCheckPaymentStatusSuccess,
		onError: onClose
	});

	const prompt = getTransactionStatusInfo(transactionStatus);
	const showLoader = !transactionStatus || isLoading;

	function onClose() {
		modal.remove();
		setTransactionStatus(null);
	}

	const handleVerificationButtonPress = () => {
		setIsInitiated(true);
		modal.show({ showLoader, prompt, onClose });
		refetch();
	};

	return {
		handleVerificationButtonPress
	};
}

export default usePaymentVerificationButton;

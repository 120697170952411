import * as types from 'store/actions/types';

export const toggleAppContrast = () => (dispatch) =>
	dispatch({
		type: types.TOGGLE_APP_CONTRAST
	});

export const toggleAnimationsAvailability = () => (dispatch) =>
	dispatch({
		type: types.TOGGLE_ANIMATIONS_AVAILABILITY
	});

export const setFontSize = (fontSize) => (dispatch) =>
	dispatch({
		type: types.SET_FONT_SIZE,
		payload: fontSize
	});

export const resetToDefaultSettings = () => (dispatch) =>
	dispatch({
		type: types.RESET_TO_DEFAULT_SETTINGS
	});

import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size, transitionMain } from 'components/styles';

export const ArrowController = styled.button`
	position: absolute;
	${({ theme, slideHeight }) =>
		theme.isWCAG && slideHeight ? 'top: 0' : 'bottom: 0'};
	${({ theme, slideHeight }) =>
		theme.isWCAG && slideHeight && 'transform: translateX(-50%)'};
	z-index: 10;
	height: 100%;
	width: max-content;
	display: flex !important;

	&:hover,
	&:focus {
		cursor: pointer;

		.slider-arrow {
			path {
				fill: ${({ theme }) => theme.primaryBlueLight};
			}
		}

		.slider-arrow-contrast {
			circle:nth-child(1) {
				fill: ${({ theme }) => theme.primaryBlueLight};
				fill-opacity: 1;
			}
			path {
				stroke: ${({ theme }) => theme.black};
			}
		}
	}

	&:focus-visible {
		circle:nth-child(2) {
			stroke: ${({ theme }) => theme.primaryBlueLight};
			stroke-width: 0.3rem;
			stroke-dasharray: 6, 6;
			stroke-linejoin: round;
			stroke-dashoffset: 1.2rem;
			${({ theme }) => theme.isContrast && 'display: none'}
		}

		circle:nth-child(3) {
			stroke: ${({ theme }) => theme.black};
			stroke-width: 0.3rem;
			stroke-dasharray: 6, 6;
			stroke-linejoin: round;
			stroke-dashoffset: 1.2rem;
			${({ theme }) => !theme.isContrast && 'display: none'}
		}
	}

	&.slick-disabled,
	&::before {
		display: none !important;
	}

	.slider-arrow {
		display: block;
		height: ${({ slideHeight }) => (slideHeight ? `${slideHeight}px` : '100%')};
		width: 40px;

		path {
			${transitionMain}
		}
	}

	.slider-arrow-contrast {
		display: block;
		height: ${({ slideHeight }) => (slideHeight ? `${slideHeight}px` : '100%')};
		width: 45px;

		${({ compact }) =>
			customMinResponsive(
				compact ? size.large : size.medium,
				css`
					width: 90px;
				`
			)};

		circle,
		path {
			${transitionMain}
		}
	}

	${({ isPrevArrow, theme }) =>
		isPrevArrow
			? css`
					left: 0;
					.slider-arrow-contrast,
					.slider-arrow {
						margin-left: ${theme.isWCAG ? 0 : 1.5}rem;

						${customMinResponsive(
							size.medium,
							css`
								margin-left: ${theme.isWCAG ? 0 : 3}rem;
							`
						)};
					}
			  `
			: css`
					right: 0;
					.slider-arrow-contrast,
					.slider-arrow {
						margin-right: ${theme.isWCAG ? 0 : 1.5}rem;

						${customMinResponsive(
							size.medium,
							css`
								margin-right: ${theme.isWCAG ? 0 : 3}rem;
							`
						)};
					}
			  `};
`;

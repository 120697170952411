import React from 'react';
import { func, bool, string } from 'prop-types';

// Import components
import { Button } from 'components/elements';

const PaymentOptionButton = ({
	onClick = () => {},
	isDisabled = false,
	buttonText = '',
	describedBy
}) => (
	<Button
		aria-describedby={describedBy}
		modifiers={['default', 'payment']}
		onClick={onClick}
		disabled={isDisabled}
	>
		{buttonText}
	</Button>
);

PaymentOptionButton.propTypes = {
	onClick: func.isRequired,
	isDisabled: bool,
	buttonText: string.isRequired,
	describedBy: string.isRequired
};

export default PaymentOptionButton;

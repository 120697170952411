import { find, isNumber } from 'lodash';

// Import helpers
import { PRODUCTS_TYPES } from 'helpers/variables';
import moment from 'moment';

export const defaultContext = {
	recommendedPasses: [],
	availablePasses: [],
	hasAvailablePasses: false,
	hasRecommendedPasses: false
};

// Passes in user's possession
export const getAvailablePasses = ({
	allProducts,
	userPasses,
	productPasses
}) => {
	const userPassesIds = userPasses?.map(({ uuid }) => uuid);
	const products = productPasses?.filter(({ uuid }) =>
		userPassesIds.includes(uuid)
	);
	return products
		?.map(mapProductsToPasses({ allProducts }))
		.filter(hasProperLimits)
		.filter(isAvailable);
};

// Passes to purchase
export const getRecommendedPasses = ({
	allProducts,
	userPasses,
	productPasses
}) => {
	const userPassesIds = userPasses?.map(({ uuid }) => uuid);
	const products = productPasses?.filter(
		({ uuid }) => !userPassesIds.includes(uuid)
	);

	return products
		.map(mapProductsToPasses({ allProducts }))
		.filter(hasPaymentMethods)
		.filter(isAvailable);
};

const mapProductsToPasses =
	({ allProducts }) =>
	(product) => {
		const item = find(allProducts, ({ uuid }) => uuid === product?.uuid);
		return {
			...product,
			prices: item?.prices,
			prices_by_payment_methods: item?.prices_by_payment_methods,
			context: item?.context
		};
	};

// Pass without usage limits is invalid, possible infinite usses
const hasProperLimits = ({ usage_limit, user_usage_limit }) => {
	if (!isNumber(usage_limit) || !isNumber(user_usage_limit)) return false;
	return user_usage_limit < usage_limit;
};

// Recommended pass must have a price, user must be able to purchase
const hasPaymentMethods = ({ prices, prices_by_payment_methods }) => {
	if (!prices || !prices_by_payment_methods) return false;
	return Object.entries(prices_by_payment_methods)?.some(
		(entry) => !!entry?.[1]
	);
};

// Pass must be still available
const isAvailable = ({ context }) => {
	if (!context?.till || !context?.since) return false;
	const { till, since } = context;
	return moment().isBetween(moment(since), moment(till));
};

// Helpers
export const getPassPriceId = (prices) => {
	const paymentMethod = find(
		prices,
		({ payment_method }) => payment_method === PRODUCTS_TYPES.PASS
	);
	return paymentMethod?.id;
};

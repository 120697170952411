import { useRef, useEffect } from 'react';

const useMobileMenu = ({ isOpen }) => {
	const mobileMenuRef = useRef();

	useEffect(() => {
		// Improve Antd elements role attributes - related to WCAG requirements
		if (isOpen && mobileMenuRef?.current) {
			const lists = mobileMenuRef.current.querySelectorAll('.ant-menu');
			const subLists =
				mobileMenuRef.current.querySelectorAll('.ant-menu-submenu');
			const items = mobileMenuRef.current.querySelectorAll('.ant-menu-item');
			const titles = mobileMenuRef.current.querySelectorAll(
				'.ant-menu-submenu-title'
			);

			// Remove redundant roles
			[...lists, ...subLists, ...items].forEach((element) =>
				element.removeAttribute('role')
			);

			// Add required role
			[...titles].forEach((element) => element.setAttribute('role', 'button'));
		}
	}, [isOpen]);

	return { mobileMenuRef };
};

export default useMobileMenu;

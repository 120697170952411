import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { focusBasic, flex, customMinResponsive, size } from 'components/styles';

const labelTransformBaseStyle = css`
	transform: scale(0.8);
	top: -0.8rem;
	left: 1rem;
	color: ${({ theme }) => theme.listBox.label.colorActive};
	padding: 0 0.8rem;
`;

const LIST_BOX_CONTAINER_MODIFIERS = {
	contact_form: () => css`
		margin-bottom: 3rem;
	`,
	payment_select: ({ theme }) => css`
		width: 100%;

		${customMinResponsive(
			size.medium,
			css`
				width: ${theme.isWCAG ? '70%' : '34rem'};
			`
		)}
	`,
	payment_promotion: ({ theme }) => css`
		width: 100%;

		${customMinResponsive(
			size.medium,
			css`
				width: ${theme.isWCAG ? '70%' : '34rem'};
			`
		)}
	`
};

const LIST_BOX_WRAPPER_MODIFIERS = {
	contact_form: () => css`
		margin: 0;
	`
};

const LIST_BOX_BUTTON_MODIFIERS = {
	contact_form: () => css`
		min-height: 4.4rem;
		height: auto;
	`
};

const LIST_BOX_OPTION_MODIFIERS = {
	payment_select: () => css`
		&:not(:last-child) {
			border-bottom: none;
		}
	`
};

export const ListBoxContainer = styled.div`
	${applyStyleModifiers(LIST_BOX_CONTAINER_MODIFIERS)};
`;

export const ListBoxWrapper = styled.div`
	display: block;
	position: relative;
	margin: 0 1.8rem;

	${customMinResponsive(
		size.medium,
		css`
			margin: 0;
		`
	)};

	${applyStyleModifiers(LIST_BOX_WRAPPER_MODIFIERS)};
`;

export const ListBoxButton = styled.button`
	${flex({ jc: 'flex-start' })}
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1.1rem 4rem 1.1rem 2rem;
	color: ${({ theme }) => theme.listBox.button.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	text-align: left;
	background-color: ${({ theme }) => theme.listBox.button.bg};
	border: 1px solid ${({ theme }) => theme.listBox.button.border};
	border-radius: 0.4rem;
	outline: none;
	cursor: pointer;

	${({ theme }) =>
		theme.isWCAG &&
		css`
			min-height: 8rem;
			height: fit-content !important;
		`}

	&:focus-visible {
		${focusBasic}
		border-color: ${({ theme }) => theme.listBox.button.borderHover};
	}

	&:hover {
		border-color: ${({ theme }) => theme.listBox.button.borderHover};
	}

	${({ isError }) =>
		isError &&
		css`
			border-color: ${({ theme }) => theme.listBox.error.border} !important;
		`}

	&::after {
		border-bottom: 0.2rem solid ${({ theme }) => theme.listBox.button.color};
		border-right: 0.2rem solid ${({ theme }) => theme.listBox.button.color};
		content: '';
		display: block;
		height: 1.2rem;
		width: 1.2rem;
		pointer-events: none;
		position: absolute;
		right: 1.6rem;
		top: 50%;
		transform: translate(0, -65%) rotate(45deg);
	}

	${applyStyleModifiers(LIST_BOX_BUTTON_MODIFIERS)};
`;

export const ListBoxMenu = styled.div`
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
	background-color: ${({ theme }) => theme.listBox.menu.bg};
	border: 1px solid ${({ theme }) => theme.listBox.menu.border};
	border-radius: 0 0 0.4rem 0.4rem;
	max-height: 25rem;
	overflow-y: scroll;
	left: 0;
	position: absolute;
	top: 100%;
	width: 100%;
	z-index: 100;
`;

export const ListBoxOption = styled.p`
	white-space: normal;
	color: ${({ theme }) => theme.listBox.option.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	overflow-wrap: break-word;
	padding: 1.2rem 2.6rem;
	cursor: pointer;

	&[aria-selected='true'] {
		font-weight: ${({ theme }) => theme.font.bold};
		color: ${({ theme }) => theme.listBox.option.colorSelected};
	}

	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.listBox.option.border};
	}

	&:hover {
		color: ${({ theme }) => theme.listBox.option.colorHover};
		box-shadow: inset 0 0 0 1px
			${({ theme }) => theme.listBox.option.colorHover};
	}

	&:focus,
	&:focus-visible {
		${focusBasic}
		outline-offset: -0.3rem;
	}

	${applyStyleModifiers(LIST_BOX_OPTION_MODIFIERS)};
`;

export const ListBoxError = styled.p`
	display: block;
	margin-top: 0.4rem;
	margin-left: auto;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.listBox.error.color};
	text-align: right;
`;

export const ListBoxLabel = styled.p`
	position: absolute;
	pointer-events: none;
	z-index: 1;
	top: 50%;
	transform: translate(0, -50%) scale(1);
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	color: ${({ theme }) => theme.listBox.label.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	line-height: 1;
	left: 2rem;

	${({ isOpen, currentValue }) =>
		(isOpen || currentValue) && labelTransformBaseStyle};

	background-color: ${({ theme, backgroundColor }) =>
		backgroundColor ? theme[backgroundColor] : theme.listBox.label.bg};
`;

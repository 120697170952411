import React from 'react';
import { func, oneOfType, shape, instanceOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

// Import components
import { Button } from 'components/elements';

// Import icons
import { ReactComponent as SearchIcon } from 'assets/icons/ic_nav_search.svg';

// Import variables
import { SECTIONS_ID } from 'helpers/variables';

const { SEARCH } = SECTIONS_ID;

const SearchButton = ({ toggle, searchRef }) => {
	const { t } = useTranslation();

	const navItemTitle = t('common_header_nav_search');

	return (
		<Tooltip placement="bottom" title={navItemTitle}>
			<Button
				modifiers={['navIcon']}
				onClick={toggle}
				spaceRight="1rem"
				id={SEARCH}
				aria-label={navItemTitle}
				ref={searchRef}
			>
				<SearchIcon aria-hidden title={navItemTitle} />
			</Button>
		</Tooltip>
	);
};

SearchButton.propTypes = {
	toggle: func.isRequired,
	searchRef: oneOfType([func, shape({ current: instanceOf(Element) })])
};

export default SearchButton;

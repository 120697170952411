// Import utilities
import { useAgreementsStatus, useFetchAgreements } from 'components/utilities';

const useAgreements = ({
	queryKey,
	agreementsToFetch,
	useCache = true,
	initialAcceptedAgreements
}) => {
	const { agreements, isAgreementsError, isAgreementsLoading } =
		useFetchAgreements({
			queryKey,
			agreementsToFetch,
			useCache
		});

	const {
		isAllAgreementsAccepted,
		isObligatoryAgreementsAccepted,
		acceptedAgreementsVersions,
		acceptedAgreementsIds,
		toggleAgreement,
		toggleAllAgreements,
		setAcceptedAgreements,
		isCustomAgreementAccepted,
		setIsCustomAgreementAccepted
	} = useAgreementsStatus({ agreements, initialAcceptedAgreements });

	return {
		agreements,
		isAgreementsError,
		isAgreementsLoading,
		isAllAgreementsAccepted,
		isObligatoryAgreementsAccepted,
		acceptedAgreementsVersions,
		acceptedAgreementsIds,
		toggleAgreement,
		toggleAllAgreements,
		setAcceptedAgreements,
		isCustomAgreementAccepted,
		setIsCustomAgreementAccepted
	};
};

export default useAgreements;

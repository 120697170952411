import React from 'react';
import {
	arrayOf,
	number,
	string,
	shape,
	oneOfType,
	node,
	bool
} from 'prop-types';
import { useSelector } from 'react-redux';

// Import styles
import { SubNavigationList } from './styles';

// Import components
import SubNavItem from './SubNavItem';

const SubNavigation = ({ submenu, basePath, children, isOpened }) => {
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	return (
		<>
			{children}
			<SubNavigationList
				isOpened={isOpened}
				isSubmenu={!!submenu.length}
				isContrast={isContrast}
				role="list"
			>
				<SubNavItem isMainItem basePath={basePath} />

				{submenu?.map((sublink) => (
					<SubNavItem key={sublink.id} sublink={sublink} basePath={basePath} />
				))}
			</SubNavigationList>
		</>
	);
};

SubNavigation.propTypes = {
	submenu: arrayOf(
		shape({
			id: number.isRequired,
			name: string.isRequired,
			link: string.isRequired,
			model_id: string,
			model_type: string
		})
	).isRequired,
	children: oneOfType([node, arrayOf(node)]).isRequired,
	basePath: string.isRequired,
	isOpened: bool.isRequired
};

export default SubNavigation;

import { useEffect } from 'react';

// Import helpers
import { handleFormSubmit } from 'helpers/form';

export const useModalForm = ({
	form,
	closeModal,
	submitAction,
	isEnabled,
	validation,
	isOpen,
	isError
}) => {
	/*eslint-disable */
	useEffect(() => {
		const { resetForm, setSubmitting } = form.current;
		// Reset form if modal is closed
		!isOpen && resetForm();
		isError && setSubmitting(false);
	}, [isOpen, isError]);
	/*eslint-enable */

	const handleCloseModal = () => {
		// Close modal
		closeModal();
		// Reset form
		form.current.resetForm();
	};

	const submitFunction = async (values) =>
		await submitAction({
			values,
			isEnabled,
			callback: handleCloseModal
		});

	const handleModalFormSubmit = (values, { setErrors, setSubmitting }) =>
		handleFormSubmit({
			setErrors,
			setSubmitting,
			values,
			validation,
			onSubmit: submitFunction
		});

	return { handleModalFormSubmit };
};

import React from 'react';
import { MenuItem } from '@reach/menu-button';
import VisuallyHidden from '@reach/visually-hidden';
import PropTypes from 'prop-types';

// Import styles
import { Option } from './styles';

const SettingsItem = ({ isSelected, children, onSelect, ariaText }) => (
	<MenuItem
		title={ariaText}
		as={Option}
		onSelect={onSelect}
		isSelected={isSelected}
	>
		<VisuallyHidden>{ariaText}</VisuallyHidden>
		<span aria-hidden>{children}</span>
	</MenuItem>
);

SettingsItem.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
	onSelect: PropTypes.func.isRequired,
	ariaText: PropTypes.string.isRequired
};

export default SettingsItem;

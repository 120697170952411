import { useMemo } from 'react';

// import helpers
import { getTranslation, checkProductSubtype } from 'helpers';

const useProductTranslations = (product, isPlain = false) =>
	useMemo(() => {
		const { subtype, translations, context } = product;
		const { isEpisode } = checkProductSubtype(subtype);

		const translation =
			isEpisode && !isPlain ? context?.series?.translations : translations;
		const productTranslations = getTranslation(translation);

		return { ...product, ...productTranslations };
		//eslint-disable-next-line
	}, [product]);

export default useProductTranslations;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool } from 'prop-types';

//Import components
import DisabilityFontOptions from './DisabilityFontOptions';
import DisabilityNavIcon from './DisabilityNavIcon';

// Import styled components
import {
	Wrapper,
	StyledDisabilityIcon,
	StyledContrastIcon,
	StyledResetIcon,
	Text,
	Container,
	IconDivider
} from './styles';

// Import logic
import useDisabilityNav from './useDisabilityNav';

const DisabilityNav = ({ isMobile }) => {
	const { t } = useTranslation();

	const {
		animationIcon,
		animationIconAriaLabel,
		contrastIconAriaLabel,
		animationActionInfo,
		contrastActionInfo,
		fontChangeActionInfo,
		setFontSize,
		toggleAnimationsAvailability,
		toggleAppContrast,
		resetToDefaultSettings
	} = useDisabilityNav({ isMobile });

	return (
		<Wrapper>
			<Container>
				<StyledDisabilityIcon aria-hidden="true" />
				<Text>{t('common_header_accessibility')}</Text>
			</Container>
			<Container>
				<Text marginRight>{t('common_header_animations')}</Text>
				<DisabilityNavIcon
					ariaLabel={animationIconAriaLabel}
					toggleAction={toggleAnimationsAvailability}
					actionInfo={animationActionInfo}
				>
					{animationIcon}
				</DisabilityNavIcon>
				<IconDivider />
				<Text marginRight>{t('common_header_contrast')}</Text>
				<DisabilityNavIcon
					ariaLabel={contrastIconAriaLabel}
					toggleAction={toggleAppContrast}
					actionInfo={contrastActionInfo}
				>
					<StyledContrastIcon aria-hidden />
				</DisabilityNavIcon>
				<IconDivider />
				{!isMobile && (
					<>
						<DisabilityFontOptions
							setFontSize={setFontSize}
							actionInfo={fontChangeActionInfo}
						/>
						<IconDivider />
					</>
				)}
				<Text marginRight>{t('common_header_reset')}</Text>
				<DisabilityNavIcon
					ariaLabel={t('aria_header_reset')}
					toggleAction={resetToDefaultSettings}
				>
					<StyledResetIcon aria-hidden="true" />
				</DisabilityNavIcon>
			</Container>
		</Wrapper>
	);
};

DisabilityNav.propTypes = {
	isMobile: bool.isRequired
};

export default DisabilityNav;

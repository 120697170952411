import React from 'react';
import { bool } from 'prop-types';

// Import styles
import { Wrapper } from './styles';

// Import helpers
import { PLAYER_SELECTORS } from 'helpers/variables';

// Import components
import useFloatPlayer from './useFloatPlayer';
import PlayerWrapper from 'components/elements/organisms/player_vod/PlayerWrapper';
import { ErrorBoundary } from 'components/elements';

const FloatPlayer = ({ ...rest }) => {
	const { floatMode, playerModeProps, isAuth } = useFloatPlayer(rest);

	if (!isAuth) return null;

	return (
		<ErrorBoundary>
			<Wrapper {...floatMode}>
				<PlayerWrapper floatMode={floatMode} {...playerModeProps} />
			</Wrapper>
		</ErrorBoundary>
	);
};

FloatPlayer.defaultProps = {
	isCatchup: false,
	playerType: PLAYER_SELECTORS.DETAILS
};

FloatPlayer.propTypes = {
	isCatchup: bool
};

export default FloatPlayer;

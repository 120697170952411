import * as types from 'store/actions/types';
import services from 'services/services';
import { openNotification } from 'components/utilities/notification/notification';
import i18next from 'i18next';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';
import { getUrl } from './movie_list_actions_helper';
import { getQueries } from 'helpers';

/**
 * function responsible for fetching movies and series list and write it to store
 * @param {object} filter
 * @param {number} filter.offset
 * @param {number} filter.limit
 * @param {"title"|"title_org"|"rating"} filter.order
 * @param {"asc"|"desc"} filter.orderDir
 * @param {number|null} filter.genre
 * @param {function} dispatch
 * @param {boolean} clear
 */
export async function fetchMoviesList(
	sectionId = null,
	filter,
	dispatch,
	clear = false,
	isPacket = false
) {
	try {
		const { type, limit = 100 } = filter;
		dispatch({ type: types.MOVIES_LIST_LOADING });

		if (clear) dispatch({ type: types.MOVIES_LIST_CLEAR });

		const url = getUrl({ sectionId, isPacket, type });
		const queries = getQueries({ filter, url });

		const {
			data: { data: moviesSeriesList }
		} = await services.get(`${url}${queries}`);

		dispatch({
			type: types.MOVIES_LIST_SUCCESS,
			payload: moviesSeriesList,
			total: moviesSeriesList.length > 0 && moviesSeriesList.length >= limit - 1
		});
	} catch (error) {
		dispatch({ type: types.MOVIES_LIST_ERROR });

		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: i18next.t('vod_root_error_title'),
				description: i18next.t('vod_root_error_description')
			});
	}
}

export function updateSortName(sortName, dispatch) {
	if (sortName) {
		dispatch({ type: types.UPDATE_SORTING_NAME, payload: sortName });
	}
}

export function clearMoviesListData(dispatch) {
	dispatch({ type: types.MOVIES_LIST_CLEAR_DATA });
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import utilities
import { ToggleRPC, usePlayerShortcuts } from 'components/utilities';

// Import components
import {
	Loader,
	PlayerError,
	PlayerPip,
	BigPlayButton
} from 'components/elements';
import usePlayerContent from '../usePlayerContent';
import TopHeadline from 'components/elements/organisms/player_vod/PlayerWrapper/TopHeadline';
import Resume from 'components/elements/organisms/player_vod/PlayerWrapper/resume/Resume';
import NextEpisode from 'components/elements/organisms/player_vod/PlayerWrapper/next_episode/NextEpisode';
import Watermark from 'components/elements/organisms/player_vod/PlayerWrapper/Watermark';
import PlayerBottomBar from 'components/elements/organisms/player_vod/PlayerWrapper/PlayerBottomBar';
import CastReceiverName from 'components/elements/organisms/player_vod/PlayerWrapper/CastReceiverName';

// Import styles
import { BgGradient, Poster, PosterImg } from '../styles';

const ShakaPlayerContent = ({ type }) => {
	usePlayerShortcuts();
	const { t } = useTranslation();

	const {
		buffering,
		isReady,
		error,
		continueProgress,
		handlePlayPause,
		isFullScreen,
		setFullScreen,
		setEndMargin,
		showNextEpisode,
		showContinueProgress,
		showBigPlayButton,
		floatMode,
		isAudio,
		posterUrl,
		isFocus
	} = usePlayerContent({ type });

	const { isFloatMode } = floatMode;

	const [isFloatPlayerAudio, setIsFloatPlayerAudio] = useState(false);
	const [floatPlayerPosterUrl, setFloatPlayerPosterUrl] = useState('');

	const isPosterAvailable = isAudio && posterUrl;
	const isFloatPlayerPosterAvailable =
		isFloatPlayerAudio && floatPlayerPosterUrl;

	// Setting float player parameters for float player in product details page
	useEffect(() => {
		if (isFloatMode) {
			setIsFloatPlayerAudio(isPosterAvailable);
			setFloatPlayerPosterUrl(posterUrl);
		}
		//eslint-disable-next-line
	}, [isFloatMode]);

	const isPosterVisible = isFloatMode
		? isFloatPlayerPosterAvailable
		: isPosterAvailable;

	const url = isFloatMode ? floatPlayerPosterUrl : posterUrl;

	return (
		<>
			<BgGradient
				onDoubleClick={setFullScreen}
				onClick={handlePlayPause}
				isFocus={isFocus}
			/>

			{isPosterVisible && (
				<Poster>
					<PosterImg src={url} alt={t('common_poster_alternative_text')} />{' '}
				</Poster>
			)}

			{buffering && (
				<Loader background={false} position="absolute" zIndex={1} />
			)}

			<TopHeadline isError={error.isError} />

			<Watermark />

			<CastReceiverName />

			{isReady && (
				<PlayerBottomBar playerType={type} isFullScreen={isFullScreen} />
			)}

			{showBigPlayButton && (
				<BigPlayButton
					isFloatMode={isFloatMode && !isFullScreen}
					modifiers={['icon', 'catchup', 'playerIconHover', 'playerPlay']}
					onClick={handlePlayPause}
				/>
			)}

			{showContinueProgress && (
				<ToggleRPC initial={true}>
					{({ on, toggle }) =>
						on && <Resume currentTime={continueProgress.time} toggle={toggle} />
					}
				</ToggleRPC>
			)}

			{showNextEpisode && (
				<ToggleRPC initial={true}>
					{({ on, toggle }) =>
						on && <NextEpisode setEndMargin={setEndMargin} toggle={toggle} />
					}
				</ToggleRPC>
			)}

			<PlayerPip isReady={isReady} />

			{error.isError && <PlayerError error={error} />}
		</>
	);
};

ShakaPlayerContent.propTypes = {
	type: PropTypes.string.isRequired
};

export default ShakaPlayerContent;

// Import helpers
import {
	defaultLanguage,
	hasSeveralLanguages,
	projectlanguages
} from 'helpers/languages';

// Import variables
import { LOCAL_STORAGE_LANGUAGE } from 'helpers/variables';

// Import translations
import * as dictionary from 'translations/dictionary';

export const langResources = Object.entries(dictionary).reduce((acc, next) => {
	const [key, translation] = next;
	acc[key] = { translation };
	return acc;
}, {});

const checkIsLanguageAvailable = (language) =>
	projectlanguages.includes(language);

export const getUserLang = () => {
	// get language from search params
	const { searchParams } = new URL(document.location);
	const langFromParams = searchParams.get('lang');

	// get language from local storage
	const localStorageLang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

	let userLang = defaultLanguage;

	if (langFromParams) {
		userLang = langFromParams;
	} else if (!localStorageLang) {
		userLang = defaultLanguage;
	} else if (hasSeveralLanguages) {
		// check if local storage language is available in env project languages
		const isLangAvailable = checkIsLanguageAvailable(localStorageLang);
		userLang = isLangAvailable ? localStorageLang : defaultLanguage;
	}

	// set language to local storage
	localStorage.setItem(LOCAL_STORAGE_LANGUAGE, userLang);

	return userLang;
};

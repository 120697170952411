import React from 'react';
import { oneOfType, string, number } from 'prop-types';

// Import helpers
import { checkIsEnglishName } from './helpers';

// Import variables
import { LANGUAGES_TYPES } from 'helpers/variables';

// Import components
import { ProperName } from 'components/elements';

const { EN } = LANGUAGES_TYPES;

const ProperContent = ({ name }) => {
	const isEnglishName = checkIsEnglishName(name);

	return isEnglishName ? <ProperName text={name} lang={EN} /> : name;
};

ProperContent.propTypes = {
	name: oneOfType([string, number]).isRequired
};

export default ProperContent;

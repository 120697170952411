import * as types from 'store/actions/types';
import services from 'services/services';

// Import api
import { GET_INSTITUTIONS_LIST_API } from 'helpers/api';

// Import helpers
import { sortInstitutions } from 'helpers/institutions';

// ******************** FETCH INSTITUTIONS LIST ********************
export const fetchInstitutionsList = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_INSTITUTIONS_LIST_LOADING
		});

		const { data } = await services.get(GET_INSTITUTIONS_LIST_API);

		const institutions = sortInstitutions(data?.data);

		dispatch({
			type: types.FETCH_INSTITUTIONS_LIST_SUCCESS,
			payload: institutions
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_INSTITUTIONS_LIST_ERROR,
			payload: error.response
		});
	}
};

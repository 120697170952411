import React from 'react';
import ReactAriaModal from 'react-aria-modal';
import {
	oneOfType,
	arrayOf,
	node,
	string,
	object,
	func,
	bool
} from 'prop-types';

const AriaModal = ({
	children,
	underlayStyle,
	onExit,
	titleText,
	titleId,
	...restProps
}) => {
	const getApplicationNode = () => document.getElementById('root');

	return (
		<ReactAriaModal
			titleText={titleText}
			titleId={titleId}
			onExit={onExit}
			getApplicationNode={getApplicationNode}
			underlayStyle={underlayStyle}
			{...restProps}
		>
			{children}
		</ReactAriaModal>
	);
};

AriaModal.defaultProps = {
	underlayStyle: {},
	titleText: '',
	titleId: '',
	toggle: () => {},
	focusTrapPaused: false
};

AriaModal.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	underlayStyle: object,
	toggle: func.isRequired,
	titleText: string,
	titleId: string,
	dialogClass: string,
	underlayClass: string,
	onExit: func,
	focusTrapPaused: bool
};

export default AriaModal;

import { useSelector } from 'react-redux';

// Import themes
import { pureOttTheme } from 'components/theme/pure_ott_theme/pureOttTheme';
import { pureCkfTheme } from 'components/theme/pure_ckf_theme/pureCkfTheme';

// Import helpres
import { getFontSize } from 'helpers/wcag';
import { setFontSize } from 'components/theme/fonts';

// Import variables
import { THEME_NAMES } from 'helpers/variables';

const { PURE_OTT, OTT, TV_ONLINE, CKF } = THEME_NAMES;

/**
 * Custom hook that checks for default theme based on provided value or env value.
 * Default pure ott theme is selected
 * Switch values can be added based on specific project
 * @param {string} theme - custom theme name (for future developement)
 */
const useSelectTheme = (theme) => {
	const { fontSize, isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	let selectedTheme = pureOttTheme;
	const themeName = theme || process.env.REACT_APP_DEFAULT_THEME;

	switch (themeName) {
		case PURE_OTT:
		case OTT:
		case TV_ONLINE:
			selectedTheme = pureOttTheme;
			break;

		case CKF:
			selectedTheme = pureCkfTheme({ isContrast });
			break;

		default:
			selectedTheme = pureOttTheme;
			break;
	}

	const size = getFontSize(fontSize);
	const fontSizeOptions = setFontSize(size);
	selectedTheme.isWCAG = fontSize === 'default' ? false : true;

	return { ...selectedTheme, ...fontSizeOptions };
};

export default useSelectTheme;

import React from 'react';
import { string } from 'prop-types';

// Import components
import { SliderSection, Loader } from 'components/elements';

// Import logic
import usePacketSlider from './usePacketSlider';

const PacketSlider = ({ sliderTitle }) => {
	const { isLoading, products } = usePacketSlider();

	if (isLoading) return <Loader position="static" background={false} />;

	if (!products.length) return null;

	return (
		<SliderSection
			showAll={false}
			sliderTitle={sliderTitle}
			name={sliderTitle}
			content={products}
			modifiers="collection"
			id="packet_slider"
		/>
	);
};

PacketSlider.propTypes = {
	sliderTitle: string
};

export default PacketSlider;

import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch, useStore } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { deleteVideoSession } from 'store/actions';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';
import { usePlayerFocusContext } from 'components/context/player/focus';

// Import helpres
import { PLAYER_SELECTORS } from 'helpers/variables';
import { fetchMovieResources } from '../helpers';
import { getVideoEndStatus } from './helpers';
import { getImage } from 'components/views/vod/helpers/getImage';
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';

const { POSTER } = PRODUCT_IMAGES_TYPES;

// Set player type
const playerSelector = PLAYER_SELECTORS.VOD;

const usePlayerContent = ({ type }) => {
	const { continueProgress } = useSelector((state) => state.continue_watching);
	// Get data from state
	const {
		data,
		episodes: { next }
	} = useSelector((state) => state.movieDetails);

	const { error } = useSelector((state) => state[type]);
	const [scrollYPosition, setScrollYPosition] = React.useState(0);

	const store = useStore();
	const dispatch = useDispatch();
	const history = useHistory();
	const { type: productType } = useParams();

	// Get data from context
	const {
		isEnded,
		isReady,
		endMarginAction,
		endMarginActionCancel,
		buffering,
		play,
		pause,
		isPaused,
		isFullScreen,
		setFullScreen,
		setEndMargin,
		isPreview,
		closePreview,
		floatMode
	} = usePlayerVodContext();

	const { isFocus, focusFreeze, focusUnfreeze } = usePlayerFocusContext();

	// --------- Variables ----------------
	// Check if product is avaiable / purschased by user
	const isEpisodeAvaiable = next ? !!next.uuid : false;

	// check if product is an episode
	const isEpisode = data?.subtype === 'episode' ? true : false;

	const showNextEpisode =
		endMarginAction &&
		!endMarginActionCancel &&
		next &&
		isEpisode &&
		isEpisodeAvaiable;

	const showRecommendation = endMarginAction && !isPreview && !isEpisode;

	const showContinueProgress =
		isReady && !isPreview && continueProgress?.time >= 4;

	const showBigPlayButton = isReady && isPaused;

	const { isFloatMode, closeFloatMode } = floatMode;

	const posterUrl = getImage({ images: data?.images, type: POSTER });

	// --------- Handlers ----------------
	const handleFetchResources = async () => {
		// Delete video live session
		// Dispatch an deleteVideoSession action in player folder
		await deleteVideoSession({ type: playerSelector })(
			dispatch,
			store.getState
		);
		// Fetch movie resources
		await fetchMovieResources({ id: next.uuid, type: productType, dispatch });
	};

	const handlePlayPause = () => (isPaused ? play() : pause());

	const handleOnVideoEnd = () => {
		// Cehck if movie is ended, and product type is an episode and product is purschased by user
		const { isNextEpisode, isVideoEnded } = getVideoEndStatus({
			isEnded,
			isEpisode,
			isFloatMode,
			endMarginActionCancel,
			isEpisodeAvaiable
		});

		if (isEnded && isPreview) {
			closePreview();
		} else if (isEnded && isFloatMode) {
			closeFloatMode();
		} else if (isNextEpisode) {
			handleFetchResources();
		} else if (isVideoEnded) {
			history.goBack();
		}
	};

	// --------- Effects ----------------
	React.useEffect(() => {
		handleOnVideoEnd();
		// eslint-disable-next-line
	}, [isEnded, isFloatMode]);

	React.useEffect(() => {
		if (isFullScreen) {
			setScrollYPosition(window.scrollY);
		} else if (scrollYPosition) {
			window.scrollTo(0, scrollYPosition);
		}
		// eslint-disable-next-line
	}, [isFullScreen]);

	React.useEffect(() => {
		isPaused ? focusFreeze() : focusUnfreeze();
		// eslint-disable-next-line
	}, [isPaused]);

	return {
		buffering,
		isReady,
		error,
		continueProgress,
		handlePlayPause,
		isFullScreen,
		setFullScreen,
		setEndMargin,
		showNextEpisode,
		showRecommendation,
		showContinueProgress,
		showBigPlayButton,
		floatMode,
		isAudio: data?.is_audio,
		posterUrl,
		isFocus
	};
};

export default usePlayerContent;

import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

// Import utilities
import { useInterval, useDelayFocus } from 'components/utilities';

// Import helpers
import { handleSlickSliderArrowAriaUpdate } from 'helpers/wcag';

const INITIAL_DELAY = 10000;

const useCarousel = ({ content, slideTransitionTime = 0 }) => {
	const prevArrowRef = useRef(null);
	const nextArrowRef = useRef(null);
	const { areAnimationsAvailable, isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);
	const { delayFocus } = useDelayFocus();

	const initDelay = areAnimationsAvailable ? INITIAL_DELAY : null;

	// ---- component state ----
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isPreviewOpened, setIsPreviewOpened] = useState(false);
	const [delay, setDelay] = useState(initDelay);

	// ---- variables ----
	const currentItem = content[currentIndex];

	// ---- handlers ----
	const handleStartStopCarousel = ({ isOpened = false }) => {
		setIsPreviewOpened(isOpened);
		// run interval if preview player is closed and user didn't trun off animations
		const runInterval = !isOpened && areAnimationsAvailable;
		// set interval delay
		const delay = runInterval ? INITIAL_DELAY : null;
		setDelay(delay);
	};

	const handleSetNextElement = () => {
		let nextIndex = currentIndex + 1;
		// check if array of elements is ended
		const isEndOfArray = nextIndex === content.length;
		// if array is ended set nextItem as first array item
		const nextItem = isEndOfArray ? 0 : nextIndex;
		// set current index
		setCurrentIndex(nextItem);
	};

	const handleChangeCurrentIndex = (index) => {
		const isFirstSlide = index === 0;
		const isLastSlide = index === content.length - 1;
		const delay = slideTransitionTime + 10;

		if (isFirstSlide && nextArrowRef.current) {
			delayFocus(nextArrowRef.current, delay);
		}

		if (isLastSlide && prevArrowRef.current) {
			delayFocus(prevArrowRef.current, delay);
		}

		// stop interval
		setDelay(null);
		// change index
		setCurrentIndex(index);
	};

	const handleArrowAriaUpdate = ({ prev, next }) =>
		handleSlickSliderArrowAriaUpdate({ prev, next });

	// ---- effects ----
	useEffect(() => {
		if (!areAnimationsAvailable) {
			// stop interval if user turn off animations
			setDelay(null);
		} else if (!delay && !isPreviewOpened) {
			// run interval if preview player is closed and user didn't trun off animations
			setDelay(INITIAL_DELAY);
		}
		// eslint-disable-next-line
	}, [delay, areAnimationsAvailable]);

	// ---- hook to manage the interval ----
	useInterval(handleSetNextElement, delay);

	return {
		currentIndex,
		currentItem,
		prevArrowRef,
		nextArrowRef,
		isContrast,
		changeCurrentIndex: handleChangeCurrentIndex,
		startStopCarousel: handleStartStopCarousel,
		handleArrowAriaUpdate
	};
};

export default useCarousel;

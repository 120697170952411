import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Import helpers
import { PLAYER_ERROR_TYPES } from 'helpers/player';
import { checkPlayerType } from 'helpers';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import components
import { PlayerElements as Player } from 'components/elements';
import ParentalControlButton from './ParentalControlButton';
import Packets from './Packets';

const { BLOCKED_BY_PARENTAL_CONTROL, RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS } =
	PLAYER_ERROR_TYPES;

const PlayerError = ({ error: { message, errorCode = '' }, playerType }) => {
	const { pathname } = useLocation();
	const type = playerType || checkPlayerType(pathname);
	const {
		floatMode: { isFloatMode }
	} = usePlayerVodContext();

	return (
		<Player.Error isFloatMode={isFloatMode}>
			<Player.ErrorContent>
				<Player.IconLock role="img" />
				<Player.ErrorHeading isFloatMode={isFloatMode} role="alert">
					{message}
				</Player.ErrorHeading>
				{errorCode === BLOCKED_BY_PARENTAL_CONTROL && (
					<ParentalControlButton playerType={type} />
				)}
				{errorCode === RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS && <Packets />}
			</Player.ErrorContent>
		</Player.Error>
	);
};

PlayerError.propTypes = {
	error: PropTypes.shape({
		message: PropTypes.string,
		errorCode: PropTypes.string
	}).isRequired,
	playerType: PropTypes.string
};

export default PlayerError;

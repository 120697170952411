import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Import components
import { Paragraph } from 'components/elements';

// Import styles
import { flex, transitionMain, focusBasic } from 'components/styles';

export const Preview = styled.div`
	height: 7rem;
	width: 10rem;
	overflow: hidden;
	${flex()}
`;

export const Image = styled.img`
	max-width: 100%;
	height: auto;
	max-height: 100%;
	width: auto;
`;

export const Content = styled.div`
	flex: 1;
	overflow: hidden;
	padding-left: 2rem;
`;

export const ParagraphStyled = styled(Paragraph.Ellipsis)`
	color: ${({ theme }) => theme.grayMedium};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.1)}rem;
	${transitionMain}
`;

export const LinkStyled = styled(Link)`
	&:link,
	&:visited {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		padding: 1rem 1.5rem;
		${transitionMain}
	}

	&:hover,
	&:active {
		background: ${({ theme: { searchBox } }) => searchBox.itemHoverBg};

		${ParagraphStyled} {
			color: ${({ theme: { searchBox } }) => searchBox.itemTitleHover};
		}
	}

	&:focus-visible {
		${focusBasic}
		outline-offset: -0.3rem;
		${ParagraphStyled} {
			color: ${({ theme: { searchBox } }) => searchBox.itemTitleHover};
		}
	}
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { string, func, array, bool } from 'prop-types';

// Import components
import { Loader } from 'components/elements';
import Agreement from './Agreement';
import { CommonButton } from 'components/views/vod/components/buttons';

// Import styles
import { Title, Description, ButtonsWrapper } from './styles';

// Import helpers
import { customHtmlParser } from 'helpers';

const UserApproval = ({
	headerText,
	descriptionText,
	agreements,
	toggleAgreement,
	acceptedAgreementsIds,
	isAgreementsLoading,
	onAcceptChecked,
	onAcceptAll,
	isDisabled,
	titleId
}) => {
	const { t } = useTranslation();

	return isAgreementsLoading ? (
		<Loader background={false} position="absolute" zIndex={1} />
	) : (
		<>
			{headerText && <Title id={titleId}>{headerText}</Title>}
			{descriptionText && (
				<Description>{customHtmlParser(descriptionText)}</Description>
			)}
			{/* eslint-disable-next-line */}
			<ul role="list">
				{agreements?.map((agreement) => (
					<Agreement
						key={agreement.id}
						toggleAgreement={toggleAgreement}
						acceptedAgreementsIds={acceptedAgreementsIds}
						{...agreement}
					/>
				))}
			</ul>

			<ButtonsWrapper>
				<CommonButton onClick={onAcceptChecked} isDisabled={isDisabled}>
					{t('agreements_modal_accept')}
				</CommonButton>
				<CommonButton onClick={onAcceptAll} isPrime>
					{t('agreements_modal_accept_all')}
				</CommonButton>
			</ButtonsWrapper>
		</>
	);
};

UserApproval.propTypes = {
	headerText: string,
	descriptionText: string,
	agreements: array.isRequired,
	toggleAgreement: func.isRequired,
	acceptedAgreementsIds: array.isRequired,
	isAgreementsLoading: bool,
	onAcceptChecked: func.isRequired,
	onAcceptAll: func.isRequired,
	isDisabled: bool,
	titleId: string
};

export default UserApproval;

import { useSelector } from 'react-redux';
import {
	useParams,
	useHistory,
	generatePath,
	useRouteMatch
} from 'react-router-dom';
import { useCallback } from 'react';

// Import select options
import { ALL_INSTITUTIONS } from './select_options';

// Import paths
import { MAIN_PATHS, VOD_PATHS } from 'components/routes/paths';

const { SECTION_DETAIL_PAGE, CATEGORY_PAGE } = VOD_PATHS;
const { SEARCH } = MAIN_PATHS;

function useInstitutionSelect() {
	const history = useHistory();
	const params = useParams();
	const { institutions } = useSelector(({ institutions }) => institutions);
	const match = useRouteMatch([SECTION_DETAIL_PAGE, CATEGORY_PAGE, SEARCH]);

	const handleSelectChange = useCallback(
		(id) => {
			if (match) {
				const institutionUuid = id === ALL_INSTITUTIONS.uuid ? null : id;
				const path = generatePath(match.path, { ...params, institutionUuid });
				history.replace(`${path}${history.location.search}`);
			}
		},
		// eslint-disable-next-line
		[history.location.pathname]
	);

	const institution =
		institutions.find(({ uuid }) => uuid === params.institutionUuid) ??
		ALL_INSTITUTIONS;

	const institutionsList = [ALL_INSTITUTIONS, ...institutions];

	const forceFocus = history.action === 'REPLACE';

	return {
		institutionsList,
		selectedValue: institution?.uuid,
		selectedInstitutionName: institution?.name,
		forceFocus,
		selectChange: handleSelectChange
	};
}

export default useInstitutionSelect;

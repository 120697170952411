import { useContext } from 'react';

// Import helpers
import { defaultContext } from './helpers';

// Import context
import { PassesContext } from './PassesProvider';

function usePassesContext() {
	const context = useContext(PassesContext);
	return context ?? defaultContext;
}

export default usePassesContext;

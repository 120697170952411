import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

export const StyledFieldsetContainer = styled.fieldset`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: 2rem;
	grid-row-gap: 1.5rem;
	margin-bottom: ${({ theme }) => (theme.isWCAG ? 10 : 3)}rem;

	${customMinResponsive(
		size.small,
		css`
			grid-template-columns: repeat(2, 1fr);
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			grid-template-columns: ${({ theme }) =>
				theme.isWCAG ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'};
			grid-row-gap: ${({ theme }) => (theme.isWCAG ? 8 : 13)}rem;
		`
	)}
`;

export const FILTER_SELECT_OPTIONS = [
	{
		name: 'vod_section_details_filters_default',
		query: { order: null, orderDir: null }
	},
	{
		name: 'vod_section_details_filters_title_asc',
		query: { order: 'title', orderDir: 'asc' }
	},
	{
		name: 'vod_section_details_filters_title_desc',
		query: { order: 'title', orderDir: 'desc' }
	},
	{
		name: 'vod_section_details_filters_year_asc',
		query: { order: 'year', orderDir: 'asc' }
	},
	{
		name: 'vod_section_details_filters_year_desc',
		query: { order: 'year', orderDir: 'desc' }
	},
	{
		name: 'vod_section_details_filters_created_at_asc',
		query: { order: 'created_at', orderDir: 'asc' }
	},
	{
		name: 'vod_section_details_filters_created_at_desc',
		query: { order: 'created_at', orderDir: 'desc' }
	},
	{
		name: 'vod_section_details_filters_duration_asc',
		query: { order: 'duration', orderDir: 'asc' }
	},
	{
		name: 'vod_section_details_filters_duration_desc',
		query: { order: 'duration', orderDir: 'desc' }
	},
	{
		name: 'vod_section_details_filters_view_count_asc',
		query: { order: 'view_count', orderDir: 'asc' }
	},
	{
		name: 'vod_section_details_filters_view_count_desc',
		query: { order: 'view_count', orderDir: 'desc' }
	}
];

export const SEARCH_FILTER_SELECT_OPTIONS = [
	{
		name: 'vod_section_details_filters_default',
		query: { order: null, orderDir: null }
	},
	{
		name: 'vod_section_details_filters_title_asc',
		query: { order: 'title', orderDir: 'asc' }
	},
	{
		name: 'vod_section_details_filters_title_desc',
		query: { order: 'title', orderDir: 'desc' }
	},
	{
		name: 'vod_section_details_filters_created_at_asc',
		query: { order: 'created_at', orderDir: 'asc' }
	},
	{
		name: 'vod_section_details_filters_created_at_desc',
		query: { order: 'created_at', orderDir: 'desc' }
	},
	{
		name: 'vod_section_details_filters_duration_asc',
		query: { order: 'duration', orderDir: 'asc' }
	},
	{
		name: 'vod_section_details_filters_duration_desc',
		query: { order: 'duration', orderDir: 'desc' }
	},
	{
		name: 'vod_section_details_filters_view_count_asc',
		query: { order: 'view_count', orderDir: 'asc' }
	},
	{
		name: 'vod_section_details_filters_view_count_desc',
		query: { order: 'view_count', orderDir: 'desc' }
	}
];

import React from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

// Import styled components
import { StyledList, StyledNav } from './styles';

// Import helpers
import { checkIsLoginPage, getTabNavList } from './helpers';

const TabNav = () => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const isLoginPage = checkIsLoginPage(pathname);

	const tabNavList = getTabNavList(isLoginPage);

	const renderLinks = tabNavList.map(({ href, text }) => (
		<li key={text}>
			<a href={href}>{t(text)}</a>
		</li>
	));

	return (
		<StyledNav>
			<StyledList role="list">{renderLinks}</StyledList>
		</StyledNav>
	);
};

export default TabNav;

import React, { forwardRef } from 'react';
import { string, func, number, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styled components
import { ArrowController } from './styles';

// Import assets
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow_xl_l_contrast.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow_xl_r_contrast.svg';

// Import helpers
import { SLIDER_ARROW_TYPES } from 'helpers/variables';

const { PREV } = SLIDER_ARROW_TYPES;

const SliderArrow = forwardRef(
	(
		{
			type,
			name,
			currentSlide,
			changeCurrentIndex,
			onClick,
			isSliderSection = false,
			...rest
		},
		ref
	) => {
		const { t } = useTranslation();

		const isPrevArrow = type === PREV;

		const ariaLabel = name
			? `${t(`aria_slider_arrow_button_${type}`)}: ${name}`
			: t(`aria_slider_arrow_button_${type}`);

		const handleSlideChange = () => {
			// Condition to trigger library native click event only
			if (isSliderSection) return onClick();

			const slideIndex = isPrevArrow ? currentSlide - 1 : currentSlide + 1;
			changeCurrentIndex(slideIndex);
			onClick();
		};

		return (
			<ArrowController
				isPrevArrow={isPrevArrow}
				{...rest}
				onClick={handleSlideChange}
				aria-label={ariaLabel}
				ref={ref}
			>
				{isPrevArrow ? (
					<IconArrowLeft className="slider-arrow-contrast" />
				) : (
					<IconArrowRight className="slider-arrow-contrast" />
				)}
			</ArrowController>
		);
	}
);

SliderArrow.propTypes = {
	type: string.isRequired,
	name: string,
	changeCurrentIndex: func,
	onClick: func,
	currentSlide: number,
	isSliderSection: bool
};

export default SliderArrow;

import React from 'react';
import { number, object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Import logic
import useEpisodeItem from './useEpisodeItem';

// Import components
import SlideImage from '../BasicSlide/SlideImage';
import { Spacer } from 'components/elements';

// Import styles
import {
	StyledButton,
	Title,
	AvailabilityDate
} from 'components/elements/molecules/slider/SliderItem/styles';

const EpisodeItem = ({
	slideWidth,
	slideHeight,
	spaceBetweenSlides,
	product,
	isActive
}) => {
	const { t } = useTranslation();

	const {
		episodeCover,
		linkParams,
		title,
		metadata,
		availablilityDate,
		showAvailabilityDate
	} = useEpisodeItem({ product });

	return (
		<StyledButton
			as={Link}
			to={linkParams}
			marginright={spaceBetweenSlides}
			width={slideWidth}
			height={slideHeight}
			aria-label={title}
			tabIndex={isActive ? null : '-1'}
			aria-hidden={isActive ? null : true}
		>
			<SlideImage slideHeight={slideHeight} src={episodeCover} />

			{title && (
				<Title>
					<Spacer elements={[metadata, title]} />
				</Title>
			)}

			{showAvailabilityDate && (
				<AvailabilityDate>
					{t('vod_product_available_from')} {availablilityDate}
				</AvailabilityDate>
			)}
		</StyledButton>
	);
};

EpisodeItem.defaultProps = {
	setSelected: () => {}
};

EpisodeItem.propTypes = {
	slideWidth: number.isRequired,
	slideHeight: number.isRequired,
	spaceBetweenSlides: number.isRequired,
	product: object.isRequired,
	isActive: bool
};

export default EpisodeItem;

import React, { useState } from 'react';
import { object, string, shape, node } from 'prop-types';

// Import components
import { Input } from 'components/elements';

// Import styles
import { FormError, BottomWrapper } from '../styles';
import { Wrapper } from './styles';

const VoucherField = ({
	field,
	form: { touched, errors },
	placeholder,
	children,
	...rest
}) => {
	const [isFocused, setIsFocus] = useState(false);

	const { name, onBlur } = field;
	const isError = touched[name] && errors[name];
	const errorText = errors[name] || '';

	const handleFocus = () => setIsFocus(true);

	const handleBlur = () => {
		onBlur();
		setIsFocus(false);
	};

	return (
		<>
			<Wrapper isError={isError} isFocused={isFocused}>
				<Input
					id={name}
					aria-required="true"
					aria-invalid={isError}
					aria-labelledby={`${name}-alert`}
					placeholder={placeholder}
					modifiers="voucher"
					autoComplete="off"
					required
					{...field}
					{...rest}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
				{children}
			</Wrapper>
			<BottomWrapper>
				<FormError isErrorVisible={isError} id={`${name}-alert`}>
					{errorText}
				</FormError>
			</BottomWrapper>
		</>
	);
};

VoucherField.defaultProps = {
	type: 'text',
	autoComplete: 'false'
};

VoucherField.propTypes = {
	type: string.isRequired,
	field: object.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	placeholder: string.isRequired,
	autoComplete: string,
	children: node
};

export default VoucherField;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'antd';

// Import icons
import { ReactComponent as BellIcon } from 'assets/icons/new_ic_bell.svg';

// Import styles
import { flex } from 'components/styles';

// Import components
import { Button } from 'components/elements';
import { RemindersMenu } from '../../common/RemindersMenu';
import { ProgramRecordings } from 'components/elements';
import Share from './share/Share';

// Import actions
import { deleteReminder } from 'store/actions';

// Import helpers
import { checkAppType } from 'helpers';

const PopoverToolbox = ({
	reminderId,
	programId,
	isUpcoming = false,
	theme,
	startDate
}) => {
	const { data } = useSelector(({ devices }) => devices);
	const dispatch = useDispatch();
	const { isTvSmart } = checkAppType();

	const recordingConditions = data.length > 0 && !isTvSmart;

	return (
		<ToolBox>
			{isUpcoming && (
				<Box>
					{reminderId ? (
						<Button onClick={() => deleteReminder(reminderId)(dispatch)}>
							<BellIcon stroke={theme.popover.iconActive} />
						</Button>
					) : (
						<Dropdown
							trigger={['hover']}
							overlay={() => (
								<RemindersMenu programId={programId} startDate={startDate} />
							)}
						>
							<Button>
								<BellIcon stroke={theme.popover.icon} />
							</Button>
						</Dropdown>
					)}
				</Box>
			)}

			{recordingConditions && (
				<Box>
					<ProgramRecordings programId={programId} />
				</Box>
			)}
			<Box>
				<Share />
			</Box>
		</ToolBox>
	);
};

const ToolBox = styled.div`
	height: 7rem;
	display: flex;
	justify-content: space-between;
`;

const Box = styled.div`
	flex: 1;
	${flex()}

	&:nth-child(2) {
		border-left: 1px solid ${({ theme: { popover } }) => popover.border};
		border-right: 1px solid ${({ theme: { popover } }) => popover.border};
	}
	&:last-child {
		border-right: 1px solid transparent;
	}

	svg {
		height: 3rem;
		width: 3rem;
	}

	${({ isCatchup }) =>
		isCatchup &&
		css`
			svg {
				g,
				path {
					fill: ${({ theme }) => theme.orange};
				}
			}
		`}
`;

PopoverToolbox.propTypes = {
	isUpcoming: PropTypes.bool.isRequired,
	programId: PropTypes.string.isRequired,
	reminderId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])])
};

export default withTheme(PopoverToolbox);

import React, { Children } from 'react';
import { arrayOf, oneOfType, object, element } from 'prop-types';
import { useMediaQuery } from 'react-responsive';

// Import helpers
import { SLIDER_ARROW_TYPES } from 'helpers/variables';
import { getPassSliderSettings } from './helpers';

// Import components
import { PassSliderWrapper, PassSlider, PassesGrid } from './styles';
import PassArrow from './PassArrow';

const { PREV, NEXT } = SLIDER_ARROW_TYPES;

const PassCarousel = ({ children }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
	const passSliderSettings = getPassSliderSettings({ isMobile });

	const toShow = passSliderSettings?.slidesToShow ?? 3;
	const passes = Children.count(children);

	if (passes <= toShow) {
		return (
			<PassesGrid role="list" isMobile={isMobile}>
				{children}
			</PassesGrid>
		);
	}
	return (
		<PassSliderWrapper isMobile={isMobile}>
			<PassSlider
				{...passSliderSettings}
				prevArrow={<PassArrow type={PREV} />}
				nextArrow={<PassArrow type={NEXT} />}
				toShow={toShow}
			>
				{children}
			</PassSlider>
		</PassSliderWrapper>
	);
};

PassCarousel.propTypes = {
	children: arrayOf(oneOfType([object, element]))
};

export default PassCarousel;

import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { array, string, bool } from 'prop-types';

// Import styles
import { Table } from './styles';

// Import Components
import { TableBody, TableHead } from './components';

const ReactTable = ({
	tableColumns,
	tableData,
	className,
	isHeadVisible,
	ariaLabel
}) => {
	// eslint-disable-next-line
	const columns = useMemo(() => tableColumns, []);
	// eslint-disable-next-line
	const data = useMemo(() => tableData, [tableData]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data });

	return (
		<Table
			className={`blueonline-table ${className}`}
			{...getTableProps()}
			aria-label={ariaLabel}
		>
			{isHeadVisible && <TableHead headerGroups={headerGroups} />}
			<TableBody
				getTableBodyProps={getTableBodyProps}
				rows={rows}
				prepareRow={prepareRow}
			/>
		</Table>
	);
};

ReactTable.defaultProps = {
	isHeadVisible: true
};

ReactTable.propTypes = {
	tableColumns: array.isRequired,
	tableData: array,
	className: string,
	isHeadVisible: bool,
	ariaLabel: string.isRequired
};

export default ReactTable;

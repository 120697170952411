import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, string } from 'prop-types';

// Import components
import { AriaBaseModal, AriaModalContent } from 'components/elements';
import { CommonButton } from 'components/views/vod/components/buttons';

// Import utilities
import { useToggle } from 'components/utilities';

const InfoModal = ({ onToggle, title, description }) => {
	const { t } = useTranslation();
	const [isOpened, toggle] = useToggle(true);

	const handleToogle = () => {
		onToggle();
		toggle();
	};

	const titleId = 'info_modal_title';

	if (!isOpened) return null;

	return (
		<AriaBaseModal
			close={handleToogle}
			style={{ width: '35vw', minWidth: '28rem' }}
			titleId={titleId}
		>
			<AriaModalContent
				title={title}
				titleId={titleId}
				description={description}
			>
				<CommonButton onClick={handleToogle}>
					{t('common_modal_close_button_title')}
				</CommonButton>
			</AriaModalContent>
		</AriaBaseModal>
	);
};

InfoModal.defaultProps = {
	onToggle: () => {},
	title: '',
	description: ''
};

InfoModal.propTypes = {
	onToggle: func,
	title: string.isRequired,
	description: string.isRequired
};

export default InfoModal;

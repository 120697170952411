import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { getProductDetailsApi } from 'helpers';

// Import api
import { QUERY_PRODUCT_DETAILS } from 'helpers/api';

const useProductDetailsQuery = ({
	uuid,
	type,
	onSuccess = () => {},
	onError = () => {},
	enabled = true
} = {}) => {
	const queryKey = `${QUERY_PRODUCT_DETAILS}_${uuid}`;

	const queryFn = () => {
		const url = getProductDetailsApi({ id: uuid, type });
		return services.get(url);
	};

	const { data, status, isLoading, isFetching, isError, remove } = useQuery({
		queryKey,
		queryFn,
		onSuccess,
		onError,
		enabled
	});

	useEffect(() => {
		return () => remove();
		//eslint-disable-next-line
	}, []);

	const productDetails = data?.data;

	return {
		productDetails,
		productDetailsStatus: status,
		isProductDetailsLoading: isLoading || isFetching,
		isProductDetailsError: isError
	};
};

export default useProductDetailsQuery;
